import { postAuth } from "../../../../global/globalUtils";

export const requestChannel = async (channels, userId) => {
  try {
    if (channels.length === 0) {
      return {
        success: true,
        message: "No channels to process",
      };
    }

    const response = await fetch(
      "https://python.data.teledeck.news/verify-channels",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          channels,
          userId,
        }),
      }
    );

    const results = await response.json();

    if (!response.ok) {
      return {
        success: false,
        message: "An error occurred while processing the requests",
        error: results.message,
      };
    }

    return {
      success: true,
      message: "All requests processed successfully",
      details: results,
    };
  } catch (error) {
    console.error("Channel request error:", error);
    return {
      success: false,
      message: "An error occurred while processing the requests",
      error: error.message,
    };
  }
};

export const requestChannelRecent = async (channels) => {
  console.log("facebook", channels);
  try {
    if (channels.length === 0) {
      return {
        success: true,
        message: "No channels to process",
      };
    }

    const response = await postAuth(`/channel/request`, {
      channels,
    });
    const data = await response.json();

    if (response.ok) {
      return {
        success: true,
        message: "All requests processed successfully",
        details: data,
      };
    }

    return {
      success: false,
      message: "An error occurred while processing the requests",
    };
  } catch (error) {
    console.error("Channel request error:", error);
    return {
      success: false,
      message: "An error occurred while processing the requests",
      error: error.message,
    };
  }
};
