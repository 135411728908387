import React, { createContext, useState, useContext } from "react";

const CountsContext = createContext();

const CountsProvider = ({ children }) => {
  const [postCount, setPostCount] = useState({
    telegram: 0,
    twitter: 0,
    facebook: 0,
  });
  const [channelCount, setChannelCount] = useState({
    telegram: 0,
    twitter: 0,
    facebook: 0,
  });

  return (
    <CountsContext.Provider
      value={{
        postCount,
        setPostCount,
        channelCount,
        setChannelCount,
      }}
    >
      {children}
    </CountsContext.Provider>
  );
};

// Custom hook to use the FiltersContext
const useCounts = () => {
  const context = useContext(CountsContext);
  if (!context) {
    throw new Error("useCounts must be used within a FiltersProvider");
  }
  return context;
};

export { CountsContext, CountsProvider, useCounts };
