import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  CircularProgress,
} from "@mui/material";
import { formatDate, regenerateCode } from "./utils";
import { useTranslation } from "../../../../components/translate/TranslateContext";

const CodePopup = ({ open, onClose, code }) => {
  const [codeData, setCodeData] = useState(code);
  const [loading, setLoading] = useState(false);
  const { translate } = useTranslation();

  useEffect(() => {
    setCodeData(code);
  }, [code]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{translate("Organization Code")}</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translate("Organization Code")}</TableCell>
                <TableCell>{translate("Code Expires At")}</TableCell>
                <TableCell>{translate("Code Last Regenerated At")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{codeData?.code || "N/A"}</TableCell>
                <TableCell>
                  {formatDate(codeData?.code_expiry) || "N/A"}
                </TableCell>
                <TableCell>
                  {formatDate(codeData?.regenerated_at) || "N/A"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        {/* Button for regenerating the code */}
        <Button
          onClick={() => {
            regenerateCode(code?.organization_id, setCodeData, setLoading);
          }}
          color="primary"
          disabled={loading}
          variant="contained"
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            translate("Regenerate Code")
          )}
        </Button>

        {/* Close Button */}
        <Button onClick={onClose} color="primary">
          {translate("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CodePopup;
