import { DateTime } from "luxon";

// In your utils/dateUtils.js
export const formatDateForAPI = (date) => {
  // If date is a string (from datetime-local input), parse it
  const dateTime =
    typeof date === "string"
      ? DateTime.fromISO(date)
      : DateTime.fromJSDate(date);

  if (!dateTime.isValid) {
    console.error("Invalid date:", date);
    return null;
  }

  // Convert to UTC+2 and format with timezone indicator
  return dateTime.setZone("UTC+2").toFormat("yyyy-MM-dd'T'HH:mm:ss'+02:00'");
};

// Format datetime for the datetime-local input
export const formatDateTimeForInput = (date) => {
  const dateTime = DateTime.fromISO(date);
  // Format as YYYY-MM-DDTHH:mm (format required by datetime-local input)
  return dateTime.toFormat("yyyy-MM-dd'T'HH:mm");
};

// Get current time in UTC+2
export const getCurrentUTC2Time = () => {
  return DateTime.now()
    .setZone("UTC+2")
    .toFormat("yyyy-MM-dd'T'HH:mm:ss'+02:00'");
};
