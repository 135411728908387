export const mergeAllPosts = (editorRef, filteredPosts, translate) => {
  if (editorRef.current) {
    const currentDate = new Date().toLocaleDateString();
    // Center align the title and set text direction to RTL
    const mergedTitle = `
      <h1 style="text-align: center;">${translate(
        "My Merged Bookmarks"
      )} - ${currentDate}</h1>
      <p style="margin-bottom: 20px;"></p>
    `;

    const mergedContent = filteredPosts
      .map((post) => {
        const postContent = post.text || "No content";
        const postDate = new Date(post.timestamp).toLocaleString(); // Format post timestamp

        return `
          <h3 style="text-align: end;">${post.chat_title}:</h3>
          <p style="text-align: end;">${postContent}</p>
          <small style="text-align: end;">Date: ${postDate}</small>
          <p style="margin-bottom: 20px;"></p> <!-- This adds a line break between posts -->
          <hr style="margin-bottom: 20px;" /> <!-- Use hr for horizontal line with margin -->
        `;
      })
      .join(""); // Concatenate all posts

    const finalContent = `
      <div style="text-align: end;">${mergedTitle}${mergedContent}</div>
    `; // Set the overall alignment to RTL

    // Insert the content into TinyMCE editor
    editorRef.current.setContent(finalContent);
  }
};

export const handleTextInput = (textInput) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = textInput;

  const imgTag = tempDiv.querySelector("img");
  const mediaUrl = imgTag ? imgTag.src : "";

  let cleanedInput = tempDiv.textContent || tempDiv.innerText || "";

  cleanedInput = cleanedInput.replace(/[\u200B-\u200D\uFEFF]/g, "");
  cleanedInput = cleanedInput.trim();

  const result = mediaUrl ? `${cleanedInput}\n${mediaUrl}` : cleanedInput;

  return result;
};

export const handleCopyToClipboard = (postText, setIsCopied) => {
  navigator.clipboard
    .writeText(postText)
    .then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false); // Reset the icon after 2000 milliseconds
      }, 2000);
    })
    .catch((err) => {
      console.error("Failed to copy text to clipboard", err);
    });
};
