import {
  Card,
  CardContent,
  Box,
  Typography,
  styled,
  useTheme,
  Skeleton,
  Fade,
} from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import TelegramIcon from "@mui/icons-material/Telegram";
import { formatNumber } from "../Index";
import X from "../twitter.svg";
import { FacebookOutlined } from "@mui/icons-material";
import { CircleSkeleton, LoadingOverlay } from "./ChannelDistribution";
import { useTranslation } from "../../translate/TranslateContext";

const CardContentWrapper = styled(CardContent)(
  ({ theme }) => `
     padding: ${theme.spacing(2.5, 3, 3)};
     &:last-child {
       padding-bottom: ${theme.spacing(3)};
     }
`
);

const StatsWrapper = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${theme.spacing(2)};
    gap: ${theme.spacing(2)};
`
);

const StatBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    padding: ${theme.spacing(1.5)};
    border-radius: ${theme.general.borderRadius}px;
    background: ${theme.colors.alpha.black[5]};
`
);

function PostDistribution({ count, loading }) {
  const theme = useTheme();
  const { translate, direction } = useTranslation();

  const totalPosts = loading
    ? 0
    : count.telegram + count.twitter + count.facebook;

  const data = loading
    ? [{ name: "Loading", value: 1, color: theme.palette.grey[300] }]
    : [
        {
          name: "Telegram",
          value: count.telegram,
          color: theme.palette.primary.main,
        },
        {
          name: "Twitter",
          value: count.twitter,
          color: theme.palette.secondary.main,
        },
        {
          name: "Facebook",
          value: count.facebook,
          color: theme.palette.secondary.main,
        },
      ];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 2,
            boxShadow: theme.shadows[3],
            borderRadius: 1,
          }}
        >
          <Typography variant="body1" color="text.primary">
            {payload[0].name}
          </Typography>
          <Typography variant="h6" color="text.primary">
            {formatNumber(payload[0].value)} posts
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {Math.round((payload[0].value / totalPosts) * 100)}% of total
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const RADIAN = Math.PI / 180;
    // Calculate the position for the label
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "15px",
          fontWeight: "bold",
          textShadow: "0px 0px 3px rgba(0,0,0,0.5)", // Add shadow for better visibility
        }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <Card>
      <CardContentWrapper>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" gutterBottom>
              {translate("Post Distribution")}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ width: "100%", height: 300, mt: 3, position: "relative" }}>
          <Fade in={!loading} timeout={500}>
            <Box sx={{ width: "100%", height: "100%" }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius={70}
                    outerRadius={110}
                    paddingAngle={5}
                    dataKey="value"
                    label={renderCustomizedLabel}
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip
                    content={<CustomTooltip />}
                    wrapperStyle={{ zIndex: 10 }}
                  />
                  <Legend
                    verticalAlign="bottom"
                    height={36}
                    content={({ payload }) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 3,
                          mt: 2,
                        }}
                      >
                        {payload.map((entry, index) => (
                          <Box
                            key={`legend-${index}`}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Box
                              sx={{
                                width: 12,
                                height: 12,
                                borderRadius: "50%",
                                bgcolor: entry.color,
                              }}
                            />
                            <Typography variant="body2">
                              {translate(entry.payload.name)}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Fade>

          {loading && (
            <LoadingOverlay>
              <CircleSkeleton
                variant="circular"
                width={220}
                height={220}
                animation="wave"
                $direction={direction}
              />
            </LoadingOverlay>
          )}

          {/* Add absolute positioned center text */}
          <Box
            sx={{
              position: "absolute",
              top: "45%",
              insetInlineStart: "50%",
              transform:
                direction === "ltr"
                  ? "translate(-50%, -50%)"
                  : "translate(50%, -50%)",
              textAlign: "center",
            }}
          >
            <Fade in={!loading} timeout={800}>
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: "bold",
                    color: theme.palette.text.primary,
                    lineHeight: 1,
                  }}
                >
                  {loading ? <Skeleton width={80} /> : formatNumber(totalPosts)}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  {loading ? <Skeleton width={60} /> : translate("Total Posts")}
                </Typography>
              </Box>
            </Fade>
          </Box>
        </Box>

        <StatsWrapper>
          <StatBox>
            <TelegramIcon sx={{ color: "#0088cc", mr: 1 }} />
            <Box>
              {loading ? (
                <>
                  <Skeleton variant="text" width={60} height={28} />
                  <Skeleton variant="text" width={100} />
                </>
              ) : (
                <>
                  <Typography variant="h4">
                    {formatNumber(count.telegram)}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    {translate("Telegram Posts")}
                  </Typography>
                </>
              )}
            </Box>
          </StatBox>
          <StatBox>
            <img
              src={X}
              alt="twitter"
              style={{
                width: "24px",
                height: "24px",
                marginRight: "8px",
              }}
            />
            <Box>
              {loading ? (
                <>
                  <Skeleton variant="text" width={60} height={28} />
                  <Skeleton variant="text" width={100} />
                </>
              ) : (
                <>
                  <Typography variant="h4">
                    {formatNumber(count.twitter)}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    {translate("Twitter Posts")}
                  </Typography>
                </>
              )}
            </Box>
          </StatBox>
          <StatBox>
            <img
              src={FacebookOutlined}
              alt="facebook"
              style={{
                width: "24px",
                height: "24px",
                marginRight: "8px",
              }}
            />
            <Box>
              {loading ? (
                <>
                  <Skeleton variant="text" width={60} height={28} />
                  <Skeleton variant="text" width={100} />
                </>
              ) : (
                <>
                  <Typography variant="h4">
                    {formatNumber(count.facebook)}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    {translate("Facebook Posts")}
                  </Typography>
                </>
              )}
            </Box>
          </StatBox>
        </StatsWrapper>
      </CardContentWrapper>
    </Card>
  );
}

export default PostDistribution;
