import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  ThemeProvider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { fetchOrgs } from "../Payments/utils";
import { useTranslation } from "../../../../components/translate/TranslateContext";
import { createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Styled components for the container with reduced max-width
const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

// Main SearchBar component
const SearchBar = ({
  filters,
  setFilters,
  organizations,
  setOrganizations,
}) => {
  const [localFilters, setLocalFilters] = useState(filters);
  const { translate, direction } = useTranslation();

  const theme = createTheme({
    direction: direction, // Set the direction to RTL
    palette: {
      mode: "light", // or 'dark' if you want dark mode by default
    },
  });

  const stylisPlugins =
    direction === "rtl" ? [prefixer, rtlPlugin] : [prefixer];

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins,
  });

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  useEffect(() => {
    fetchOrgs(setOrganizations);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalFilters({ ...localFilters, [name]: value });
  };

  const handleApplyFilters = () => {
    setFilters(localFilters);
  };

  const handleResetFilters = () => {
    setLocalFilters({
      searchTerm: "",
      organization: null,
      timeSpent: "",
      role: "",
      startDate: "",
      endDate: "",
      email_verified: null,
    });
  };

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <div dir={direction}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" component="div">
                {translate("Filters Section")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FiltersContainer>
                <Grid container spacing={2}>
                  {/* Search Term */}
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label={translate("Search by Email")}
                      placeholder={translate("Enter email...")}
                      variant="outlined"
                      fullWidth
                      name="searchTerm"
                      value={localFilters.searchTerm}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  {/* Organization */}
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="organization-label" shrink>
                        {translate("Select Organization")}
                      </InputLabel>
                      <Select
                        labelId="organization-label"
                        name="organization_id"
                        value={localFilters.organization}
                        onChange={(e) => {
                          setLocalFilters({
                            ...localFilters,
                            organization: e.target.value,
                          });
                        }}
                        fullWidth
                        displayEmpty
                        label={translate("Select Organization")}
                        dir={direction === "rtl" ? "ltr" : "rtl"}
                      >
                        <MenuItem value={null}>
                          <em>{translate("None")}</em>
                        </MenuItem>
                        <MenuItem value="solo">
                          <em>{translate("Solo")}</em>
                        </MenuItem>
                        {organizations.map((org) => (
                          <MenuItem key={org.id} value={org.id}>
                            {org.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Time Spent */}
                  {/* <Grid item xs={12} sm={6} md={4}> */}
                    {/* <FormControl fullWidth>
                      <InputLabel id="time-spent-label">
                        {translate("Sort by Time Spent")}
                      </InputLabel>
                      <Select
                        labelId="time-spent-label"
                        name="timeSpent"
                        value={localFilters.timeSpent}
                        onChange={handleInputChange}
                        label={translate("Sort by Time Spent")}
                        dir={direction === "rtl" ? "ltr" : "rtl"}
                      >
                        <MenuItem value="">
                          <em>{translate("None")}</em>
                        </MenuItem>
                        <MenuItem value="most_time">
                          {translate("Most Time")}
                        </MenuItem>
                        <MenuItem value="least_time">
                          {translate("Least Time")}
                        </MenuItem>
                      </Select>
                    </FormControl> */}
                  {/* </Grid> */}

                  {/* Role */}
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="role-label">
                        {translate("Role")}
                      </InputLabel>
                      <Select
                        labelId="role-label"
                        name="role"
                        value={localFilters.role}
                        onChange={handleInputChange}
                        label={translate("Role")}
                        dir={direction === "rtl" ? "ltr" : "rtl"}
                      >
                        <MenuItem value="">
                          <em>{translate("None")}</em>
                        </MenuItem>
                        <MenuItem value="admin">{translate("Admin")}</MenuItem>
                        <MenuItem value="business">
                          {translate("Business Admin")}
                        </MenuItem>
                        <MenuItem value="user">{translate("User")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Start Date */}
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label={translate("Start Date")}
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      fullWidth
                      name="startDate"
                      value={localFilters.startDate}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  {/* End Date */}
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label={translate("End Date")}
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      fullWidth
                      name="endDate"
                      value={localFilters.endDate}
                      onChange={handleInputChange}
                    />
                  </Grid>

                  {/* Email Verified */}
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                      <InputLabel id="email_verified">
                        {translate("Email Verified")}
                      </InputLabel>
                      <Select
                        labelId="email_verified"
                        name="email_verified"
                        value={localFilters.email_verified}
                        onChange={handleInputChange}
                        label={translate("Email Verified")}
                        dir={direction === "rtl" ? "ltr" : "rtl"}
                      >
                        <MenuItem value={1}>{translate("Verified")}</MenuItem>
                        <MenuItem value={0}>
                          {translate("Not Verified")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* Apply and Reset Buttons */}
                <Grid container spacing={2} justifyContent="flex-start">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleApplyFilters}
                      sx={{
                        maxWidth: "300px",
                        margin: "20px 0",
                      }}
                    >
                      {translate("Apply Filters")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleResetFilters}
                      sx={{
                        maxWidth: "300px",
                        margin: "20px 0",
                        backgroundColor: "red",
                      }}
                    >
                      {translate("Reset Filters")}
                    </Button>
                  </Grid>
                </Grid>
              </FiltersContainer>
            </AccordionDetails>
          </Accordion>
        </div>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default SearchBar;
