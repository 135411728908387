import { useRef, useState, useEffect } from "react";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  Card,
  Box,
  CardContent,
  CardHeader,
  Menu,
  MenuItem,
  Divider,
  useTheme,
  IconButton,
} from "@mui/material";
import Chart from "react-apexcharts";
import { getAuth } from "../../../global/globalUtils";
import { useFilters } from "../FilterContext";
import { useTranslation } from "../../translate/TranslateContext";

function PostOverview() {
  const theme = useTheme();
  const { translate } = useTranslation();
  const actionRef1 = useRef(null);
  const actionRef2 = useRef(null);
  const [openYear, setOpenMenuYear] = useState(false);
  const [openSource, setOpenMenuSource] = useState(false);
  const [selectedYear, setSelectedYear] = useState("All Time");
  const [localSelectedSource, setLocalSelectedSource] = useState("All Sources");
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [monthData, setMonthData] = useState([]);

  // States for drill-down
  const [isDrillDown, setIsDrillDown] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);

  // Generate years from 2024 to current year
  const currentYear = new Date().getFullYear();
  const years = [
    { value: "all", text: "All Time" },
    ...Array.from({ length: currentYear - 2023 }, (_, i) => ({
      value: (2024 + i).toString(),
      text: (2024 + i).toString(),
    })),
  ];

  const sources = [
    { value: "all", text: "All Sources" },
    { value: "telegram", text: "Telegram" },
    { value: "twitter", text: "Twitter" },
    { value: "facebook", text: "Facebook" },
  ];

  const {
    keyword,
    selectedSource,
    startDate,
    endDate,
    selectedChannels,
    selectedCategories,
    sentiment,
  } = useFilters();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const year = selectedYear === "All Time" ? "" : selectedYear;
        const source =
          localSelectedSource === "All Sources"
            ? ""
            : localSelectedSource.toLowerCase();

        // Choose endpoint based on view mode
        const endpoint = isDrillDown
          ? "/dashboard/daily-post-count"
          : "/dashboard/monthly-post-count";

        // Calculate date range for API call
        let apiStartDate = startDate;
        let apiEndDate = endDate;

        if (isDrillDown && selectedMonth) {
          apiStartDate = selectedMonth; // This is already YYYY-MM-01 format
          const [year, month] = selectedMonth.split("-");
          // Get last day of the selected month
          apiEndDate = new Date(parseInt(year), parseInt(month), 0)
            .toISOString()
            .split("T")[0];
        }

        const params = new URLSearchParams({
          keyword: keyword,
          source: selectedSource || source,
          start_date: apiStartDate,
          end_date: apiEndDate,
          channels: selectedChannels.join(","),
          year: year,
          categories: selectedCategories.join(","),
          sentiment: sentiment,
        });

        const response = await getAuth(`${endpoint}?${params.toString()}`);
        const result = await response.json();

        if (result.status === "success") {
          if (isDrillDown) {
            const newLabels = result.data.dailyData.map((item) => {
              const date = new Date(item.date);
              return date.getDate().toString();
            });
            setLabels(newLabels);
            const transformedData = processDataForDailyChart(
              result.data.dailyData,
              localSelectedSource
            );
            setChartData(transformedData);
          } else {
            // Store the raw month data
            setMonthData(result.data.monthlyData);

            // Create display labels
            const newLabels = result.data.monthlyData.map((item) => {
              const [year, month] = item.yearMonth.split("-");
              const date = new Date(year, parseInt(month) - 1);
              return date.toLocaleString("default", {
                month: "short",
                year: "numeric",
              });
            });
            setLabels(newLabels);

            const transformedData = processDataForChart(
              result.data.monthlyData,
              localSelectedSource
            );
            setChartData(transformedData);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    selectedYear,
    localSelectedSource,
    keyword,
    selectedSource,
    startDate,
    endDate,
    selectedChannels,
    isDrillDown,
    selectedMonth,
  ]);

  const processDataForChart = (monthlyData, source) => {
    let series = [];

    if (source === "All Sources") {
      series = [
        {
          name: "Total Posts",
          data: monthlyData.map((item) => item.total),
        },
      ];
    } else if (source === "Telegram") {
      series = [
        {
          name: "Telegram",
          data: monthlyData.map((item) => item.telegram),
        },
      ];
    } else if (source === "Twitter") {
      series = [
        {
          name: "Twitter",
          data: monthlyData.map((item) => item.twitter),
        },
      ];
    } else if (source === "Facebook") {
      series = [
        {
          name: "Facebook",
          data: monthlyData.map((item) => item.facebook),
        },
      ];
    }

    return series;
  };

  const processDataForDailyChart = (dailyData, source) => {
    let series = [];

    if (source === "All Sources") {
      series = [
        {
          name: "Total Posts",
          data: dailyData.map((item) => item.total),
        },
      ];
    } else if (source === "Telegram") {
      series = [
        {
          name: "Telegram",
          data: dailyData.map((item) => item.telegram),
        },
      ];
    } else if (source === "Twitter") {
      series = [
        {
          name: "Twitter",
          data: dailyData.map((item) => item.twitter),
        },
      ];
    } else if (source === "Facebook") {
      series = [
        {
          name: "Facebook",
          data: dailyData.map((item) => item.facebook),
        },
      ];
    }

    return series;
  };

  const handleMonthClick = (monthIndex) => {
    // Get the yearMonth directly from the stored data
    const selectedYearMonth = monthData[monthIndex].yearMonth;
    const [year, month] = selectedYearMonth.split("-");

    // Create date for first day of selected month (YYYY-MM-01 format)
    const firstDayOfMonth = `${year}-${month}-01`;

    setSelectedMonth(firstDayOfMonth);
    setIsDrillDown(true);
  };

  const handleBackToMonthly = () => {
    setIsDrillDown(false);
    setSelectedMonth(null);
  };

  const chartOptions = {
    chart: {
      background: "transparent",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      events: {
        click: (event, chartContext, config) => {
          if (!isDrillDown && config.dataPointIndex !== -1) {
            handleMonthClick(config.dataPointIndex);
          }
        },
      },
    },
    colors: [theme.palette.primary.main, theme.palette.error.main],
    dataLabels: {
      enabled: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      strokeDashArray: 5,
      borderColor: theme.palette.divider,
    },
    legend: {
      show: true,
      position: "bottom",
    },
    markers: {
      size: 4,
      strokeWidth: 2,
      hover: {
        size: 6,
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
        formatter: (value) => value.toLocaleString(),
      },
    },
    tooltip: {
      theme: theme.palette.mode === "dark" ? "dark" : "light",
      y: {
        formatter: (value) => value.toLocaleString(),
      },
    },
  };

  return (
    <Card>
      <CardHeader
        action={
          <Box>
            {isDrillDown ? (
              <IconButton
                onClick={handleBackToMonthly}
                size="small"
                sx={{ mr: 1 }}
              >
                <ArrowBackIcon />
              </IconButton>
            ) : (
              <>
                <Button
                  size="small"
                  variant="outlined"
                  ref={actionRef1}
                  onClick={() => setOpenMenuYear(true)}
                  endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
                  sx={{ mr: 1 }}
                >
                  {selectedYear}
                </Button>
                <Menu
                  disableScrollLock
                  anchorEl={actionRef1.current}
                  onClose={() => setOpenMenuYear(false)}
                  open={openYear}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  {years.map((year) => (
                    <MenuItem
                      key={year.value}
                      onClick={() => {
                        setSelectedYear(year.text);
                        setOpenMenuYear(false);
                      }}
                    >
                      {year.text}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}

            <Button
              size="small"
              variant="outlined"
              ref={actionRef2}
              onClick={() => setOpenMenuSource(true)}
              endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}
            >
              {localSelectedSource}
            </Button>
            <Menu
              disableScrollLock
              anchorEl={actionRef2.current}
              onClose={() => setOpenMenuSource(false)}
              open={openSource}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {sources.map((source) => (
                <MenuItem
                  key={source.value}
                  onClick={() => {
                    setLocalSelectedSource(source.text);
                    setOpenMenuSource(false);
                  }}
                >
                  {source.text}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        }
        title={
          isDrillDown
            ? `${translate("Daily Posts")} - ${new Date(
                selectedMonth
              ).toLocaleString("default", {
                month: "long",
                year: "numeric",
              })}`
            : translate("Post Overview")
        }
      />
      <Divider />
      <CardContent>
        <Box sx={{ height: 300, width: "100%" }}>
          {loading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              {translate("Loading...")}
            </Box>
          ) : (
            <Chart
              options={chartOptions}
              series={chartData}
              type="line"
              height="100%"
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default PostOverview;
