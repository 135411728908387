import React, { useState } from "react";
import styled from "styled-components";
import { useTheme } from "../themes/ThemeContext";
import { useTranslation } from "../translate/TranslateContext";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

export const Modal = styled.div`
  background-color: ${({ theme }) => (theme === "dark" ? "#333" : "white")};
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  margin: 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

// Specific style for the Export button
const ExportButton = styled(ModalButton)`
  background-color: #218838; // Green
  &:hover {
    background-color: #218838; // Dark green
  }
`;

// Specific style for the Cancel button
const CancelButton = styled(ModalButton)`
  background-color: #c82333; // Dark red
  &:hover {
    background-color: #dc3545; // Light red
  }
`;

// Style for the language select dropdown
const LanguageSelect = styled.select`
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid ${({ $theme }) => $theme.borderColor};
  color: ${({ $theme }) => $theme.color};
  background-color: ${({ $theme }) => $theme.background};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// Custom styles for the modal overlay
const customOverlayStyles = {
  overlay: {
    zIndex: 1000,
  },
};

const TranslatePopup = ({ setLanguage, language, setIsOpen }) => {
  const [userLanguage, setUserLanguage] = useState(language);
  const { theme } = useTheme();
  const { translate } = useTranslation();

  const closeModal = () => setIsOpen(false);

  const handleLanguageChange = (event) => {
    setUserLanguage(event.target.value);
  };

  const handleLangSelect = () => {
    setLanguage(userLanguage);
    closeModal();
  };

  return (
    <>
      <Overlay>
        <Modal theme={theme} style={customOverlayStyles}>
          <h2
            style={{
              color: theme === "dark" ? "#fff" : "#000",
              textAlign: "center",
            }}
          >
            {translate("Select Language")}
          </h2>
          <LanguageSelect
            value={userLanguage}
            onChange={handleLanguageChange}
            $theme={theme}
          >
            <option value="original">{translate("Original")}</option>
            <option value="en">{translate("English (United States)")}</option>
            <option value="fr">{translate("French (France)")}</option>
            <option value="es">{translate("Spanish (Spain)")}</option>
            <option value="ar">{translate("Arabic")}</option>
          </LanguageSelect>
          <ButtonContainer>
            <ExportButton
              onClick={() => {
                handleLangSelect();
              }}
              theme={theme}
            >
              {translate("Translate")}
            </ExportButton>
            <CancelButton onClick={closeModal} theme={theme}>
              {translate("Cancel")}
            </CancelButton>
          </ButtonContainer>
        </Modal>
      </Overlay>
    </>
  );
};

export default TranslatePopup;
