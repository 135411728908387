import * as React from "react";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { inputsCustomizations } from "./customizations/inputs";
import { dataDisplayCustomizations } from "./customizations/dataDisplay";
import { feedbackCustomizations } from "./customizations/feedback";
import { navigationCustomizations } from "./customizations/navigation";
import { surfacesCustomizations } from "./customizations/surfaces";

export const colorSchemes = {
  light: {
    palette: {
      primary: {
        main: "#1976d2",
      },
      secondary: {
        main: "#dc004e",
      },
      background: {
        default: "#ffffff",
        paper: "#f5f5f5",
      },
      text: {
        primary: "#000000",
        secondary: "#555555",
      },
    },
  },
  dark: {
    palette: {
      primary: {
        main: "#90caf9",
      },
      secondary: {
        main: "#f48fb1",
      },
      background: {
        default: "#121212",
        paper: "#1d1d1d",
      },
      text: {
        primary: "#ffffff",
        secondary: "#aaaaaa",
      },
    },
  },
};

export const typography = {
  fontFamily: "Roboto, Arial, sans-serif",
  h1: {
    fontSize: "2.5rem",
  },
  h2: {
    fontSize: "2rem",
  },
  body1: {
    fontSize: "1rem",
  },
};

export const shadows = [
  "none",
  "0px 1px 3px rgba(0, 0, 0, 0.12)",
  "0px 1px 5px rgba(0, 0, 0, 0.2)",
  // Add more shadows as needed
];

export const shape = {
  borderRadius: 8,
};

const getStoredTheme = () => {
  if (typeof window !== "undefined") {
    const storedTheme = localStorage.getItem("theme");
    return storedTheme ? storedTheme : "light";
  }
  return "light";
};

function AppTheme(props) {
  const { children, disableCustomTheme, themeComponents } = props;
  const [mode, setMode] = React.useState(getStoredTheme());

  React.useEffect(() => {
    const storedTheme = getStoredTheme();
    if (storedTheme !== mode) {
      setMode(storedTheme);
    }
  }, [mode]);

  const theme = React.useMemo(() => {
    const createdTheme = disableCustomTheme
      ? {}
      : createTheme({
          cssVariables: {
            colorSchemeSelector: "data-mui-color-scheme",
            cssVarPrefix: "template",
          },
          palette: {
            mode,
            ...colorSchemes[mode].palette, // Ensure the correct palette is used
          },
          typography,
          shadows,
          shape,
          components: {
            ...inputsCustomizations,
            ...dataDisplayCustomizations,
            ...feedbackCustomizations,
            ...navigationCustomizations,
            ...surfacesCustomizations,
            ...themeComponents,
            MuiTextField: {
              styleOverrides: {
                root: {
                  "& input:-webkit-autofill": {
                    WebkitBoxShadow:
                      "0 0 0 100px rgba(0, 0, 0, 0) inset !important", // Transparent autofill background
                    WebkitTextFillColor: "inherit !important", // Inherit font color
                    animation: "none !important", // Disable animation
                  },
                  '[data-mui-color-scheme="dark"] & input:-webkit-autofill': {
                    WebkitBoxShadow:
                      "0 0 0 100px rgba(0, 0, 0, 0) inset !important",
                    WebkitTextFillColor: "inherit !important",
                    animation: "none !important",
                  },
                },
              },
            },
          },
        });
    return createdTheme;
  }, [disableCustomTheme, themeComponents, mode]);

  if (disableCustomTheme) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return (
    <ThemeProvider theme={theme} disableTransitionOnChange>
      {children}
    </ThemeProvider>
  );
}

AppTheme.propTypes = {
  children: PropTypes.node,
  disableCustomTheme: PropTypes.bool,
  themeComponents: PropTypes.object,
};

export default AppTheme;
