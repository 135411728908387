import {
  CardHeader,
  Divider,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState, useCallback } from "react";
import { formatDateForAPI } from "../../../global/dateUtils";
import { useFilters } from "../FilterContext";
import { useTranslation } from "../../translate/TranslateContext";

const DATE_RANGES = {
  now: { label: "Now", minutes: 15 },
  last24h: { label: "Last 24 Hours", days: 1 },
  last3days: { label: "Last 3 Days", days: 3 },
  lastWeek: { label: "Last Week", days: 7 },
};

const DISPLAY_COUNTS = [5, 10, 15];

const INITIAL_FILTERS = {
  searchQuery: "",
  dateRange: "last24h",
  displayCount: 5,
};

function TopOrganizations() {
  // Current filter states (not yet applied)
  const [currentFilters, setCurrentFilters] = useState(INITIAL_FILTERS);

  // Applied filter states (used for API calls)
  const [appliedFilters, setAppliedFilters] = useState(INITIAL_FILTERS);

  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);

  const { keyword } = useFilters();

  const { translate } = useTranslation();

  const getDateRange = useCallback((range) => {
    const endDate = new Date();
    const startDate = new Date();

    if (range === "now") {
      startDate.setMinutes(endDate.getMinutes() - DATE_RANGES[range].minutes);
    } else {
      startDate.setDate(endDate.getDate() - DATE_RANGES[range].days);
    }

    return {
      from: startDate.toISOString(),
      to: endDate.toISOString(),
    };
  }, []);

  const fetchOrganizations = useCallback(async () => {
    setLoading(true);
    const { to, from } = getDateRange(appliedFilters.dateRange);

    try {
      const params = new URLSearchParams({
        global_search: keyword,
        local_search: appliedFilters.searchQuery,
        start_date: formatDateForAPI(from),
        end_date: formatDateForAPI(to),
        top_k: appliedFilters.displayCount.toString(),
        entity_type: "organization",
      });

      const response = await fetch(
        `https://ner.teledeck.news/count_entities?${params.toString()}`
      );

      const data = await response.json();

      if (data.status) {
        setOrganizations(data.results);
      }
    } catch (error) {
      console.error("Failed to fetch organizations:", error);
    } finally {
      setLoading(false);
    }
  }, [appliedFilters, keyword, getDateRange]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  const handleApplyFilters = () => {
    setAppliedFilters(currentFilters);
  };

  const handleFilterChange = (filterName, value) => {
    setCurrentFilters((prev) => ({
      ...prev,
      [filterName]: value,
    }));
  };

  return (
    <Card>
      <CardHeader
        title={translate("Top Mentioned Organizations")}
        subheader={translate("Organizations ranked by number of mentions")}
      />
      <Divider />

      <Box sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Box sx={{ display: "flex", gap: 2 }}>
            <TextField
              sx={{ flex: 1 }}
              value={currentFilters.searchQuery}
              onChange={(e) =>
                handleFilterChange("searchQuery", e.target.value)
              }
              placeholder={translate("Search organizations...")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              size="small"
            />
            <FormControl size="small" sx={{ minWidth: 150 }}>
              <InputLabel>{translate("Time Range")}</InputLabel>
              <Select
                value={currentFilters.dateRange}
                label={translate("Time Range")}
                onChange={(e) =>
                  handleFilterChange("dateRange", e.target.value)
                }
              >
                {Object.entries(DATE_RANGES).map(([key, { label }]) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ minWidth: 100 }}>
              <InputLabel>{translate("Show")}</InputLabel>
              <Select
                value={currentFilters.displayCount}
                label={translate("Show")}
                onChange={(e) =>
                  handleFilterChange("displayCount", e.target.value)
                }
              >
                {DISPLAY_COUNTS.map((count) => (
                  <MenuItem key={count} value={count}>
                    {count}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              onClick={handleApplyFilters}
              disabled={loading}
            >
              {translate("Apply Filters")}
            </Button>
          </Box>
        </Stack>
      </Box>
      <Divider />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{translate("Organization")}</TableCell>
              <TableCell align="right">{translate("Mentions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography>{translate("Loading...")}</Typography>
                </TableCell>
              </TableRow>
            ) : organizations.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Typography>{translate("No organizations found")}</Typography>
                </TableCell>
              </TableRow>
            ) : (
              organizations.map((org) => (
                <TableRow hover key={org.id}>
                  <TableCell>
                    <Typography variant="body1" fontWeight="bold">
                      {org.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body1">{org.count}</Typography>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default TopOrganizations;
