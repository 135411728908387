import {
  CardHeader,
  Divider,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Avatar,
  TextField,
  Box,
  Typography,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { getAuth, website } from "../../../global/globalUtils";
import { formatNumber } from "../Index";
import { useFilters } from "../FilterContext";
import { useTranslation } from "../../translate/TranslateContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Props interface for type safety
const TopChannelsTable = ({
  title,
  subheader,
  searchPlaceholder,
  sourceId,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [channels, setChannels] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const { translate, direction } = useTranslation();

  const theme = createTheme({
    direction: direction,
  });

  const {
    keyword,
    startDate,
    endDate,
    selectedChannels,
    selectedCategories,
    sentiment,
  } = useFilters();

  useEffect(() => {
    const params = new URLSearchParams({
      keyword: keyword,
      source: sourceId,
      start_date: startDate,
      end_date: endDate,
      sentiment: sentiment,
      channels: selectedChannels.join(","),
      categories: selectedCategories.join(","),
      search: searchQuery,
      page: page + 1,
      limit: rowsPerPage,
    });

    const fetchTopChannels = async () => {
      try {
        setLoading(true);
        const response = await getAuth(
          `/dashboard/top-channels?${params.toString()}`
        );
        if (response.status === 200) {
          const data = await response.json();
          setChannels(data.channels);
          setTotal(data.pagination.total_items);
        }
      } catch (error) {
        console.error("Error fetching top channels:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTopChannels();
  }, [
    page,
    rowsPerPage,
    searchQuery,
    keyword,
    startDate,
    endDate,
    selectedChannels,
    sourceId,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const LoadingTableRow = () => (
    <TableRow>
      <TableCell>
        <Skeleton variant="circular" width={40} height={40} />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" width={200} />
      </TableCell>
      <TableCell align="right">
        <Skeleton variant="text" width={60} />
      </TableCell>
    </TableRow>
  );

  return (
    <Card>
      <CardHeader title={title} subheader={subheader} />
      <Divider />

      {/* Search Box */}
      <Box sx={{ p: 2 }}>
        <TextField
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={searchPlaceholder}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          size="small"
          disabled={loading}
        />
      </Box>
      <Divider />

      {/* Table */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {sourceId === 1 ? translate("Channel") : translate("Account")}
              </TableCell>
              <TableCell>{translate("Name")}</TableCell>
              <TableCell align="right">{translate("Posts")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array.from(new Array(rowsPerPage)).map((_, index) => (
                  <LoadingTableRow key={index} />
                ))
              : channels.map((channel) => (
                  <TableRow hover key={channel.id}>
                    <TableCell>
                      <Avatar
                        src={
                          channel.profile_picture_url
                            ? channel.profile_picture_url
                            : channel.image
                            ? `${website}${channel.image}`
                            : null
                        }
                        alt={`${
                          sourceId === 1
                            ? translate("Channel")
                            : translate("Account")
                        } Icon`}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = null;
                        }}
                        sx={{ width: 40, height: 40 }}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" fontWeight="bold">
                        {channel.chat_title}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body1">
                        {formatNumber(channel.post_count)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      {loading ? (
        <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
          <Skeleton variant="rectangular" width={320} height={36} />
        </Box>
      ) : (
        <ThemeProvider theme={theme}>
          <TablePagination
            component="div"
            count={total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
            labelRowsPerPage={translate("Rows per page")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${translate("from")} ${count}`
            }
          />
        </ThemeProvider>
      )}
    </Card>
  );
};

export default TopChannelsTable;
