import React from "react";
import styled from "styled-components";
import { useTranslation } from "../translate/TranslateContext";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.border_color || "#ddd"};
  background-color: ${({ theme }) => theme.background || "#fff"};
  cursor: move;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: ${({ theme }) => theme.text || "#333"};

  &:hover {
    background-color: rgb(216, 213, 213);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const ChatHeader = ({ onMouseDown, toggleSidebar, isSidebarOpen }) => {
  const { translate } = useTranslation();

  const handleMouseDown = (event) => {
    event.stopPropagation();
    onMouseDown(event);
  };

  return (
    <Header onMouseDown={handleMouseDown}>
      <Title>{translate("Chat Assistant")}</Title>
      <ButtonGroup>
        <IconButton
          onClick={toggleSidebar}
          title={isSidebarOpen ? "Close filters" : "Open filters"}
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
            />
          </svg>
        </IconButton>
      </ButtonGroup>
    </Header>
  );
};

export default ChatHeader;
