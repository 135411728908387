import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import styledComponents from "styled-components";
import AppTheme from "../shared-theme/AppTheme";
import { CircularProgress, Typography } from "@mui/material";
import { useAuth } from "../auth-context/AuthContext";
import { toast } from "sonner";
import { useTheme } from "../../themes/ThemeContext";
import logo from "../../../assets/images/teledeck.png";
import whiteLogo from "../../../assets/images/Teledeck.svg";
import { useNavigate } from "react-router-dom";
import { resendCode, verifyEmail } from "./utils";
import { useTranslation } from "../../translate/TranslateContext";
import LanguageSwitcher from "../../settings/tabs/components/LanguageSwitcher";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

const LanguageContainer = styledComponents.div`
  position: absolute;
  inset-inline-start: 10px;
  top: 10px;
`;

export default function EmailVerification(props) {
  const [code, setCode] = React.useState("");
  const [codeError, setCodeError] = React.useState(false);
  const [codeErrorMessage, setCodeErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [isResending, setIsResending] = React.useState(false);
  const [cooldown, setCooldown] = React.useState(0);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { direction, translate } = useTranslation();

  React.useEffect(() => {
    let timer;
    if (cooldown > 0) {
      timer = setTimeout(() => setCooldown(cooldown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [cooldown]);

  const validateInputs = () => {
    let isValid = true;

    if (code === "") {
      setCodeError(true);
      setCodeErrorMessage(translate("Verification Code is required"));
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    if (codeError) {
      event.preventDefault();
      return;
    }
    event.preventDefault();
    if (!validateInputs()) return;

    try {
      setLoading(true);
      const result = await verifyEmail(code);

      if (result.success === true) {
        setAuth({
          isAuthenticated: true,
          email_verified: true,
          isLoading: false,
          tokenAttemptedRefresh: false,
        });

        toast.success(translate("Email verified successfully"));

        navigate("/", { replace: true });
      } else {
        console.error("Verification unsuccessful");
        toast.error(result.message || translate("Verification unsuccessful"));
      }
    } catch (error) {
      console.error(
        "An unexpected error occurred during email verification:",
        error
      );
      toast.error(translate("An unexpected error occurred during email verification"));
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    await resendCode(setIsResending);
    setCooldown(30);
  };

  return (
    <AppTheme {...props}>
      <LanguageContainer>
        <LanguageSwitcher />
      </LanguageContainer>
      <CssBaseline enableColorScheme />
      <Stack
        direction={{ xs: "column-reverse", md: "row" }}
        sx={{
          justifyContent: "center",
          gap: { xs: 6, sm: 12 },
          p: { xs: 2, sm: 4, md: 0 },
          m: "auto",
        }}
      >
        <SignUpContainer direction="column" justifyContent="space-between">
          <Card variant="outlined">
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <img
                src={theme === "light" ? logo : whiteLogo}
                alt="Teledeck"
                style={{ width: "250px", alignSelf: "center" }}
              />

              <FormControl>
                <FormLabel htmlFor="code">
                  {translate("Verification Code")}
                </FormLabel>
                <TextField
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                    setCodeError(false);
                    setCodeErrorMessage("");
                  }}
                  required
                  fullWidth
                  id="code"
                  placeholder={translate("code")}
                  name="code"
                  autoComplete="code"
                  variant="outlined"
                  error={codeError}
                  helperText={codeErrorMessage}
                  color={codeError ? "error" : "primary"}
                  type="password"
                  sx={{
                    "& .MuiFormHelperText-root": {
                      textAlign: "start", // Ensures the helper text is aligned to the right for RTL
                    },
                  }}
                />
              </FormControl>

              <Typography
                variant="body2"
                align={direction === "ltr" ? "left" : "right"}
              >
                {translate("Please Check Your Email for the Verification Code")}
              </Typography>

              <Button
                type="submit"
                disabled={loading || isResending}
                variant="contained"
                startIcon={loading && <CircularProgress size={20} />}
                style={{ backgroundColor: "rgb(30, 40, 54)" }}
              >
                {loading ? "" : translate("Verify")}
              </Button>

              <Button
                type="button"
                disabled={isResending || cooldown > 0}
                variant="text"
                onClick={handleResendCode}
              >
                {isResending
                  ? translate("Sending...")
                  : cooldown > 0
                  ? `${translate("Send Code")} (${cooldown}${translate("s")})`
                  : translate("Send Code")}
              </Button>
            </Box>
          </Card>
        </SignUpContainer>
      </Stack>
    </AppTheme>
  );
}
