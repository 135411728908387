import React, { createContext, useEffect, useRef } from "react";
import { dashboardSse, getAuth } from "../../../global/globalUtils";
import { useFilters } from "../FilterContext";

export const SentimentEventSourceContext = createContext({
  subscribe: () => {},
  unsubscribe: () => {},
});

export const SentimentEventSourceProvider = ({ children }) => {
  const eventSourceRef = useRef(null);
  const subscribers = useRef(new Set());
  const { keyword, selectedSource, startDate, endDate, selectedChannels } =
    useFilters();

  // Function to fetch SSE token using the access token
  const fetchSseToken = async () => {
    try {
      const response = await getAuth("/auth/sse-token", {});
      const data = await response.json();
      connectEventSource(data.sseToken, {
        startDate,
        endDate,
        keyword,
        source: selectedSource,
        channels: selectedChannels.join(","),
      }); // Connect with the new token and current filters
    } catch (error) {
      console.error("Failed to fetch SSE token with access token:", error);
    }
  };

  // Function to initialize or reconnect the EventSource with the new token
  const connectEventSource = (token, filters) => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close(); // Close existing connection if open
    }

    // Construct query parameters from filters
    const queryParams = new URLSearchParams({
      token,
      ...filters, // Spread filter parameters into the query string
    });

    const eventSource = new EventSource(
      `${dashboardSse}/events?${queryParams}`
    );
    eventSource.onmessage = function (event) {
      const newMessage = JSON.parse(event.data);

      if (newMessage == null) {
        console.warn(
          "Received null or undefined message, refreshing the page..."
        );
        // window.location.reload(); // Force a refresh of the page
      } else {
        subscribers.current.forEach((handler) => handler(newMessage));
      }
    };

    eventSource.onerror = function (err) {
      console.error("EventSource failed:", err);
      eventSource.close();
      // Reconnect with a new token after a delay
      setTimeout(fetchSseToken, 5000);
    };

    eventSourceRef.current = eventSource;
  };

  useEffect(() => {
    fetchSseToken(); // Fetch token and connect on mount

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    // Reconnect EventSource whenever filters change
    fetchSseToken();
  }, [startDate, endDate, keyword, selectedSource, selectedChannels]);

  const subscribe = (handler) => {
    subscribers.current.add(handler);
  };

  const unsubscribe = (handler) => {
    subscribers.current.delete(handler);
  };

  return (
    <SentimentEventSourceContext.Provider value={{ subscribe, unsubscribe }}>
      {children}
    </SentimentEventSourceContext.Provider>
  );
};
