import { React, useState } from "react";
import { createNewColumn, getNextColumnPosition } from "../utils/newColumnUtil";
import styled from "styled-components";
import { website } from "../../../global/globalUtils";
import { useColumns } from "../../column/ColumnsContext";
import avatar from "../../../assets/images/default-avatar.avif";
import { SubscriberCount } from "./SecondColumn";
import { useTutorial } from "../../tutorial/TutorialContext";
import { useAuth } from "../../authentication/auth-context/AuthContext";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../translate/TranslateContext";

const ChannelItem = styled.div`
  &:hover {
    background-color: ${({ theme }) => theme.hover_background};
  }
`;

const ScrollableDiv = styled.div`
  /* Webkit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.chip_inactive};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.chip_inactive}; /* Optional: adds space around the thumb */
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.background};
  ${({ theme }) => theme.chip_inactive};
`;

const DoneButtonItem = styled.button`
  background-color: ${({ theme }) => theme.done_button};
  color: white;
  font-size: 18px;
  padding: 12px 24px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: ${({ theme }) => theme.done_hover};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.disabled_button};
    cursor: not-allowed;
  }
`;

const ClearAllButton = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    color: #ff4d4d;
  }
`;

export const ChosenChannelsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ColumnHeader = styled.h3`
  color: ${({ exceedLimit }) => (exceedLimit ? "red" : "inherit")};
`;

// Helper function to get filter settings
const getFilterSettings = () => {
  const checkedButton = document.querySelector(
    ".checkmark-button.checked"
  )?.innerText;

  return {
    text: checkedButton === "Text Only",
    images: checkedButton === "Images",
    videos: checkedButton === "Videos",
    links: checkedButton === "Hyper Links",
  };
};

// Helper function to validate column creation input
const validateColumnInput = (
  columnName,
  selectedIcon,
  chosenChannels,
  bundle,
  columnsCount,
  translate
) => {
  // Bundle validation
  if ((bundle === "free_trial" || bundle === "solo") && columnsCount >= 3) {
    return {
      valid: false,
      message: translate(
        "You have reached the maximum number of columns allowed for your plan."
      ),
      shouldNavigate: true,
    };
  }

  // General validations
  if (!columnName) {
    return {
      valid: false,
      message: translate("Please provide a column name"),
    };
  }

  if (!selectedIcon) {
    return { valid: false, message: translate("Please select an icon") };
  }

  if (chosenChannels.length > 30) {
    return { valid: false, message: translate("channels limit") };
  }

  if (chosenChannels.length === 0) {
    return {
      valid: false,
      message: translate("at least one channel"),
    };
  }

  return { valid: true };
};

const ThirdColumn = ({
  onClose,
  chosenChannels,
  onRemoveChannel,
  selectedIcon,
  hideButtons,
  deactivateRegularColumn,
  notificationEnabled,
  notificationSound,
  columnName,
  selectedFile,
}) => {
  const { translate } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { columnsCount, addColumn } = useColumns();
  const { stepIndex, setStepIndex } = useTutorial();
  const { bundle } = useAuth();
  const navigate = useNavigate();

  const prepareFormData = async () => {
    const formData = new FormData();

    // Add basic column information
    formData.append("name", columnName);
    formData.append("notifications", notificationEnabled ? "1" : "0");
    formData.append("isLoudSound", notificationSound);
    formData.append("column_position", await getNextColumnPosition());

    // Handle icon based on type
    if (selectedIcon?.type === "custom" && selectedFile) {
      formData.append("icon", selectedFile);
      formData.append("icon_type", "custom");
    } else if (selectedIcon?.type === "default" && selectedIcon?.value) {
      formData.append("icon", selectedIcon.value);
      formData.append("icon_type", "default");
    } else {
      // This should never happen due to validation, but as a fallback
      throw new Error("Invalid icon selection");
    }

    // Add filter settings
    const filterSettings = getFilterSettings();
    formData.append("get_video", filterSettings.videos ? "1" : "0");
    formData.append("get_image", filterSettings.images ? "1" : "0");
    formData.append("get_preview", filterSettings.links ? "1" : "0");
    formData.append("get_text_only", filterSettings.text ? "1" : "0");

    // Add search on top setting
    formData.append(
      "is_searchOnTop",
      document.querySelector(".Checkbox")?.checked ? "1" : "0"
    );

    // Add channels as an array
    chosenChannels.forEach((channel, index) => {
      formData.append(`channels[${index}]`, channel.id);
    });

    return formData;
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      // Validate inputs
      const validation = validateColumnInput(
        columnName,
        selectedIcon,
        chosenChannels,
        bundle,
        columnsCount,
        translate
      );

      if (!validation.valid) {
        toast.info(validation.message);
        if (validation.shouldNavigate) {
          navigate("/bundles");
        }
        return;
      }

      // Prepare the form data
      const formData = await prepareFormData();

      // Send the request
      const result = await createNewColumn(formData);

      if (result.success) {
        toast.success(result.message);

        // Handle tutorial progression if needed
        if (stepIndex === 5) {
          setStepIndex(6);
        }

        // Add the column to the context
        if (result.column) {
          addColumn(result.column);
        }

        // Close the popup
        onClose();
      } else {
        toast.error(result.message || "Failed to create column");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      toast.error(
        error.message ||
          "An unexpected error occurred while creating the column"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={deactivateRegularColumn ? "" : "RegularColumn"}>
      <div className="ChosenChannelsMain">
        <ChosenChannelsHeader>
          <ColumnHeader exceedLimit={chosenChannels.length > 30}>
            {translate("Chosen Channels")} {`(${chosenChannels.length})`}
          </ColumnHeader>
          {chosenChannels.length > 0 ? (
            <ClearAllButton
              onClick={() => chosenChannels.forEach(onRemoveChannel)}
            >
              {translate("Clear All")}
            </ClearAllButton>
          ) : null}
        </ChosenChannelsHeader>
        <ScrollableDiv className="ChannelsContainer">
          {chosenChannels.map((channel) => (
            <ChannelItem key={channel.id} className="Channel">
              <div className="ChannelElement Chanol">
                <div className="ChannelProfileandName">
                  <div>
                    <img
                      src={
                        channel.profile_picture_url
                          ? channel.profile_picture_url
                          : channel.image
                          ? `${website}${channel.image}`
                          : avatar
                      }
                      alt="Channel Icon"
                      className="ChannelIcon"
                      onError={(e) => {
                        e.target.onerror = null; // Prevent infinite loop if fallback image also fails
                        e.target.src = avatar; // Set fallback image
                      }}
                    />
                  </div>
                  <div className="ChannelName">
                    <span className="channel_name">{channel.chat_title}</span>
                    <br />
                    <span className="channel_username">
                      @{channel.sender_username}
                    </span>
                    <SubscriberCount>
                      {channel.subscriber_count !== null &&
                      channel.subscriber_count !== undefined &&
                      channel.subscriber_count !== 0
                        ? `${channel.subscriber_count.toLocaleString()} subscribers`
                        : ""}
                    </SubscriberCount>
                  </div>
                </div>
                <button
                  className="CloseButton"
                  onClick={() => onRemoveChannel(channel)}
                >
                  <i className="material-icons">close</i>
                </button>
              </div>
            </ChannelItem>
          ))}
        </ScrollableDiv>
        {!hideButtons && (
          <div className="ButtonContainer">
            <DoneButtonItem
              className="DoneButton ButtonLayout"
              onClick={handleSubmit}
              disabled={isSubmitting}
              id="done-button"
            >
              {isSubmitting ? translate("Creating...") : translate("Create")}
            </DoneButtonItem>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThirdColumn;
