import React from "react";
import { useTranslation } from "../../../translate/TranslateContext";
import styled from "styled-components";

const LanguageSwitcherContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var();
  /* padding: 8px; */
  border-radius: 20px;
  gap: 8px;
  width: fit-content;
`;

const LanguageButton = styled.button`
  background: ${({ selected }) => (selected ? "#007bff" : "transparent")};
  color: ${({ selected }) => (selected ? "white" : "grey")};
  border: none;
  padding: 8px 16px;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: bold;

  &:hover {
    background: #007bff;
    color: white;
  }
`;

const LanguageSwitcher = ({ showLabel = false }) => {
  const { translate, language, changeLanguage } = useTranslation();

  return (
    <>
      {showLabel && <p>{translate("Select language")}</p>}
      <LanguageSwitcherContainer>
        <LanguageButton
          selected={language === "ar"}
          onClick={() => changeLanguage("ar")}
        >
          {translate("Arabic")}
        </LanguageButton>
        <LanguageButton
          selected={language === "en"}
          onClick={() => changeLanguage("en")}
        >
          {translate("English")}
        </LanguageButton>
      </LanguageSwitcherContainer>
    </>
  );
};

export default LanguageSwitcher;
