import {
  CardContent,
  Card,
  Box,
  Typography,
  styled,
  useTheme,
  Skeleton,
  Fade,
} from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import { useFilters } from "../FilterContext";
import { useContext, useEffect } from "react";
import { SentimentEventSourceContext } from "./SentimentEventSourceProvider";
import { useTranslation } from "../../translate/TranslateContext";

const StatsWrapper = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${theme.spacing(2)};
    gap: ${theme.spacing(2)};
`
);

const StatBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    padding: ${theme.spacing(1.5)};
    border-radius: ${theme.general.borderRadius}px;
    background: ${theme.colors.alpha.black[5]};
    flex: 1;
`
);

const LoadingOverlay = styled(Box)`
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.background.paper}AA;
  border-radius: inherit;
`;

const CircleSkeleton = styled(Skeleton)`
  position: absolute;
  top: 50%;
  inset-inline-start: 50%;
  transform: ${({ $direction }) =>
    $direction === "ltr" ? "translate(-50%, -50%)" : "translate(50%, -50%)"};
`;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  index,
  payload,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "15px",
        fontWeight: "bold",
        textShadow: "0px 0px 3px rgba(0,0,0,0.5)",
      }}
    >
      {payload.percentage}%
    </text>
  );
};

function SentimentAnalysis({ sentiments = [], setSentiments, loading }) {
  const theme = useTheme();
  const { translate, direction } = useTranslation();

  const { sentiment: selectedSentiment } = useFilters();
  const { subscribe, unsubscribe } = useContext(SentimentEventSourceContext);

  // handle live messages
  useEffect(() => {
    const handleMessage = (newMessage) => {
      // Validate incoming message
      if (!newMessage || !newMessage.sentiment) {
        console.error("Received invalid message:", newMessage);
        return;
      }

      if (!["positive", "negative", "neutral"].includes(newMessage.sentiment)) {
        console.error("Invalid sentiment value:", newMessage.sentiment);
        return;
      }

      setSentiments((prevItems) => {
        // Create a copy of the previous items
        const updatedItems = [...prevItems];

        // Find the item to update
        const itemIndex = updatedItems.findIndex(
          (item) =>
            item.sentiment.toLowerCase() === newMessage.sentiment.toLowerCase()
        );

        if (itemIndex !== -1) {
          // Update the count for the matching sentiment
          updatedItems[itemIndex] = {
            ...updatedItems[itemIndex],
            count: updatedItems[itemIndex].count + 1,
          };

          // Calculate new total
          const total = updatedItems.reduce((sum, item) => sum + item.count, 0);

          // Update percentages for all items
          return updatedItems.map((item) => ({
            ...item,
            percentage: Number(((item.count / total) * 100).toFixed(1)),
          }));
        }

        return prevItems;
      });
    };

    subscribe(handleMessage);
    return () => unsubscribe(handleMessage);
  }, [subscribe, unsubscribe, setSentiments]);

  const getSegmentOpacity = (segmentSentiment) => {
    if (!selectedSentiment) return 1;
    return segmentSentiment?.toLowerCase() === selectedSentiment?.toLowerCase()
      ? 1
      : 0.3;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { sentiment, count, percentage } = payload[0].payload;
      return (
        <Box
          sx={{
            bgcolor: "background.paper",
            p: 2,
            boxShadow: theme.shadows[3],
            borderRadius: 1,
          }}
        >
          <Typography variant="body1" color="text.primary">
            {sentiment} Sentiment
          </Typography>
          <Typography variant="h6" color="text.primary">
            {count} posts
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {percentage}% of total
          </Typography>
        </Box>
      );
    }
    return null;
  };

  const loadingData = [{ value: 1, color: theme.palette.grey[300] }];

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h3" gutterBottom>
              {translate("Sentiment Analysis")}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {translate("Distribution of posts by sentiment")}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: "100%", height: 300, mt: 3, position: "relative" }}>
          <Fade in={!loading} timeout={500}>
            <Box sx={{ width: "100%", height: "100%" }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={loading ? loadingData : sentiments}
                    cx="50%"
                    cy="50%"
                    innerRadius={70}
                    outerRadius={110}
                    paddingAngle={5}
                    dataKey="count"
                    labelLine={false}
                    label={!loading && renderCustomizedLabel}
                  >
                    {(loading ? loadingData : sentiments).map(
                      (entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={entry.color}
                          fillOpacity={getSegmentOpacity(entry.sentiment)}
                        />
                      )
                    )}
                  </Pie>
                  {!loading && (
                    <>
                      <Tooltip content={<CustomTooltip />} />
                      <Legend
                        verticalAlign="bottom"
                        height={36}
                        content={({ payload }) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              gap: 3,
                              mt: 2,
                            }}
                          >
                            {payload.map((entry, index) => (
                              <Box
                                key={`legend-${index}`}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                  opacity: getSegmentOpacity(
                                    entry.payload.sentiment
                                  ),
                                }}
                              >
                                <Box
                                  sx={{
                                    width: 12,
                                    height: 12,
                                    borderRadius: "50%",
                                    bgcolor: entry.color,
                                  }}
                                />
                                <Typography variant="body2">
                                  {translate(entry.payload.sentiment)}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        )}
                      />
                    </>
                  )}
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Fade>

          {loading && (
            <LoadingOverlay>
              <CircleSkeleton
                variant="circular"
                width={220}
                height={220}
                animation="wave"
                $direction={direction}
              />
            </LoadingOverlay>
          )}
        </Box>

        <StatsWrapper>
          {[
            {
              icon: (
                <SentimentSatisfiedAltIcon
                  sx={{ color: theme.colors.success.main, mr: 1 }}
                />
              ),
              value: sentiments[0]?.count,
              label: translate("Positive"),
              sentiment: "positive",
            },
            {
              icon: (
                <SentimentVeryDissatisfiedIcon
                  sx={{ color: theme.colors.error.main, mr: 1 }}
                />
              ),
              value: sentiments[1]?.count,
              label: translate("Negative"),
              sentiment: "negative",
            },
            {
              icon: (
                <SentimentNeutralIcon
                  sx={{ color: theme.colors.warning.main, mr: 1 }}
                />
              ),
              value: sentiments[2]?.count,
              label: translate("Neutral"),
              sentiment: "neutral",
            },
          ].map((stat, index) => (
            <StatBox
              key={index}
              sx={{ opacity: getSegmentOpacity(stat.sentiment) }}
            >
              {stat.icon}
              <Box>
                {loading ? (
                  <>
                    <Skeleton width={60} height={28} />
                    <Skeleton width={80} />
                  </>
                ) : (
                  <>
                    <Typography variant="h4">{stat.value}</Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                      {stat.label}
                    </Typography>
                  </>
                )}
              </Box>
            </StatBox>
          ))}
        </StatsWrapper>
      </CardContent>
    </Card>
  );
}

export default SentimentAnalysis;
