import axios from "axios";
import { toast } from "sonner";
import { webApi } from "../../global/globalUtils";

const BOOKMARK_API = `${webApi}/bookmarks`;

// Configure axios with credentials for cookie-based authentication
const getAuthConfig = () => {
  return {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  };
};

/**
 * Bookmark a post
 * @param {number} postId - ID of the post to bookmark
 * @param {number|null} categoryId - Optional category ID
 * @returns {Promise} - The created bookmark data
 */
export const bookmarkPost = async (postId, categoryId = null) => {
  try {
    const response = await axios.post(
      `${BOOKMARK_API}/posts`,
      { post_id: postId, category_id: categoryId },
      getAuthConfig()
    );

    // toast.success(response.data.message || "Post bookmarked successfully");
    return response.data.data;
  } catch (error) {
    handleApiError(error, "Failed to bookmark post");
    throw error;
  }
};

/**
 * Remove a bookmark
 * @param {number} postId - ID of the post to unbookmark
 * @returns {Promise} - Success status
 */
export const unbookmarkPost = async (postId) => {
  try {
    const response = await axios.delete(
      `${BOOKMARK_API}/posts/${postId}`,
      getAuthConfig()
    );

    // toast.success(response.data.message || "Post removed from bookmarks");
    return response.data;
  } catch (error) {
    handleApiError(error, "Failed to remove bookmark");
    throw error;
  }
};

/**
 * Create a new bookmark category
 * @param {string} name - Name of the new category
 * @returns {Promise} - The created category data
 */
export const createBookmarkCategory = async (name) => {
  try {
    const response = await axios.post(
      `${BOOKMARK_API}/categories`,
      { name },
      getAuthConfig()
    );

    toast.success(response.data.message || "Category created successfully");
    return response.data.data;
  } catch (error) {
    handleApiError(error, "Failed to create category");
    throw error;
  }
};

/**
 * Get all bookmark categories for the user
 * @returns {Promise} - Array of categories
 */
export const getBookmarkCategories = async () => {
  try {
    const response = await axios.get(
      `${BOOKMARK_API}/categories`,
      getAuthConfig()
    );

    return response.data.data || [];
  } catch (error) {
    handleApiError(error, "Failed to fetch categories");
    return [];
  }
};

/**
 * Delete a bookmark category
 * @param {number} categoryId - ID of the category to delete
 * @param {number|null} targetCategoryId - Optional target category to move bookmarks to
 * @returns {Promise} - Success status
 */
export const deleteBookmarkCategory = async (
  categoryId,
  targetCategoryId = null
) => {
  try {
    const config = getAuthConfig();
    // For axios delete with data, we need to pass the data in the config object
    config.data = { target_category_id: targetCategoryId };

    const response = await axios.delete(
      `${BOOKMARK_API}/categories/${categoryId}`,
      config
    );

    toast.success(response.data.message || "Category deleted successfully");
    return response.data;
  } catch (error) {
    handleApiError(error, "Failed to delete category");
    throw error;
  }
};

/**
 * Get all bookmarked posts
 * @param {Object} options - Filter and pagination options
 * @param {number|null} options.categoryId - Optional category filter
 * @param {number} options.page - Page number (default: 1)
 * @param {number} options.limit - Items per page (default: 10)
 * @returns {Promise} - Bookmarked posts and pagination data
 */
export const getBookmarkedPosts = async ({
  categoryId = null,
  page = 1,
  limit = 10,
} = {}) => {
  try {
    let url = `${BOOKMARK_API}/posts?page=${page}&limit=${limit}`;
    if (categoryId) {
      url += `&category_id=${categoryId}`;
    }

    const response = await axios.get(url, getAuthConfig());
    return {
      bookmarks: response.data.data || [],
      pagination: response.data.pagination || {},
    };
  } catch (error) {
    handleApiError(error, "Failed to fetch bookmarked posts");
    return { bookmarks: [], pagination: {} };
  }
};

/**
 * Update a bookmark's category
 * @param {number} bookmarkId - ID of the bookmark to update
 * @param {number|null} categoryId - New category ID or null to remove categorization
 * @returns {Promise} - The updated bookmark data
 */
export const updateBookmarkCategory = async (bookmarkId, categoryId = null) => {
  try {
    const response = await axios.patch(
      `${BOOKMARK_API}/posts/${bookmarkId}/category`,
      { category_id: categoryId },
      getAuthConfig()
    );

    toast.success(response.data.message || "Bookmark updated successfully");
    return response.data.data;
  } catch (error) {
    handleApiError(error, "Failed to update bookmark");
    throw error;
  }
};

/**
 * Check if a post is bookmarked
 * @param {number} postId - Post ID to check
 * @returns {Promise<boolean>} - True if post is bookmarked
 */
export const isPostBookmarked = async (postId) => {
  try {
    // We'll assume that if the post is in the list of bookmarks, it's bookmarked
    const { bookmarks } = await getBookmarkedPosts({ limit: 1000 }); // Get all bookmarks
    return bookmarks.some((bookmark) => bookmark.post_id === postId);
  } catch (error) {
    handleApiError(error, "Failed to check bookmark status");
    return false;
  }
};

/**
 * Toggle bookmark status for a post
 * @param {number} postId - Post ID to toggle
 * @param {number|null} categoryId - Optional category ID (for bookmarking)
 * @returns {Promise<boolean>} - New bookmark status (true if bookmarked)
 */
export const toggleBookmark = async (postId, categoryId = null) => {
  try {
    const isBookmarked = await isPostBookmarked(postId);

    if (isBookmarked) {
      await unbookmarkPost(postId);
      return false;
    } else {
      await bookmarkPost(postId, categoryId);
      return true;
    }
  } catch (error) {
    handleApiError(error, "Failed to toggle bookmark");
    throw error;
  }
};

export const unbookmarkPostList = async (postIds) => {
  try {
    const config = getAuthConfig();
    // For DELETE requests, data needs to be in the config object
    config.data = { post_ids: postIds };

    const response = await axios.delete(`${BOOKMARK_API}/post-list`, config);

    toast.success(response.data.message || "Posts removed from bookmarks");
    return response.data;
  } catch (error) {
    handleApiError(error, "Failed to remove bookmarks");
    throw error;
  }
};

/**
 * Utility function to handle API errors
 * @param {Error} error - The error object
 * @param {string} fallbackMessage - Fallback error message
 */
export const handleApiError = (
  error,
  fallbackMessage = "An error occurred"
) => {
  // Get appropriate error message
  let errorMessage = fallbackMessage;

  if (error.response) {
    // Server responded with an error status
    const serverMessage =
      error.response.data.message || error.response.data.error;
    if (serverMessage) {
      errorMessage = serverMessage;
    } else if (error.response.status === 401) {
      errorMessage = "Please log in to continue";
    } else if (error.response.status === 403) {
      errorMessage = "You don't have permission to perform this action";
    } else if (error.response.status === 404) {
      errorMessage = "The requested resource was not found";
    }
  } else if (error.request) {
    // Request was made but no response received
    errorMessage =
      "No response from server. Please check your internet connection.";
  }

  // Show toast notification
  toast.error(errorMessage);

  // Log detailed error to console for debugging
  console.error("API Error:", error);
};

export const deleteCategoriesFromAPI = async (
  categoryIds,
) => {
  try {
    const config = getAuthConfig();
    // For DELETE requests, data needs to be in the config object
    config.data = {
      category_ids: categoryIds
    };

    const response = await axios.delete(`${BOOKMARK_API}/categories`, config);

    toast.success(response.data.message || "Categories deleted successfully");
    return response.data;
  } catch (error) {
    handleApiError(error, "Failed to delete categories");
    throw error;
  }
};
