import AccentHeaderLayout from "./header";
import { Helmet } from "react-helmet-async";
import { Grid } from "@mui/material";

// charts
import LineChart from "./charts/LineChart";
import SentimentAnalysis from "./charts/SentimentAnalysis";
import PostDistribution from "./charts/PostDistribution";
import ChannelDistribution from "./charts/ChannelDistribution";
import PostsByCategory from "./charts/PostsByCategory";

// tables
import TopOrganizations from "./tables/TopOrganizations";
import TopPersonas from "./tables/TopPersona";

import { useContext, useEffect, useState } from "react";
import { getAuth } from "../../global/globalUtils";
import { useFilters } from "./FilterContext";
import { useCounts } from "./CountsContext";
import NetworkMap from "./charts/network-map/Index";
import TopChannelsTable from "./tables/TopChannelsTable";
import { EventSourceContext } from "../../components/sse-context/EventSourceContext";
import { useTranslation } from "../translate/TranslateContext";

export const formatNumber = (num) => {
  if (num === undefined || num === null) return "0";
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function DashboardAnalytics() {
  const [categories, setCategories] = useState([]);
  const [sentiments, setSentiments] = useState([]);
  const [loading, setLoading] = useState({
    postCount: true,
    channelCount: true,
    categories: true,
    sentiments: true,
  });
  const { subscribe, unsubscribe } = useContext(EventSourceContext);
  const { translate } = useTranslation();

  const {
    keyword,
    selectedSource,
    startDate,
    endDate,
    selectedChannels,
    selectedCategories,
    sentiment,
  } = useFilters();

  const { postCount, setPostCount, channelCount, setChannelCount } =
    useCounts();

  useEffect(() => {
    const checkFilters = (message) => {
      // Source ID to name mapping
      const SOURCE_MAPPING = {
        1: "telegram",
        2: "twitter",
        4: "facebook",
      };

      // Check end date - if it's before current time, don't add new messages
      if (endDate) {
        const endDateTime = new Date(endDate).getTime();
        const currentTime = new Date().getTime();
        if (endDateTime < currentTime) {
          return false;
        }
      }

      // Check keyword filter
      if (
        keyword?.trim() &&
        (!message.text ||
          !message.text.toLowerCase().includes(keyword.trim().toLowerCase()))
      ) {
        return false;
      }

      // Check source filter - compare using the mapping
      if (
        selectedSource &&
        message.source_name.toLowerCase() !==
          SOURCE_MAPPING[selectedSource]?.toLowerCase()
      ) {
        return false;
      }

      // Check channel filter
      if (
        selectedChannels?.length > 0 &&
        !selectedChannels.includes(message.channel_id)
      ) {
        return false;
      }

      return true;
    };

    const handleMessage = (newMessage) => {
      if (checkFilters(newMessage)) {
        setPostCount((prevCount) => {
          const newCount = { ...prevCount };
          if (newMessage.source_name.toLowerCase() === "telegram") {
            newCount.telegram += 1;
          } else if (newMessage.source_name.toLowerCase() === "twitter") {
            newCount.twitter += 1;
          } else if (newMessage.source_name.toLowerCase() === "facebook") {
            newCount.facebook += 1;
          }
          return newCount;
        });
      }
    };

    subscribe(handleMessage);
    return () => unsubscribe(handleMessage);
  }, [
    subscribe,
    unsubscribe,
    keyword,
    selectedSource,
    selectedChannels,
    startDate,
    endDate,
    setPostCount,
  ]);

  useEffect(() => {
    const params = new URLSearchParams({
      keyword: keyword,
      source: selectedSource,
      start_date: startDate,
      end_date: endDate,
      channels: selectedChannels.join(","),
      categories: selectedCategories.join(","),
      sentiment: sentiment,
    });
    const fetchPostAndChannelCount = async () => {
      try {
        setLoading((prevLoading) => ({
          ...prevLoading,
          postCount: true,
          channelCount: true,
        }));

        const response = await getAuth(
          `/dashboard/total-counts?${params.toString()}`
        );
        const data = await response.json();
        setPostCount(data.data.posts);
        setChannelCount(data.data.channels);
      } catch (error) {
        console.error("Error fetching post and channel count:", error);
      } finally {
        setLoading((prevLoading) => ({
          ...prevLoading,
          postCount: false,
          channelCount: false,
        }));
      }
    };

    const fetchCategories = async () => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, categories: true }));

        const response = await getAuth(
          `/dashboard/posts-per-category?${params.toString()}`
        );
        const data = await response.json();
        setCategories(data.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, categories: false }));
      }
    };

    const fetchSentiments = async () => {
      try {
        setLoading((prevLoading) => ({ ...prevLoading, sentiments: true }));
        const response = await getAuth(
          `/dashboard/total-sentiments?${params.toString()}`
        );
        const sentiments = await response.json();
        const colors = ["#4caf50", "#f44336", "#ff9800"];
        const sentimentsWithColors = sentiments.data.map(
          (sentiment, index) => ({
            ...sentiment,
            color: colors[index % colors.length],
          })
        );
        setSentiments(sentimentsWithColors);
      } catch (error) {
        console.error("Error fetching sentiments: ", error);
      } finally {
        setLoading((prevLoading) => ({ ...prevLoading, sentiments: false }));
      }
    };

    fetchCategories();
    fetchPostAndChannelCount();
    fetchSentiments();
  }, [
    keyword,
    selectedSource,
    startDate,
    endDate,
    selectedChannels,
    selectedCategories,
  ]);

  return (
    <>
      <Helmet>
        <title>Analytics Dashboard</title>
      </Helmet>
      <AccentHeaderLayout />
      <style>
        {`
          /* Custom scrollbar styles */
          ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }

          ::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          ::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 4px;
          }

          ::-webkit-scrollbar-thumb:hover {
            background: #555;
          }

          /* Firefox scrollbar styles */
          * {
            scrollbar-width: thin;
            scrollbar-color: #888 #f1f1f1;
          }
        `}
      </style>
      <Grid
        sx={{
          px: 4,
          mt: 3,
          flexGrow: 1,
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Grid
            container
            spacing={4}
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item md={4} xs={12}>
              {" "}
              <SentimentAnalysis
                sentiments={sentiments}
                loading={loading.sentiments}
                setSentiments={setSentiments}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              {" "}
              <PostDistribution count={postCount} loading={loading.postCount} />
            </Grid>
            <Grid item md={4} xs={12}>
              {" "}
              <ChannelDistribution
                count={channelCount}
                loading={loading.channelCount}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PostsByCategory
            categories={categories}
            loading={loading.categories}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TopChannelsTable
            title={translate("Top Telegram Channels")}
            sourceId={1}
            searchPlaceholder={translate("Search Telegram channels...")}
            subheader={translate("Channels ranked by number of posts")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TopChannelsTable
            title={translate("Top Twitter Accounts")}
            sourceId={2}
            searchPlaceholder={translate("Search Twitter accounts...")}
            subheader={translate("Accounts ranked by number of posts")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TopChannelsTable
            title={translate("Top Facebook Accounts")}
            sourceId={2}
            searchPlaceholder={translate("Search Facebook accounts...")}
            subheader={translate("Accounts ranked by number of posts")}
          />
        </Grid>
        <Grid item xs={12}>
          <LineChart />
        </Grid>
        <Grid item md={6} xs={12}>
          <TopOrganizations />
        </Grid>
        <Grid item md={6} xs={12}>
          <TopPersonas />
        </Grid>
        <Grid item xs={12}>
          <NetworkMap />
        </Grid>
      </Grid>

      {/* <Footer /> */}
    </>
  );
}

export default DashboardAnalytics;
