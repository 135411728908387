const DB_NAME = "TelegramDeckDB";
const DB_VERSION = 9;
let dbInstance = null;

function openDb() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
      console.error("Database error: " + event.target.errorCode);
      reject("Database error: " + event.target.errorCode);
    };

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      let bookmarkedStore;

      // Create object stores if they don't exist
      if (!db.objectStoreNames.contains("columns")) {
        db.createObjectStore("columns", { keyPath: "id" });
      }
      if (!db.objectStoreNames.contains("channels")) {
        db.createObjectStore("channels", { keyPath: "id" });
      }

      // Handle "bookmarked_posts" store creation or migration
      if (!db.objectStoreNames.contains("bookmarked_posts")) {
        // Create new structure if it doesn't exist
        bookmarkedStore = db.createObjectStore("bookmarked_posts", {
          keyPath: "category",
        });
      } else {
        // If store exists, check if it's the old structure by checking its keyPath
        bookmarkedStore =
          event.target.transaction.objectStore("bookmarked_posts");

        // Check if the existing store uses 'post_id' as the keyPath (old structure)
        if (bookmarkedStore.keyPath === "post_id") {
          const oldStoreRequest = bookmarkedStore.getAll();
          oldStoreRequest.onsuccess = (event) => {
            const allPosts = event.target.result;

            // Migrate all posts into a single "uncategorized" category
            const uncategorizedPosts = allPosts.map((post) => ({
              ...post,
              bookmark_category: "uncategorized",
            }));

            // Delete the old store and create a new one with the correct structure
            db.deleteObjectStore("bookmarked_posts");

            // Create the new structure with 'category' as the keyPath
            bookmarkedStore = db.createObjectStore("bookmarked_posts", {
              keyPath: "category",
            });

            // Store the migrated posts under the "uncategorized" category
            bookmarkedStore.transaction.oncomplete = () => {
              const newTransaction = db.transaction(
                "bookmarked_posts",
                "readwrite"
              );
              const newStore = newTransaction.objectStore("bookmarked_posts");

              newStore.put({
                category: "uncategorized",
                bookmarked_posts: uncategorizedPosts,
              });

              newTransaction.onerror = (event) => {
                console.error(
                  "Error during migration transaction:",
                  event.target.error
                );
              };
            };
          };

          oldStoreRequest.onerror = (event) => {
            console.error(
              "Error accessing old structure data:",
              event.target.error
            );
          };
        }
      }
    };

    request.onsuccess = (event) => {
      dbInstance = event.target.result;
      resolve(dbInstance);
    };
  });
}

function withStore(mode, callback) {
  return new Promise((resolve, reject) => {
    openDb()
      .then((db) => {
        const transaction = db.transaction(
          ["columns", "channels", "bookmarked_posts"],
          mode
        );
        transaction.oncomplete = () => resolve();
        transaction.onerror = (event) =>
          reject("Transaction failed: " + event.target.error);

        callback({ db: db, transaction: transaction });
      })
      .catch((error) => {
        console.error("Failed to open DB: ", error);
        reject(error);
      });
  });
}

export { openDb, withStore };
