import React, { useMemo } from "react";

/**
 * Truncates text and adds ellipsis for Twitter-like sources
 * @param {string} text - Input text to truncate
 * @param {number} limit - Character limit
 * @returns {string} Truncated text with ellipsis if needed
 */
const truncateText = (text, limit) => {
  // Return early if text is empty or under the limit
  if (!text || text.length <= limit) return text;

  // Find the last space before the limit
  const lastSpace = text.lastIndexOf(" ", limit);

  // If no space found or space is at the start, cut at exact limit
  if (lastSpace === -1 || lastSpace === 0) {
    return text.slice(0, limit).trim() + "...";
  }

  // Cut at the last space
  return text.slice(0, lastSpace).trim() + "...";
};

/**
 * Highlights search terms within text while handling special cases
 */
const TextHighlighter = ({
  text = "",
  globalSearch = "",
  inputValue = "",
  source = "",
}) => {
  // Move all processing into useMemo to maintain hooks order
  const highlightedContent = useMemo(() => {
    // Handle empty text case
    if (!text) {
      return "";
    }

    // Process text based on source
    let processedText = text;
    if (source?.toLowerCase() === "twitter") {
      processedText = truncateText(processedText, 220);
    }

    const searchTerm = globalSearch || inputValue;

    // If no search term, return processed text
    if (!searchTerm) {
      return processedText;
    }

    try {
      // Escape special regex characters and split search terms
      const terms = searchTerm
        .split(/[\s&|]+/)
        .map((term) => term.trim())
        .filter((term) => term)
        .map((term) => term.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));

      if (terms.length === 0) {
        return processedText;
      }

      // Create regex with word boundaries for better matching
      const regex = new RegExp(`(${terms.join("|")})`, "giu");

      // Split and map content with highlights
      return processedText.split(regex).map((part, index) =>
        regex.test(part) ? (
          <mark key={`highlight-${index}`} className="search-highlight">
            {part}
          </mark>
        ) : (
          part
        )
      );
    } catch (error) {
      console.error("Highlighting error:", error);
      return processedText;
    }
  }, [text, globalSearch, inputValue, source]);

  return <span className="text-highlighter">{highlightedContent}</span>;
};

export default TextHighlighter;
