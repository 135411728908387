import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  MdOutlineDashboard, // for Dashboard
  MdOutlinePeopleAlt, // for Users
  MdOutlineLiveTv, // for Channels
  MdOutlineAssignmentTurnedIn, // for Channel Requests
  MdOutlineCorporateFare, // for Organizations
  MdOutlineLabel,
  MdOutlineHome,
  MdOutlineLogout,
  MdOutlinePayments,
} from "react-icons/md";
import "./Sidebar.scss";
import { useAuth } from "../../../components/authentication/auth-context/AuthContext";
import { handleLogout } from "../../../components/authentication/logout/Logout";
import { useTranslation } from "../../../components/translate/TranslateContext";

const Sidebar = () => {
  const { role } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const { translate } = useTranslation();

  return (
    <>
      <nav
        className="sidebar afj-sidebar 
          sidebar-show"
      >
        <div className="sidebar-top">
          <div className="sidebar-brand">
            <span className="sidebar-brand-text">Teledeck</span>
          </div>
          <div>
            <h3>
              {role === "admin"
                ? translate("Welcome, Administrator")
                : translate("Welcome, Business Admin")}
            </h3>
          </div>
        </div>

        <div className="sidebar-body">
          <div
            className="sidebar-menu"
            style={{
              maxHeight: "550px",
              overflowY: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "#adb5bd #f8f9fa",
            }}
          >
            <ul className="menu-list">
              {role === "admin" && (
                <>
                  <li className="menu-item">
                    <Link
                      to="/admin"
                      className={
                        location.pathname === "/admin"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlineDashboard size={18} />
                      </span>
                      <span className="menu-link-text">
                        {translate("Dashboard")}
                      </span>
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/admin/users"
                      className={
                        location.pathname === "/admin/users"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlinePeopleAlt size={20} />
                      </span>
                      <span className="menu-link-text">
                        {translate("Users")}
                      </span>
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/admin/organizations"
                      className={
                        location.pathname === "/admin/organizations"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlineCorporateFare size={20} />
                      </span>
                      <span className="menu-link-text">
                        {translate("Organizations")}
                      </span>
                    </Link>
                  </li>

                  <li className="menu-item">
                    <Link
                      to="/admin/bundles"
                      className={
                        location.pathname === "/admin/bundles"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlinePayments size={20} />
                      </span>
                      <span className="menu-link-text">
                        {translate("Bundles")}
                      </span>
                    </Link>
                  </li>

                  <li className="menu-item">
                    <Link
                      to="/admin/subscriptions"
                      className={
                        location.pathname === "/admin/subscriptions"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlinePayments size={20} />
                      </span>
                      <span className="menu-link-text">
                        {translate("Subscriptions")}
                      </span>
                    </Link>
                  </li>

                  <li className="menu-item">
                    <Link
                      to="/admin/payments"
                      className={
                        location.pathname === "/admin/payments"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlinePayments size={20} />
                      </span>
                      <span className="menu-link-text">
                        {translate("Payments")}
                      </span>
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/admin/channels"
                      className={
                        location.pathname === "/admin/channels"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlineLiveTv size={20} />
                      </span>
                      <span className="menu-link-text">
                        {translate("Channels")}
                      </span>
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/admin/channel-requests"
                      className={
                        location.pathname === "/admin/channel-requests"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlineAssignmentTurnedIn size={20} />
                      </span>
                      <span className="menu-link-text">
                        {translate("Channel Requests")}
                      </span>
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link
                      to="/admin/tags"
                      className={
                        location.pathname === "/admin/tags"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlineLabel size={20} />
                      </span>
                      <span className="menu-link-text">
                        {translate("Tags")}
                      </span>
                    </Link>
                  </li>
                </>
              )}

              {role === "business" && (
                <>
                  {/* <li className="menu-item">
                    <Link
                      to="/admin/business-dashboard"
                      className={
                        location.pathname === "/admin/business-dashboard"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlineDashboard size={18} />
                      </span>
                      <span className="menu-link-text">Dashboard</span>
                    </Link>
                  </li> */}
                  <li className="menu-item">
                    <Link
                      to="/admin/business-users"
                      className={
                        location.pathname === "/admin/business-users"
                          ? "menu-link active"
                          : "menu-link"
                      }
                    >
                      <span className="menu-link-icon">
                        <MdOutlinePeopleAlt size={20} />
                      </span>
                      <span className="menu-link-text">
                        {translate("Organization Users")}
                      </span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>

          <div className="sidebar-menu sidebar-menu2">
            <ul className="menu-list">
              <li className="menu-item">
                <Link to="/" className="menu-link">
                  <span className="menu-link-icon">
                    <MdOutlineHome size={20} />
                  </span>
                  <span
                    onClick={() => navigate("/")}
                    className="menu-link-text"
                  >
                    {translate("Home")}
                  </span>
                </Link>
              </li>
              <li className="menu-item">
                <Link
                  to="#"
                  className="menu-link"
                  onClick={() => handleLogout(translate)}
                >
                  <span className="menu-link-icon">
                    <MdOutlineLogout size={20} />
                  </span>
                  <span className="menu-link-text">{translate("Logout")}</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
