import { toast } from "sonner";
import Swal from "sweetalert2";
export const website = "https://data.teledeck.news";
// export const website = "http://localhost:3000";
export const webApi = `${website}/api`;
export const webSse = `${website}/sse`;
export const mapSse = `${website}/sse/map`;
export const dashboardSse = `${website}/sse/dashboard`;

const handleResponse = (response) => {
  // Session has expired or is unauthorized
  if (response.status === 401) {
    Swal.fire({
      icon: "warning",
      title: "Session Expired",
      text: "Your session has expired. Please log in again.",
      showCancelButton: true,
      confirmButtonText: "Refresh",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }
  return response;
};

export const getAuth = async (url) => {
  return fetch(`${webApi}${url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then(handleResponse);
};

export const post = async (url, data) => {
  console.log("webApi: ", webApi);
  return fetch(`${webApi}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    credentials: "include",
  }).then(handleResponse);
};

export const postAuth = async (url, data) => {
  return fetch(`${webApi}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    credentials: "include",
  }).then(handleResponse);
};

export const putAuth = async (url, data) => {
  return fetch(`${webApi}${url}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    credentials: "include",
  }).then(handleResponse);
};

export const deleteAuth = async (url) => {
  return fetch(`${webApi}${url}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  }).then(handleResponse);
};

export const handleNavigation = (bundle, navigate, targetPath) => {
  if (bundle !== "free_trial" && bundle !== "solo") {
    if (navigate) {
      navigate(targetPath);
    }
  } else {
    toast.error("This feature is not available in your plan");
    if (navigate) {
      navigate("/bundles");
    }
  }
};
