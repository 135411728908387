import { Box, Card, Tooltip, styled } from "@mui/material";
import HeaderSearch from "./Search";
import HeaderUserbox from "./Userbox";
import Logo from "./Logo";
import MenuIcon from "@mui/icons-material/Menu";

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: 0;
    inset-inline-end: 0;
    z-index: 6;
    background-color: rgb(30, 40, 54);
    backdrop-filter: blur(3px);
    position: fixed;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
`
);

const BoxLogoWrapper = styled(Box)(
  ({ theme }) => `
  margin-inline-end: ${theme.spacing(2)};

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    width: calc(${theme.sidebar.width} - ${theme.spacing(4)});
  }
    
`
);

function Header() {
  return (
    <HeaderWrapper>
      <Box display="flex" alignItems="center">
        <BoxLogoWrapper>
          <Logo />
        </BoxLogoWrapper>
        <Box
          component="span"
          sx={{
            display: { xs: "none", sm: "inline-block" },
          }}
        >
          <HeaderSearch />
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <HeaderUserbox />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
