import React from "react";
import styled from "styled-components";
import { useTutorial } from "../tutorial/TutorialContext";
import { useTranslation } from "../translate/TranslateContext";

const ItemContainer = styled.div`
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: white;
  box-sizing: border-box;
  padding: 20px 10px;
  background-color: ${({ theme }) => theme.nav_background};

  &:hover {
    background-color: ${({ theme }) => theme.nav_hover};
  }

  @media (max-width: 768px) {
    display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "")};
  }
`;

const ItemText = styled.div`
  font-size: 14px;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
  max-width: 100px;
`;

const ItemIcon = styled.i`
  font-size: 24px;
  color: white;
`;

const ItemNumber = styled.div`
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 12px;
`;

const NavItem = ({
  icon,
  name,
  number,
  onClick,
  isClickable,
  newColumn,
  map,
  hideOnMobile = false,
}) => {
  const { translate } = useTranslation();
  const { stepIndex, setStepIndex } = useTutorial();

  const handleClick = () => {
    if (onClick && isClickable) {
      if (stepIndex === 2) {
        setStepIndex(3);
      }

      onClick();
    }
  };

  return (
    <ItemContainer
      onClick={handleClick}
      style={{ pointerEvents: isClickable ? "auto" : "none" }}
      id={newColumn ? "new-column" : map ? "map-button" : ""}
      hideOnMobile={hideOnMobile}
    >
      <ItemIcon className="material-icons">{icon}</ItemIcon>
      <ItemText>{translate(name)}</ItemText>
      <ItemNumber>{number}</ItemNumber>
    </ItemContainer>
  );
};

export default NavItem;
