import React, { useState } from "react";
import styled from "styled-components";
import { useTheme } from "../../../themes/ThemeContext";
import { DatePicker } from "../../../../global/styled-components/DatePicker";
import { useSearch } from "./SearchContext";
import Select from "react-select";
import { useColumns } from "../../../column/ColumnsContext";
import { formatDateTimeForInput } from "../../../../global/dateUtils";
import { DateTime } from "luxon";
import { useTranslation } from "../../../translate/TranslateContext";

const StyledSelect = styled(Select)`
  font-size: 14px;
  width: 100%;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
`;

export const SearchInputContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 8px 12px 8px 36px; // Increased left padding for icon
  border: 1px solid ${({ theme }) => theme.border_color};
  border-radius: 4px;
  background: ${({ theme }) => theme.column_background};
  color: ${({ theme }) => theme.column_text};
  font-size: 14px;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.blue};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.blue}20;
  }

  &::placeholder {
    color: ${({ theme }) => theme.column_text};
    opacity: 0.5;
  }
`;

export const SearchIcon = styled.span`
  position: absolute;
  inset-inline-start: 8px;
  color: ${({ theme }) => theme.column_text};
  opacity: 0.5;
  font-size: 20px;
  pointer-events: none;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
  color: ${({ theme }) => theme.column_text};
  cursor: pointer;
  user-select: none;
`;

export const Checkbox = styled.input`
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 16px;
  justify-content: flex-end;
`;

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
  min-width: 120px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .material-icons {
    font-size: 18px;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.blue}30;
  }
`;

export const SearchButton = styled(BaseButton)`
  background: #1e2836;
  color: white;
  border: none;

  &:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px ${({ theme }) => theme.blue}30;
  }

  &:active:not(:disabled) {
    transform: translateY(0);
    box-shadow: none;
  }
`;

const ClearButton = styled(BaseButton)`
  background: transparent;
  color: ${({ theme }) => theme.column_text};
  border: 1px solid ${({ theme }) => theme.border_color};

  &:hover:not(:disabled) {
    background: ${({ theme }) =>
      theme.isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.05)"};
  }

  .material-icons {
    color: ${({ theme }) => theme.error || "#dc3545"};
  }
`;

const SearchComponent = () => {
  const { theme } = useTheme();
  const { columns } = useColumns();
  const [searchInput, setSearchInput] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [isExactMatch, setIsExactMatch] = useState(true);

  const [selectedColumns, setSelectedColumns] = useState([]);
  const {
    setGlobalSearch,
    setGlobalDate,
    setGlobalColumns,
    setColumnExactMatch,
    clearExactMatchSettings,
  } = useSearch();

  const { translate } = useTranslation();

  const handleColumnChange = (selectedOptions) => {
    setSelectedColumns(selectedOptions);
    setGlobalColumns(selectedOptions.map((option) => option.value));
  };

  const handleSearch = (e) => setSearchInput(e.target.value);
  const handleSearchDateChange = (e) => setSearchDate(e.target.value);
  const handleSearchPress = () => {
    setGlobalSearch(searchInput);
    setGlobalDate(searchDate);
    // If specific columns are selected, set exact match for only those columns
    if (selectedColumns.length > 0) {
      columns.forEach((column) => {
        if (selectedColumns.find((selected) => selected.value === column.id)) {
          setColumnExactMatch(column.id, isExactMatch);
        }
      });
    } else {
      // If no columns are selected, set exact match for all columns
      columns.forEach((column) => {
        setColumnExactMatch(column.id, isExactMatch);
      });
    }
  };

  const clearFilters = () => {
    setSearchInput("");
    setSearchDate("");
    setSelectedColumns([]);
    setGlobalSearch("");
    setGlobalDate("");
    setGlobalColumns([]);
    // Reset exact match for all columns
    clearExactMatchSettings();
    setIsExactMatch(true);
  };

  return (
    <div className="search-top">
      <SearchContainer>
        <SearchInputContainer>
          <SearchInput
            type="text"
            placeholder={translate("Search...")}
            value={searchInput}
            onChange={handleSearch}
            title="Press Enter to search"
          />
          <SearchIcon className="material-icons">search</SearchIcon>
        </SearchInputContainer>

        <CheckboxContainer>
          <CheckboxLabel>
            <Checkbox
              type="checkbox"
              checked={isExactMatch}
              onChange={(e) => setIsExactMatch(e.target.checked)}
            />
            {translate("Match Exact Words")}
          </CheckboxLabel>
        </CheckboxContainer>
      </SearchContainer>

      <StyledSelect
        isMulti
        options={columns.map((column) => ({
          value: column.id,
          label: column.name,
        }))}
        value={selectedColumns}
        onChange={handleColumnChange}
        placeholder={translate("Select Specific Columns (optional)")}
        theme={theme}
        styles={{
          control: (base, state) => ({
            ...base,
            fontSize: "14px",
            backgroundColor:
              theme === "dark" ? "#333" : theme.column_background,
          }),
          menu: (base) => ({
            ...base,
            backgroundColor: theme === "dark" ? "#333" : "#fff",
          }),
          singleValue: (base) => ({
            ...base,
            color: theme === "dark" ? "#fff" : "#000",
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused
              ? theme === "dark"
                ? "#555"
                : "#ddd"
              : theme === "dark"
              ? "#333"
              : "#fff",
            color: state.isFocused
              ? theme === "dark"
                ? "#fff"
                : "#000"
              : theme === "dark"
              ? "#fff"
              : "#000",
            cursor: "pointer",
          }),
          input: (base) => ({
            ...base,
            color: theme.text,
          }),
        }}
      />

      <DatePicker
        type="datetime-local"
        className="date-time-picker"
        $theme={theme}
        value={searchDate}
        onChange={handleSearchDateChange}
        title={translate("Filter By Date")}
        max={formatDateTimeForInput(DateTime.now().toISO())}
      />

      <ButtonContainer>
        <ClearButton
          onClick={clearFilters}
          title={translate("Clear all filters")}
        >
          <span className="material-icons">clear</span>
          {translate("Clear Filters")}
        </ClearButton>

        <SearchButton
          onClick={handleSearchPress}
          title={translate("Search across all selected columns")}
        >
          <span className="material-icons">search</span>
          {translate("Search")}
        </SearchButton>
      </ButtonContainer>
    </div>
  );
};

export default SearchComponent;
