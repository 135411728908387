import React from "react";
import { Chip, StyledSourceImage } from "./ColumnStyles";
import { updateColumnsToAPIChips } from "../column-utils/ColumnUtils";
import { updateColumnInDB } from "../../localdb/DBColumn";
import { website } from "../../../global/globalUtils";
import { useTranslation } from "../../translate/TranslateContext";

const FilterChips = ({
  column,
  setColumn,
  selectedSource,
  handleSourceClick,
  setForceUpdate,
  updateColumn,
  updateTimestamp,
}) => {
  const { translate } = useTranslation();
  const handleChipClick = async (chipType) => {
    updateTimestamp();
    const updates = {
      get_text_only: 0,
      get_image: column.get_image,
      get_video: column.get_video,
      get_preview: 0,
    };

    switch (chipType) {
      case "textOnly":
        updates.get_text_only = column.get_text_only ? 0 : 1;
        break;
      case "images":
        updates.get_image = column.get_image ? 0 : 1;
        break;
      case "videos":
        updates.get_video = column.get_video ? 0 : 1;
        break;
      case "preview":
        updates.get_preview = column.get_preview ? 0 : 1;
        break;
      default:
        break;
    }

    if (chipType === "preview" && updates.get_preview === 1) {
      updates.get_text_only = 0;
      updates.get_image = 0;
      updates.get_video = 0;
    } else if (
      (chipType === "images" || chipType === "videos") &&
      (updates.get_image === 1 || updates.get_video === 1)
    ) {
      updates.get_preview = 0;
    } else if (chipType === "textOnly" && updates.get_text_only === 1) {
      updates.get_image = 0;
      updates.get_video = 0;
      updates.get_preview = 0;
    }

    const updatedColumn = {
      ...column,
      ...updates,
    };

    setColumn(updatedColumn);
    setForceUpdate((f) => !f);

    try {
      await updateColumnInDB(column.id, updatedColumn);
    } catch (error) {
      console.error("Failed to update column in DB:", error);
    }

    try {
      await updateColumnsToAPIChips(updatedColumn);
    } catch (error) {
      console.error("Failed to update column in API:", error);
    }

    updateColumn(updatedColumn);
  };

  return (
    <div className="chips-container" id="column-header-filters">
      <div className="chips-container">
        <Chip
          $active={selectedSource === "Telegram"}
          onClick={() => handleSourceClick("Telegram")}
          className="material-symbols-outlined chip-icon"
          title={translate("Filter By Telegram Posts")}
        >
          <StyledSourceImage
            src={`${website}/source-logo/telegram.svg`}
            alt="Telegram Logo"
          />
        </Chip>
        <Chip
          $active={selectedSource === "Twitter"}
          onClick={() => handleSourceClick("Twitter")}
          className="material-symbols-outlined chip-icon"
          title={translate("Filter By Twitter Posts")}
        >
          <StyledSourceImage
            src={`${website}/source-logo/twitter.svg`}
            alt="Twitter Logo"
          />
        </Chip>
        <Chip
          $active={selectedSource === "Facebook"}
          onClick={() => handleSourceClick("Facebook")}
          className="material-symbols-outlined chip-icon"
          title={translate("Filter By Facebook Posts")}
        >
          <StyledSourceImage
            src={`${website}/source-logo/facebook.svg`}
            alt="Facebook Logo"
          />
        </Chip>
      </div>

      {/* <hr className="vertical-line" /> */}
      <div className="chips-container">
        <Chip
          $active={column.get_text_only === 1}
          onClick={() => handleChipClick("textOnly")}
          className="material-symbols-outlined chip-icon"
          title={translate("Filter By Text Only")}
        >
          title
        </Chip>
        {/* <hr className="vertical-line" /> */}

        <Chip
          $active={column.get_preview === 1}
          onClick={() => handleChipClick("preview")}
          className="material-symbols-outlined chip-icon"
          title={translate("Filter By Previews")}
        >
          preview
        </Chip>
        {/* <hr className="vertical-line" /> */}

        <Chip
          $active={column.get_image === 1}
          onClick={() => handleChipClick("images")}
          className="material-symbols-outlined chip-icon"
          title={translate("Filter By Images")}
        >
          image
        </Chip>

        <Chip
          $active={column.get_video === 1}
          onClick={() => handleChipClick("videos")}
          className="material-symbols-outlined chip-icon"
          title={translate("Filter By Videos")}
        >
          videocam
        </Chip>
      </div>
    </div>
  );
};

export default FilterChips;
