import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import AppTheme from "../shared-theme/AppTheme";
import { CircularProgress } from "@mui/material";
import { useAuth } from "../auth-context/AuthContext";
import { toast } from "sonner";
import { useTheme } from "../../themes/ThemeContext";
import logo from "../../../assets/images/teledeck.png";
import whiteLogo from "../../../assets/images/Teledeck.svg";
import { useNavigate } from "react-router-dom";
import { startTrial } from "./utils";
import { useTranslation } from "../../translate/TranslateContext";
import styledComponents from "styled-components";
import LanguageSwitcher from "../../settings/tabs/components/LanguageSwitcher";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

const LanguageContainer = styledComponents.div`
  position: absolute;
  inset-inline-start: 10px;
  top: 10px;
`;

export default function RegisterTrial(props) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { translate } = useTranslation();

  const validateInputs = () => {
    let isValid = true;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage(translate("Please enter a valid email address."));
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage("");
    }

    if (!password || password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage(
        translate("Password must be at least 6 characters long.")
      );
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
    }

    if (confirmPassword !== password) {
      setConfirmPasswordError(true);
      setConfirmPasswordErrorMessage(translate("Passwords do not match."));
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    if (emailError || passwordError || confirmPasswordError) {
      event.preventDefault();
      return;
    }

    event.preventDefault();
    if (!validateInputs()) return;

    const formData = {
      email,
      password,
      confirmPassword,
    };

    try {
      setLoading(true);
      const response = await startTrial(formData);
      const data = response.data;

      if (response.success) {
        setAuth({
          isAuthenticated: true,
          role: data.role,
          isLoading: false,
          userId: data.userId,
          bundle: data.bundle,
        });
        navigate("/verify-email", { replace: true });
        toast.success(translate("Registration Successful"));
      } else {
        console.error("Registration Error:", response.message);
        toast.error(`${response.message}`);
      }
    } catch (error) {
      toast.error(translate("Something went wrong. Please try again later."));
      console.error("Registration Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppTheme {...props}>
      <LanguageContainer>
        <LanguageSwitcher />
      </LanguageContainer>
      <CssBaseline enableColorScheme />
      <Stack
        direction={{ xs: "column-reverse", md: "row" }}
        sx={{
          justifyContent: "center",
          gap: { xs: 6, sm: 12 },
          p: { xs: 2, sm: 4, md: 0 },
          m: "auto",
        }}
      >
        <SignUpContainer direction="column" justifyContent="space-between">
          <Card variant="outlined">
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <img
                src={theme === "light" ? logo : whiteLogo}
                alt="Teledeck"
                style={{ width: "250px", alignSelf: "center" }}
              />

              <FormControl>
                <FormLabel htmlFor="email">{translate("Email")}</FormLabel>
                <TextField
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(false);
                    setEmailErrorMessage("");
                  }}
                  required
                  fullWidth
                  id="email"
                  placeholder="your@email.com"
                  name="email"
                  autoComplete="email"
                  variant="outlined"
                  error={emailError}
                  helperText={emailErrorMessage}
                  color={passwordError ? "error" : "primary"}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      textAlign: "start", // Ensures the helper text is aligned to the right for RTL
                    },
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="password">
                  {translate("Password")}
                </FormLabel>
                <TextField
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError(false);
                    setPasswordErrorMessage("");
                  }}
                  required
                  fullWidth
                  name="password"
                  placeholder="••••••"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  variant="outlined"
                  error={passwordError}
                  helperText={passwordErrorMessage}
                  color={passwordError ? "error" : "primary"}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      textAlign: "start", // Ensures the helper text is aligned to the right for RTL
                    },
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="confirmPassword">
                  {translate("Confirm Password")}
                </FormLabel>
                <TextField
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setConfirmPasswordError(false);
                    setConfirmPasswordErrorMessage("");
                  }}
                  required
                  fullWidth
                  name="confirmPassword"
                  placeholder="••••••"
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  variant="outlined"
                  error={confirmPasswordError}
                  helperText={confirmPasswordErrorMessage}
                  color={confirmPasswordError ? "error" : "primary"}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      textAlign: "start", // Ensures the helper text is aligned to the right for RTL
                    },
                  }}
                />
              </FormControl>

              <Button
                type="submit"
                disabled={loading}
                fullWidth
                variant="contained"
                startIcon={loading && <CircularProgress size={20} />}
                style={{ backgroundColor: "rgb(30, 40, 54)" }}
              >
                {loading ? "" : translate("Start Your 7 Days Free Trial")}
              </Button>
            </Box>
          </Card>
        </SignUpContainer>
      </Stack>
    </AppTheme>
  );
}
