import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getAuth, website } from "../../global/globalUtils";
import avatar from "../../assets/images/default-avatar.avif";
import countries from "i18n-iso-countries";
import Select from "react-select";
import { useTheme } from "../themes/ThemeContext";
import { toast } from "sonner";
import { useTranslation } from "../translate/TranslateContext";

const SidebarWrapper = styled.div`
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.popup_background || "#fff"};
  border: 1px solid ${({ theme }) => theme.border_color || "#ddd"};
  border-inline-start: none;
  border-radius: 8px 0 0 8px;
  width: 280px;
  overflow: visible;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1), 1px 0 4px -2px rgba(0, 0, 0, 0.15);
  z-index: 2;
  transition: transform 0.3s ease;
  transform: ${({ $isOpen, $dir }) =>
    $isOpen
      ? "translateX(0)"
      : $dir === "rtl"
      ? "translateX(100%)"
      : "translateX(-100%)"};
`;

const SidebarContent = styled.div`
  padding: 20px;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scrollbar_thumb || "#888"};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.scrollbar_track || "#f1f1f1"};
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text || "#333"};
`;

const DateInput = styled.input`
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.border_color || "#ddd"};
  border-radius: 6px;
  font-size: 14px;
  outline: none;
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text || "#333"};

  &:focus {
    border-color: ${({ theme }) => theme.primary || "#1e2836"};
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const ChannelInput = styled.input`
  width: 90%;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.border_color || "#ddd"};
  border-radius: 6px;
  font-size: 14px;
  outline: none;
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text || "#333"};

  &:focus {
    border-color: ${({ theme }) => theme.primary || "#1e2836"};
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  max-width: 100%;
  top: 100%;
  inset-inline-start: 0;
  inset-inline-end: 0;
  background-color: ${({ theme }) => theme.popup_background || "#fff"};
  border: 1px solid ${({ theme }) => theme.border_color || "#ddd"};
  border-radius: 6px;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scrollbar_thumb || "#888"};
    border-radius: 4px;
  }
`;

export const DropdownItem = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) =>
      theme.post_hover_background || "#f5f5f5"};
  }
`;

const Avatar = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 8px;
  background-color: ${({ color }) => color || "#1e2836"};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-weight: 500;
  color: white;
  font-size: 12px;
  text-transform: uppercase;
`;

export const ChannelInfo = styled.div`
  flex: 1;
  min-width: 0; // Enables text truncation
`;

export const ChannelName = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.text || "#333"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SourceIcon = styled.img`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 15px;
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px 4px 4px;
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text || "#333"};
  border: 1px solid ${({ theme }) => theme.border_color || "#ddd"};
  border-radius: 16px;
  font-size: 12px;
  max-width: 200px;

  .avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }

  .channel-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .close-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 2px;
    margin-inline-start: auto;
    color: ${({ theme }) => theme.text || "#333"};

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const NoResults = styled.div`
  padding: 8px;
  color: ${({ theme }) => theme.text_secondary || "#666"};
  font-size: 14px;
  text-align: center;
`;

export const ErrorMessage = styled.div`
  color: #dc3545;
  font-size: 12px;
  margin-top: 4px;
`;

const sidebarVariants = {
  open: {
    x: 280,
    opacity: 1,
    visibility: "visible",
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
      opacity: { duration: 0 }, // Instant opacity change
      visibility: { duration: 0 }, // Instant visibility change
    },
  },
  closed: {
    x: 0,
    opacity: 0,
    visibility: "hidden",
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 30,
      opacity: { delay: 0.2 }, // Delay opacity change until after movement
      visibility: { delay: 0.2 }, // Delay visibility change until after movement
    },
  },
};

// countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

// const countryOptions = Object.keys(countries.getAlpha2Codes()).map((code) => ({
//   value: code,
//   label: countries.getName(code, "en"),
// }));

const FilterSidebar = ({
  isOpen,
  onClose,
  dateRange,
  setDateRange,
  selectedChannels,
  setSelectedChannels,
  // setSelectedCountries,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [error, setError] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const [channels, setChannels] = useState([]);
  // const [selectedChannelData, setSelectedChannelData] = useState([]);
  const sidebarRef = useRef(null);
  // const [countries, setCountries] = useState([]);
  // const { theme } = useTheme();
  const { translate, direction } = useTranslation();
  const controllerRef = useRef(null);
  const latestRequestTime = useRef(0);

  // const handleCountryChange = (selectedOptions) => {
  //   if (selectedOptions.length > 5) {
  //     toast.error(translate("Maximum of 5 countries allowed"));
  //     return;
  //   }
  //   setCountries(selectedOptions);
  //   setSelectedCountries(selectedOptions.map((option) => option.value));
  // };

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        if (controllerRef.current) {
          controllerRef.current.abort();
        }

        // Create a new controller for this request
        const controller = new AbortController();
        controllerRef.current = controller;

        // Capture the timestamp of this request
        const requestTime = Date.now();
        latestRequestTime.current = requestTime;

        const response = await getAuth(
          `/dashboard/top-channels?search=${searchTerm}`,
          { signal: controller.signal }
        );
        const data = await response.json();
        // Filter out already selected channels
        const filteredChannels = data.channels.filter(
          (channel) => !selectedChannels.includes(channel)
        );
        if (requestTime >= latestRequestTime.current) {
          setChannels(filteredChannels);
        }
      } catch (error) {
        console.error("Error fetching channels", error);
      }
    };

    const debounceTimer = setTimeout(() => {
      fetchChannels();
    }, 300);

    return () => {
      clearTimeout(debounceTimer);
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [searchTerm, selectedChannels]);

  const handleChannelSelect = (channel) => {
    if (selectedChannels.length >= 10) {
      setError(translate("Maximum of 10 channels allowed"));
      return;
    }

    if (!selectedChannels.includes(channel)) {
      setSelectedChannels((prev) => [...prev, channel]);
      // setSelectedChannelData((prev) => [...prev, channel]);
      setSearchTerm("");
      setError("");
      setShowDropdown(false);
    }
  };

  const handleRemoveChannel = (channelToRemove) => {
    setSelectedChannels((prev) =>
      prev.filter((channelId) => channelId !== channelToRemove)
    );
    // setSelectedChannelData((prev) =>
    //   prev.filter((channel) => channel.id !== channelToRemove.id)
    // );
    setError("");
  };

  const handleClickOutside = (event) => {
    // Handle dropdown click outside
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }

    // Handle sidebar click outside
    if (
      isOpen &&
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target)
    ) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Only add the event listener if the sidebar is open
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isOpen, onClose]);

  return (
    <SidebarWrapper
      ref={sidebarRef}
      initial="closed"
      $isOpen={isOpen}
      // animate={isOpen ? "open" : "closed"}
      variants={sidebarVariants}
      $dir={direction}
    >
      <SidebarContent>
        <Section>
          <SectionTitle>{translate("Date Range")}</SectionTitle>
          <DateInput
            type="date"
            value={dateRange.from || ""}
            onChange={(e) =>
              setDateRange((prev) => ({ ...prev, from: e.target.value }))
            }
            placeholder={translate("Start date")}
          />
          <DateInput
            type="date"
            value={dateRange.to || ""}
            onChange={(e) =>
              setDateRange((prev) => ({ ...prev, to: e.target.value }))
            }
            placeholder={translate("End date")}
          />
        </Section>

        {/* <Section>
          <SectionTitle>{translate("Countries")}</SectionTitle>
          <SelectWrapper>
            <InputWrapper>
              <Select
                isMulti
                options={countryOptions}
                value={countries}
                onChange={handleCountryChange}
                placeholder={translate("Select countries...")}
                styles={{
                  control: (base) => ({
                    ...base,
                    fontSize: "14px",
                    backgroundColor: theme === "dark" ? "#333" : "#F8F8F8",
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: theme === "dark" ? "#333" : "#fff",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: theme === "dark" ? "#fff" : "#000",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused
                      ? theme === "dark"
                        ? "#555"
                        : "#ddd"
                      : theme === "dark"
                      ? "#333"
                      : "#fff",
                    color: state.isFocused
                      ? theme === "dark"
                        ? "#fff"
                        : "#000"
                      : theme === "dark"
                      ? "#fff"
                      : "#000",
                    cursor: "pointer",
                  }),
                }}
              />
            </InputWrapper>
          </SelectWrapper>
        </Section> */}

        <Section>
          <SectionTitle>{translate("Channels")}</SectionTitle>
          <SelectWrapper>
            <InputWrapper>
              <ChannelInput
                ref={inputRef}
                type="text"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(() => e.target.value);
                  setShowDropdown(true);
                }}
                onFocus={() => setShowDropdown(true)}
                placeholder={translate("Search channels...")}
              />
              {showDropdown && (
                <Dropdown ref={dropdownRef}>
                  {channels.length > 0 ? (
                    channels.map((channel) => (
                      <DropdownItem
                        key={channel.id}
                        onClick={() => handleChannelSelect(channel)}
                      >
                        <Avatar
                          src={
                            channel.profile_picture_url
                              ? channel.profile_picture_url
                              : channel.image
                              ? `${website}${channel.image}`
                              : avatar
                          }
                          alt="Channel Icon"
                          onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop if fallback image also fails
                            e.target.src = avatar; // Set fallback image
                          }}
                        />

                        <ChannelInfo>
                          <ChannelName>{channel.chat_title}</ChannelName>
                        </ChannelInfo>
                        <SourceIcon
                          src={`${website}${channel.source_logo}`}
                          alt="Source Icon"
                        />
                      </DropdownItem>
                    ))
                  ) : (
                    <NoResults>{translate("No channels found")}</NoResults>
                  )}
                </Dropdown>
              )}
            </InputWrapper>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </SelectWrapper>

          <ChipsContainer>
            {selectedChannels.map((channel) => (
              <Chip key={channel.id}>
                <Avatar
                  src={
                    channel.profile_picture_url
                      ? channel.profile_picture_url
                      : channel.image
                      ? `${website}${channel.image}`
                      : avatar
                  }
                  alt="Channel Icon"
                  onError={(e) => {
                    e.target.onerror = null; // Prevent infinite loop if fallback image also fails
                    e.target.src = avatar; // Set fallback image
                  }}
                />

                <ChannelInfo>
                  <ChannelName>{channel.chat_title}</ChannelName>
                </ChannelInfo>
                <SourceIcon
                  src={`${website}${channel.source_logo}`}
                  alt="Source Icon"
                />
                <span
                  className="close-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveChannel(channel);
                  }}
                >
                  ✕
                </span>
              </Chip>
            ))}
          </ChipsContainer>
        </Section>
      </SidebarContent>
    </SidebarWrapper>
  );
};

export default FilterSidebar;
