import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  CategorySelect,
  defaultColumn,
  HeaderContainer,
  NoBookmarksMessage,
  SearchContainer,
  SearchIcon,
  SearchInput,
} from "../side-panel/tabs/bookmarks/Bookmarks";
import { useBookmarks } from "../bookmarks/bookmark_context";
import CopyPost from "./components/CopyPost";
import SharePopup from "./components/SharePopup";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useTheme } from "../themes/ThemeContext";
import blackLogo from "../../assets/images/teledeck-no-phrase.png";
import whiteLogo from "../../assets/images/Teledeck.svg";
import { handleTextInput, mergeAllPosts } from "./utils";
import Chat from "./components/Chat";
import { useTranslation } from "../translate/TranslateContext";
import TranslateColumn from "../column/column-components/TranslateColumn";
import { translateTexts } from "../column/column-utils/ColumnUtils";
import { useTranslationLoading } from "../column/TranslationLoadingContext";

// Height of the navbar
const NAVBAR_HEIGHT = "60px";

// This container will serve as the parent container for the whole page
const PageContainer = styled.div`
  display: flex;
  height: calc(100vh - ${NAVBAR_HEIGHT});
  margin-top: ${NAVBAR_HEIGHT}; /* Push the content below the navbar */
  overflow: hidden;
`;

const FlexFullWidth = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;

const Navbar = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: ${NAVBAR_HEIGHT}; /* Set the navbar height */
  z-index: 1000;
  background-color: ${({ theme }) => (theme === "light" ? "#fff" : "#222f3e")};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Logo = styled.img`
  width: 180px;
  cursor: pointer;
`;

const Title = styled.h1`
  font-size: 24px; /* Increased font size */
  font-weight: 700; /* Bolder weight */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Subtle text shadow */
  margin: 0;
  text-align: center;
  flex-grow: 1;
  animation: fadeIn 1.5s ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const NavLinksContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NavLink = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.text};
  margin-inline-start: 20px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
  position: relative;

  &:hover {
    color: blue;
  }

  .material-icons {
    margin-inline-start: 5px;
  }
`;

const ShareNavLink = styled(NavLink)`
  position: relative;
`;

const BookmarksContainer = styled.div`
  width: 400px;
  height: 100%;
  flex-shrink: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => (theme === "light" ? "#fff" : "#222f3e")};
  padding: 10px;
  border-inline-end: 1px solid
    ${({ theme }) => (theme === "light" ? "#ddd" : "#333")}; /* Add a slight border on the right */

  &::-webkit-scrollbar {
    width: 6px; /* Make the scrollbar thinner */
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme === "light" ? "#EBEBEB" : " rgba(255, 255, 255, 0.2)"};
    border-radius: 10px;
    transition: background-color 0.3s ease;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-inline-end: 0;
    margin-bottom: 20px;
  }
`;

function BookmarkEditPage() {
  const { bookmarks, categories, filterBookmarksByCategory } = useBookmarks();
  // added states for translations
  // const [translatedMessages, setTranslatedMessages] = useState(new Map());
  const [localMessages, setLocalMessages] = useState([]);
  const [postsLanguage, setPostsLanguage] = useState("original");
  const { setTranslationLoading } = useTranslationLoading();

  //
  const { theme } = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [media, setMedia] = useState(null);
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [textInput, setTextInput] = useState(() => {
    const savedText = localStorage.getItem("textInput");
    return savedText !== null ? savedText : "";
  });
  const [chatContext, setChatContext] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const { translate, direction, language } = useTranslation();

  useEffect(() => {
    localStorage.setItem("textInput", textInput);
  }, [textInput]);

  useEffect(() => {
    if (editorRef.current && media) {
      const imgHtml = `<img src="${media}" width="600" height="400" alt="Media" />`;
      editorRef.current.insertContent(imgHtml);
      setMedia("");
    }
  }, [media]);

  const toggleSharePopup = () => {
    setIsSharePopupOpen(!isSharePopupOpen);
  };

  const handleEditorChange = (content, editor) => {
    setTextInput(content);
  };

  useEffect(() => {
    if (!bookmarks || typeof bookmarks !== "object") return [];

    let posts = filterBookmarksByCategory(selectedCategories);

    // Then filter by search term if it exists
    if (searchTerm.trim()) {
      posts = posts.filter((post) => {
        const postText = post.text ? post.text.toLowerCase() : "";
        return postText.includes(searchTerm.toLowerCase());
      });
    }

    setLocalMessages(posts);
  }, [bookmarks, searchTerm, selectedCategories]);

  useEffect(() => {
    setChatContext((prevContext) => {
      if (isAllSelected) {
        // Add all filtered posts to the context
        const newContext = localMessages
          .filter(
            (post) => !prevContext.some((context) => context.id === post.id)
          )
          .map((post) => ({ id: post.id, text: post.text }));

        return [...prevContext, ...newContext];
      } else {
        // Remove all filtered posts from the context
        return prevContext.filter(
          (context) => !localMessages.some((post) => post.id === context.id)
        );
      }
    });
  }, [isAllSelected, localMessages]);

  const translatedMessagesRef = useRef(new Map());

  useEffect(() => {
    const translateMessages = async () => {
      if (postsLanguage !== "original") {
        // ✅ Use all localMessages (no filtering)
        const newMessages = localMessages;

        if (newMessages.length > 0) {
          const textsToTranslate = newMessages.map((message) => message.text);

          // ✅ Set loading states before calling API
          const loadingStates = {};
          newMessages.forEach((msg) => {
            loadingStates[msg.id] = true;
          });
          setTranslationLoading((prev) => ({ ...prev, ...loadingStates }));

          const translations = await translateTexts(
            textsToTranslate,
            postsLanguage,
            setTranslationLoading
          );

          if (translations) {
            // ✅ Store translations in ref to prevent re-renders
            newMessages.forEach((msg, index) => {
              translatedMessagesRef.current.set(
                msg.id,
                translations[index] || msg.text
              );
            });

            // ✅ Update localMessages only once at the end
            setLocalMessages((prevMessages) =>
              prevMessages.map((msg) => ({
                ...msg,
                text: translatedMessagesRef.current.get(msg.id) || msg.text,
              }))
            );

            // ✅ Update loading states after translation is done
            setTranslationLoading((prev) => {
              const newState = { ...prev };
              newMessages.forEach((msg) => {
                newState[msg.id] = false;
              });
              return newState;
            });
          }
        }
      }
    };

    translateMessages();
  }, [postsLanguage, setTranslationLoading]);

  const handleSelectedCategories = (selectedCategories) => {
    setSelectedCategories(selectedCategories.map((category) => category.value));
  };

  return (
    <>
      <Navbar theme={theme}>
        <Logo
          src={theme === "light" ? blackLogo : whiteLogo}
          alt="Logo"
          onClick={() => {
            navigate("/");
          }}
        />

        <Title>{translate("Bookmarks Text Editor")}</Title>

        <NavLinksContainer>
          <NavLink
            onClick={() => {
              mergeAllPosts(editorRef, localMessages, translate);
            }}
          >
            {translate("Merge All")}
            <span className="material-icons">merge_type</span>
          </NavLink>
          <ShareNavLink
            onClick={() => {
              toggleSharePopup();
            }}
          >
            {translate("Share")}
            <span className="material-icons">share</span>
            <SharePopup
              isOpen={isSharePopupOpen}
              text={handleTextInput(textInput)}
            />
          </ShareNavLink>
          <NavLink
            onClick={() => {
              navigate("/");
            }}
          >
            {translate("Exit")}
            <span className="material-icons">exit_to_app</span>
          </NavLink>
        </NavLinksContainer>
      </Navbar>

      <PageContainer>
        <BookmarksContainer theme={theme}>
          <HeaderContainer>
            <FlexFullWidth>
              <SearchContainer $fullWidth>
                <SearchInput
                  type="text"
                  placeholder={translate("Select...")}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <SearchIcon className="material-icons">search</SearchIcon>
              </SearchContainer>
              <TranslateColumn setLanguage={setPostsLanguage} />
            </FlexFullWidth>
            {/* Multi-select to filter by categories using React Select */}
            {!(categories.length === 0) && (
              <CategorySelect
                isMulti
                options={categories?.map((cat) => ({
                  label: cat.name,
                  value: cat.id,
                }))}
                onChange={handleSelectedCategories}
                placeholder={translate("Select Categories")}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    fontSize: "16px",
                    backgroundColor: theme.inputBackground,
                    borderRadius: "8px",
                    color: theme.text,
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: theme.inputBackground,
                    zIndex: 999999,
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: theme.textColor,
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused
                      ? theme === "dark"
                        ? "#555"
                        : "#ddd"
                      : theme === "dark"
                      ? "#333"
                      : "#fff",
                    color: state.isFocused
                      ? theme === "dark"
                        ? "#fff"
                        : "#000"
                      : theme === "dark"
                      ? "#fff"
                      : "#000",
                    cursor: "pointer",
                  }),
                  input: (base) => ({
                    ...base,
                    color: theme.text,
                  }),
                }}
              />
            )}
          </HeaderContainer>
          {localMessages.length > 0 ? (
            localMessages.map((post, index) => (
              <div
                key={post.post_id}
                style={{
                  paddingBottom:
                    index === localMessages.length - 1 ? "20px" : "0",
                }}
              >
                <CopyPost
                  post={post}
                  column={defaultColumn}
                  is_a_bookmark={true}
                  setTextInput={setTextInput}
                  setMedia={setMedia}
                  chatContext={chatContext}
                  setChatContext={setChatContext}
                />
              </div>
            ))
          ) : (
            <NoBookmarksMessage>
              {translate("No Bookmarked Posts")}
            </NoBookmarksMessage>
          )}
        </BookmarksContainer>
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          value={textInput}
          init={{
            height: "100%",
            width: "100%",
            language: language,
            directionality: direction,
            skin: `${theme === "light" ? "oxide" : "oxide-dark"}`,
            content_css: `${theme === "light" ? "default" : "dark"}`,
            plugins:
              "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
            toolbar:
              "undo redo | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image media | alignleft aligncenter alignright alignjustify | indent outdent | numlist bullist",
            branding: false,
          }}
          onEditorChange={handleEditorChange}
          onInit={(evt, editor) => (editorRef.current = editor)}
        />
      </PageContainer>
      <Chat
        chatContext={chatContext}
        isAllSelected={isAllSelected}
        setIsAllSelected={setIsAllSelected}
      />
    </>
  );
}

export default BookmarkEditPage;
