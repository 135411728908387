import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import SearchBar from "./SearchBar";
import { deleteUser, editUserAPI, fetchUsers, formatDate } from "./UsersUtils";
import { formatToDatetimeLocal } from "../Payments/utils";
import { useTranslation } from "../../../../components/translate/TranslateContext";
import ColumnSort from "./ColumnSort";
import { activateUser, deactivateUser } from "../BusinessUsers/UsersUtils";

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* Center align items vertically */
  margin-top: 20px;

  & > button {
    margin: 0 5px;
  }

  .page-info {
    margin: 0 10px;
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Table = () => {
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    searchTerm: "",
    organization: null,
    timeSpent: "",
    email_verified: null,
    startDate: "",
    endDate: "",
    role: "",
    sortColumn: "",
    sortOrder: "asc",
  });

  const [editUser, setEditUser] = useState({
    id: 0,
    name: "",
    phone: "",
    email: "",
    role: "",
    organization_id: 0,
    email_verified: 0,
    total_time_spent: null,
    created_at: null,
  });
  const [organizations, setOrganizations] = useState([]);

  const { translate } = useTranslation();

  const handleEditChange = (e) => {
    setEditUser({
      ...editUser,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    fetchUsers(
      filters,
      setUsers,
      setLoading,
      setCount,
      currentPage,
      itemsPerPage
    );
  }, [currentPage, filters, itemsPerPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSort = (column) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      sortColumn: column,
      sortOrder:
        prevFilters.sortColumn === column && prevFilters.sortOrder === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  // Open modal for editing an existing payment
  const handleEditClick = (item) => {
    setEditUser({
      id: item.id,
      name: item.name,
      phone: item.phone,
      email: item.email,
      role: item.role,
      organization_id: item.organization_id,
      email_verified: item.email_verified,
      total_time_spent: formatToDatetimeLocal(item.total_time_spent),
      created_at: formatToDatetimeLocal(item.created_at),
    });
    setEditModalOpen(true);
  };

  // Submit logic for both create and edit
  const handleEditSubmit = async () => {
    await editUserAPI(editUser);
    setEditModalOpen(false);
    fetchUsers(
      filters,
      setUsers,
      setLoading,
      setCount,
      currentPage,
      itemsPerPage
    );
  };

  return (
    <>
      <Dialog
        open={editModalOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setEditModalOpen(false);
          }
        }}
      >
        {" "}
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label={translate("Full Name")}
            name="name"
            type="text"
            value={editUser.name}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label={translate("Phone Number")}
            name="phone"
            type="number"
            value={editUser.phone}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label={translate("Email")}
            name="email"
            type="email"
            value={editUser.email}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            margin="dense"
            label={translate("Email Verification")}
            name="email_verified"
            select
            value={editUser.email_verified}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value={0}>Not Verified</MenuItem>
            <MenuItem value={1}>Verified</MenuItem>
          </TextField>

          <TextField
            margin="dense"
            label={translate("Role")}
            name="role"
            select
            value={editUser.role}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value="admin">{translate("Admin")}</MenuItem>
            <MenuItem value="business">{translate("Business Admin")}</MenuItem>
            <MenuItem value="user">{translate("User")}</MenuItem>
          </TextField>

          <InputLabel>{translate("Edit Organization")}</InputLabel>
          <FormControl fullWidth margin="dense">
            <Select
              name="organization_id"
              value={editUser.organization_id}
              onChange={handleEditChange}
              fullWidth
            >
              {organizations.map((org) => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditModalOpen(false)} color="secondary">
            {translate("Cancel")}
          </Button>
          <Button onClick={handleEditSubmit} color="primary">
            {translate("Save Changes")}
          </Button>
        </DialogActions>
      </Dialog>
      <SearchBar
        filters={filters}
        setFilters={setFilters}
        organizations={organizations}
        setOrganizations={setOrganizations}
      />
      <p style={{ marginBottom: "20px" }}>
        {translate("Number Of Users")}: {count}
      </p>
      <div className="data-table-diagram">
        {loading ? (
          <p>{translate("Loading...")}</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>
                    {translate("Name")}
                    <ColumnSort
                      sortOrder={filters.sortOrder}
                      column="name"
                      sortHandler={handleSort}
                      sortedColumn={filters.sortColumn}
                    />
                  </th>
                  <th>
                    {translate("Phone")}
                    <ColumnSort
                      sortOrder={filters.sortOrder}
                      column="phone"
                      sortHandler={handleSort}
                      sortedColumn={filters.sortColumn}
                    />
                  </th>
                  <th>
                    {translate("Email")}
                    <ColumnSort
                      sortOrder={filters.sortOrder}
                      column="email"
                      sortHandler={handleSort}
                      sortedColumn={filters.sortColumn}
                    />
                  </th>
                  <th>
                    {translate("Role")}
                    <ColumnSort
                      sortOrder={filters.sortOrder}
                      column="role"
                      sortHandler={handleSort}
                      sortedColumn={filters.sortColumn}
                    />
                  </th>
                  <th>{translate("Organization")}</th>
                  <th>{translate("Email Verification")}</th>
                  <th>
                    {translate("Total Time Spent (hours)")}
                    <ColumnSort
                      sortOrder={filters.sortOrder}
                      column="total_time_spent"
                      sortHandler={handleSort}
                      sortedColumn={filters.sortColumn}
                    />
                  </th>
                  <th>
                    {translate("Creation Date")}
                    <ColumnSort
                      sortOrder={filters.sortOrder}
                      column="created_at"
                      sortHandler={handleSort}
                      sortedColumn={filters.sortColumn}
                    />
                  </th>
                  <th>{translate("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0 ? (
                  users.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name || "N/A"}</td>
                      <td>{item.phone || "N/A"}</td>
                      <td>{item.email}</td>
                      <td>{item.role || "N/A"}</td>
                      <td>{item.Organization?.name || "N/A"}</td>
                      <td>
                        {item.email_verified
                          ? translate("Verified")
                          : translate("Not Verified")}
                      </td>
                      <td>{item.total_time_spent || "N/A"}</td>
                      <td>
                        {item.created_at ? formatDate(item.created_at) : "N/A"}
                      </td>
                      <td>
                        <ButtonContainer>
                          <Button
                            onClick={() => handleEditClick(item)}
                            variant="contained"
                            sx={{
                              backgroundColor: "#ffc107", // Yellow color for "Edit"
                              color: "#000",
                              "&:hover": {
                                backgroundColor: "#e0a800",
                              },
                            }}
                          >
                            {translate("Edit")}
                          </Button>
                          <Button
                            onClick={() => {
                              if (!item.is_disabled) {
                                if (
                                  window.confirm(
                                    translate(
                                      "Are you sure you want to deactivate this user?"
                                    )
                                  )
                                ) {
                                  deactivateUser(item.id, users, setUsers);
                                }
                              } else {
                                if (
                                  window.confirm(
                                    translate(
                                      "Are you sure you want to activate this user?"
                                    )
                                  )
                                ) {
                                  activateUser(item.id, users, setUsers);
                                }
                              }
                            }}
                            variant="contained"
                            sx={{
                              backgroundColor: "red",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#cc0000",
                              },
                            }}
                          >
                            {translate(
                              item.is_disabled ? "Activate" : "Deactivate"
                            )}
                          </Button>
                          <Button
                            onClick={() => {
                              if (
                                window.confirm(
                                  translate(
                                    "Are you sure you want to delete this user?"
                                  )
                                )
                              ) {
                                deleteUser(item.id);
                              }
                            }}
                            variant="contained"
                            sx={{
                              backgroundColor: "red",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#cc0000",
                              },
                            }}
                            startIcon={<DeleteIcon />}
                          >
                            {translate("Delete")}
                          </Button>
                        </ButtonContainer>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">{translate("No users found")}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <PaginationControls>
              <Button
                variant="contained"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                {translate("First")}
              </Button>
              <Button
                variant="contained"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {translate("Previous")}
              </Button>
              <span className="page-info">
                {translate("Page")} {currentPage} {translate("of")} {totalPages}
              </span>
              <Button
                variant="contained"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                {translate("Next")}
              </Button>
              <Button
                variant="contained"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                {translate("Last")}
              </Button>
            </PaginationControls>
          </>
        )}
      </div>
    </>
  );
};

export default Table;
