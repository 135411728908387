import React, { useEffect, useRef, useState } from "react";
import {
  ColumnHeader,
  ColumnHeaderText,
  ColumnHeaderIconClick,
  ChipContainer,
  FiltersDropDown,
  ThemedInput,
  SearchHeader,
  DropdownMenu,
  DropdownItem,
  DropdownContainer,
  CheckboxLabel,
} from "./ColumnStyles";
import {
  deleteColumn,
  duplicateColumn,
  markColumnAsTrending,
} from "../column-utils/ColumnUtils";
import FromToPopup from "../../column_popups/DownloadPopup";
import { DatePicker } from "../../../global/styled-components/DatePicker";
import FilterChips from "./Chips";
import { useColumns } from "../ColumnsContext";
import { useTheme } from "../../themes/ThemeContext";
import { useTutorial } from "../../tutorial/TutorialContext";
import { toast } from "sonner";
import { useAuth } from "../../authentication/auth-context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useSearch } from "../../side-panel/tabs/global-search/SearchContext";
import { formatDateTimeForInput } from "../../../global/dateUtils";
import { DateTime } from "luxon";
import ColumnIcon from "./ColumnIcon";
import { useTranslation } from "../../translate/TranslateContext";
import TranslateColumn from "./TranslateColumn";
import styled from "styled-components";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  font-size: 12px;
`;

const ColumnHead = ({
  column,
  setColumn,
  // provided,
  inputValue,
  setInputValue,
  setEditPopupVisible,
  handleDateChange,
  dateValue,
  setLanguage,
  language,
  updateTimestamp,
  setForceUpdate,
  selectedSource,
  handleSourceClick,
}) => {
  const {
    updateColumn,
    columnsCount,
    addColumn,
    removeColumn,
    updateColumnTrending,
    isTrendingPrivate,
  } = useColumns();
  const { theme } = useTheme();
  const [isFromToPopupVisible, setFromToPopupVisible] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isMoreVertDropdownVisible, setIsMoreVertDropdownVisible] =
    useState(false);
  const [isTrendingDisabled, setIsTrendingDisabled] = useState(false);

  const dropdownRef = useRef(null);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const { bundle } = useAuth();
  const navigate = useNavigate();
  const { globalIsExactMatch, setColumnExactMatch } = useSearch();

  const { stepIndex, setStepIndex } = useTutorial();

  const { translate, direction } = useTranslation();

  const toggleDropdown = () => {
    if (isMoreVertDropdownVisible) setIsMoreVertDropdownVisible(false);
    setIsDropdownVisible(!isDropdownVisible);
    if (stepIndex === 6) {
      setStepIndex(7);
    }
  };
  const toggleMoreVertDropdown = () => {
    if (isDropdownVisible) setIsDropdownVisible(!isDropdownVisible);
    setIsMoreVertDropdownVisible(!isMoreVertDropdownVisible);
  };

  const handleInputChange = (event) => {
    updateTimestamp();
    const newValue = event.target.value;
    setInputValue(newValue);
    localStorage.setItem(`inputValue-${column.id}`, newValue);
  };

  const handleMouseEnter = () => {
    setShowSearchDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowSearchDropdown(false);
  };

  const handleCheckboxChange = (e) => {
    const exactSearchMatch = e.target.checked;
    setColumnExactMatch(column.id, exactSearchMatch);
  };

  // Close the dropdown if the click is outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
        setIsMoreVertDropdownVisible(false);
      }
    };

    // Add when the dropdown is open
    if (isDropdownVisible || isMoreVertDropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownVisible, isMoreVertDropdownVisible]);

  const markAsTrending = async (isTrending, columnId) => {
    setIsTrendingDisabled(true);
    const res = await markColumnAsTrending(isTrending, columnId);
    if (res) {
      if (isTrending) toast.success(translate("marked as trending"));
      else toast.success(translate("unmarked as trending"));
      setIsTrendingDisabled(false);
      updateColumnTrending(columnId, isTrending);
    }
  };

  return (
    <>
      <ColumnHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <ColumnIcon
            columnId={column.id}
            iconType={column.icon_type}
            iconPath={column.icon}
          />
          {isTrendingPrivate && (
            <FlexContainer>
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  checked={column.isTrending}
                  onChange={(e) => markAsTrending(e.target.checked, column.id)}
                  disabled={isTrendingDisabled}
                />
                <span className="checkmark"></span>
              </label>
            </FlexContainer>
          )}
        </div>

        <ColumnHeaderText>{column.name}</ColumnHeaderText>

        <ColumnHeaderIconClick
          className="material-icons"
          onClick={toggleDropdown}
          id="filters-dropdown"
        >
          tune
        </ColumnHeaderIconClick>
        <ColumnHeaderIconClick
          className="material-icons"
          onClick={toggleMoreVertDropdown}
        >
          more_vert
        </ColumnHeaderIconClick>
      </ColumnHeader>
      {isDropdownVisible && (
        <FiltersDropDown $isOpen={isDropdownVisible}>
          <SearchHeader>
            <div className="draggable-input-icon-container">
              <div
                className="left-side-search"
                id="column-header-search"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <ThemedInput
                  type="text"
                  className="DraggableColumnNameInput TextArea"
                  placeholder={translate("Search...")}
                  value={inputValue}
                  onChange={handleInputChange}
                  title={translate("Search For A Term")}
                />
                <span
                  className="material-icons search-icon"
                  style={{ direction: direction }}
                >
                  search
                </span>
                <DropdownContainer $show={showSearchDropdown}>
                  <CheckboxLabel>
                    <input
                      type="checkbox"
                      checked={globalIsExactMatch.get(column.id) ?? true}
                      onChange={handleCheckboxChange}
                    />
                    {translate("Match Exact Word")}
                  </CheckboxLabel>
                </DropdownContainer>
              </div>
              <div className="right-side-icons">
                <TranslateColumn
                  setLanguage={setLanguage}
                  language={language}
                />
                <span
                  className="material-icons column-header-icon"
                  onClick={() => {
                    if (bundle === "free_trial" || bundle === "solo") {
                      toast.error("This feature is not available in your plan");
                      navigate("/bundles");
                    } else setFromToPopupVisible(!isFromToPopupVisible);
                  }}
                  title={translate("Download Column Media or CSV")}
                  id="column-header-export"
                >
                  download
                </span>
              </div>
            </div>
            <DatePicker
              type="datetime-local"
              value={dateValue}
              onChange={handleDateChange}
              className="date-time-picker"
              $theme={theme}
              title="Filter By Date"
              id="column-header-date"
              max={formatDateTimeForInput(DateTime.now().toISO())}
            />
            {isFromToPopupVisible && (
              <FromToPopup
                isOpen={isFromToPopupVisible}
                onClose={() => setFromToPopupVisible(!isFromToPopupVisible)}
                search={inputValue}
                columnId={column.id}
                columnName={column.name}
              />
            )}
          </SearchHeader>

          <ChipContainer>
            <FilterChips
              column={column}
              setColumn={setColumn}
              selectedSource={selectedSource}
              handleSourceClick={handleSourceClick}
              setForceUpdate={setForceUpdate}
              updateColumn={updateColumn}
              updateTimestamp={updateTimestamp}
            />
          </ChipContainer>
        </FiltersDropDown>
      )}
      {isMoreVertDropdownVisible && (
        <div style={{ position: "relative" }}>
          <DropdownMenu ref={dropdownRef} $isOpen={isMoreVertDropdownVisible}>
            <DropdownItem onClick={setEditPopupVisible} theme={theme}>
              {translate("Edit Column")}
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                duplicateColumn(
                  column,
                  columnsCount,
                  addColumn,
                  toggleMoreVertDropdown,
                  translate
                );
              }}
              theme={theme}
            >
              {translate("Duplicate Column")}
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                deleteColumn(
                  column.id,
                  removeColumn,
                  toggleMoreVertDropdown,
                  translate
                );
              }}
              theme={theme}
            >
              {translate("Delete Column")}
            </DropdownItem>
          </DropdownMenu>
        </div>
      )}
    </>
  );
};

export default ColumnHead;
