import React, { useState, useEffect, useRef } from "react";
import DraggableColumn from "./DraggableColumn";
import styled from "styled-components";
import { useColumns } from "./ColumnsContext";
import ChatBot from "../chat/ChatBot";

import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";

const Container = styled.div`
  display: flex;
  height: 100vh;
  overflow-x: auto;

  background-color: ${({ theme }) => theme.background};
  padding: 10px;
  padding-inline-start: 0px;
  box-sizing: border-box;
  justify-content: start;

  /* justify-content: ${({ $dir }) =>
    $dir === "rtl" ? "flex-start" : "flex-end"}; */
  /* flex-direction: ${({ $dir }) =>
    $dir === "rtl" ? "row-reverse" : "row"}; */
  /* flex-direction: ${({ dir }) => (dir === "rtl" ? "row-reverse" : "row")}; */
  /* direction: ${({ dir }) => dir}; */
  /* Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.chip_inactive};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.chip_inactive};
  }
`;

const ColumnsContainer = () => {
  const { columns, reorderColumns } = useColumns();
  const [showsearchpopup, setshowsearchpopup] = useState("false");
  const [positionX, setPositionX] = useState(0);
  const searchContainerRef = useRef(null);

  useEffect(() => {
    // Load from local storage on mount
    const savedVisibility = localStorage.getItem("searchVisibility");
    const savedPositionX = localStorage.getItem("searchPositionX");
    if (savedVisibility) {
      setshowsearchpopup(savedVisibility);
    }
    if (savedPositionX) {
      setPositionX(parseInt(savedPositionX, 10));
      if (searchContainerRef.current) {
        searchContainerRef.current.style.left = `${savedPositionX}px`;
      }
    }
  }, []);

  useEffect(() => {
    // Save to local storage on state changes
    localStorage.setItem("searchVisibility", showsearchpopup);
    localStorage.setItem("searchPositionX", positionX);
  }, [showsearchpopup, positionX]);

  const onDragEnd = (event) => {
    if (!event || !event.over) return;

    const { active, over } = event;
    if (!active || !over || active.id === over.id) return;

    const oldIndex = columns.findIndex((column) => column.id === active.id);
    const newIndex = columns.findIndex((column) => column.id === over.id);

    if (oldIndex !== -1 && newIndex !== -1) {
      reorderColumns(oldIndex, newIndex);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  return (
    <>
      <ChatBot />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          items={columns}
          strategy={horizontalListSortingStrategy}
        >
          <Container $showsearchpopup={showsearchpopup}>
            {columns.map((column, index) => (
              <DraggableColumn
                key={column.id}
                static_column={column}
                index={index}
              />
            ))}
          </Container>
        </SortableContext>
      </DndContext>
    </>
  );
};

export default ColumnsContainer;
