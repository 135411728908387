import React from "react";
import { useTranslation } from "../../../translate/TranslateContext";

export const ContentRow = ({ title, children }) => {
  const { translate } = useTranslation();
  return (
    <div className="flexy">
      <div className="SettingsContentTitle">{translate(title)}</div>
      <div className="SettingsContentOption">{children}</div>
    </div>
  );
};

export const DefaultCategory = () => {
  return <></>;
};
