import React, { useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import arLocale from "i18n-iso-countries/langs/ar.json";
import logo from "../../../assets/images/logo.png";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useFilter } from "../contexts/FilterContext";
import { useTheme } from "../../themes/ThemeContext";
import { useMapRef } from "../contexts/MapContext";
import { BsGlobe } from "react-icons/bs";
import { useTranslation } from "../../translate/TranslateContext";

countries.registerLocale(enLocale);
countries.registerLocale(arLocale);

const regions = {
  "Israel-Palestine": {
    countries: ["IL", "PS", "LB"],
    zoomLevel: 7,
  },
  "Israel-Syria": {
    countries: ["SY", "IL"],
    zoomLevel: 7,
  },
  "Ukraine-Russia": {
    countries: ["UA", "RU"],
    zoomLevel: 6,
  },
  Europe: {
    countries: ["FR", "DE", "IT", "ES", "GB"],
    zoomLevel: 5,
  },
};

const regionCenters = {
  "Israel-Palestine": [32.5, 35.5],
  "Israel-Syria": [34.8, 39.0],
  "Ukraine-Russia": [50.746864, 39.96047],
  Europe: [49.170457, 10.809419],
};

const Navbar = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: ${({ theme }) => theme.column_background};
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
`;

const RegionButton = styled.button`
  background-color: ${({ $isSelected, theme }) =>
    $isSelected ? " rgb(30, 40, 54)" : theme.button_background};
  color: ${({ $isSelected, theme }) =>
    $isSelected ? "#fff" : theme === "dark" ? "#bcb8cb" : "#000"};
  padding: 10px 15px;
  margin-inline-end: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  &:hover {
    color: ${({ $isSelected, theme }) =>
      $isSelected ? "#fff" : theme === "dark" ? "#fff" : "#7359aa"};
  }
`;

const SelectCountriesButton = styled.button`
  background-color: ${({ theme }) => theme.button_background};
  color: ${({ theme }) => (theme === "dark" ? "#bcb8cb" : "#000")};
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  &:hover {
    color: ${({ $isSelected, theme }) =>
      $isSelected ? "#fff" : theme === "dark" ? "#fff" : "#7359aa"};
  }

  display: flex;
  align-items: center;

  svg {
    margin-inline-end: 8px; // Add spacing between icon and text
    font-size: 18px;
  }
`;

const SelectLabel = styled.h3`
  font-size: 18px;
  color: ${({ theme }) => theme.primary};
  margin-bottom: 20px;
  margin-top: 0;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const ModalContent = styled.div`
  background: ${({ theme }) => theme.column_background};
  padding: 20px;
  border-radius: 8px;
  max-width: 700px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 2001;
  position: relative;
`;

const Logo = styled.img`
  height: 40px; /* Adjust the size of the logo as needed */
  margin-inline-end: 20px;
  cursor: pointer;
`;

const NavBar = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { translate, language } = useTranslation();
  const { mapRef } = useMapRef();
  const { setCountries, setIsFreeView, isFreeView } = useFilter();
  const [selectedRegion, setSelectedRegion] = useState("Israel-Palestine");
  const [selectedCountries, setSelectedCountries] = useState(
    regions["Israel-Palestine"].countries.map((code) => ({
      value: code,
      label: countries.getName(code, language),
    }))
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRegionSelect = (region) => {
    setIsFreeView(false);
    setSelectedRegion(region);

    const selectedRegionData = regions[region];
    const newSelectedCountries = selectedRegionData.countries.map((code) => ({
      value: code,
      label: countries.getName(code, language),
    }));
    setSelectedCountries(newSelectedCountries);
    setCountries(newSelectedCountries.map((country) => country.value));

    if (mapRef.current) {
      const map = mapRef.current;
      const regionCenter = regionCenters[region];
      const zoomLevel = selectedRegionData.zoomLevel || 7;

      if (regionCenter) {
        map.setView(regionCenter, zoomLevel);
      }
    }
  };

  const handleCountryChange = (selectedOptions) => {
    if (selectedOptions.length <= 5) {
      setSelectedCountries(selectedOptions || []);
      setIsFreeView(false);
      setCountries((selectedOptions || []).map((option) => option.value));

      if (
        selectedRegion &&
        !regions[selectedRegion].countries.every((code) =>
          (selectedOptions || []).some((option) => option.value === code)
        )
      ) {
        setSelectedRegion(null);
      }
    } else {
      toast.warning(translate("You can only select up to 5 countries."), {
        duration: 3000,
        position: "bottom-center",
      });
    }
  };

  const handleFreeViewSelect = () => {
    setIsFreeView(true);
    setSelectedRegion(null);
    setSelectedCountries([]);
    setCountries([]);

    if (mapRef.current) {
      mapRef.current.setView([37.661909, 25.780937], 2);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  const countryOptions = Object.keys(countries.getAlpha2Codes()).map(
    (code) => ({
      value: code,
      label: countries.getName(code, language),
    })
  );

  return (
    <>
      <Navbar>
        <Logo
          src={logo}
          alt="Logo"
          onClick={() => {
            navigate("/");
          }}
        />
        {Object.keys(regions).map((region) => (
          <RegionButton
            key={region}
            $isSelected={selectedRegion === region}
            onClick={() => handleRegionSelect(region)}
            theme={theme}
          >
            {translate(region)}
          </RegionButton>
        ))}
        <RegionButton
          $isSelected={isFreeView}
          onClick={() => handleFreeViewSelect()}
          theme={theme}
        >
          {translate("Free View")}
        </RegionButton>
        <SelectCountriesButton onClick={openModal} theme={theme}>
          <BsGlobe />
          {translate("Select Countries")}
        </SelectCountriesButton>
      </Navbar>

      {isModalOpen && (
        <ModalOverlay onClick={handleOverlayClick}>
          <ModalContent>
            {/* <ModalCloseButton onClick={closeModal}>&times;</ModalCloseButton> */}
            <SelectLabel>
              {translate("Choose countries to view news for:")}
            </SelectLabel>
            <Select
              isMulti
              options={countryOptions}
              value={selectedCountries}
              onChange={handleCountryChange}
              placeholder={translate("Select countries...")}
              styles={{
                control: (base) => ({
                  ...base,
                  fontSize: "14px",
                  backgroundColor: theme === "dark" ? "#333" : "#F8F8F8",
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: theme === "dark" ? "#333" : "#fff",
                }),
                singleValue: (base) => ({
                  ...base,
                  color: theme === "dark" ? "#fff" : "#000",
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isFocused
                    ? theme === "dark"
                      ? "#555"
                      : "#ddd"
                    : theme === "dark"
                    ? "#333"
                    : "#fff",
                  color: state.isFocused
                    ? theme === "dark"
                      ? "#fff"
                      : "#000"
                    : theme === "dark"
                    ? "#fff"
                    : "#000",
                  cursor: "pointer",
                }),
              }}
            />
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default NavBar;
