import React, { useEffect, useState } from "react";
import {
  Button as MuiButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import ViewFeaturesPopup from "./ViewFeaturesPopup";
import { deactiveBundle, fetchData, generateCode, updateBundle } from "./utils";
import { useTranslation } from "../../../../components/translate/TranslateContext";
import ColumnSort from "../Users/ColumnSort";

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  & > button {
    margin: 0 5px;
  }

  .page-info {
    margin: 0 10px;
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

const CountParagraph = styled.p``;

const Table = () => {
  const [bundles, setBundles] = useState([]);
  const [features, setFeatures] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [codeLoading, setCodeLoading] = useState(false);
  const [viewFeaturesModalOpen, setViewFeaturesModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editBundle, setEditBundle] = useState({
    id: "",
    title: "",
    display_name: "",
    price_per_user_monthly: "",
    price_per_user_yearly: "",
    status: "",
    features: [],
  });

  const [filters, setFilters] = useState({
    sortColumn: "",
    sortOrder: "asc",
  });

  const { translate } = useTranslation();

  useEffect(() => {
    fetchData(
      setBundles,
      setCount,
      currentPage,
      itemsPerPage,
      setLoading,
      filters
    );
  }, [currentPage, itemsPerPage, count, filters]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSort = (column) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      sortColumn: column,
      sortOrder:
        prevFilters.sortColumn === column && prevFilters.sortOrder === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  const onCloseViewFeatureModal = () => {
    setViewFeaturesModalOpen(false);
  };

  const handleEditClick = (bundle) => {
    setEditBundle(bundle);
    setEditModalOpen(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditBundle((prevOrg) => ({
      ...prevOrg,
      [name]: value,
    }));
  };

  const handleFeatureChange = (event) => {
    const selectedIds = event.target.value;

    // Update assigned features based on selected IDs
    const updatedFeatures = features.filter((feature) =>
      selectedIds.includes(feature.id)
    );

    setEditBundle((prevBundle) => ({
      ...prevBundle,
      features: updatedFeatures,
    }));
  };

  const selectedFeatureIds = editBundle.features.map((feature) => feature.id);

  const handleStatusChange = (event) => {
    handleEditChange({
      target: {
        name: "status",
        value: event.target.checked ? 1 : 0,
      },
    });
  };

  const handleEditSubmit = async () => {
    await updateBundle(editBundle);
    setEditModalOpen(false);
    fetchData(setBundles, setCount, currentPage, itemsPerPage, setLoading);
  };

  return (
    <>
      <ViewFeaturesPopup
        open={viewFeaturesModalOpen}
        onClose={onCloseViewFeatureModal}
        features={features}
        setFeatures={setFeatures}
      />

      <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>{translate("Edit Bundles")}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Display Name"
            name="display_name"
            value={editBundle.display_name}
            onChange={handleEditChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Max Users"
            name="max_users"
            type="number"
            value={editBundle.max_users}
            onChange={handleEditChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Price Monthly"
            name="price_per_user_monthly"
            type="number"
            value={editBundle.price_per_user_monthly}
            onChange={handleEditChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Price Yearly"
            name="price_per_user_yearly"
            type="number"
            value={editBundle.price_per_user_yearly}
            onChange={handleEditChange}
            fullWidth
          />

          <FormControl margin="dense" fullWidth>
            <InputLabel id="features-label">
              {translate("Assign Features")}
            </InputLabel>
            <Select
              labelId="features-label"
              multiple
              value={selectedFeatureIds}
              onChange={handleFeatureChange}
              renderValue={(selected) =>
                selected
                  .map(
                    (id) => features.find((feature) => feature.id === id)?.name
                  )
                  .join(", ")
              }
            >
              {features.map((feature) => (
                <MenuItem key={feature.id} value={feature.id}>
                  <Checkbox checked={selectedFeatureIds.includes(feature.id)} />
                  <ListItemText primary={feature.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={editBundle.status === 1}
                  onChange={handleStatusChange}
                />
              }
              label={translate("Active Status")}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={() => setEditModalOpen(false)} color="secondary">
            {translate("Cancel")}
          </MuiButton>
          <MuiButton onClick={handleEditSubmit} color="primary">
            {translate("Save")}
          </MuiButton>
        </DialogActions>
      </Dialog>

      <FlexContainer>
        <CountParagraph>
          {translate("Number Of Bundles")}: {count}
        </CountParagraph>

        <MuiButton
          onClick={() => {
            setViewFeaturesModalOpen(true);
          }}
          variant="contained"
          sx={{
            backgroundColor: "#007bff",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#0056b3",
            },
          }}
        >
          {translate("View Features")}
        </MuiButton>
      </FlexContainer>
      <div className="data-table-diagram">
        {loading ? (
          <p>{translate("Loading...")}</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>
                    {translate("Display Name")}
                    <ColumnSort
                      sortOrder={filters.sortOrder}
                      column="display_name"
                      sortHandler={handleSort}
                      sortedColumn={filters.sortColumn}
                    />
                  </th>
                  <th>
                    {translate("Max Users")}
                    <ColumnSort
                      sortOrder={filters.sortOrder}
                      column="max_users"
                      sortHandler={handleSort}
                      sortedColumn={filters.sortColumn}
                    />
                  </th>
                  <th>
                    {translate("Price Monthly")}
                    <ColumnSort
                      sortOrder={filters.sortOrder}
                      column="price_per_user_monthly"
                      sortHandler={handleSort}
                      sortedColumn={filters.sortColumn}
                    />
                  </th>
                  <th>
                    {translate("Price Yearly")}
                    <ColumnSort
                      sortOrder={filters.sortOrder}
                      column="price_per_user_yearly"
                      sortHandler={handleSort}
                      sortedColumn={filters.sortColumn}
                    />
                  </th>
                  <th>
                    {translate("Status")}
                    <ColumnSort
                      sortOrder={filters.sortOrder}
                      column="status"
                      sortHandler={handleSort}
                      sortedColumn={filters.sortColumn}
                    />
                  </th>
                  <th>{translate("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {bundles.length > 0 ? (
                  bundles.map((item) => (
                    <tr key={item.id}>
                      <td>{item.display_name || "N/A"}</td>
                      <td>{item.max_users || "Custom"}</td>
                      <td>{item.price_per_user_monthly || "N/A"} $</td>
                      <td>{item.price_per_user_yearly || "N/A"} $</td>
                      <td>{item.status ? "Active" : "Inactive"}</td>
                      <td>
                        <ButtonContainer>
                          {(item.slug === "solo" ||
                            item.slug === "solo_premium") && (
                            <MuiButton
                              onClick={() => {
                                generateCode(item.id, setCodeLoading);
                              }}
                              variant="contained"
                              sx={{
                                backgroundColor: "#007bff",
                                color: "#fff",
                                "&:hover": {
                                  backgroundColor: "#0056b3",
                                },
                              }}
                            >
                              {codeLoading ? (
                                <CircularProgress size={24} color="inherit" />
                              ) : (
                                translate("Create Signup Code")
                              )}
                            </MuiButton>
                          )}
                          <MuiButton
                            onClick={() => handleEditClick(item)}
                            variant="contained"
                            sx={{
                              backgroundColor: "#ffc107", // Yellow color for "Edit"
                              color: "#000",
                              "&:hover": {
                                backgroundColor: "#e0a800",
                              },
                            }}
                          >
                            {translate("Edit")}
                          </MuiButton>
                          <MuiButton
                            onClick={() => {
                              if (
                                window.confirm(
                                  translate(
                                    "Are you sure you want to deactivate this organization?"
                                  )
                                )
                              ) {
                                deactiveBundle(item.id);
                              }
                            }}
                            variant="contained"
                            sx={{
                              backgroundColor: "red",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#cc0000",
                              },
                            }}
                            startIcon={<DeleteIcon />}
                          >
                            {translate("Deactivate")}
                          </MuiButton>
                        </ButtonContainer>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">{translate("No bundles found")}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <PaginationControls>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                {translate("First")}
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {translate("Previous")}
              </MuiButton>
              <span className="page-info">
                {translate("Page")} {currentPage} {translate("of")} {totalPages}
              </span>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                {translate("Next")}
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                {translate("Last")}
              </MuiButton>
            </PaginationControls>
          </>
        )}
      </div>
    </>
  );
};

export default Table;
