import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import SidePanelTabs from "./Tabs";
import { useTutorial } from "../tutorial/TutorialContext";
import { useTranslation } from "../translate/TranslateContext";

const ToggleButton = styled.button`
  position: fixed;
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  inset-inline-end: ${(props) => (props.$show ? "430px" : "-8px")};
  top: ${(props) =>
    props.$top}px; /* Use $ prefix to make it a transient prop */
  z-index: 21;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: 60px;
  transition: right 0.3s ease; /* Add animation */

  @media (max-width: 768px) {
    inset-inline-end: ${(props) => (props.$show ? "85%" : "-8px")};
  }

  &:hover {
    background-color: ${({ theme }) => theme.cancel_hover};
  }

  span {
    margin-inline-start: 8px;
    color: #333;
    font-size: 16px;
    color: ${({ theme }) => theme.text};
  }
`;

const iconAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(180deg); }
`;

const Icon = styled.span`
  font-size: 30px !important;
  margin-inline-end: 15px;
  transition: transform 0.3s ease;
  animation: ${iconAnimation} 0.3s forwards;
`;

const SidePanelButton = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [top, setTop] = useState(70);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const { direction } = useTranslation();
  const { stepIndex, setStepIndex } = useTutorial();

  const toggleSidebar = () => {
    setShowSidebar((prev) => !prev);
    if (stepIndex === 12) {
      setTimeout(() => {
        setStepIndex(13);
      }, 500);
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartY(e.clientY - top);
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newTop = e.clientY - startY;
      const windowHeight = window.innerHeight;
      const boundedTop = Math.max(0, Math.min(newTop, windowHeight - 50));
      setTop(boundedTop);
      e.preventDefault();
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, startY]);

  return (
    <div>
      <ToggleButton
        $top={top}
        $show={showSidebar}
        onMouseDown={handleMouseDown}
        onClick={toggleSidebar}
        id="sidepanel-button"
      >
        <Icon className="material-symbols-outlined">
          {direction === "ltr"
            ? showSidebar
              ? "right_panel_open"
              : "right_panel_close"
            : showSidebar
            ? "right_panel_close"
            : "right_panel_open"}
        </Icon>
      </ToggleButton>
      <SidePanelTabs show={showSidebar} />
    </div>
  );
};

export default SidePanelButton;
