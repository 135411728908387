import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { themeCreator } from "./base";

export const ThemeContext = React.createContext();

const ThemeProviderWrapper = function (props) {
  const curThemeName = localStorage.getItem("appTheme") || "PurpleFlowTheme";
  const [themeName, _setThemeName] = useState("PurpleFlowTheme");
  const theme = themeCreator("PurpleFlowTheme");
  const setThemeName = (themeName) => {
    localStorage.setItem("appTheme", themeName);
    _setThemeName(themeName);
  };

  return (
    <ThemeContext.Provider value={setThemeName}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProviderWrapper;
