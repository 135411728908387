import { toast } from "sonner";
import { postAuth } from "../../../../global/globalUtils";
import { DateTime } from "luxon";
import { formatDateForAPI } from "../../../../global/dateUtils";

export const sanitizeFilename = (name) => {
  // Remove invalid file name characters regex
  return name.replace(/[/\\?%*:|"<>]/g, "-");
};

export const isDateRangeValid = (from, to, hoursLimit) => {
  const limitInMilliseconds = hoursLimit * 60 * 60 * 1000;
  const diffTime = Math.abs(to - from);
  const isValid = diffTime <= limitInMilliseconds;
  const message = `Date range must be within ${hoursLimit} hours.`;
  return { isValid, message };
};

export const validateDates = (from, to, timeLimit) => {
  if (!from || !to) {
    return { isValid: false, message: "Please fill all fields" };
  }

  const fromDate = DateTime.fromISO(from);
  const toDate = DateTime.fromISO(to);

  if (!fromDate.isValid || !toDate.isValid) {
    return { isValid: false, message: "Invalid date format" };
  }

  if (fromDate >= toDate) {
    return { isValid: false, message: "From date should be before To date" };
  }

  return isDateRangeValid(fromDate.toJSDate(), toDate.toJSDate(), timeLimit);
};

// Utility function for handling file downloads
export const handleFileDownload = async (blob, filename) => {
  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = downloadUrl;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(downloadUrl);
};

export const handleDownloadCSV = async ({
  From,
  To,
  searchInput,
  isExactMatch,
  selectedLanguage,
  setCSVLoading,
}) => {
  try {
    setCSVLoading(true);

    // Format and validate dates
    const formattedFrom = formatDateForAPI(From);
    const formattedTo = formatDateForAPI(To);

    const validation = validateDates(
      formattedFrom,
      formattedTo,
      process.env.REACT_APP_POST_EXPORT_TIME_LIMIT
    );

    if (!validation.isValid) {
      toast.error(validation.message);
      return;
    }

    // Get user's timezone using Luxon
    const userTimezone = DateTime.local().zoneName;

    const response = await postAuth("/post/download-csv", {
      from: formattedFrom,
      to: formattedTo,
      search: searchInput,
      language: selectedLanguage.value,
      isExactMatch,
      timezone: userTimezone,
    });

    if (response.ok) {
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const filename = searchInput
        ? `${sanitizeFilename(searchInput)}_posts.csv`
        : "filtered_posts.csv";
      a.download = filename;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downloadUrl);
      toast.success("File has been downloaded successfully.");
    } else {
      const errorData = await response.json();
      toast.error(errorData.message || "Failed to download file.");
    }
  } catch (error) {
    toast.error("An unexpected error occurred. Please try again.");
    console.error("Error during handleDownloadCSV:", error);
  } finally {
    setCSVLoading(false);
  }
};

export const handleDownload = async ({
  From,
  To,
  searchInput,
  isExactMatch,
  mediaSelection,
  setMediaLoading,
}) => {
  try {
    setMediaLoading(true);

    if (From === "" || To === "") {
      toast.error("Please fill all fields");
      return;
    }

    // Format and validate dates
    const formattedFrom = formatDateForAPI(From);
    const formattedTo = formatDateForAPI(To);

    const validation = validateDates(
      formattedFrom,
      formattedTo,
      process.env.REACT_APP_POST_DOWNLOAD_TIME_LIMIT
    );

    if (!validation.isValid) {
      toast.error(validation.message);
      return;
    }

    if (!mediaSelection.images && !mediaSelection.videos) {
      toast.error("Please select at least one type of media");
      return;
    }

    // Send to backend
    const response = await postAuth("/post/download-column-media", {
      search: searchInput,
      mediaSelection,
      from: formattedFrom,
      to: formattedTo,
      isExactMatch,
    });

    if (response.ok) {
      const blob = await response.blob();
      await handleFileDownload(blob, "media.zip");
      toast.success("Media has been downloaded successfully.");
    } else {
      const errorData = await response.json();
      toast.error(errorData.message || "Failed to download media.");
    }
  } catch (error) {
    toast.error("An unexpected error occurred.");
    console.error("Error during handleDownload:", error);
  } finally {
    setMediaLoading(false);
  }
};
