import React from "react";
import { BookmarkProvider } from "../components/bookmarks/bookmark_context";
import { SearchProvider } from "../components/side-panel/tabs/global-search/SearchContext";
import { ThemeProvider } from "../components/themes/ThemeContext";
import GlobalStyle from "../global/GlobalStyle";
import { ToastContainer } from "react-toastify";
import { Toaster } from "sonner";
import { TranslationLoadingProvider } from "../components/column/TranslationLoadingContext";
import { TutorialProvider } from "../components/tutorial/TutorialContext";
import { HelmetProvider, Helmet } from "react-helmet-async";

const Providers = ({ children }) => {
  return (
    <ThemeProvider>
      <HelmetProvider>
        <TranslationLoadingProvider>
          <TutorialProvider>
            <BookmarkProvider>
              <SearchProvider>
                {" "}
                <Helmet defaultTitle="Teledeck" titleTemplate="%s | Teledeck" />
                {/* GlobalStyle can be considered a global provider for styled-components */}
                <GlobalStyle />
                <ToastContainer limit={2} style={{ zIndex: 10 }} />
                <Toaster
                  richColors
                  position="top-center"
                  closeButton
                  toastOptions={{
                    style: {
                      paddingRight: "30px",
                    },
                  }}
                />{" "}
                {children}
              </SearchProvider>
            </BookmarkProvider>
          </TutorialProvider>
        </TranslationLoadingProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
};

export default Providers;
