import { openDB } from "idb";
import {
  bookmarkPost,
  createBookmarkCategory,
  deleteCategoriesFromAPI,
  getBookmarkCategories,
  getBookmarkedPosts,
  handleApiError,
  unbookmarkPost,
  unbookmarkPostList,
} from "./bookmark_api_utils";

// Initialize IndexedDB
const dbPromise = openDB("bookmarks-db", 1, {
  upgrade(db) {
    db.createObjectStore("bookmarks", { keyPath: "id" });
    db.createObjectStore("categories", { keyPath: "id" });
  },
});

// Cache all bookmarks from server
export const syncBookmarksFromServer = async () => {
  try {
    // Get all bookmarks from server (no pagination)
    const { bookmarks } = await getBookmarkedPosts({ limit: 1000 });
    const categories = await getBookmarkCategories();

    const db = await dbPromise;
    const tx = db.transaction(["bookmarks", "categories"], "readwrite");

    // Clear and re-add all bookmarks
    await tx.objectStore("bookmarks").clear();
    for (const bookmark of bookmarks) {
      await tx.objectStore("bookmarks").add(bookmark);
    }

    // Clear and re-add all categories
    await tx.objectStore("categories").clear();
    for (const category of categories) {
      await tx.objectStore("categories").add(category);
    }

    await tx.done;
    return true;
  } catch (error) {
    console.error("Failed to sync bookmarks from server:", error);
    return false;
  }
};

// Check if post is bookmarked (local first)
export const isPostBookmarked = async (postId) => {
  try {
    // Try local first
    const db = await dbPromise;
    const bookmarks = await db.getAll("bookmarks");
    const isBookmarked = bookmarks.some((bookmark) => bookmark.id === postId);

    return isBookmarked;
  } catch (error) {
    // Fallback to server if IndexedDB fails
    console.error("IndexedDB error, falling back to server:", error);
    const { bookmarks } = await getBookmarkedPosts({ limit: 1000 });
    return bookmarks.some((bookmark) => bookmark.id === postId);
  }
};

// Enhanced toggle bookmark with local caching
export const toggleBookmark = async (postId, categoryId = null) => {
  try {
    const isBookmarked = await isPostBookmarked(postId);

    if (isBookmarked) {
      // Remove from server
      await unbookmarkPost(postId);

      // Remove from local DB
      const db = await dbPromise;
      const tx = db.transaction("bookmarks", "readwrite");
      const store = tx.objectStore("bookmarks");
      const bookmarks = await store.getAll();
      const bookmarkToRemove = bookmarks.find((b) => b.id === postId);

      if (bookmarkToRemove) {
        await store.delete(bookmarkToRemove.id);
      }

      await tx.done;
      return false;
    } else {
      // Add to server
      const newBookmark = await bookmarkPost(postId, categoryId);

      // Add to local DB with proper error handling
      const db = await dbPromise;
      const tx = db.transaction("bookmarks", "readwrite");
      const store = tx.objectStore("bookmarks");

      // Check if the bookmark already exists
      const existingBookmark = await store.get(newBookmark.id);

      if (existingBookmark) {
        // Update existing bookmark instead of adding a new one
        await store.put(newBookmark);
      } else {
        // Add new bookmark
        await store.add(newBookmark);
      }

      await tx.done;

      return newBookmark;
    }
  } catch (error) {
    handleApiError(error, "Failed to toggle bookmark");
    throw error;
  }
};

export const unbookmarkPostListDB = async (postIds) => {
  try {
    // Call the API to unbookmark
    await unbookmarkPostList(postIds);

    // Remove from local DB
    const db = await dbPromise;
    const tx = db.transaction("bookmarks", "readwrite");
    const store = tx.objectStore("bookmarks");

    if (Array.isArray(postIds)) {
      for (const postId of postIds) {
        await store.delete(postId);
      }
    }

    await tx.done;

    return true;
  } catch (error) {
    console.error("Error unbookmarking post:", error);
    return false;
  }
};

// Get all bookmarked posts from IndexedDB
export const getBookmarkedPostsFromDB = async () => {
  try {
    const db = await dbPromise;
    const bookmarks = await db.getAll("bookmarks");
    return bookmarks;
  } catch (error) {
    console.error("Error fetching bookmarks from IndexedDB:", error);
    return [];
  }
};

// Get bookmarked posts with filtering and pagination options
export const getBookmarkedPostsFiltered = async ({
  categoryId = null,
  page = 1,
  limit = 10,
} = {}) => {
  try {
    const db = await dbPromise;
    let bookmarks = await db.getAll("bookmarks");

    // Filter by category if specified
    if (categoryId !== null) {
      bookmarks = bookmarks.filter(
        (bookmark) => bookmark.category_id === categoryId
      );
    }

    // Calculate total for pagination
    const total = bookmarks.length;

    // Apply pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    const paginatedBookmarks = bookmarks.slice(startIndex, endIndex);

    return {
      bookmarks: paginatedBookmarks,
      pagination: {
        totalItems: total,
        totalPages: Math.ceil(total / limit),
        currentPage: page,
        itemsPerPage: limit,
      },
    };
  } catch (error) {
    console.error("Error fetching filtered bookmarks from IndexedDB:", error);
    return { bookmarks: [], pagination: {} };
  }
};

export const createCategoryDB = async (category) => {
  try {
    const newCategory = await createBookmarkCategory(category);
    const db = await dbPromise;
    await db.add("categories", newCategory);
    return newCategory;
  } catch (error) {
    console.error("Error creating category in IndexedDB:", error);
    return null;
  }
};

export const getCategoriesFromDB = async () => {
  try {
    const db = await dbPromise;
    const categories = await db.getAll("categories");
    return categories;
  } catch (error) {
    console.error("Error fetching categories from IndexedDB:", error);
    return [];
  }
};

const deleteCategoriesFromDB = async (categoryIds) => {
  if (!Array.isArray(categoryIds) || categoryIds.length === 0) {
    throw new Error("Please provide at least one category ID to delete");
  }

  const db = await dbPromise;
  const tx = db.transaction(["bookmarks", "categories"], "readwrite");

  try {
    // Delete all the categories
    for (const categoryId of categoryIds) {
      await tx.objectStore("categories").delete(categoryId);
    }

    // delete all bookmarks in the deleted categories
    const bookmarks = await tx.objectStore("bookmarks").getAll();
    for (const bookmark of bookmarks) {
      if (categoryIds.includes(bookmark.bookmark_category?.id)) {
        await tx.objectStore("bookmarks").delete(bookmark.id);
      }
    }

    await tx.done;
    return true;
  } catch (error) {
    // If transaction fails, abort it
    if (tx.state !== "inactive") {
      tx.abort();
    }
    console.error("Error deleting categories from IndexedDB:", error);
    throw error;
  }
};

/**
 * Delete categories from both server and local DB
 * @param {Array<number>} categoryIds - Array of category IDs to delete
 * @param {number|null} targetCategoryId - Optional category to move bookmarks to
 * @returns {Promise<boolean>} Success status
 */
export const deleteCategories = async (categoryIds) => {
  try {
    // First, call the API to delete on the server
    await deleteCategoriesFromAPI(categoryIds);

    // If server call succeeds, update local DB
    await deleteCategoriesFromDB(categoryIds);
    return true;
  } catch (error) {
    console.error("Error in deleteCategories:", error);
    // No need to show toast errors here as they're already handled in the API function
    return false;
  }
};
