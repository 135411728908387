import React, { useEffect, useState } from "react";
import { Button as MuiButton, TextField } from "@mui/material";
import { deleteAuth, getAuth, postAuth } from "../../../../global/globalUtils";
import styled from "styled-components";
import { toast } from "sonner";
import { formatDate } from "../Organizations/utils";
import { useTranslation } from "../../../../components/translate/TranslateContext";

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* Center align items vertically */
  margin-top: 20px;

  & > button {
    margin: 0 5px;
  }

  .page-info {
    margin: 0 10px;
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 10px; /* Space between buttons */
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const Table = () => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [channelRequests, setChannelRequests] = useState([]);
  const [channelSearchTerm, setChannelSearchTerm] = useState("");
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const { translate } = useTranslation();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getAuth(
        `/dashboard/channel_requests/?page=${currentPage}&limit=${itemsPerPage}&channelSearch=${channelSearchTerm}&userSearch=${userSearchTerm}`
      );
      const responseData = await response.json();
      setChannelRequests(responseData.data);
      setCount(responseData.total);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 500); // 500ms debounce delay

    return () => clearTimeout(delayDebounceFn);
  }, [channelSearchTerm, currentPage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 500); // 500ms debounce delay

    return () => clearTimeout(delayDebounceFn);
  }, [userSearchTerm, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  const resolveChannelRequest = async (id, status) => {
    try {
      const response = await postAuth(`/dashboard/channel_requests/${id}`, {
        status: status,
      });
      await response.json();
      if (response.ok) {
        toast.success(translate("Channel request resolved successfully"));
        fetchData();
      } else {
        toast.error(translate("Failed to resolve channel request"));
      }
    } catch (error) {
      console.error("Failed to resolve channel request", error);
    }
  };

  return (
    <>
      <HeaderContainer>
        <SearchContainer>
          <TextField
            label={translate("Search Channel")}
            variant="filled"
            value={channelSearchTerm}
            onChange={(e) => setChannelSearchTerm(e.target.value)}
          />
          <TextField
            label={translate("Search User")}
            variant="filled"
            value={userSearchTerm}
            onChange={(e) => setUserSearchTerm(e.target.value)}
          />
        </SearchContainer>
        <span>
          {translate("Number Of Requested Channels")}: {count}
        </span>
      </HeaderContainer>
      <div className="data-table-diagram">
        {loading ? (
          <p>{translate("Loading...")}</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>{translate("Requested Channel")}</th>
                  <th>{translate("Platform")}</th>
                  <th>{translate("Requested By")}</th>
                  <th>{translate("Status")}</th>
                  <th>{translate("Created At")}</th>
                  <th>{translate("Updated At")}</th>
                  <th>{translate("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {channelRequests.length > 0 ? (
                  channelRequests.map((item) => (
                    <tr key={item.id}>
                      <td>{item.channel_username || "N/A"}</td>
                      <td>{item.Source?.name || "N/A"}</td>
                      <td>{item.User?.email || "N/A"}</td>
                      <td
                        style={{
                          color:
                            item.status === "APPROVED"
                              ? "#007bff"
                              : item.status === "REJECTED"
                              ? "#dc3545"
                              : "#F29339",
                        }}
                      >
                        {item.status}
                      </td>

                      <td>{formatDate(item.createdAt)}</td>
                      <td>{formatDate(item.updatedAt)}</td>
                      <td>
                        <ButtonContainer>
                          <MuiButton
                            onClick={() => {
                              resolveChannelRequest(item.id, "APPROVED");
                            }}
                            variant="contained"
                            sx={{
                              backgroundColor: "#007bff", // Blue color for "View Team"
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#0056b3", // Darker blue on hover
                              },
                            }}
                          >
                            {translate("Resolve")}
                          </MuiButton>
                          <MuiButton
                            onClick={() => {
                              resolveChannelRequest(item.id, "REJECTED");
                            }}
                            variant="contained"
                            sx={{
                              backgroundColor: "#dc3545", // Red color for "Delete"
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#c82333", // Darker red on hover
                              },
                            }}
                          >
                            {translate("Reject")}
                          </MuiButton>
                        </ButtonContainer>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">
                      {translate("No Channel Requests found")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <PaginationControls>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                {translate("First")}
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {translate("Previous")}
              </MuiButton>
              <span className="page-info">
                {translate("Page")} {currentPage} {translate("of")} {totalPages}
              </span>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                {translate("Next")}
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                {translate("Last")}
              </MuiButton>
            </PaginationControls>
          </>
        )}
      </div>
    </>
  );
};

export default Table;
