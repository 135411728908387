import React, { createContext, useState, useContext } from "react";

const FiltersContext = createContext();

const FiltersProvider = ({ children }) => {
  const [keyword, setKeyword] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sentiment, setSentiment] = useState("");

  return (
    <FiltersContext.Provider
      value={{
        keyword,
        setKeyword,
        selectedSource,
        setSelectedSource,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        selectedChannels,
        setSelectedChannels,
        selectedCategories,
        setSelectedCategories,
        sentiment,
        setSentiment,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

// Custom hook to use the FiltersContext
const useFilters = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error("useFilters must be used within a FiltersProvider");
  }
  return context;
};

export { FiltersContext, FiltersProvider, useFilters };
