import React, { useState } from "react";
import styled from "styled-components";
import { BookmarkComponent } from "./tabs/bookmarks/Bookmarks";
import SearchComponent from "./tabs/global-search/SearchTab";
import TrendingTab from "./tabs/trending/TrendingTab";
import DeleteCategoryModal from "./tabs/bookmarks/DeletePopup";
import TelegramPopup from "../post/widgets/TelegramWidget";
import TwitterPopup from "../post/widgets/TwitterWidget";
import FacebookPopup from "../post/widgets/FacebookWidget";
import { useAuth } from "../authentication/auth-context/AuthContext";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import DownloadTab from "./tabs/global-download/DownloadTab";
import { useTranslation } from "../translate/TranslateContext";

const SidePanelContainer = styled.div`
  position: fixed;
  inset-inline-end: 0;
  top: 0;
  width: 400px;
  height: 100vh;
  padding: 20px;
  background-color: ${({ theme }) => theme.column_background};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 22;
  transform: translateX(
    ${(props) =>
      props.$show ? "0" : props.$direction === "rtl" ? "-100%" : "100%"}
  );
  transition: transform 0.3s ease;
  max-height: 95vh;

  @media (max-width: 768px) {
    width: 80%;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.chip_inactive};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.chip_inactive};
  }
`;

const TabsHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.column_background};
  padding: 0;
  z-index: 2;
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 2px solid ${({ theme }) => theme.border || "#eaeaea"};
  overflow: hidden;
  justify-content: center;
  @media (max-width: 768px) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

const Tab = styled.div`
  font-size: 14px; /* Reduced font size */
  padding: 8px 8px; /* Reduced padding */
  cursor: pointer;
  display: flex;
  position: relative;
  gap: 5px;
  transition: background-color 0.3s;
  border-bottom: ${({ theme, $selected }) =>
    $selected ? `2px solid ${theme.background}` : "none"};

  border-start-start-radius: 5px;
  border-start-end-radius: 5px;

  &:hover {
    background-color: ${({ theme }) =>
      theme.post_hover_background || "#f5f5f5"};
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -3px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    height: 3px;
    background-color: #1e2836;
    transform: ${({ $selected }) => ($selected ? "scaleX(1)" : "scaleX(0)")};
    transform-origin: bottom left;
    transition: transform 0.3s;
  }

  p {
    margin: 0 0 0 8px;
    font-size: 14px; /* Reduced font size */
  }

  .material-icons {
    font-size: 20px; /* Reduced icon size */
  }

  ${({ $hideOnMobile }) =>
    $hideOnMobile &&
    `
    @media (max-width: 768px) {
      display: none; /* Hide the tab on mobile screens */
    }
  `}
`;

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0;
  height: calc(100vh - 80px); // Adjust based on your header height

  &::-webkit-scrollbar {
    width: 0; /* Make the scrollbar invisible */
    height: 0; /* Make the scrollbar invisible */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Make the track invisible */
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* Make the thumb invisible */
    border-radius: 10px;
    border: none; /* Remove the border */
  }

  /* For Firefox */
  scrollbar-width: none; /* Hide the scrollbar */
  scrollbar-color: transparent transparent; /* Make the scrollbar invisible */
`;

const SidePanelTabs = ({ show }) => {
  const [showBookmarks, setShowBookmarks] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [showTrending, setShowTrending] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  const [isPostPopupOpen, setIsPostPopupOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [currentsender, setCurrentSender] = useState("");
  const [currentSource, setCurrentSource] = useState("");
  const [currentTweetId, setCurrentTweetId] = useState("");
  const [currentPostUrl, setCurrentPostUrl] = useState("");
  const { bundle } = useAuth();
  const navigate = useNavigate();
  const { translate, direction } = useTranslation();

  return (
    <>
      {isDeleteModalOpen && (
        <DeleteCategoryModal
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
        />
      )}
      {isPostPopupOpen &&
        (currentSource === "Telegram" ? (
          <TelegramPopup
            senderusername={currentsender}
            post_id={currentId}
            onClose={() => setIsPostPopupOpen(false)}
          />
        ) : currentSource === "Facebook" ? (
          <FacebookPopup
            postUrl={currentPostUrl}
            onClose={() => setIsPostPopupOpen(false)}
          />
        ) : (
          <TwitterPopup
            tweetId={currentTweetId}
            onClose={() => setIsPostPopupOpen(false)}
          />
        ))}
      <SidePanelContainer $show={show} $direction={direction}>
        <TabsHeader>
          <Tabs>
            <Tab
              id="sidepanel-bookmarks"
              $selected={showBookmarks}
              onClick={() => {
                setShowBookmarks(true);
                setShowSearch(false);
                setShowTrending(false);
                setShowDownload(false);
              }}
            >
              <span className="material-icons">bookmark</span>
              <span>{translate("Bookmarks")}</span>
            </Tab>
            <Tab
              id="sidepanel-trending"
              $selected={showTrending}
              onClick={() => {
                setShowBookmarks(false);
                setShowSearch(false);
                setShowDownload(false);
                if (bundle === "free_trial") {
                  toast.error("This feature is not available in your plan");
                  navigate("/bundles");
                } else setShowTrending(true);
              }}
            >
              <span className="material-icons">trending_up</span>
              <span>{translate("Trending")}</span>
            </Tab>
            <Tab
              id="sidepanel-search"
              $selected={showSearch}
              onClick={() => {
                setShowBookmarks(false);
                setShowSearch(true);
                setShowTrending(false);
                setShowDownload(false);
              }}
            >
              <span className="material-icons">search</span>
              <span>{translate("Search")}</span>
            </Tab>
            <Tab
              id="sidepanel-search"
              $selected={showDownload}
              onClick={() => {
                setShowBookmarks(false);
                setShowSearch(false);
                setShowTrending(false);
                setShowDownload(true);
              }}
            >
              <span className="material-icons">download</span>
              <span>{translate("Download")}</span>
            </Tab>
          </Tabs>
        </TabsHeader>
        <ScrollableContent>
          {showBookmarks && (
            <BookmarkComponent
              setIsPostPopupOpen={setIsPostPopupOpen}
              setCurrentId={setCurrentId}
              setCurrentSender={setCurrentSender}
              setIsDeleteModalOpen={setIsDeleteModalOpen}
              setCurrentSource={setCurrentSource}
              setCurrentTweetId={setCurrentTweetId}
              setCurrentPostUrl={setCurrentPostUrl}
            />
          )}
          {showSearch && <SearchComponent />}
          {showTrending && <TrendingTab />}
          {showDownload && <DownloadTab />}
        </ScrollableContent>
      </SidePanelContainer>
    </>
  );
};

export default SidePanelTabs;
