import React from "react";

const ColumnSort = ({ sortOrder, column, sortedColumn, sortHandler }) => {
  return (
    <button
      onClick={() => sortHandler(column)}
      style={{
        color: "#000",
        opacity: sortedColumn === column ? 1 : 0.5,
      }}
    >
      {sortOrder === "asc" ? <span> ▲</span> : <span> ▼</span>}
    </button>
  );
};

export default ColumnSort;
