import React from "react";
import Appearance from "./Appearance";
import LanguageSwitcher from "./components/LanguageSwitcher";

const General = () => {
  return (
    <div>
      <Appearance />
      <LanguageSwitcher showLabel />
    </div>
  );
};

export default General;
