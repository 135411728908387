import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useTheme } from "../../themes/ThemeContext";

const Background = styled.div`
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  position: relative; /* Ensure relative positioning for the back icon */
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  background-color: ${({ theme }) => theme.main};
  border-radius: 8px; /* Optional for rounded corners */
`;

const BackIcon = styled.span`
  position: absolute;
  top: 60px; /* Adjust for spacing from the top */
  inset-inline-start: 15px; /* Adjust for spacing from the left */
  font-size: 24px; /* Icon size */
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const WidgetContainer = styled.div`
  width: 100%;
  max-height: 95vh;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.popup_background};

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 8px; /* Decrease the width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.scrollbarTrack}; /* Track color */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scrollbarThumb}; /* Thumb color */
    border-radius: 10px;
    border: 2px solid ${({ theme }) => theme.scrollbarTrack}; /* Adds space around thumb */
  }

  /* Firefox scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.scrollbarThumb}
    ${({ theme }) => theme.scrollbarTrack};
`;

function TelegramPopup({ senderusername, post_id, onClose }) {
  const widgetRef = useRef(null);
  const { theme } = useTheme();

  useEffect(() => {
    if (widgetRef.current) {
      widgetRef.current.innerHTML = "";

      const script = document.createElement("script");

      script.src = "https://telegram.org/js/telegram-widget.js?22";
      script.setAttribute("data-telegram-post", `${senderusername}/${post_id}`);
      script.setAttribute("data-width", "100%");
      script.setAttribute("data-dark", theme === "dark" ? 1 : 0);
      script.async = true;

      // Append the script to the container designated for the widget
      widgetRef.current.appendChild(script);
      const widgetcurrent = widgetRef.current;

      // Optional: Cleanup the script when the component unmounts
      return () => {
        if (widgetcurrent) {
          widgetcurrent.removeChild(script);
        }
      };
    }
  }, [senderusername, post_id, theme]);

  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <Background onClick={handleBackgroundClick}>
      <PopupContainer>
        <BackIcon onClick={onClose} className="material-icons">
          keyboard_return
        </BackIcon>
        <WidgetContainer ref={widgetRef} />
      </PopupContainer>
    </Background>
  );
}

export default TelegramPopup;
