import React, { useEffect, useRef, useState, useCallback } from "react";
import ReactDOMServer from "react-dom/server";
import * as d3 from "d3";
import { getAuth } from "../../../../global/globalUtils";
import { toast } from "sonner";
import { BsBuilding, BsPerson, BsCircleFill } from "react-icons/bs";
import { LoadMoreButton, NODE_CONFIG, styles, transformData } from "./utils";
import DataTable from "./DataTable";
import { useFilters } from "../../FilterContext";

const BATCH_SIZE = 250;
const MAX_PAGES = 6;

const NetworkMap = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [allData, setAllData] = useState({ organizations: [], persons: [] });
  const [selectedPost, setSelectedPost] = useState(null);
  const [allPosts, setAllPosts] = useState([]);

  const svgRef = useRef();
  const simulationRef = useRef(null);
  const containerRef = useRef(null);
  const tooltipRef = useRef();

  const {
    keyword,
    selectedSource,
    startDate,
    endDate,
    selectedChannels,
    selectedCategories,
    sentiment,
  } = useFilters();

  // Memoized data transformation
  const transformedData = useCallback(() => {
    const result = transformData(allData.organizations, allData.persons);

    // Collect all unique posts
    const posts = result.nodes
      .filter((node) => node.type === "post")
      .map((post) => ({
        id: post.id.replace("post_", ""),
        text: post.text,
        timestamp: post.timestamp,
        channel: post.channel,
        url: post.url,
      }));

    setAllPosts(posts);
    return result;
  }, [allData]);

  // Optimized data fetching
  const fetchData = async () => {
    if (page >= MAX_PAGES) {
      setHasMore(false);
      toast.warning("Reached maximum limit of data which is 2000 records");
      return;
    }

    const params = new URLSearchParams({
      keyword: keyword,
      source: selectedSource,
      start_date: startDate,
      end_date: endDate,
      channels: selectedChannels.join(","),
      categories: selectedCategories.join(","),
      sentiment: sentiment,
      limit: BATCH_SIZE,
      page: page,
    });

    try {
      setLoading(true);
      const response = await getAuth(`/dashboard/ner?${params.toString()}`);

      if (!response.ok) {
        toast.error("Failed to fetch network data");
        return;
      }

      const jsonData = await response.json();

      // Set hasMore based on both data availability AND page limit
      setHasMore(page < MAX_PAGES);

      // If it's page 1, override existing data, otherwise merge
      setAllData((prevData) => {
        if (page === 1) {
          return {
            organizations: [...jsonData.data.organizations],
            persons: [...jsonData.data.persons],
          };
        }
        return {
          organizations: [
            ...prevData.organizations,
            ...jsonData.data.organizations,
          ],
          persons: [...prevData.persons, ...jsonData.data.persons],
        };
      });
    } catch (error) {
      console.error("Error fetching network data:", error);
      toast.error("Error loading network data");
      // Set hasMore to false on error for initial load
      if (page === 1) {
        setHasMore(false);
      }
    } finally {
      setLoading(false);
    }
  };

  // Initial load
  useEffect(() => {
    fetchData();
  }, [
    keyword,
    selectedSource,
    startDate,
    endDate,
    selectedChannels,
    selectedCategories,
    sentiment,
    page,
  ]);

  // Initialize visualization
  const initializeVisualization = useCallback(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    const width = svg.node().getBoundingClientRect().width;
    const height = svg.node().getBoundingClientRect().height;
    const bounds = svg.node().getBoundingClientRect();

    // Check if dimensions are valid
    if (bounds.width === 0 || bounds.height === 0) {
      return null;
    }

    // Clear previous content
    svg.selectAll("*").remove();

    // Create container first with a zoomed out scale (0.5)
    containerRef.current = svg
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2}) scale(0.5)`); // Adjusted values

    // Create zoom behavior
    const zoom = d3
      .zoom()
      .scaleExtent([0.1, 4])
      .on("zoom", (event) => {
        if (containerRef.current) {
          containerRef.current.attr("transform", event.transform);
        }
      });

    // Apply zoom behavior after setting initial transform
    svg.call(zoom);

    return { width, height };
  }, []);

  // Update visualization
  const updateVisualization = useCallback(() => {
    // Initialize first to create the container
    const dimensions = initializeVisualization();
    if (!dimensions) {
      return;
    }
    const { nodes, links } = transformedData();

    const { width, height } = dimensions;

    // Cleanup previous simulation
    if (simulationRef.current) {
      simulationRef.current.stop();
    }

    // Optimized force simulation
    simulationRef.current = d3
      .forceSimulation(nodes)
      .force(
        "link",
        d3
          .forceLink(links)
          .id((d) => d.id)
          .distance(100)
      )
      .force("charge", d3.forceManyBody().strength(-200))
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force("collision", d3.forceCollide().radius(25))
      .alphaDecay(0.05) // Faster convergence
      .velocityDecay(0.3); // Reduced oscillation

    // Draw elements with optimized selections
    const container = containerRef.current;

    // Optimized link rendering
    const linkElements = container
      .selectAll("line.link")
      .data(links, (d) => `${d.source.id}-${d.target.id}`)
      .join((enter) =>
        enter
          .append("line")
          .attr("class", "link")
          .style("stroke", "#999")
          .style("stroke-opacity", 0.6)
          .style("stroke-width", 1)
      );

    // Optimized node rendering
    const nodeElements = container
      .selectAll("g.node")
      .data(nodes, (d) => d.id)
      .join((enter) => {
        const nodeGroup = enter
          .append("g")
          .attr("class", "node")
          .call(
            d3
              .drag()
              .on("start", dragStarted)
              .on("drag", dragged)
              .on("end", dragEnded)
          );

        // Add visual elements
        addNodeVisuals(nodeGroup);
        addNodeInteractions(nodeGroup);

        return nodeGroup;
      });

    // Optimized simulation updates
    simulationRef.current.on("tick", () => {
      linkElements
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);

      nodeElements.attr("transform", (d) => `translate(${d.x},${d.y})`);
    });

    // Drag handlers
    function dragStarted(event) {
      if (!event.active && simulationRef.current)
        simulationRef.current.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragEnded(event) {
      if (!event.active && simulationRef.current)
        simulationRef.current.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }
  }, [transformedData]);

  // Add node visuals
  const addNodeVisuals = useCallback((nodeGroup) => {
    nodeGroup.each(function (d) {
      const node = d3.select(this);
      const nodeSize = NODE_CONFIG.sizes[d.type];

      // Add background circle
      node
        .append("circle")
        .attr("r", nodeSize)
        .style("fill", NODE_CONFIG.colors[d.type])
        .style("stroke", "#fff")
        .style("stroke-width", 2);

      // Add icon if not a post
      if (d.type !== "post") {
        const icon = d.type === "organization" ? BsBuilding : BsPerson;
        node
          .append("foreignObject")
          .attr("width", nodeSize * 1.5)
          .attr("height", nodeSize * 1.5)
          .attr("x", -nodeSize * 0.75)
          .attr("y", -nodeSize * 0.75)
          .append("xhtml:div")
          .style("width", "100%")
          .style("height", "100%")
          .style("display", "flex")
          .style("align-items", "center")
          .style("justify-content", "center")
          .html(() =>
            ReactDOMServer.renderToString(
              icon({ size: nodeSize, color: "#fff" })
            )
          );
      }

      // Add label
      node
        .append("text")
        .text(d.type === "post" ? d.channel : d.name)
        .attr("x", d.type === "post" ? 15 : 20)
        .attr("y", 5)
        .style("font-size", "12px")
        .style("font-family", "'Segoe UI', sans-serif")
        .style("pointer-events", "none");
    });
  }, []);

  // Add node interactions
  const addNodeInteractions = useCallback((nodeGroup) => {
    nodeGroup
      .on("mouseover", (event, d) => {
        const tooltip = d3.select(tooltipRef.current);
        const content =
          d.type === "post"
            ? `
            <div style="font-weight: bold">Post</div>
            <div style="margin-top: 5px">${d.text?.substring(0, 150)}${
                d.text?.length > 150 ? "..." : ""
              }</div>
            <div style="margin-top: 5px">Published: ${new Date(
              d.timestamp
            ).toLocaleDateString()}</div>
            <div>Channel: ${d.channel}</div>
          `
            : `
            <div style="font-weight: bold">${d.name}</div>
            <div>Type: ${d.type.charAt(0).toUpperCase() + d.type.slice(1)}</div>
            <div>${d.additionalInfo}</div>
          `;

        tooltip
          .style("display", "block")
          .style("insetInlineStart", `${event.pageX + 10}px`)
          .style("top", `${event.pageY + 10}px`)
          .html(content);
      })
      .on("mouseout", () => {
        d3.select(tooltipRef.current).style("display", "none");
      })
      .on("click", (event, d) => {
        if (d.type === "post") {
          // Check if we're already at the bottom
          const isAtBottom =
            window.innerHeight + window.pageYOffset >=
            document.documentElement.scrollHeight - 100;

          const modifiedPost = {
            ...d,
            id: d.id.replace("post_", ""), // Remove the "post_" prefix
          };

          setSelectedPost(modifiedPost);

          if (!isAtBottom) {
            const dataTable = document.getElementById(
              `post-${modifiedPost.id}`
            );
            if (dataTable) {
              dataTable.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          }
        }
      });
  }, []);

  // Update visualization when data changes
  useEffect(() => {
    if (allData.organizations.length || allData.persons.length) {
      // Remove the setTimeout - it might be causing issues
      updateVisualization();
    }

    return () => {
      if (simulationRef.current) {
        simulationRef.current.stop();
      }
    };
  }, [allData, updateVisualization]);

  return (
    <div
      style={{
        marginBottom: 20,
      }}
    >
      <div style={styles.container}>
        <svg ref={svgRef} style={styles.svg}>
          <defs>
            <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
              <feDropShadow dx="0" dy="1" stdDeviation="2" floodOpacity="0.2" />
            </filter>
          </defs>
        </svg>

        <div style={styles.legend}>
          <div style={styles.legendItem}>
            <BsCircleFill size={12} color={NODE_CONFIG.colors.post} />
            <span>Posts</span>
          </div>
          <div style={styles.legendItem}>
            <BsBuilding size={12} color={NODE_CONFIG.colors.organization} />
            <span>Organizations</span>
          </div>
          <div style={styles.legendItem}>
            <BsPerson size={12} color={NODE_CONFIG.colors.person} />
            <span>Persons</span>
          </div>
          {hasMore && (
            <LoadMoreButton
              onClick={() => {
                setPage(page + 1);
              }}
              disabled={loading}
            >
              {loading ? "Loading..." : "Load More Data"}
            </LoadMoreButton>
          )}
        </div>

        <div ref={tooltipRef} style={styles.tooltip}></div>
      </div>
      <DataTable posts={allPosts} selectedPost={selectedPost} />
    </div>
  );
};

export default NetworkMap;
