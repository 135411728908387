import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import styled from "styled-components";
import { useCustomStyles } from "../utils/CustomStyles";
import Notifications from "../popup_components/NotificationSelector";
import avatar from "../../../assets/images/default-avatar.avif";
import { useTheme } from "../../themes/ThemeContext";
import { toast } from "sonner";
import { webApi } from "../../../global/globalUtils";
import Spinner from "../../../global/loaders/Spinner";
import { useTranslation } from "../../translate/TranslateContext";

// Styled Components
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.popup_background};
  border-radius: 8px;
  padding-inline-start: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 320px;
  max-width: 480px;
`;

const Section = styled.section`
  margin-bottom: 24px;
`;

const SectionHeader = styled.h3`
  color: ${({ theme }) => theme.text};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
`;

const Input = styled.input`
  padding: 10px 12px;
  border: 1px solid ${({ theme }) => theme.border_color};
  border-radius: 4px;
  background: ${({ theme }) =>
    theme.input_background || theme.popup_background};
  color: ${({ theme }) => theme.text};
  font-size: 14px;
  transition: border-color 0.2s ease;

  &:focus {
    border-color: ${({ theme }) => theme.primary || "#007bff"};
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.placeholder || "#8894a8"};
  }
`;

const IconSelectContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  align-items: center;
`;

const IconPreview = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border_color};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: ${({ theme }) =>
    theme.input_background || theme.popup_background};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  span {
    font-size: 24px;
  }
`;

const IconOptionsContainer = styled.div`
  flex: 1;
`;

const UploadButton = styled.button`
  background: ${({ theme }) => theme.button_background || "#007bff"};
  color: ${({ theme }) => theme.button_text || "#ffffff"};
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const IconTypeToggle = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
`;

const ToggleButton = styled.button`
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border_color};
  background: ${({ active, theme }) =>
    active ? theme.button_background : "transparent"};
  color: ${({ active, theme }) => (active ? theme.button_text : theme.text)};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.hover_background};
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.error || "#dc3545"};
  font-size: 12px;
  margin-top: 4px;
  display: block;
`;

export const FirstColumn = ({
  selectedIcon,
  setSelectedIcon,
  onNotificationChange,
  onNotificationSoundChange,
  column,
  setColumnName,
  setSelectedFile,
}) => {
  const [iconType, setIconType] = useState(column?.icon_type || "default");
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);
  const customStyles = useCustomStyles();
  const [iconOptions, setIconOptions] = useState([]);
  const { theme } = useTheme();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [fetchedCustomIcon, setFetchedCustomIcon] = useState(null);
  const [isIconLoading, setIsIconLoading] = useState(false);

  const { translate } = useTranslation();

  useEffect(() => {
    // Initialize selectedIcon based on the column data
    if (column) {
      if (column?.icon_type === "custom") {
        // For custom icons, we don't have the preview URL immediately
        // so we set the type without a preview, and let the fetch effect handle it
        setSelectedIcon({
          type: "custom",
          value: column.icon,
        });
      } else if (column?.icon_type === "default" && column.icon) {
        // For default icons, we can set the preview right away
        setSelectedIcon({
          type: "default",
          value: column.icon,
          preview: `/default-column-icons/${theme}/${column.icon}.png`,
        });
      }
    }
  }, [column, theme]);

  // Available icons list - could be moved to a constants file
  const availableIcons = ["favorite", "home", "newspaper", "star"];

  // Handle icon type selection (default or custom)
  const handleIconTypeChange = (type) => {
    setIconType(type);
    setError("");

    // Don't clear selected icon when switching to the same type that's already set
    if (selectedIcon?.type === type) {
      return;
    }

    // If switching to custom and there's an existing custom icon in the column
    if (type === "custom" && column?.icon_type === "custom") {
      setSelectedIcon({
        type: "custom",
        value: column.icon,
      });
      return;
    }

    // Clear selected icon when switching types
    setSelectedIcon(null);

    // Clear file selection when switching back to default icons
    if (type === "default") {
      setSelectedFile(null);

      // Clean up any existing preview URL
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
        setPreviewUrl(null);
      }
    }
  };

  // Handle default icon selection
  const handleIconSelect = (icon) => {
    setError("");
    setSelectedIcon({
      type: "default",
      value: icon,
      // Store the full path for convenience in preview
      preview: `/default-column-icons/${theme}/${icon}.png`,
    });
  };

  // Set up icon options when component mounts
  useEffect(() => {
    // Create the options array for the Select component
    const options = availableIcons.map((iconName) => ({
      value: iconName,
      label: iconName,
    }));

    setIconOptions(options);

    // If we already have a selected icon and it's a default one,
    // update its preview path with the current theme
    if (selectedIcon && selectedIcon.type === "default" && selectedIcon.value) {
      setSelectedIcon({
        ...selectedIcon,
        preview: `/default-column-icons/${theme}/${selectedIcon.value}.png`,
      });
    }
  }, [theme]);

  // Clean up object URLs when component unmounts
  useEffect(() => {
    return () => {
      // Cleanup any created object URLs to prevent memory leaks
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, []);

  // Handle file selection for custom icon
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Validate file type
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedTypes.includes(file.type)) {
      setError("Invalid file type. Only JPEG, PNG, and GIF are allowed.");
      toast.error("Invalid file type. Only JPEG, PNG, and GIF are allowed.");
      return;
    }

    // Validate file size (e.g., max 2MB)
    const maxSize = 2 * 1024 * 1024; // 2MB
    if (file.size > maxSize) {
      setError(`File size exceeds maximum allowed (2MB).`);
      toast.error("File size exceeds maximum allowed (2MB).");
      return;
    }

    // Clean up previous preview URL if it exists
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }

    // Create a new preview URL
    const newPreviewUrl = URL.createObjectURL(file);
    setPreviewUrl(newPreviewUrl);

    // Update selected icon state
    setSelectedIcon({
      type: "custom",
      value: file.name, // Store filename for display purposes
      preview: newPreviewUrl,
    });

    // Pass the file to parent component
    setSelectedFile(file);
  };

  // Effect to fetch the custom icon if needed
  useEffect(() => {
    // Only fetch if it's a custom icon without a preview (existing icon from server)
    if (
      iconType === "custom" &&
      selectedIcon?.type === "custom" &&
      !selectedIcon.preview &&
      column?.id
    ) {
      setIsIconLoading(true);

      const fetchIcon = async () => {
        try {
          const response = await fetch(
            `${webApi}/column/column-icon/${column?.id}`,
            {
              credentials: "include",
            }
          );

          if (!response.ok) {
            throw new Error("Failed to load icon");
          }

          const blob = await response.blob();
          const objectUrl = URL.createObjectURL(blob);

          setFetchedCustomIcon(objectUrl);
          setIsIconLoading(false);
        } catch (err) {
          console.error("Error loading icon:", err);
          setIsIconLoading(false);
        }
      };

      fetchIcon();

      // Cleanup function to revoke object URL when component unmounts
      return () => {
        if (fetchedCustomIcon) {
          URL.revokeObjectURL(fetchedCustomIcon);
        }
      };
    }
  }, [selectedIcon, column?.id]);

  // Get the actual icon to display in the preview
  const getIconPreview = () => {
    if (!selectedIcon) return null;

    if (selectedIcon.type === "custom") {
      // If we have a preview from a newly uploaded file, use that
      if (selectedIcon.preview) {
        return selectedIcon.preview;
      }
      // For existing custom icons, use the fetched blob URL
      else if (fetchedCustomIcon) {
        return fetchedCustomIcon;
      }
      // Return null while loading
      return null;
    } else if (selectedIcon.type === "default") {
      return selectedIcon.preview || selectedIcon.value;
    }

    return null;
  };

  return (
    <ColumnContainer>
      <Section>
        <SectionHeader>{translate("Name")}</SectionHeader>
        <Input
          type="text"
          placeholder={translate("Enter column name")}
          aria-label="Column name"
          onChange={(e) => setColumnName(e.target.value)}
          defaultValue={column?.name || ""}
        />
      </Section>

      <Section>
        <SectionHeader>{translate("Icon")}</SectionHeader>
        <IconTypeToggle>
          <ToggleButton
            active={iconType === "default"}
            onClick={() => handleIconTypeChange("default")}
            type="button"
          >
            {translate("Default Icons")}
          </ToggleButton>
          <ToggleButton
            active={iconType === "custom"}
            onClick={() => handleIconTypeChange("custom")}
            type="button"
          >
            {translate("Custom Icon")}
          </ToggleButton>
        </IconTypeToggle>

        <IconSelectContainer>
          {iconType === "custom" && (
            <IconPreview>
              {selectedIcon ? (
                <>
                  {isIconLoading ? (
                    <Spinner size={5} />
                  ) : (
                    <img
                      src={getIconPreview()}
                      alt="Selected icon"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = avatar;
                      }}
                    />
                  )}
                </>
              ) : (
                <span>?</span>
              )}
            </IconPreview>
          )}

          <IconOptionsContainer>
            {iconType === "default" ? (
              <Select
                options={iconOptions}
                placeholder={translate("Select...")}
                styles={customStyles}
                value={
                  selectedIcon && selectedIcon.type === "default"
                    ? { value: selectedIcon.value, label: selectedIcon.value }
                    : null
                }
                onChange={(option) => handleIconSelect(option.value)}
                getOptionLabel={(option) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={`/default-column-icons/${theme}/${option.value}.png`}
                      alt={option.label}
                      style={{
                        marginRight: 10,
                        width: 24,
                        height: 24,
                      }}
                    />
                    {translate(option.value)}
                  </div>
                )}
                noOptionsMessage={() => translate("No icons found")}
              />
            ) : (
              <>
                <UploadButton
                  onClick={() => fileInputRef.current?.click()}
                  type="button"
                >
                  {translate("Upload Image")}
                </UploadButton>
                <HiddenInput
                  ref={fileInputRef}
                  type="file"
                  accept="image/jpeg,image/png,image/gif"
                  onChange={handleFileSelect}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </>
            )}
          </IconOptionsContainer>
        </IconSelectContainer>
      </Section>

      <Section>
        <Notifications
          onNotificationChange={onNotificationChange}
          onNotificationSoundChange={onNotificationSoundChange}
          column={column}
        />
      </Section>
    </ColumnContainer>
  );
};

export default FirstColumn;
