import React, { useState } from "react";
import styled from "styled-components";
import FirstColumn from "./popup_internal_columns/FirstColumn";
import SecondColumn from "./popup_internal_columns/SecondColumn";
import ThirdColumn from "./popup_internal_columns/ThirdColumn";
import Settings, { fadeIn } from "../settings/Settings";
import "../../assets/css/Popup.css";
import { useTranslation } from "../translate/TranslateContext";

const PopupContainer = styled.div`
  background-color: ${({ theme }) => theme.popup_background};
  box-shadow: 0px 0px 10px ${({ theme }) => theme.popup_shadow};
  border-radius: 10px;
  animation: ${fadeIn} 0.3s ease-out;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  font-size: 32px;
  padding: 0 16px;
  background: none;
  border: none;
  color: white;
  transition: all 0.2s ease-in-out;
  line-height: 1;

  &:hover {
    color: #bbb;
  }

  &:focus {
    outline: none;
  }
`;

const Popup = ({ onClose, type }) => {
  const [checkedButtons, setCheckedButtons] = useState({
    text: true,
    images: true,
    videos: true,
    links: true,
  });
  const [columnName, setColumnName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [availableChannels, setAvailableChannels] = useState([]);
  const [chosenChannels, setChosenChannels] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [notificationEnabled, setNotificationEnabled] = useState(false);
  const [notificationSound, setNotificationSound] = useState("Off");
  const { translate } = useTranslation();

  const handleToggle = (type) => {
    setCheckedButtons({
      ...checkedButtons,
      [type]: !checkedButtons[type],
    });
  };


  const handleAddChannel = (channel) => {
    setAvailableChannels((prev) => prev.filter((ch) => ch.id !== channel.id));
    setChosenChannels((prev) => [...prev, channel]);
  };

  const handleRemoveChannel = (channel) => {
    setChosenChannels((prev) => prev.filter((ch) => ch.id !== channel.id));
    setAvailableChannels((prev) => [channel, ...prev]);
  };

  const handleNotificationChange = (event) => {
    setNotificationEnabled(event.target.value === "On");
  };

  const handleNotificationSoundChange = (event) => {
    setNotificationSound(event.target.value);
  };

  return (
    <PopupContainer>
      {type === "newColumn" && (
        <div className="ColumnContainer">
          <div className="afj-columnHeader">
            <h2 className="popupheader">{translate("Create Column")}</h2>
            <CloseButton onClick={onClose}>&times;</CloseButton>
          </div>
          <hr className="SectionDivider" />
          <div className="ColumnElements">
            <FirstColumn
              selectedIcon={selectedIcon}
              setSelectedIcon={setSelectedIcon}
              onNotificationChange={handleNotificationChange}
              onNotificationSoundChange={handleNotificationSoundChange}
              column={null}
              setColumnName={setColumnName}
              setSelectedFile={setSelectedFile}
            />
            <SecondColumn
              checkedButtons={checkedButtons}
              handleToggle={handleToggle}
              channels={availableChannels}
              onAddChannel={handleAddChannel}
              chosenChannels={chosenChannels}
              setChannels={setAvailableChannels}
            />
            <ThirdColumn
              onClose={onClose}
              chosenChannels={chosenChannels}
              onRemoveChannel={handleRemoveChannel}
              selectedIcon={selectedIcon}
              notificationEnabled={notificationEnabled}
              notificationSound={notificationSound}
              columnName={columnName}
              selectedFile={selectedFile}
            />
          </div>
        </div>
      )}
      <Settings open={type === "settings"} onClose={onClose} />
    </PopupContainer>
  );
};

export default Popup;
