import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const Background = styled.div`
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  background-color: ${({ theme }) => theme.main};
  border-radius: 8px;
`;

const BackIcon = styled.span`
  position: absolute;
  top: 60px;
  inset-inline-start: 15px;
  font-size: 24px;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const WidgetContainer = styled.div`
  width: 100%;
  max-height: 95vh;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.popup_background};
  display: flex;
  justify-content: center;
  align-items: center;
`;

function FacebookWidget({ postUrl, onClose }) {
  const widgetRef = useRef(null);

  useEffect(() => {
    // Load Facebook SDK if not already loaded
    if (!window.FB) {
      const script = document.createElement("script");
      script.src =
        "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v17.0";
      script.async = true;
      script.onload = () => window.FB && window.FB.XFBML.parse();
      document.body.appendChild(script);
    } else {
      window.FB.XFBML.parse();
    }

    // Cleanup on unmount
    return () => {
      if (widgetRef.current) {
        widgetRef.current.innerHTML = "";
      }
    };
  }, [postUrl]);

  const handleBackgroundClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <Background onClick={handleBackgroundClick}>
      <PopupContainer>
        <BackIcon onClick={onClose} className="material-icons">
          keyboard_return
        </BackIcon>
        <WidgetContainer ref={widgetRef}>
          <div
            className="fb-post"
            data-href={postUrl}
            data-width="500"
            data-show-text="true"
          ></div>
        </WidgetContainer>
      </PopupContainer>
    </Background>
  );
}

export default FacebookWidget;
