import React, { useState } from "react";
import styled from "styled-components";
import NewsMapSidebar from "./sidepanel-components/news-tab/NewsTab";
import FiltersSidebar from "./sidepanel-components/filters-tab/FilterTab";
import KeyTab from "./sidepanel-components/key-tab/KeyTab";
import { useTheme } from "../../themes/ThemeContext";
import TelegramPopup from "../../post/widgets/TelegramWidget";
import TwitterPopup from "../../post/widgets/TwitterWidget";
import FacebookPopup from "../../post/widgets/FacebookWidget";
import { useTranslation } from "../../translate/TranslateContext";

const SidePanelContainer = styled.div`
  position: fixed;
  inset-inline-end: 0;
  top: 0;
  width: 400px;
  height: 100vh;
  padding: 20px;
  padding-top: 11px;
  background-color: ${({ theme }) => theme.column_background};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transform: translateX(
    ${(props) =>
      props.$show ? "0" : props.direction === "ltr" ? "100%" : "-100%"}
  );
  transition: transform 0.3s ease;
  max-height: 98vh;

  @media (max-width: 768px) {
    width: 60%;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.chip_inactive};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.chip_inactive};
  }
`;

const TabsHeader = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.column_background};
  padding: 0;
  z-index: 2;
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 2px solid ${({ theme }) => theme.border || "#eaeaea"};
  overflow: hidden;
  justify-content: center;
`;

const Tab = styled.div`
  padding: 8px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  gap: 5px;
  transition: background-color 0.3s;
  color: ${({ $selected, theme }) =>
    $selected ? theme.text : theme === "dark" ? "#bcb8cb" : "#000"};
  border-bottom: ${({ $selected }) =>
    $selected ? "2px solid #1e2836" : "none"};

  &:hover {
    color: ${({ $selected, theme }) =>
      $selected ? theme.text : theme === "dark" ? "#e0e0e0" : "#7359aa"};
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -3px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    height: 2px;
    background-color: #1e2836;
    transform: ${({ $selected }) => ($selected ? "scaleX(1)" : "scaleX(0)")};
    transform-origin: bottom left;
    transition: transform 0.3s;
  }

  .material-icons {
    font-size: 20px;
  }
`;

const ScrollableContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0;
  height: calc(100vh - 80px); // Adjust based on your header height

  &::-webkit-scrollbar {
    width: 0; /* Make the scrollbar invisible */
    height: 0; /* Make the scrollbar invisible */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Make the track invisible */
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent; /* Make the thumb invisible */
    border-radius: 10px;
    border: none; /* Remove the border */
  }

  /* For Firefox */
  scrollbar-width: none; /* Hide the scrollbar */
  scrollbar-color: transparent transparent; /* Make the scrollbar invisible */
`;

const ContentWrapper = styled.div`
  display: ${({ $show }) => ($show ? "block" : "none")};
`;

const SidePanelTabs = ({ show }) => {
  const [isPostPopupOpen, setIsPostPopupOpen] = useState(false);
  const [showNews, setShowNews] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [currentTweetId, setCurrentTweetId] = useState("");
  const [currentSource, setCurrentSource] = useState("");
  const [currentsender, setCurrentSender] = useState("");
  const [currentPostUrl, setCurrentPostUrl] = useState("");
  const { theme } = useTheme();
  const { translate, direction } = useTranslation();

  const setIsOpen = (value) => {
    setIsPostPopupOpen(value);
  };

  return (
    <>
      {isPostPopupOpen &&
        (currentSource === "Telegram" ? (
          <TelegramPopup
            senderusername={currentsender}
            post_id={currentId}
            onClose={() => setIsOpen(false)}
          />
        ) : currentSource === "Facebook" ? (
          <FacebookPopup
            postUrl={currentPostUrl}
            onClose={() => setIsOpen(false)}
          />
        ) : (
          <TwitterPopup
            tweetId={currentTweetId}
            onClose={() => setIsOpen(false)}
          />
        ))}
      <SidePanelContainer $show={show} $direction={direction}>
        <TabsHeader>
          <Tabs>
            <Tab
              $selected={showNews}
              theme={theme}
              onClick={() => {
                setShowNews(true);
                setShowFilters(false);
                setShowKey(false);
              }}
            >
              <span className="material-symbols-outlined">news</span>{" "}
              <span>{translate("News")}</span>
            </Tab>
            <Tab
              $selected={showFilters}
              theme={theme}
              onClick={() => {
                setShowNews(false);
                setShowFilters(true);
                setShowKey(false);
              }}
            >
              <span className="material-symbols-outlined">tune</span>{" "}
              <span>{translate("Filters")}</span>
            </Tab>
            <Tab
              $selected={showKey}
              theme={theme}
              onClick={() => {
                setShowNews(false);
                setShowFilters(false);
                setShowKey(true);
              }}
            >
              <span className="material-symbols-outlined">key</span>{" "}
              <span>{translate("Key")}</span>
            </Tab>
          </Tabs>
        </TabsHeader>
        <ScrollableContent>
          <ContentWrapper $show={showNews}>
            <NewsMapSidebar
              setIsPostPopupOpen={setIsPostPopupOpen}
              setCurrentId={setCurrentId}
              setCurrentSender={setCurrentSender}
              setCurrentSource={setCurrentSource}
              setCurrentTweetId={setCurrentTweetId}
              setCurrentPostUrl={setCurrentPostUrl}
            />
          </ContentWrapper>
          <ContentWrapper $show={showFilters}>
            <FiltersSidebar />
          </ContentWrapper>
          <ContentWrapper $show={showKey}>
            <KeyTab />
          </ContentWrapper>
        </ScrollableContent>
      </SidePanelContainer>
    </>
  );
};

export default SidePanelTabs;
