import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getBookmarkedPostsFromDB,
  getCategoriesFromDB,
  deleteCategories,
  toggleBookmark,
  createCategoryDB,
  unbookmarkPostListDB,
} from "./bookmark_local_db";
import { useBookmarkSync } from "../authentication/auth-context/AuthContext";

const BookmarkContext = createContext({
  bookmarks: [],
  isLoading: false,
  bookmarkPost: () => Promise.resolve(),
  unbookmarkPost: () => Promise.resolve(),
  refreshBookmarks: () => Promise.resolve(),
  filterBookmarksByCategory: () => [],
});

export const BookmarkProvider = ({ children }) => {
  const [bookmarks, setBookmarks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { bookmarksSynced, syncBookmarks } = useBookmarkSync();

  // Load bookmarks from IndexedDB
  const loadBookmarks = async () => {
    setIsLoading(true);
    try {
      const fetchedBookmarks = await getBookmarkedPostsFromDB();
      const fetchedCategories = await getCategoriesFromDB();
      setBookmarks(fetchedBookmarks);
      setCategories(fetchedCategories);
    } catch (error) {
      console.error("Error loading bookmarks:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Initial load and when sync status changes
  useEffect(() => {
    if (bookmarksSynced) {
      loadBookmarks();
    }
  }, [bookmarksSynced]);

  // Refresh bookmarks - both sync from server and reload from DB
  const refreshBookmarks = async () => {
    await syncBookmarks();
    await loadBookmarks();
  };

  // Check if a post is bookmarked
  const isPostBookmarked = (postId) => {
    return bookmarks.some((bookmark) => bookmark.id === postId);
  };

  // Bookmark a post
  const bookmarkPost = async (postId, categoryId = null) => {
    try {
      // Call server API first
      const newBookmark = await toggleBookmark(postId, categoryId);

      // Update local state
      setBookmarks((prev) => [...prev, newBookmark]);
      return true;
    } catch (error) {
      console.error("Error bookmarking post:", error);
      return false;
    }
  };

  // Unbookmark a post
  const unbookmarkPost = async (postId) => {
    // Find the bookmark before removing it (to restore if needed)
    const bookmarkToRemove = bookmarks.find(
      (bookmark) => bookmark.id === postId
    );
    try {
      // Update local state immediately (optimistic)
      setBookmarks((prev) => prev.filter((bookmark) => bookmark.id !== postId));

      // Then call the API
      await toggleBookmark(postId);

      return true;
    } catch (error) {
      console.error("Error removing bookmark:", error);

      // Revert optimistic update on error
      if (bookmarkToRemove) {
        setBookmarks((prev) => [...prev, bookmarkToRemove]);
      }

      return false;
    }
  };

  const unbookmarkAllPosts = async () => {
    try {
      // Update local state immediately (optimistic)
      setBookmarks([]);

      await unbookmarkPostListDB(bookmarks.map((bookmark) => bookmark.id));

      return true;
    } catch (error) {
      console.error("Error removing bookmark:", error);

      return false;
    }
  };

  const unbookmarkUncategorizedPosts = async () => {
    try {
      // Update local state immediately (optimistic)
      setBookmarks((prev) =>
        prev.filter((bookmark) => bookmark.bookmark_category !== null)
      );

      await unbookmarkPostListDB(
        bookmarks
          .filter((bookmark) => bookmark.bookmark_category === null)
          .map((bookmark) => bookmark.id)
      );

      return true;
    } catch (error) {
      console.error("Error removing bookmark:", error);

      return false;
    }
  };

  const createNewCategory = async (categoryName) => {
    try {
      // Call the DB function which handles both local DB and API
      const newCategory = await createCategoryDB(categoryName);

      // Update local state
      setCategories((prevCategories) => [...prevCategories, newCategory]);
      return true;
    } catch (error) {
      console.error("Error creating category:", error);
      return false;
    }
  };

  // Filter bookmarks by category
  const filterBookmarksByCategory = (categoryIds = null) => {
    // Return all bookmarks if no category filter is provided
    if (categoryIds === null) {
      return bookmarks;
    }

    // Handle single category ID (backward compatibility)
    if (!Array.isArray(categoryIds)) {
      return bookmarks.filter(
        (bookmark) => bookmark.bookmark_category?.id === categoryIds
      );
    }

    // If empty array is provided, return all bookmarks
    if (categoryIds.length === 0) {
      return bookmarks;
    }

    // Filter by multiple categories
    return bookmarks.filter((bookmark) =>
      bookmark.bookmark_category
        ? categoryIds.includes(bookmark.bookmark_category.id)
        : false
    );
  };

  // Delete categories function
  const deleteContextCategories = async (categoryIds) => {
    if (!Array.isArray(categoryIds) || categoryIds.length === 0) {
      return false;
    }

    try {
      // Call the DB function which handles both local DB and API
      const success = await deleteCategories(categoryIds);

      if (success) {
        // 1. Remove deleted categories from state
        setCategories((prevCategories) =>
          prevCategories.filter(
            (category) => !categoryIds.includes(category.id)
          )
        );

        // 2. Remove the associated bookmarks from state
        setBookmarks((prevBookmarks) =>
          prevBookmarks.filter(
            (bookmark) => !categoryIds.includes(bookmark.bookmark_category?.id)
          )
        );

        return true;
      }

      return success;
    } catch (error) {
      console.error("Error deleting categories:", error);
      return false;
    }
  };

  return (
    <BookmarkContext.Provider
      value={{
        bookmarks,
        categories,
        isLoading,
        bookmarkPost,
        unbookmarkPost,
        unbookmarkUncategorizedPosts,
        unbookmarkAllPosts,
        isPostBookmarked,
        refreshBookmarks,
        createNewCategory,
        filterBookmarksByCategory,
        deleteContextCategories,
      }}
    >
      {children}
    </BookmarkContext.Provider>
  );
};

export const useBookmarks = () => useContext(BookmarkContext);
