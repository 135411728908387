import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { useTranslation } from "../../../../components/translate/TranslateContext";

// Styled components for the container with reduced max-width
const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

// Main SearchBar component
const FilterBar = ({ filters, setFilters, setLoading, bundles }) => {
  const [localFilters, setLocalFilters] = useState(filters);

  const { translate } = useTranslation();

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalFilters({ ...localFilters, [name]: value });
  };

  const handleApplyFilters = () => {
    setFilters(localFilters);
  };

  const handleResetFilters = () => {
    setLocalFilters({
      search: "",
      bundle_id: null,
      start_date: "",
      end_date: "",
    });
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" component="div">
          {translate("Filters Section")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FiltersContainer>
          <Grid container spacing={2}>
            {/* Search Term */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label={translate("Search by Email")}
                placeholder={translate("Enter email...")}
                variant="outlined"
                fullWidth
                name="search"
                value={localFilters.search}
                onChange={handleInputChange}
              />
            </Grid>

            {/* Organization */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="bundle-label" shrink>
                  {translate("Select Bundle")}
                </InputLabel>
                <Select
                  labelId="bundle-label"
                  name="bundle_id"
                  value={localFilters.bundle_id}
                  onChange={handleInputChange}
                  fullWidth
                  displayEmpty
                  label={translate("Select Bundle")}
                >
                  <MenuItem value={null}>
                    <em>{translate("None")}</em>
                  </MenuItem>
                  <MenuItem value={1}>
                    <em>{translate("Free Trial")}</em>
                  </MenuItem>
                  {bundles.map((bundle) => (
                    <MenuItem key={bundle.id} value={bundle.id}>
                      {bundle.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Start Date */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label={translate("Start Date")}
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                name="start_date"
                value={localFilters.start_date}
                onChange={handleInputChange}
              />
            </Grid>

            {/* End Date */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label={translate("End Date")}
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                name="end_date"
                value={localFilters.end_date}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          {/* Apply and Reset Buttons */}
          <Grid container spacing={2} justifyContent="flex-start">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyFilters}
                sx={{
                  maxWidth: "300px",
                  margin: "20px 0",
                }}
              >
                {translate("Apply Filters")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleResetFilters}
                sx={{
                  maxWidth: "300px",
                  margin: "20px 0",
                  backgroundColor: "red",
                }}
              >
                {translate("Reset Filters")}
              </Button>
            </Grid>
          </Grid>
        </FiltersContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterBar;
