import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import icon from "../../assets/images/chatbot.svg";
import FilterSidebar from "./FilterSidebar";
import ChatHeader from "./ChatHeader";
import { toast } from "sonner";
import { formatDateForAPI } from "../../global/dateUtils";
import { useTranslation } from "../translate/TranslateContext";
// import { transform } from "lodash";

const ChatContainer = styled.div`
  position: fixed;
  z-index: 1000;
`;

const ChatButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  inset-inline-end: 20px;
  display: inline-block;
  z-index: 1000;
`;

const ChatButton = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.primary || "#1e2836"};
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  .icon-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    &.chat-icon {
      opacity: ${({ $isOpen }) => ($isOpen ? 0 : 1)};
      transform: rotate(${({ $isOpen }) => ($isOpen ? "180deg" : "0deg")});
    }

    &.close-icon {
      opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
      transform: rotate(${({ $isOpen }) => ($isOpen ? "0deg" : "-180deg")});
    }
  }
`;

const ChatWrapper = styled.div`
  position: relative;
  display: inline-block;
  z-index: 1; // Ensure proper stacking context
`;

const ChatBox = styled(motion.div)`
  background-color: ${({ theme }) => theme.popup_background || "#fff"};
  color: ${({ theme }) => theme.text || "#333"};
  border: 1px solid ${({ theme }) => theme.border_color || "#ddd"};
  border-radius: 8px;
  display: flex;
  min-width: 500px;
  min-height: 500px;
  max-width: 90vw;
  max-height: 90vh;
  resize: both;
  overflow: hidden;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    min-width: 300px;
    min-height: 300px;
  }
`;

const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; // Add explicit width
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  background-color: ${({ theme }) =>
    theme.popup_background || "#fff"}; // Add background
  z-index: 0; // Ensure content stays below sidebar
`;

const ChatBody = styled(motion.div)`
  flex: 1;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scrollbar_thumb || "#888"};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.scrollbar_track || "#f1f1f1"};
  }

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.scrollbar_thumb || "#888"}
    ${({ theme }) => theme.scrollbar_track || "#f1f1f1"};
`;

const ChatInputContainer = styled.div`
  padding: 10px;
  border-top: 1px solid ${({ theme }) => theme.border_color || "#ddd"};
  display: flex;
  gap: 10px;
`;

const ChatInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.border_color || "#ddd"};
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  background-color: ${({ theme }) => theme.popup_background || "#fff"};
  color: ${({ theme }) => theme.text || "#333"};
`;

const ChatSendButton = styled.button`
  background-color: #1e2836;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  width: 70px;

  &:hover {
    background-color: ${({ theme }) => theme.hover || "#333"};
  }
`;

const ChatMessage = styled(motion.div)`
  background-color: ${({ $isUser }) => ($isUser ? "#f0f0f0" : "#e3eaf5")};
  color: ${({ $isUser }) => ($isUser ? "#333" : "#000")};
  padding: 8px;
  border-radius: 8px;
  max-width: 80%;
  align-self: ${({ $isUser }) => ($isUser ? "flex-end" : "flex-start")};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  line-height: 1.5;
`;

const ToastMessage = styled(motion.div)`
  position: absolute;
  inset-inline-end: calc(100% + 15px);
  bottom: 25%;
  background-color: ${({ theme }) => theme.primary || "#1e2836"};
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  white-space: nowrap;
  font-size: 14px;
  pointer-events: none;
`;

const ChatBot = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const chatBodyRef = useRef(null);
  const chatBoxRef = useRef(null);
  const [boxSize] = useState({ width: 600, height: 600 });

  const calculateInitialPosition = () => {
    const padding = 20; // Distance from viewport edges
    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
      return {
        top: padding,
        insetInlineStart: padding,
      };
    }

    return {
      top: Math.min(
        window.innerHeight - boxSize.height - padding, // height + padding
        window.innerHeight * 0.1 // or 10% from top
      ),
      insetInlineStart: Math.min(
        window.innerWidth - boxSize.width - padding, // width + padding
        window.innerWidth * 0.48 // or 48% from left
      ),
    };
  };

  const [boxPosition, setBoxPosition] = useState(calculateInitialPosition);

  const [dragging, setDragging] = useState(false);
  const dragStartPos = useRef({ x: 0, y: 0 });
  const [loading, setLoading] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [selectedChannels, setSelectedChannels] = useState([]);
  // const [selectedCountries, setSelectedCountries] = useState([]);

  const { direction, translate } = useTranslation();

  useEffect(() => {
    if (isChatOpen) {
      const newPosition = calculateInitialPosition();
      setBoxPosition(newPosition);
    }
  }, [isChatOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (isChatOpen) {
        const newPosition = calculateInitialPosition();
        setBoxPosition(newPosition);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isChatOpen]);

  const toggleChat = () => {
    setIsChatOpen((prev) => !prev);
  };

  const handleMouseEnter = () => {
    if (!isChatOpen) {
      setShowToast(true);
    }
  };

  const handleMouseLeave = () => {
    setShowToast(false);
  };

  const handleSend = async () => {
    const trimmedInput = inputValue.trim();
    if (!trimmedInput) return;

    console.log("selected channels: ", selectedChannels);
    console.log("asdasd: ", trimmedInput);

    const userMessage = { text: trimmedInput, isUser: true };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInputValue("");

    try {
      setLoading(true);

      // Format dates with proper timezone handling
      const fromDateTime = formatDateForAPI(dateRange.from);
      const toDateTime = formatDateForAPI(dateRange.to);

      const channelIds = selectedChannels.map((channel) => channel.id);

      const response = await fetch("https://chatbot.teledeck.news/chat-bot", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          question: trimmedInput,
          start_date: fromDateTime,
          end_date: toDateTime,
          channels: channelIds,
          // countries: selectedCountries,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const botMessage = { text: data.message, isUser: false };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Error sending message:", error);

      const errorMessage = {
        text: "An error occurred while processing your request. Please try again.",
        isUser: false,
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);

      toast.error(
        "An error occurred while processing your request. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleDragStart = (e) => {
    e.preventDefault();
    setDragging(true);
    dragStartPos.current = { x: e.clientX, y: e.clientY };
  };

  const handleDrag = (e) => {
    if (!dragging) return;

    const deltaX = e.clientX - dragStartPos.current.x;
    const deltaY = e.clientY - dragStartPos.current.y;

    setBoxPosition((prev) => ({
      top: Math.min(
        Math.max(prev.top + deltaY, 0),
        window.innerHeight - boxSize.height
      ),
      insetInlineStart:
        direction === "ltr"
          ? Math.min(
              Math.max(prev.insetInlineStart + deltaX, 0),
              window.innerWidth - boxSize.width
            )
          : Math.min(
              Math.max(prev.insetInlineStart - deltaX, 0),
              window.innerWidth - boxSize.width
            ),
    }));

    dragStartPos.current = { x: e.clientX, y: e.clientY };
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", handleDrag);
      document.addEventListener("mouseup", handleDragEnd);
    } else {
      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleDragEnd);
    }

    return () => {
      document.removeEventListener("mousemove", handleDrag);
      document.removeEventListener("mouseup", handleDragEnd);
    };
  }, [dragging]);

  return (
    <ChatContainer>
      <ChatButtonWrapper
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ChatButton
          onClick={toggleChat}
          title={isChatOpen ? "Close chat" : "Open chat"}
          $isOpen={isChatOpen}
        >
          <div className="icon-container chat-icon">
            <img
              src={icon}
              alt="Chat"
              width="36px"
              height="36px"
              style={{ color: "white" }}
            />
          </div>
          <div className="icon-container close-icon">
            <span
              className="material-icons"
              style={{
                fontSize: "30px",
              }}
            >
              close
            </span>
          </div>
        </ChatButton>

        <AnimatePresence>
          {showToast && !isChatOpen && (
            <ToastMessage
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.2 }}
            >
              Ask our AI
            </ToastMessage>
          )}
        </AnimatePresence>
      </ChatButtonWrapper>

      <AnimatePresence>
        {isChatOpen && (
          <ChatWrapper
            style={{
              position: "fixed",
              top: `${boxPosition.top}px`,
              insetInlineStart: `${boxPosition.insetInlineStart}px`,
            }}
          >
            <ChatBox
              ref={chatBoxRef}
              style={{
                width: `${boxSize.width}px`,
                height: `${boxSize.height}px`,
              }}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              <FilterSidebar
                isOpen={isSidebarOpen}
                onClose={() => setSidebarOpen(false)}
                dateRange={dateRange}
                setDateRange={setDateRange}
                selectedChannels={selectedChannels}
                setSelectedChannels={setSelectedChannels}
                // setSelectedCountries={setSelectedCountries}
              />

              <ChatContent $isSidebarOpen={isSidebarOpen}>
                <ChatHeader
                  onMouseDown={handleDragStart}
                  toggleSidebar={() => setSidebarOpen((prev) => !prev)}
                  isSidebarOpen={isSidebarOpen}
                />

                <ChatBody ref={chatBodyRef}>
                  <ChatMessage
                    key={0}
                    $isUser={false}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {translate("Welcome to the chat!")}
                  </ChatMessage>
                  {messages.map((message, index) => (
                    <ChatMessage
                      key={index}
                      $isUser={message.isUser}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      {message.text.split("\n").map((line, i) => (
                        <React.Fragment key={i}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </ChatMessage>
                  ))}
                  {loading && (
                    <ChatMessage $isUser={false}>
                      <span>⏳ Loading...</span>
                    </ChatMessage>
                  )}
                </ChatBody>
                <ChatInputContainer>
                  <ChatInput
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyPress}
                    placeholder={translate("Type your message...")}
                  />
                  <ChatSendButton onClick={handleSend} disabled={loading}>
                    {translate("Send")}
                  </ChatSendButton>
                </ChatInputContainer>
              </ChatContent>
            </ChatBox>
          </ChatWrapper>
        )}
      </AnimatePresence>
    </ChatContainer>
  );
};

export default ChatBot;
