import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "../components/authentication/auth-context/AuthContext.js";
import { openDb } from "../components/localdb/DBUtils.js";
import LoadingSpinner from "../global/loaders/AppLoader.js";
import Providers from "./Providers.js";
import Main from "./Main";
import AdminRoutes from "../admin/AdminRoutes.js";
import BookmarkEditor from "../components/bookmark-editor/BookmarkEditor.js";
import Map from "../components/map/Map.js";
import SignUp from "../components/authentication/register/SignUp.js";
import ResetPassword from "../components/authentication/password-reset/PasswordReset.js";
import Pricing from "../components/bundles/Pricing.js";
import SignInSide from "../components/authentication/login/SignInSide.js";
import RegisterTrial from "../components/authentication/free-trial/RegisterTrial.js";
import EmailVerification from "../components/authentication/verify-email/EmailVerification.js";
import DashboardAnalytics from "../components/dashboard/Index.js";
import ThemeProviderWrapper from "../components/dashboard/theme/ThemeProvider.js";
import { FiltersProvider } from "../components/dashboard/FilterContext.js";
import { CountsProvider } from "../components/dashboard/CountsContext.js";
import { EventSourceProvider } from "../components/sse-context/EventSourceContext.js";
import { SentimentEventSourceProvider } from "../components/dashboard/charts/SentimentEventSourceProvider.js";
import ForgotPassword from "../components/authentication/password-reset/ForgotPassword.js";

function App() {
  const { isLoading } = useAuth();

  useEffect(() => {
    const initializeDb = async () => {
      try {
        await openDb();
      } catch (error) {
        console.error("Error initializing database:", error);
      }
    };

    initializeDb();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <BrowserRouter>
      <Providers>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Main />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bookmark-editor"
            element={
              <PremiumRoutes>
                <ProtectedRoute>
                  <BookmarkEditor />
                </ProtectedRoute>
              </PremiumRoutes>
            }
          />
          <Route
            path="/map"
            element={
              <PremiumRoutes>
                <ProtectedRoute>
                  <Map />
                </ProtectedRoute>
              </PremiumRoutes>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <SignInSide />
              </PublicRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <SignUp />
              </PublicRoute>
            }
          />
          <Route
            path="/trial"
            element={
              <PublicRoute>
                <RegisterTrial />
              </PublicRoute>
            }
          />
          <Route
            path="/verify-email"
            element={
              <PublicRoute>
                <EmailVerification />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <ProtectedRoute>
                <ResetPassword />
              </ProtectedRoute>
            }
          />

          <Route
            path="/forgot-password"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />

          <Route
            path="/bundles"
            element={
              <ProtectedRoute>
                <Pricing />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/*"
            element={
              <AdminRoute>
                <AdminRoutes />
              </AdminRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <AdminOnlyRoute>
                <EventSourceProvider>
                  <FiltersProvider>
                    <SentimentEventSourceProvider>
                      <CountsProvider>
                        <ThemeProviderWrapper>
                          <DashboardAnalytics />
                        </ThemeProviderWrapper>
                      </CountsProvider>
                    </SentimentEventSourceProvider>
                  </FiltersProvider>
                </EventSourceProvider>
              </AdminOnlyRoute>
            }
          />
        </Routes>
      </Providers>
    </BrowserRouter>
  );
}

function ProtectedRoute({ children }) {
  const { isAuthenticated, email_verified } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  if (!email_verified) {
    return <Navigate to="/verify-email" replace />;
  }
  return children;
}

function PublicRoute({ children }) {
  const { isAuthenticated, email_verified } = useAuth();
  if (isAuthenticated && email_verified) {
    return <Navigate to="/" replace />;
  }
  return children;
}

function AdminRoute({ children }) {
  const { role, email_verified } = useAuth();
  if (role !== "admin" && role !== "business") {
    return <Navigate to="/" replace />;
  }
  if (!email_verified) {
    return <Navigate to="/verify-email" replace />;
  }
  return children;
}

function AdminOnlyRoute({ children }) {
  const { role, email_verified } = useAuth();
  if (role !== "admin") {
    return <Navigate to="/" replace />;
  }
  if (!email_verified) {
    return <Navigate to="/verify-email" replace />;
  }
  return children;
}

function PremiumRoutes({ children }) {
  const { bundle } = useAuth();
  if (bundle === "free_trial" || bundle === "solo") {
    return <Navigate to="/bundles" replace />;
  }
  return children;
}

export default App;
