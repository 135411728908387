import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { format } from "date-fns";
import { getAuth } from "../../../../global/globalUtils";
import Pagination from "@mui/material/Pagination";

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    min-width: 600px;
    background-color: ${({ theme }) => theme.popup_background};
    color: ${({ theme }) => theme.text};
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const Th = styled.th`
  padding: 12px;
  text-align: start;
  border-bottom: 2px solid ${({ theme }) => theme.border_color};
  color: ${({ theme }) => theme.text};
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.border_color};
  color: ${({ theme }) => theme.text};
`;

const StatusChip = styled.span`
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  ${({ $status, theme }) => {
    switch ($status) {
      case "APPROVED":
        return `
          background-color: ${theme.success_background || "#4CAF50"};
          color: white;
        `;
      case "PENDING":
        return `
          background-color: ${theme.warning_background || "#FFA726"};
          color: white;
        `;
      case "REJECTED":
        return `
          background-color: ${theme.error_background || "#F44336"};
          color: white;
        `;
      case "CANCELLED":
        return `
          background-color: ${theme.disabled_background || "#9E9E9E"};
          color: white;
        `;
      default:
        return "";
    }
  }}
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 24px;
  color: ${({ theme }) => theme.text_secondary};
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;

  .MuiPagination-root {
    .MuiPaginationItem-root {
      color: ${({ theme }) => theme.text};

      &.Mui-selected {
        background-color: ${({ theme }) => theme.primary || "#1976d2"};
        color: white;

        &:hover {
          background-color: ${({ theme }) => theme.primary_dark || "#1565c0"};
        }
      }
    }
  }
`;

const RequestHistoryModal = ({ open, onClose }) => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 3;

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await getAuth(
          `/channel/request-history?page=${page}&limit=${itemsPerPage}`
        );
        const data = await response.json();

        if (response.ok) {
          setRequests(data.data || []);
          const total = data.pagination.totalItems || data.data.length;
          setTotalPages(Math.ceil(total / itemsPerPage));
        } else {
          console.error("Failed to fetch request history");
        }
      } catch (error) {
        console.error("Error fetching request history:", error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchRequests();
    }
  }, [open, page]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Reset page when modal opens
  useEffect(() => {
    if (open) {
      setPage(1);
    }
  }, [open]);

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>Channel Request History</DialogTitle>
      <DialogContent>
        {loading ? (
          <EmptyState>Loading...</EmptyState>
        ) : requests.length > 0 ? (
          <>
            <Table>
              <thead>
                <tr>
                  <Th>Channel</Th>
                  <Th>Platform</Th>
                  <Th>Status</Th>
                  <Th>Requested On</Th>
                </tr>
              </thead>
              <tbody>
                {requests.map((request) => (
                  <tr key={request.id}>
                    <Td>@{request.channel_username}</Td>{" "}
                    <Td>{request.Source?.name}</Td>
                    <Td>
                      <StatusChip $status={request.status}>
                        {request.status}
                      </StatusChip>
                    </Td>
                    <Td>
                      {format(new Date(request.createdAt), "MMM dd, yyyy")}
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>{" "}
            <PaginationContainer>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size="medium"
                showFirstButton
                showLastButton
              />
            </PaginationContainer>{" "}
          </>
        ) : (
          <EmptyState>No request history found</EmptyState>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default RequestHistoryModal;
