import { toast } from "sonner";
import { getAuth, putAuth } from "../../../../global/globalUtils";
import Swal from "sweetalert2";
import { filterQueryParams } from "../Users/UsersUtils";

export const fetchData = async (
  setBundles,
  setCount,
  currentPage,
  itemsPerPage,
  setLoading,
  filters
) => {
  try {
    const filteredFilters = filterQueryParams(filters);
    const queryString = new URLSearchParams(filteredFilters).toString();

    const response = await getAuth(
      `/bundle/?page=${currentPage}&limit=${itemsPerPage}${
        queryString ? `&${queryString}` : ""
      }`
    );
    const responseData = await response.json();
    setBundles(responseData.data);
    setCount(responseData.total);
  } catch (error) {
    console.error("Failed to fetch bundles:", error);
    toast.error("Failed to fetch bundles");
    setBundles([]);
  } finally {
    setLoading(false);
  }
};

export const fetchFeatures = async (
  setFeatures,
  setCount,
  currentPage,
  itemsPerPage,
  setLoading
) => {
  try {
    setLoading(true);
    const response = await getAuth(
      `/feature/?page=${currentPage}&limit=${itemsPerPage}`
    );
    const data = await response.json();
    setFeatures(data.data);
    setCount(data.total);
  } catch (error) {
    console.error("Failed to fetch features:", error);
    toast.error("Failed to fetch features");
    setFeatures([]);
  } finally {
    setLoading(false);
  }
};

export const deactiveBundle = async (id) => {
  try {
    const response = await putAuth(`/bundle/${id}/deactivate`);
    await response.json();
    if (response.ok) {
      toast.success("Bundle deactivated successfully");
    }
  } catch (error) {
    toast.error("Failed to deactivate bundle");
    console.error("Failed to delete organization:", error);
  }
};

export const updateBundle = async (bundle) => {
  try {
    const response = await putAuth(`/bundle/${bundle.id}`, bundle);
    await response.json();
    if (response.ok) {
      toast.success("Bundle updated successfully");
    }
  } catch (error) {
    toast.error("Failed to update bundle");
    console.error("Failed to update organization:", error);
  }
};

export const updateBundleFeatures = async (features) => {
  try {
    const response = await putAuth(`/feature`, {
      features,
    });
    await response.json();
    if (response.ok) {
      toast.success("Features updated successfully");
    }
  } catch (error) {
    toast.error("Failed to update features");
    console.error("Failed to update features:", error);
  }
};

export const formatDate = (apiDate) => {
  if (!apiDate) {
    return "";
  }

  // Remove the 'Z' to prevent UTC conversion
  const localDateString = apiDate.replace("Z", "");

  // Create a new Date object using the local time string
  const date = new Date(localDateString);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return date.toLocaleDateString(undefined, options);
};

export const generateCode = async (bundle_id, setLoading) => {
  try {
    setLoading(true);
    const response = await getAuth(
      `/bundle/signup-code?bundle_id=${bundle_id}`
    );
    const data = await response.json();
    if (response.ok) {
      toast.success("Code generated successfully");
      Swal.fire({
        title: "Code Generated",
        text: `The code is: ${data.code.code}`,
        icon: "success",
        confirmButtonText: "OK",
      });
    }
  } catch (error) {
    toast.error("Failed to regenerate code");
    console.error("Failed to regenerate code:", error);
  } finally {
    setLoading(false);
  }
};
