import { getAuth } from "../../../../global/globalUtils";
import AreaCard from "./AreaCard";
import "./AreaCards.scss";
import { useEffect, useState } from "react";
import Spinner from "../../../../global/loaders/Spinner";
import { useTranslation } from "../../../../components/translate/TranslateContext";

const formatNumber = (num) => {
  if (num === undefined || num === null) return "0";
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const AreaCards = () => {
  // Data states
  const [userCount, setUserCount] = useState(0);
  const [organizationCount, setOrganizationCount] = useState(0);
  const [channelRequests, setChannelRequests] = useState(0);
  const [telegramPosts, setTelegramPosts] = useState(0);
  const [twitterPosts, setTwitterPosts] = useState(0);
  const [telegramChannels, setTelegramChannels] = useState(0);
  const [twitterChannels, setTwitterChannels] = useState(0);
  const [facebookPosts, setFacebookPosts] = useState(0);
  const [facebookChannels, setFacebookChannels] = useState(0);



  // Loading states
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isLoadingOrgs, setIsLoadingOrgs] = useState(true);
  const [isLoadingRequests, setIsLoadingRequests] = useState(true);
  const [isLoadingCounts, setIsLoadingCounts] = useState(true);

  const { translate } = useTranslation();

  useEffect(() => {
    const fetchUserCount = async () => {
      setIsLoadingUsers(true);
      try {
        const response = await getAuth("/dashboard/user-count");
        const data = await response.json();

        if (data.status === "success") {
          setUserCount(data.count);
        }
      } catch (error) {
        console.error("Error fetching user count:", error);
      } finally {
        setIsLoadingUsers(false);
      }
    };

    const fetchOrganizationCount = async () => {
      setIsLoadingOrgs(true);
      try {
        const response = await getAuth("/dashboard/organization-count");
        const data = await response.json();

        if (data.status === "success") {
          setOrganizationCount(data.count);
        }
      } catch (error) {
        console.error("Error fetching user count:", error);
      } finally {
        setIsLoadingOrgs(false);
      }
    };

    const fetchChannelRequests = async () => {
      setIsLoadingRequests(true);
      try {
        const response = await getAuth("/dashboard/channel-request-count");
        const data = await response.json();

        if (data.status === "success") {
          setChannelRequests(data.data);
        }
      } catch (error) {
        console.error("Error fetching channel requests:", error);
      } finally {
        setIsLoadingRequests(false);
      }
    };

    const fetchTotalCounts = async () => {
      setIsLoadingCounts(true);
      try {
        const response = await getAuth("/dashboard/total-counts");
        const data = await response.json();

        if (data.status === "success") {
          setTelegramPosts(data.data.posts.telegram);
          setTwitterPosts(data.data.posts.twitter);
          setFacebookPosts(data.data.posts.facebook);
          setTelegramChannels(data.data.channels.telegram);
          setTwitterChannels(data.data.channels.twitter);
          setFacebookChannels(data.data.channels.facebook);
        }
      } catch (error) {
        console.error("Error fetching channel requests:", error);
      } finally {
        setIsLoadingCounts(false);
      }
    };

    fetchUserCount();
    fetchOrganizationCount();
    fetchChannelRequests();
    fetchTotalCounts();
  }, []);

  return (
    <section className="content-area-cards">
      <AreaCard
        colors={["#e4e8ef", "#f29a2e"]}
        percentFillValue={65}
        isLoading={isLoadingOrgs}
        cardInfo={{
          title: translate("Organizations"),
          value: organizationCount,
          text: translate("Total number of active organizations."),
        }}
      />
      <AreaCard
        colors={["#e4e8ef", "#475be8"]}
        percentFillValue={80}
        isLoading={isLoadingUsers}
        cardInfo={{
          title: translate("Users"),
          value: userCount,
          text: translate("Total number of active users."),
        }}
      />
      <AreaCard
        colors={["#e4e8ef", "#4ce13f"]}
        percentFillValue={70}
        isLoading={isLoadingRequests}
        cardInfo={{
          title: translate("Channel Requests"),
          value: channelRequests,
          text: translate("Total number of channel requests."),
        }}
      />
      {/* <AreaCard
        colors={["#e4e8ef", "#229ED9"]}
        percentFillValue={65}
        isLoading={isLoadingCounts}
        cardInfo={{
          title: "Telegram Channels",
          value: formatNumber(telegramChannels),
          text: "Total number of Telegram Channels.",
        }}
      />
      <AreaCard
        colors={["#e4e8ef", "#229ED9"]}
        percentFillValue={80}
        isLoading={isLoadingCounts}
        cardInfo={{
          title: "Telegram Posts",
          value: formatNumber(telegramPosts),
          text: "Total number of Telegram Posts.",
        }}
      />
      <AreaCard
        colors={["#e4e8ef", "#000000"]}
        percentFillValue={30}
        isLoading={isLoadingCounts}
        cardInfo={{
          title: "Twitter Channels",
          value: formatNumber(twitterChannels),
          text: "Total number of Twitter Channels.",
        }}
      />
      <AreaCard
        colors={["#e4e8ef", "#000000"]}
        percentFillValue={40}
        isLoading={isLoadingCounts}
        cardInfo={{
          title: "Twitter Posts",
          value: formatNumber(twitterPosts),
          text: "Total number of Twitter Posts.",
        }}
      /> */}
    </section>
  );
};

export default AreaCards;
