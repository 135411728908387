import React, { useState } from "react";
import { useTranslation } from "../../translate/TranslateContext";
import TranslatePopup from "../../column_popups/TranslatePopup";

const TranslateColumn = ({ setLanguage, language }) => {
  const [isLangPopupVisible, setIsLangPopupVisible] = useState(false);

  const { translate } = useTranslation();

  return (
    <div>
      <span
        className="material-icons column-header-icon"
        onClick={() => {
          setIsLangPopupVisible(true);
        }}
        title={translate("Translate Column Text")}
        id="column-header-lang"
      >
        translate
      </span>
      {isLangPopupVisible && (
        <TranslatePopup
          setLanguage={setLanguage}
          language={language}
          isOpen={isLangPopupVisible}
          setIsOpen={setIsLangPopupVisible}
        />
      )}
    </div>
  );
};

export default TranslateColumn;
