import React, { useState, useEffect, useRef } from "react";
import { debounce } from "lodash";
import {
  fetchChannelsWithTags,
  shuffleArray,
} from "../utils/channelSearchUtil";
import { website } from "../../../global/globalUtils";
import styled from "styled-components";
import { ChosenChannelsHeader } from "./ThirdColumn";
import avatar from "../../../assets/images/default-avatar.avif";
import Spinner from "../../../global/loaders/Spinner";
import { useTranslation } from "../../translate/TranslateContext";

const MaxChannelsMessage = styled.span`
  display: block;
  margin-top: 20px;
  padding: 10px;
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.border_color};
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
`;

const ThemedInput = styled.input.attrs({
  className: "chooseChannel",
})`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  &:focus {
    border-color: ${({ theme }) => theme.blue};
  }
`;

const ChipContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${({ $active }) => ($active ? "#007bff" : "#e0e0e0")};
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${({ $active }) => ($active ? "#0056b3" : "#c0c0c0")};
    transform: scale(1.1);
  }

  img {
    width: 30px;
    height: 30px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DropdownMenu = styled.div`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.border_color};
`;

const DropdownItem = styled.div`
  &:hover {
    background-color: ${({ theme }) => theme.hover_background};
  }
`;

const TagItem = styled.button`
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.chip_active};
  &:hover {
    background-color: ${({ theme }) => theme.delete_chip};
  }
`;

const ChannelItem = styled.div`
  &:hover {
    background-color: ${({ theme }) => theme.hover_background};
  }
`;

export const SubscriberCount = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text};
  margin-top: 5px;
`;

const ScrollableDiv = styled.div`
  /* Webkit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.chip_inactive};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.chip_inactive}; /* Optional: adds space around the thumb */
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.background};
  ${({ theme }) => theme.chip_inactive};
`;

const ArrowItem = styled.button`
  color: ${({ theme }) => theme.arrow_color};
`;

const AddAllButton = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    color: #28a745;
  }
`;

const ChannelIcon = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-inline-end: 10px;
  /* Your existing styles */
`;

const SecondColumn = ({
  channels,
  onAddChannel,
  chosenChannels,
  setChannels,
  deactivateRegularColumn,
}) => {
  // filters
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [selectedSource, setSelectedSource] = useState("Telegram");
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [page, setPage] = useState(1);

  // loading states
  const [searchLoading, setSearchLoading] = useState(false);
  const [channelLoading, setChannelLoading] = useState(false);

  // UI states
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  // UI Refs
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const tagsContainerRef = useRef(null);
  const [hasMore, setHasMore] = useState(true);

  const { translate } = useTranslation();

  const handleSourceClick = (source) => {
    if (selectedSource !== source) {
      setSelectedSource(source);
      setPage(1);
      setChannels([]);
      setHasMore(true);
    }
  };

  useEffect(() => {
    const getChannels = async () => {
      // Only reset channels when search, source, or tags change, not on page change
      if (page === 1) {
        setChannels([]);
      }

      const tagIds = selectedTags.map((tag) => tag.id);
      const searchStartsWithHash = debouncedSearch.startsWith("#");

      try {
        const data = await fetchChannelsWithTags(
          searchStartsWithHash ? debouncedSearch.slice(1) : debouncedSearch,
          searchStartsWithHash
            ? []
            : chosenChannels.map((channel) => channel.id),
          tagIds,
          selectedSource,
          page,
          setChannelLoading
        );

        console.log("data: ", data);

        if (data) {
          const selectedTagIds = new Set(selectedTags.map((tag) => tag.id));

          if (searchStartsWithHash) {
            const newTagOptions = data.filteredTags
              .map((tag) => ({
                id: tag.id,
                name: `#${tag.name}`,
              }))
              .filter((tag) => !selectedTagIds.has(tag.id));
            setTags(newTagOptions);
          } else {
            setTags([]);
          }

          // Update hasMore based on whether we received any new channels
          setHasMore(data.channels.length > 0);

          // Merge new channels with existing ones, removing duplicates
          setChannels((prevChannels) => {
            const existingIds = new Set(prevChannels.map((c) => c.id));
            const newChannels = data.channels.filter(
              (c) => !existingIds.has(c.id)
            );
            return [...prevChannels, ...newChannels];
          });
        } else {
          console.error("Failed to fetch channels");
          setChannels([]);
          setTags([]);
          setHasMore(false);
        }
      } catch (error) {
        console.error("Error fetching channels:", error);
        setHasMore(false);
      }
    };

    getChannels();
  }, [debouncedSearch, selectedSource, selectedTags, page]);

  const handleChannelClick = (channel) => {
    setChannels((prevChannels) => {
      const updatedChannels = prevChannels.filter((c) => c.id !== channel.id);

      // If channels are getting low, fetch more
      if (updatedChannels.length < 5 && hasMore) {
        setPage((prev) => prev + 1);
      }

      return updatedChannels;
    });
    onAddChannel(channel);
  };

  const scrollRef = useRef(null);

  const handleTagClick = async (tag) => {
    if (!selectedTags.find((t) => t.id === tag.id)) {
      const updatedSelectedTags = [...selectedTags, tag];
      setSelectedTags(updatedSelectedTags);
      setTags((prev) => prev.filter((t) => t.id !== tag.id));
      setSearch("");
      setShowDropdown(false);
      setSearchLoading(true);
    }
    updateArrowsVisibility();
  };

  const handleTagDelete = async (tag) => {
    const updatedSelectedTags = selectedTags.filter((t) => t.id !== tag.id);
    setSelectedTags(updatedSelectedTags);
    setTags((prev) => [...prev, tag]);
    setSearchLoading(true);
    setPage(1);
    updateArrowsVisibility();
  };

  // handle click outside dropdown
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // debounce scroll event
  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      !channelLoading &&
      hasMore
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  const debouncedHandleScroll = debounce(handleScroll, 200);

  // debounce search event
  useEffect(() => {
    if (search === "") {
      setDebouncedSearch("");
      setSearchLoading(false);
      setShowDropdown(false);
      setPage(1); // Reset page when search is empty
      setHasMore(true);
      return;
    }

    const debouncedSearchHandler = debounce(() => {
      setDebouncedSearch(search);
      setSearchLoading(false);
      // Only show dropdown for hashtag searches
      setShowDropdown(search.startsWith("#"));
      // Reset page when search changes
      setPage(1);
    }, 500);

    setSearchLoading(true);
    debouncedSearchHandler();

    return () => {
      debouncedSearchHandler.cancel();
    };
  }, [search]);

  // UI Effects
  const scrollTags = (direction) => {
    if (tagsContainerRef.current) {
      const scrollAmount = direction === "left" ? -100 : 100;
      tagsContainerRef.current.scrollBy({
        insetInlineStart: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const updateArrowsVisibility = () => {
    if (tagsContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tagsContainerRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  // Modified input focus handler
  const handleInputFocus = () => {
    if (
      search &&
      search.startsWith("#") &&
      (tags.length > 0 || searchLoading)
    ) {
      setShowDropdown(true);
    }
  };

  return (
    <div
      className={deactivateRegularColumn ? "" : "RegularColumn"}
      id="add-channel"
    >
      <div className="ChooseChannelDiv">
        <ChosenChannelsHeader>
          <h3 className="ColumnHeader">{translate("Choose Channels")}</h3>
          <AddAllButton
            onClick={() => {
              const shuffledChannels = shuffleArray(channels);
              shuffledChannels.slice(0, 5).forEach(onAddChannel);
            }}
          >
            {translate("Add Randomly")}
          </AddAllButton>
        </ChosenChannelsHeader>
        <div className="chooseChannelContainer">
          <ThemedInput
            className="TextArea chooseChannel"
            type="text"
            placeholder={translate("Search for channels or tags")}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={handleInputFocus}
            value={search}
            ref={inputRef}
          />
          <ChipContainer>
            <Chip
              $active={selectedSource === "Telegram"}
              onClick={() => handleSourceClick("Telegram")}
              className="material-symbols-outlined chip-icon"
              title={translate("Filter By Telegram Only")}
            >
              <IconWrapper>
                <img
                  src={`${website}/source-logo/telegram.svg`}
                  alt="Telegram Logo"
                />
              </IconWrapper>
            </Chip>
            <Chip
              $active={selectedSource === "Twitter"}
              onClick={() => handleSourceClick("Twitter")}
              className="material-symbols-outlined chip-icon"
              title={translate("Filter By Twitter Only")}
            >
              <IconWrapper>
                <img
                  src={`${website}/source-logo/twitter.svg`}
                  alt="Twitter Logo"
                />
              </IconWrapper>
            </Chip>
            <Chip
              $active={selectedSource === "Facebook"}
              onClick={() => handleSourceClick("Facebook")}
              className="material-symbols-outlined chip-icon"
              title={translate("Filter By Facebook Only")}
            >
              <IconWrapper>
                <img
                  src={`${website}/source-logo/facebook.svg`}
                  alt="Facebook Logo"
                />
              </IconWrapper>
            </Chip>
          </ChipContainer>
          {showDropdown && search && (
            <DropdownMenu
              ref={dropdownRef}
              className={`DropdownMenu ${
                tags.length > 0 || searchLoading ? "show" : ""
              }`}
            >
              {searchLoading ? (
                <div className="LoadingMessage">Loading...</div>
              ) : (
                tags.map((tag) => (
                  <DropdownItem
                    key={tag.id}
                    className="DropdownItem"
                    onClick={() => handleTagClick(tag)}
                  >
                    <span>{tag.name}</span>
                  </DropdownItem>
                ))
              )}
            </DropdownMenu>
          )}
        </div>
        <div className="TagsContainer">
          {showLeftArrow && (
            <i
              className="material-icons ArrowIcon left"
              onClick={() => scrollTags("left")}
            >
              arrow_back
            </i>
          )}
          <div
            className="Tags"
            ref={tagsContainerRef}
            onScroll={updateArrowsVisibility}
          >
            {selectedTags.map((tag) => (
              <TagItem
                key={tag.id}
                className="TagButton"
                onClick={() => handleTagDelete(tag)}
              >
                {tag.name}
              </TagItem>
            ))}
          </div>
          {showRightArrow && (
            <i
              className="material-icons ArrowIcon right"
              onClick={() => scrollTags("right")}
            >
              arrow_forward
            </i>
          )}
        </div>
      </div>
      <ScrollableDiv
        className="ChannelsContainer"
        ref={scrollRef}
        onScroll={debouncedHandleScroll}
      >
        {channels.map((channel) => (
          <ChannelItem
            key={channel.id}
            className="Channel"
            onClick={() => handleChannelClick(channel)}
          >
            <div className="ChannelElement">
              <div className="ChannelProfileandName">
                <div>
                  <ChannelIcon
                    src={
                      channel.profile_picture_url
                        ? channel.profile_picture_url
                        : channel.image
                        ? `${website}${channel.image}`
                        : avatar
                    }
                    alt="Channel Icon"
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop if fallback image also fails
                      e.target.src = avatar; // Set fallback image
                    }}
                  />
                </div>
                <div className="ChannelName">
                  <span className="channel_name">{channel.chat_title}</span>
                  <br />
                  <span className="channel_username">
                    @{channel.sender_username}
                  </span>
                  <SubscriberCount>
                    {channel.subscriber_count !== null &&
                    channel.subscriber_count !== undefined &&
                    channel.subscriber_count !== 0
                      ? `${channel.subscriber_count.toLocaleString()} ${translate(
                          "subscribers"
                        )}`
                      : ""}
                  </SubscriberCount>
                </div>
              </div>
              <ArrowItem className="ArrowRightButton">
                <i className="material-icons">arrow_right</i>
              </ArrowItem>
            </div>
          </ChannelItem>
        ))}
        {channelLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Spinner />
          </div>
        )}
        {!channelLoading && channels.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px",
              color: "text.secondary",
            }}
          >
            No channels found
          </div>
        )}
        {!hasMore && channels.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
              color: "text.secondary",
            }}
          >
            No more channels to load
          </div>
        )}
      </ScrollableDiv>
      <MaxChannelsMessage>
        {translate("Max 30 channels can be added")}
      </MaxChannelsMessage>
    </div>
  );
};

export default SecondColumn;
