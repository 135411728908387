import React, { useEffect, useState } from "react";
import {
  Button as MuiButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import SearchBar from "./SearchBar";
import {
  fetchData,
  editPay,
  formatDate,
  formatToDatetimeLocal,
  fetchOrgs,
  createPay,
  deletePay,
} from "./utils";
import { useTranslation } from "../../../../components/translate/TranslateContext";

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  & > button {
    margin: 0 5px;
  }

  .page-info {
    margin: 0 10px;
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PaymentTable = () => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editPayment, setEditPayment] = useState({
    id: 0,
    organization_id: 0,
    name: "",
    amount: 0,
    status: 0,
    paid_at: null,
    created_at: null,
    due_by: null,
  });
  const [organizations, setOrganizations] = useState([]);

  const { translate } = useTranslation();

  const handleEditChange = (e) => {
    setEditPayment({
      ...editPayment,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    fetchData(currentPage, itemsPerPage, setLoading, setFilteredData, setCount);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  // Submit logic for both create and edit
  const handleEditSubmit = async () => {
    if (isEditMode) {
      await editPay(editPayment.id, editPayment);
    } else {
      await createPay(editPayment);
    }
    setEditModalOpen(false);
    fetchData(currentPage, itemsPerPage, setLoading, setFilteredData, setCount);
  };

  // Open modal for creating a new payment
  const handleCreateClick = () => {
    setEditPayment({
      ...editPayment,
      organization_id: 0,
      name: "",
      amount: 0,
      status: 0,
      paid_at: "",
      created_at: "",
      due_by: "",
    });
    setIsEditMode(false);
    setEditModalOpen(true);
  };

  // Open modal for editing an existing payment
  const handleEditClick = (item) => {
    setEditPayment({
      id: item.id,
      organization_id: item.organization_id,
      name: item.Organization.name,
      amount: item.amount,
      status: item.status,
      paid_at: formatToDatetimeLocal(item.paid_at),
      created_at: formatToDatetimeLocal(item.created_at),
      due_by: formatToDatetimeLocal(item.due_by),
    });
    setIsEditMode(true);
    setEditModalOpen(true);
  };

  useEffect(() => {
    fetchOrgs(setOrganizations);
  }, []);

  return (
    <section className="content-area-table">
      <Dialog
        open={editModalOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setEditModalOpen(false);
          }
        }}
      >
        {" "}
        <DialogTitle>
          {isEditMode ? translate("Edit Payment") : translate("Create Payment")}
        </DialogTitle>
        <DialogContent>
          <InputLabel>{translate("Choose Organization")}</InputLabel>
          <FormControl fullWidth margin="dense">
            <Select
              name="organization_id"
              value={editPayment.organization_id}
              onChange={handleEditChange}
              fullWidth
            >
              {organizations.map((org) => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Amount"
            name="amount"
            type="number"
            value={editPayment.amount}
            onChange={handleEditChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Status"
            name="status"
            select
            value={editPayment.status}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value={1}>{translate("Paid")}</MenuItem>
            <MenuItem value={0}>{translate("Not Paid")}</MenuItem>
          </TextField>
          <TextField
            margin="dense"
            label={translate("Paid At")}
            name="paid_at"
            type="datetime-local"
            value={editPayment.paid_at}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label={translate("Created At")}
            name="created_at"
            type="datetime-local"
            value={editPayment.created_at}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label={translate("Due By")}
            name="due_by"
            type="datetime-local"
            value={editPayment.due_by}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={() => setEditModalOpen(false)} color="secondary">
            {translate("Cancel")}
          </MuiButton>
          <MuiButton onClick={handleEditSubmit} color="primary">
            {isEditMode
              ? translate("Save Changes")
              : translate("Create Payment")}
          </MuiButton>
        </DialogActions>
      </Dialog>

      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleDownloadCSV={() => {}}
      />

      <p style={{ marginBottom: "20px" }}>
        {translate("Number Of Payments")}: {count}
      </p>
      <MuiButton
        onClick={handleCreateClick}
        variant="contained"
        color="primary"
        sx={{ marginBottom: "20px" }}
      >
        {translate("Create New Payment")}
      </MuiButton>
      <div className="data-table-diagram">
        {loading ? (
          <p>{translate("Loading...")}</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>{translate("Payment ID")}</th>
                  <th>{translate("Organization Name")}</th>
                  <th>{translate("Due Amount")}</th>
                  <th>{translate("Payment Status")}</th>
                  <th>{translate("Paid At")}</th>
                  <th>{translate("Created At")}</th>
                  <th>{translate("Due By")}</th>
                  <th>{translate("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <tr key={item.id}>
                      <td># {item.id}</td>
                      <td>{item.Organization?.name || "N/A"}</td>
                      <td>{item.amount || "N/A"} $</td>
                      <td>
                        {item.status === 1
                          ? translate("Paid")
                          : translate("Not Paid")}
                      </td>
                      <td>{item.paid_at ? formatDate(item.paid_at) : "N/A"}</td>
                      <td>{formatDate(item.created_at)}</td>
                      <td>{formatDate(item.due_by)}</td>
                      <td>
                        <ButtonContainer>
                          <MuiButton
                            onClick={() => handleEditClick(item)}
                            variant="contained"
                            sx={{
                              backgroundColor: "#ffc107", // Yellow color for "Edit"
                              color: "#000",
                              "&:hover": {
                                backgroundColor: "#e0a800",
                              },
                            }}
                          >
                            {translate("Edit")}
                          </MuiButton>
                          <MuiButton
                            onClick={() => {
                              if (
                                window.confirm(
                                  translate(
                                    "Are you sure you want to delete this payment?"
                                  )
                                )
                              ) {
                                deletePay(item.id);
                              }
                            }}
                            variant="contained"
                            sx={{
                              backgroundColor: "red",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#cc0000",
                              },
                            }}
                            startIcon={<DeleteIcon />}
                          >
                            {translate("Delete")}
                          </MuiButton>
                        </ButtonContainer>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">{translate("No payments found")}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <PaginationControls>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                {translate("First")}
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {translate("Previous")}
              </MuiButton>
              <span className="page-info">
                {translate("Page")} {currentPage} {translate("of")} {totalPages}
              </span>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                {translate("Next")}
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                {translate("Last")}
              </MuiButton>
            </PaginationControls>
          </>
        )}
      </div>
    </section>
  );
};

export default PaymentTable;
