import { Box, Badge, styled, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../../../../../assets/images/Teledeck.svg";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        margin-inline-start: ${theme.spacing(2)};
`
);

const LogoSignWrapper = styled(Box)(
  ({ theme }) => `
        width: 250px;  // Increased width
        height: 250px; // Increased height
        transform: scale(.8);
        transition: ${theme.transitions.create(["transform"])};
`
);

const CustomLogo = styled("img")(
  ({ theme }) => `
        width: 100%;
        height: 100%;
        object-fit: contain;
`
);

function Logo() {
  const theme = useTheme();

  return (
    <LogoWrapper to="/">
      <Badge
        sx={{
          ".MuiBadge-badge": {
            fontSize: theme.typography.pxToRem(11),
            right: -2,
            top: 8,
            background: theme.colors.alpha.black[30],
            color: theme.colors.alpha.trueWhite[100],
          },
        }}
        overlap="circular"
      >
        <LogoSignWrapper>
          <CustomLogo src={logo} alt="Custom Logo" />
        </LogoSignWrapper>
      </Badge>
    </LogoWrapper>
  );
}

export default Logo;
