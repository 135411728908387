import styled from "styled-components";

export const LoadMoreButton = styled.button`
  padding: 8px 16px;
  margin: 16px 0;
  text-align: center;
  background-color: #0078d4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
`;

// Node configuration constants
export const NODE_CONFIG = {
  colors: {
    post: "#0078D4",
    organization: "#107C10",
    person: "#D83B01",
  },
  sizes: {
    post: 12,
    organization: 18,
    person: 18,
  },
};

export const styles = {
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    position: "relative",
    minHeight: "600px",
  },
  svg: {
    width: "100%",
    height: "100%",
    minHeight: "600px",
    border: "1px solid #ccc",
  },
  legend: {
    position: "absolute",
    top: "20px",
    right: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    padding: "10px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    gap: "8px",
  },
  tooltip: {
    position: "absolute",
    background: "rgba(255, 255, 255, 0.95)",
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    pointerEvents: "none",
    display: "none",
    maxWidth: "300px",
    wordWrap: "break-word",
  },
};

export const transformData = (organizations = [], persons = []) => {
  const nodes = [];
  const links = [];
  const seenPosts = new Map();

  // Process organizations
  organizations.forEach((org) => {
    const orgNode = {
      id: `org_${org.name}`,
      type: "organization",
      name: org.name,
      additionalInfo: `Posts: ${org.posts.length}`,
    };
    nodes.push(orgNode);

    org.posts.forEach((post) => {
      let postNode;
      if (!seenPosts.has(post.id)) {
        postNode = {
          id: `post_${post.id}`,
          type: "post",
          text: post.text,
          timestamp: post.timestamp,
          channel: post.channel,
          url: post.post_url,
        };
        nodes.push(postNode);
        seenPosts.set(post.id, postNode);
      } else {
        postNode = seenPosts.get(post.id);
      }
      links.push({ source: postNode.id, target: orgNode.id });
    });
  });

  // Process persons
  persons.forEach((person) => {
    const personNode = {
      id: `person_${person.name}`,
      type: "person",
      name: person.name,
      additionalInfo: `Posts: ${person.posts.length}`,
    };
    nodes.push(personNode);

    person.posts.forEach((post) => {
      let postNode;
      if (!seenPosts.has(post.id)) {
        postNode = {
          id: `post_${post.id}`,
          type: "post",
          text: post.text,
          timestamp: post.timestamp,
          channel: post.channel,
          url: post.post_url,
        };
        nodes.push(postNode);
        seenPosts.set(post.id, postNode);
      } else {
        postNode = seenPosts.get(post.id);
      }
      links.push({ source: postNode.id, target: personNode.id });
    });
  });

  return { nodes, links };
};
