import { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  IconButton,
  Slide,
  styled,
  alpha,
  Avatar,
  Autocomplete,
  Popper,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import { getAuth, website } from "../../../../../global/globalUtils";
import avatar from "../../../../../assets/images/default-avatar.avif";
import { useFilters } from "../../../FilterContext";
import { useTranslation } from "../../../../translate/TranslateContext";

// Replace the styled.div with styled(Box)
export const SelectWrapper = styled(Box)(
  ({ theme }) => `
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${theme.spacing(1)};
`
);

export const InputWrapper = styled(Box)(
  ({ theme }) => `
  width: 100%;
  position: relative;
`
);

export const ChannelInput = styled("input")(
  ({ theme }) => `
  width: 90%;
  height:1.4rem;
  padding: 16px;
  border: 1px solid ${theme.palette.divider};
  border-radius: 6px;
  font-size: 14px;
  outline: none;
  background-color: ${theme.palette.background.paper};
  color: ${theme.palette.text.primary};

  &:focus {
    border-color: ${theme.palette.primary.main};
  }
`
);

export const Dropdown = styled(Box)(
  ({ theme }) => `
  position: absolute;
  width: 100%;
  top: 100%;
  inset-inline-start: 0;
  background-color: ${theme.palette.background.paper};
  border: 1px solid ${theme.palette.divider};
  border-radius: 6px;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1301;
  box-shadow: ${theme.shadows[1]};
`
);

export const DropdownItem = styled(Box)(
  ({ theme }) => `
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${theme.palette.action.hover};
  }
`
);

export const Icon = styled("img")(
  ({ theme }) => `
  width: 28px;
  height: 28px;
  border-radius: 8px;
  background-color: ${theme.palette.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-weight: 500;
  color: ${theme.palette.common.white};
  font-size: 12px;
  text-transform: uppercase;
`
);

export const ChannelInfo = styled(Box)(
  ({ theme }) => `
  flex: 1;
  min-width: 0;
`
);

const CustomTag = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  padding: "4px 8px 4px 4px",
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "16px",
  margin: "2px",
  maxWidth: "200px",
  "& .MuiAvatar-root": {
    width: "24px",
    height: "24px",
  },
  "& .MuiTypography-root": {
    fontSize: "14px",
    maxWidth: "120px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  "& img.source-icon": {
    width: "20px",
    height: "20px",
    flexShrink: 0,
  },
  "& .MuiIconButton-root": {
    padding: 4,
    marginLeft: 4,
    "&:hover": {
      backgroundColor: alpha(theme.palette.text.primary, 0.04),
    },
  },
  "& .MuiSvgIcon-root": {
    fontSize: 16,
  },
}));

const ChannelName = styled(Typography)(
  ({ theme }) => `
  font-size: 14px;
  color: ${theme.palette.text.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
);

export const SourceIcon = styled("img")(
  ({ theme }) => `
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`
);

export const ChipsContainer = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: ${theme.spacing(2)};
  width: 100%;
  min-height: 48px;
  border-radius: 4px;
  background: ${theme.palette.background.default};
`
);

export const ChannelChip = styled(Box)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px 4px 4px;
  background-color: ${theme.palette.background.paper};
  color: ${theme.palette.text.primary};
  border: 1px solid ${theme.palette.divider};
  border-radius: 16px;
  font-size: 12px;
  max-width: 200px;

  .avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }

  .channel-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .close-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 2px;
    margin-inline-start: auto;
    color: ${theme.palette.text.primary};

    &:hover {
      opacity: 0.8;
    }
  }
`
);

export const NoResults = styled(Box)(
  ({ theme }) => `
  padding: 8px;
  color: ${theme.palette.text.secondary};
  font-size: 14px;
  text-align: center;
`
);

export const ErrorMessage = styled(Typography)(
  ({ theme }) => `
  color: ${theme.palette.error.main};
  font-size: 12px;
  margin-top: 4px;
`
);

const Transition = forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `

    .MuiDialog-container {
        height: 100%;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh)
    }
`
);

const SearchInputWrapperButton = styled(Button)(
  ({ theme }) => `
  font-weight: normal;
  background: ${theme.colors.alpha.white[10]};
  border:  ${alpha(theme.colors.alpha.white[30], 0.2)} solid 1px;

  &:hover {
    background: ${alpha(theme.colors.alpha.white[30], 0.15)};
    border: ${theme.colors.alpha.white[30]} solid 1px;
  }
`
);

const BtnControl = styled(Box)(
  ({ theme }) => `
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px !important;
  background: ${alpha(theme.colors.alpha.white[100], 0.8)};
  color: ${theme.colors.alpha.black[100]};
  border-radius: ${theme.general.borderRadiusSm};
  padding: 0 6px;
  margin-inline-start: ${theme.spacing(2)};
`
);

const CustomPopper = styled(Popper)(({ theme }) => ({
  width: "25% !important",
  "& .MuiPaper-root": {
    boxShadow: theme.shadows[2],
    marginTop: "4px",
  },
  "& .MuiAutocomplete-listbox": {
    maxHeight: "250px",
    "& .MuiAutocomplete-option": {
      padding: "8px 16px",
    },
  },
}));

function FilterDialog() {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [channelSearchTerm, setChannelSearchTerm] = useState("");
  const {
    setKeyword,
    setSelectedSource,
    setStartDate,
    setEndDate,
    setSelectedChannels,
    setSelectedCategories,
    setSentiment,
  } = useFilters();

  // Filter states
  const [localKeyword, setLocalKeyword] = useState("");
  const [localSelectedSource, setLocalSelectedSource] = useState("");
  const [localStartDate, setLocalStartDate] = useState("");
  const [localEndDate, setLocalEndDate] = useState("");
  const [localSelectedChannels, setLocalSelectedChannels] = useState([]);
  const [localSelectedCategories, setLocalSelectedCategories] = useState([]);
  const [localSentiment, setLocalSentiment] = useState("");

  const [channels, setChannels] = useState([]);

  const { translate } = useTranslation();

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const response = await getAuth(
          `/dashboard/top-channels?search=${channelSearchTerm}`
        );
        const data = await response.json();
        // Filter out already selected channels
        const filteredChannels = data.channels.filter(
          (channel) => !localSelectedChannels.includes(channel.id)
        );
        setChannels(filteredChannels);
      } catch (error) {
        console.error("Error fetching channels", error);
      }
    };

    fetchChannels();
  }, [channelSearchTerm, localSelectedChannels]);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleApplyFilters = () => {
    setKeyword(localKeyword);
    setSelectedSource(localSelectedSource);
    setStartDate(localStartDate);
    setEndDate(localEndDate);
    setSelectedChannels(localSelectedChannels.map((channel) => channel.id));
    setSelectedCategories(localSelectedCategories);
    setSentiment(localSentiment);

    handleClose();
  };

  const handleClearFilters = () => {
    setKeyword("");
    setSelectedSource("");
    setStartDate("");
    setEndDate("");
    setSelectedChannels([]);
    setSelectedCategories([]);
    setSentiment("");

    setLocalKeyword("");
    setLocalSelectedSource("");
    setLocalStartDate("");
    setLocalEndDate("");
    setLocalSelectedChannels([]);
    setLocalSelectedCategories([]);
    setLocalSentiment("");

    handleClose();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.shiftKey && event.key === "S") {
        handleClickOpen();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const isDateRangeValid = () => {
    if (!localStartDate || !localEndDate) return true;
    return new Date(localStartDate) <= new Date(localEndDate);
  };

  return (
    <>
      <SearchInputWrapperButton
        variant="contained"
        disableElevation
        onClick={handleClickOpen}
        startIcon={<FilterListIcon />}
        endIcon={
          <>
            <BtnControl>Shift+S</BtnControl>
          </>
        }
      >
        {"Filter"}
      </SearchInputWrapperButton>

      <DialogWrapper
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        scroll="paper"
        onClose={handleClose}
      >
        <Box
          sx={{
            p: 3,
            height: "100%",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={3}
          >
            <Typography variant="h4">{translate("Filter Options")}</Typography>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={translate("Search by keyword")}
                value={localKeyword}
                onChange={(e) => setLocalKeyword(e.target.value)}
                placeholder={translate("Enter keywords to search...")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>{translate("Source")}</InputLabel>
                <Select
                  value={localSelectedSource}
                  label="Source"
                  onChange={(e) => setLocalSelectedSource(e.target.value)}
                >
                  <MenuItem value="">
                    <em>{translate("All Sources")}</em>
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    {translate("Telegram")}
                  </MenuItem>
                  <MenuItem key={2} value={3}>
                    {translate("Twitter")}
                  </MenuItem>
                  <MenuItem key={2} value={3}>
                    {translate("Facebook")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Date Range Selection */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={translate("Start Date")}
                type="datetime-local"
                value={localStartDate}
                onChange={(e) => setLocalStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!isDateRangeValid()}
                helperText={
                  !isDateRangeValid()
                    ? translate("Start date must be before end date")
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={translate("End Date")}
                type="datetime-local"
                value={localEndDate}
                onChange={(e) => setLocalEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!isDateRangeValid()}
                helperText={
                  !isDateRangeValid()
                    ? translate("End date must be after start date")
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>{translate("Sentiment")}</InputLabel>
                <Select
                  label={translate("Sentiment")}
                  value={localSentiment}
                  onChange={(e) => setLocalSentiment(e.target.value)}
                >
                  <MenuItem value="positive" key={1}>
                    {translate("Positive")}
                  </MenuItem>
                  <MenuItem value="negative" key={2}>
                    {translate("Negative")}
                  </MenuItem>
                  <MenuItem value="neutral" key={3}>
                    {translate("Neutral")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Categories Multi-select */}
            <Grid item xs={12} md={6}>
              {" "}
              <Autocomplete
                multiple
                id="category-select"
                options={[
                  "News",
                  "Politics",
                  "Technology",
                  "Entertainment",
                  "Sports",
                  "Business",
                ]}
                value={localSelectedCategories}
                onChange={(event, newValue) => {
                  setLocalSelectedCategories(newValue);
                }}
                PopperComponent={(props) => (
                  <CustomPopper {...props} placement="bottom-start" />
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate("Search categories")}
                    placeholder={translate("Type to search categories...")}
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      py: 1,
                    }}
                  >
                    <Typography noWrap flex={1}>
                      {option}
                    </Typography>
                  </Box>
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <CustomTag {...getTagProps({ index })}>
                      <ChannelName
                        sx={{
                          marginLeft: "8px",
                        }}
                      >
                        {option}
                      </ChannelName>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setLocalSelectedCategories((categories) =>
                            categories.filter((category) => category !== option)
                          );
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </CustomTag>
                  ))
                }
                sx={{
                  "& .MuiAutocomplete-tag": {
                    margin: "3px",
                  },
                }}
              />
            </Grid>

            {/* Account Selection */}
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                id="channel-select"
                options={channels}
                value={localSelectedChannels}
                getOptionLabel={(option) => option.chat_title || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                  if (newValue.length <= 5) {
                    setLocalSelectedChannels(newValue);
                    setError("");
                  } else {
                    setError("Maximum of 5 channels allowed");
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  setChannelSearchTerm(newInputValue);
                }}
                PopperComponent={(props) => (
                  <CustomPopper {...props} placement="bottom-start" />
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search channels"
                    error={!!error}
                    helperText={error}
                    placeholder="Type to search channels..."
                    sx={{
                      width: "100%",
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      py: 1,
                    }}
                  >
                    <Avatar
                      src={
                        option.profile_picture_url ||
                        (option.image ? `${website}${option.image}` : avatar)
                      }
                      alt={option.chat_title}
                      sx={{ width: 28, height: 28 }}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = avatar;
                      }}
                    />
                    <Typography noWrap flex={1}>
                      {option.chat_title}
                    </Typography>
                    <SourceIcon
                      src={`${website}${option.source_logo}`}
                      alt="Source Icon"
                    />
                  </Box>
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <CustomTag {...getTagProps({ index })}>
                      <Avatar
                        src={
                          option.profile_picture_url ||
                          (option.image ? `${website}${option.image}` : avatar)
                        }
                        alt={option.chat_title}
                        sx={{ width: 24, height: 24 }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = avatar;
                        }}
                      />
                      <ChannelName>{option.chat_title}</ChannelName>
                      <SourceIcon
                        src={`${website}${option.source_logo}`}
                        alt="Source Icon"
                      />

                      <IconButton
                        size="small"
                        onClick={() => {
                          setLocalSelectedChannels((channels) =>
                            channels.filter(
                              (channel) => channel.id !== option.id
                            )
                          );
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </CustomTag>
                  ))
                }
                sx={{
                  "& .MuiAutocomplete-tag": {
                    margin: "3px",
                  },
                }}
              />
            </Grid>
          </Grid>

          {/* Action Buttons */}
          <Box
            sx={{ mt: 4, display: "flex", justifyContent: "flex-end", gap: 2 }}
          >
            <Button variant="outlined" onClick={handleClearFilters}>
              {translate("Clear Filters")}
            </Button>
            <Button variant="contained" onClick={handleApplyFilters}>
              {translate("Apply Filters")}
            </Button>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
}

export default FilterDialog;
