import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import Select from "react-select";
import Swal from "sweetalert2";
import { updateColumnInDB } from "../localdb/DBColumn";
import { deleteColumnFromAPI } from "../column/column-utils/ColumnUtils";
import SecondColumn from "./popup_internal_columns/SecondColumn";
import ThirdColumn from "./popup_internal_columns/ThirdColumn";
import { useColumns } from "../column/ColumnsContext";
import { fadeIn } from "../settings/Settings";
import { toast } from "sonner";
import { CloseButton } from "./AddColumnPopup";
import FirstColumn from "./popup_internal_columns/FirstColumn";
import { webApi } from "../../global/globalUtils";
import Spinner from "../../global/loaders/Spinner";
import { useTranslation } from "../translate/TranslateContext";

const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
  animation: ${fadeIn} 0.3s ease-out;
  max-height: 90vh;
  overflow-y: auto;

  @media (max-width: 769px) {
    max-height: 80vh;
    overflow-y: auto;
  }
`;

const SeperatedButtons = styled.div`
  display: flex;
  justify-content: end;
  gap: 16px;
  padding-bottom: 16px;
`;

const SecondColumnContainer = styled.div`
  width: 320px;
  border-inline-end: 1px solid #ccc;
  padding: 0px 20px 20px 20px;
`;

const ThirdColumnContainer = styled.div`
  width: 320px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PopupHeader = styled.div`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0 16px;
`;

const HeaderGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background-color: #1e2836;
  color: white;
`;

const ThemedButton = styled.button`
  border: none;
  padding: 12px 36px;
  border-radius: 16px;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  background-color: ${({ theme }) => theme.chip_inactive};
  color: ${({ theme }) => theme.text};
  transition: ease 0.2s;
  &:hover {
    background-color: ${({ theme }) => theme.cancel_hover};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const DeleteButton = styled.button`
  font-weight: bold;
  background-color: red;
  color: white;
  border: none;
  padding: 12px 16px;
  border-radius: 16px;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const ColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-template-areas:
      "header header"
      "second third";

    & > *:nth-child(1) {
      grid-area: header;
    }

    & > *:nth-child(2) {
      grid-area: second;
    }

    & > *:nth-child(3) {
      grid-area: third;
    }
  }

  @media (max-width: 769px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "second"
      "third";

    & > *:nth-child(1) {
      grid-area: header;
    }

    & > *:nth-child(2) {
      grid-area: second;
    }

    & > *:nth-child(3) {
      grid-area: third;
    }
  }
`;

const EditPopup = ({ column, onClose, onSave }) => {
  // Keep original values to track changes
  const originalColumn = { ...column };

  // State variables
  const [columnName, setColumnName] = useState(column.name);
  const [selectedIcon, setSelectedIcon] = useState(column.icon);
  const [selectedFile, setSelectedFile] = useState(null);
  const [availableChannels, setAvailableChannels] = useState([]);
  const [chosenChannels, setChosenChannels] = useState(column.channels || []);
  const [notificationEnabled, setNotificationEnabled] = useState(
    column.notifications ? true : false
  );
  const [notificationSound, setNotificationSound] = useState(
    column.isLoudSound
  );

  const { removeColumn, updateColumn } = useColumns();
  const [loading, setLoading] = useState(false);

  const { translate } = useTranslation();

  // Event handlers
  const handleNotificationChange = (event) => {
    setNotificationEnabled(event.target.value === "On");
  };

  const handleNotificationSoundChange = (event) => {
    setNotificationSound(event.target.value);
  };

  // Function to check if a field has changed
  const hasFieldChanged = (fieldName, currentValue, originalValue) => {
    // Handle arrays specially (for channels)
    if (Array.isArray(currentValue) && Array.isArray(originalValue)) {
      if (currentValue.length !== originalValue.length) return true;

      // For channels array, compare by IDs
      if (fieldName === "channels") {
        const currentIds = currentValue.map((channel) => channel.id).sort();
        const originalIds = originalValue.map((channel) => channel.id).sort();
        return JSON.stringify(currentIds) !== JSON.stringify(originalIds);
      }

      return JSON.stringify(currentValue) !== JSON.stringify(originalValue);
    }

    return currentValue !== originalValue;
  };

  // Get only changed fields
  const getChangedFields = () => {
    const changes = {};

    if (hasFieldChanged("name", columnName, originalColumn.name)) {
      changes.name = columnName;
    }

    const newNotificationValue = notificationEnabled ? 1 : 0;
    if (
      hasFieldChanged(
        "notifications",
        newNotificationValue,
        originalColumn.notifications
      )
    ) {
      changes.notifications = newNotificationValue;
    }

    if (
      hasFieldChanged(
        "isLoudSound",
        notificationSound,
        originalColumn.isLoudSound
      )
    ) {
      changes.isLoudSound = notificationSound;
    }

    if (hasFieldChanged("channels", chosenChannels, originalColumn.channels)) {
      changes.channels = chosenChannels.map((channel) => channel.id);
    }

    // Handle icon changes separately since they're more complex
    const iconChanged =
      selectedIcon?.type !== originalColumn.icon?.type ||
      selectedIcon?.value !== originalColumn.icon?.value ||
      selectedFile !== null;

    return { changes, iconChanged };
  };

  const handleSave = async () => {
    // Validation
    if (!columnName.trim()) {
      toast.error(translate("Column name cannot be empty"));
      return;
    }

    if (chosenChannels.length === 0) {
      toast.error(translate("You must select at least one channel"));
      return;
    }

    try {
      setLoading(true);
      // Create the updated column object for local state
      const updatedColumn = {
        ...column,
        name: columnName,
        icon: selectedIcon,
        notifications: notificationEnabled ? 1 : 0,
        isLoudSound: notificationSound,
        channels: chosenChannels,
      };

      // Update local database first
      await updateColumnInDB(column.id, updatedColumn);

      // Prepare API payload with only changed data
      const { changes, iconChanged } = getChangedFields();
      const formData = new FormData();

      // Add column ID always
      formData.append("id", column.id);

      // Add only changed fields to formData
      Object.keys(changes).forEach((key) => {
        if (Array.isArray(changes[key])) {
          changes[key].forEach((item) => {
            formData.append(`${key}[]`, item);
          });
        } else {
          formData.append(key, changes[key]);
        }
      });

      // Handle icon separately to avoid duplication
      if (iconChanged) {
        if (selectedIcon?.type === "custom" && selectedFile) {
          formData.append("icon", selectedFile);
          formData.append("icon_type", "custom");
        } else if (selectedIcon?.type === "default" && selectedIcon?.value) {
          formData.append("icon", selectedIcon.value);
          formData.append("icon_type", "default");
        }
      }

      // Skip API call if nothing changed
      if (Object.keys(changes).length === 0 && !iconChanged) {
        onClose();
        return;
      }

      // Send the update to the API
      const response = await fetch(`${webApi}/column/${column.id}`, {
        method: "PUT",
        body: formData,
        credentials: "include",
      });

      const data = await response.json();

      if (!response.ok) {
        toast.error(
          data.message || "Failed to save the column. Please try again."
        );
        console.error("Error updating column:", data.message);
        return;
      }

      // Only update local state and UI after API confirms success
      await updateColumn(updatedColumn);
      onSave(updatedColumn);

      toast.success(translate("Column updated successfully"));
      onClose();
    } catch (error) {
      console.error("Error updating column:", error);
      toast.error("Failed to save the column. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    Swal.fire({
      title: translate("Are you sure?"),
      text: translate("You will not be able to recover this column!"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: translate("Yes, delete it!"),
      cancelButtonText: translate("Cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteColumnFromAPI(column.id);
          removeColumn(column.id); // Update local state to remove the column
          toast.success(translate("The column has been deleted."));
          onClose();
        } catch (error) {
          toast.error(translate("Failed to delete the column."));
        }
      }
    });
  };

  const handleAddChannel = (channel) => {
    setAvailableChannels((prev) => prev.filter((ch) => ch.id !== channel.id));
    setChosenChannels((prev) => [...prev, channel]);
  };

  const handleRemoveChannel = (channel) => {
    setChosenChannels((prev) => prev.filter((ch) => ch.id !== channel.id));
    setAvailableChannels((prev) => [channel, ...prev]);
  };

  return (
    <PopupBackground>
      <PopupContainer>
        <HeaderGroup>
          <PopupHeader>{translate("Edit Column")}</PopupHeader>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </HeaderGroup>
        <ColumnsContainer>
          <FirstColumn
            selectedIcon={selectedIcon}
            setSelectedIcon={setSelectedIcon}
            onNotificationChange={handleNotificationChange}
            onNotificationSoundChange={handleNotificationSoundChange}
            column={column}
            setColumnName={setColumnName}
            setSelectedFile={setSelectedFile}
          />
          <SecondColumnContainer>
            <SecondColumn
              channels={availableChannels}
              onAddChannel={handleAddChannel}
              chosenChannels={chosenChannels}
              setChannels={setAvailableChannels}
              deactivateRegularColumn={true}
            />
          </SecondColumnContainer>
          <ThirdColumnContainer>
            <ThirdColumn
              onClose={onClose}
              chosenChannels={chosenChannels}
              onRemoveChannel={handleRemoveChannel}
              selectedIcon={selectedIcon}
              hideButtons={true}
              deactivateRegularColumn={true}
              notificationEnabled={notificationEnabled}
              notificationSound={notificationSound}
            />
            <SeperatedButtons>
              <DeleteButton onClick={handleDelete} disabled={loading}>
                {translate("Delete Column")}
              </DeleteButton>
              <ThemedButton onClick={handleSave} disabled={loading}>
                {loading ? translate("Saving...") : translate("Save")}
              </ThemedButton>
            </SeperatedButtons>
          </ThirdColumnContainer>
        </ColumnsContainer>
      </PopupContainer>
    </PopupBackground>
  );
};

export default EditPopup;
