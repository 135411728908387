import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  TextField,
  Box,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "../../../translate/TranslateContext";

const StyledTableRow = styled(TableRow)(({ theme, selected }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
  },
  ...(selected && {
    backgroundColor: "#e3f2fd !important",
    outline: "2px solid #1976d2",
    position: "relative",
    "& > td": {
      backgroundColor: "rgba(25, 118, 210, 0.08)",
    },
  }),
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: "0.875rem",
    border: "1px solid #dadde9",
    padding: "16px",
    boxShadow: "0 4px 16px rgba(0,0,0,0.1)",
    "& .searchHighlight": {
      backgroundColor: "#fff3cd",
      padding: "0 2px",
      borderRadius: "2px",
    },
    "& .tooltipContent": {
      lineHeight: "1.6",
      letterSpacing: "0.2px",
    },
    "& .tooltipHeader": {
      borderBottom: "1px solid #eee",
      marginBottom: "12px",
      paddingBottom: "8px",
    },
    "& .tooltipMetadata": {
      color: "#666",
      fontSize: "0.8rem",
      marginTop: "12px",
      paddingTop: "8px",
      borderTop: "1px solid #eee",
    },
  },
});

const SearchContainer = styled(Box)({
  padding: "16px",
  backgroundColor: "#fff",
  borderBottom: "1px solid rgba(224, 224, 224, 1)",
});

const DataTable = ({ posts, selectedPost }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPosts, setFilteredPosts] = useState(posts);

  const { translate } = useTranslation();
  const tableRef = useRef(null);
  const selectedRowRef = useRef(null);

  useEffect(() => {
    const filtered = posts.filter((post) => {
      const searchLower = searchTerm.toLowerCase();
      return (
        post.channel.toLowerCase().includes(searchLower) ||
        post.text.toLowerCase().includes(searchLower)
      );
    });
    setFilteredPosts(filtered);
  }, [searchTerm, posts]);

  useEffect(() => {
    if (selectedPost && tableRef.current) {
      const selectedRow = document.getElementById(`post-${selectedPost.id}`);
      if (selectedRow) {
        setTimeout(() => {
          selectedRow.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          selectedRowRef.current = selectedRow;
        }, 100);
      }
    }
  }, [selectedPost]);

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  const truncateText = (text, maxLength = 150) => {
    if (!text) return "";
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + "...";
  };

  const highlightText = (text, searchTerm) => {
    if (!searchTerm) return text;
    const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <mark key={index} style={{ backgroundColor: "#fff3cd" }}>
          {part}
        </mark>
      ) : (
        part
      )
    );
  };

  const createTooltipContent = (post, searchTerm) => {
    const highlightTextInTooltip = (text) => {
      if (!searchTerm) return text;
      const parts = text.split(new RegExp(`(${searchTerm})`, "gi"));
      return parts.map((part, index) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <span key={index} className="searchHighlight">
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    return (
      <div>
        <div className="tooltipHeader">
          <Typography variant="subtitle2" fontWeight="600">
            {post.channel}
          </Typography>
        </div>
        <div className="tooltipContent">
          <Typography style={{ whiteSpace: "pre-wrap" }}>
            {highlightTextInTooltip(post.text)}
          </Typography>
        </div>
        <div className="tooltipMetadata">
          <Typography variant="caption">
            {translate("Published")}: {formatDate(post.timestamp)}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <SearchContainer>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search in channel names and content..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="small"
        />
      </SearchContainer>
      <TableContainer
        ref={tableRef}
        sx={{
          maxHeight: 800,
          scrollBehavior: "smooth",
          "& .MuiTableCell-root": {
            padding: "12px 16px",
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{translate("Channel")}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {translate("Text")}
              </TableCell>
              <TableCell>{translate("Date")}</TableCell>
              <TableCell>{translate("URL")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPosts.map((post) => (
              <StyledTableRow
                key={post.id}
                id={`post-${post.id}`}
                selected={selectedPost && selectedPost.id === post.id}
              >
                <TableCell>{highlightText(post.channel, searchTerm)}</TableCell>
                <StyledTooltip
                  title={createTooltipContent(post, searchTerm)}
                  placement="bottom-start"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "white",
                      },
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      textAlign: "center",
                      cursor: "help",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                    }}
                  >
                    {highlightText(truncateText(post.text), searchTerm)}
                  </TableCell>
                </StyledTooltip>
                <TableCell>{formatDate(post.timestamp)}</TableCell>
                <TableCell>
                  <a href={post.url} target="_blank" rel="noopener noreferrer">
                    {translate("View Post")}
                  </a>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DataTable;
