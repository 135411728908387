import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import OutlinedInput from "@mui/material/OutlinedInput";
import { toast } from "sonner";
import { forgotPassword } from "../utils";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "../../../translate/TranslateContext";

function ForgotPassword({ open, handleClose }) {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const { translate } = useTranslation();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleResetPassword = async () => {
    const response = await forgotPassword(email, setLoading);
    if (response.success) {
      toast.success(response.message);
      handleClose();
    } else {
      toast.error(response.message);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
        sx: { backgroundImage: "none" },
      }}
    >
      <DialogTitle>{translate("Reset password")}</DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}
      >
        <DialogContentText>
          {translate("Enter your email address for reset password")}
        </DialogContentText>
        <OutlinedInput
          value={email}
          onChange={handleEmailChange}
          autoFocus
          required
          margin="dense"
          id="email"
          name="email"
          label={translate("Email address")}
          placeholder={translate("Email address")}
          type="email"
          fullWidth
        />
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button onClick={handleClose}>{translate("Cancel")}</Button>
        <Button
          variant="contained"
          onClick={handleResetPassword}
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} />}
        >
          {loading ? "" : translate("Send Reset Link")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;
