import React, { useState, useEffect } from "react";
import { useTheme } from "../../themes/ThemeContext";
import { getAuth } from "../../../global/globalUtils";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

// Styled components for better visual presentation
const IconContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const ErrorIcon = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.error || "red"};
  font-size: 11px;
  background: ${({ theme }) => theme.background || "#f5f5f5"};
  border-radius: 50%;
`;

const ColumnIcon = ({ columnId, iconType, iconPath, darkMode = false }) => {
  const { theme } = useTheme();
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  // Reset states when icon type changes
  useEffect(() => {
    // Clear error state when switching icon types
    setError(false);

    // Clear imageUrl when switching to default icon
    if (iconType === "default" && imageUrl) {
      URL.revokeObjectURL(imageUrl);
      setImageUrl(null);
    }
  }, [iconType, iconPath]);

  // Effect to fetch the custom icon if needed
  useEffect(() => {
    // Clear any previous fetch if icon type changes
    if (iconType !== "custom") {
      setIsLoading(false);
      return;
    }

    // Only fetch if it's a custom icon
    if (iconType === "custom" && iconPath) {
      setIsLoading(true);
      setError(false);

      const fetchIcon = async () => {
        try {
          const response = await getAuth(`/column/column-icon/${columnId}`);

          if (!response.ok) {
            throw new Error("Failed to load icon");
          }

          const blob = await response.blob();
          const objectUrl = URL.createObjectURL(blob);

          setImageUrl(objectUrl);
          setIsLoading(false);
        } catch (err) {
          console.error("Error loading icon:", err);
          setError(true);
          setIsLoading(false);
        }
      };

      fetchIcon();

      // Cleanup function to revoke object URL when component unmounts
      return () => {
        if (imageUrl) {
          URL.revokeObjectURL(imageUrl);
        }
      };
    }
  }, [iconType, iconPath, columnId]);

  // Get the appropriate icon source based on type and current theme
  const getIconSrc = () => {
    if (iconType === "default") {
      // Make sure we have a valid iconPath for default icons
      const fallbackIcon = "news"; // Default fallback
      const iconName = iconPath || fallbackIcon;

      if (darkMode) {
        return `/default-column-icons/dark/${iconName}.png`;
      }
      return `/default-column-icons/${theme}/${iconName}.png`;
    } else if (iconType === "custom" && imageUrl) {
      return imageUrl;
    } else {
      // Placeholder for custom icons that haven't loaded yet
      const fallbackIcon = "news";
      if (darkMode) {
        return `/default-column-icons/dark/${fallbackIcon}.png`;
      }
      return `/default-column-icons/${theme}/${fallbackIcon}.png`;
    }
  };

  if (isLoading) {
    return (
      <Skeleton
        width={30}
        height={30}
        circle={true}
        style={{ borderRadius: "50%" }}
      />
    );
  }

  if (error && iconType === "custom") {
    return (
      <ErrorIcon>
        <span>!</span>
      </ErrorIcon>
    );
  }

  return (
    <IconContainer>
      <IconImage
        src={getIconSrc()}
        alt="Column icon"
        className="column-icon"
        onError={(e) => {
          // Fallback to a default icon if loading fails
          const fallbackIcon = "news";
          e.target.src = darkMode
            ? `/default-column-icons/dark/${fallbackIcon}.png`
            : `/default-column-icons/${theme}/${fallbackIcon}.png`;
        }}
      />
    </IconContainer>
  );
};

export default ColumnIcon;
