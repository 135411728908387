import { CardHeader, Divider, Card, Box, Skeleton } from "@mui/material";
import { website } from "../../../global/globalUtils";
import { formatNumber } from "../Index";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "../../translate/TranslateContext";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <Box
        sx={{
          bgcolor: "background.paper",
          p: 1.5,
          border: 1,
          borderColor: "divider",
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <img
            src={`${website}${data.icon}`}
            alt={data.category}
            style={{
              width: 20,
              height: 20,
              marginRight: 8,
            }}
          />
          <strong>{data.category}</strong>
        </Box>
        <Box sx={{ color: "text.secondary" }}>
          <div>Posts: {formatNumber(data.count)}</div>
          <div>Percentage: {data.percentage}%</div>
        </Box>
      </Box>
    );
  }
  return null;
};

function PostsByCategory({ categories = [], loading }) {
  const { translate } = useTranslation();
  // Sort categories by count in descending order
  const sortedCategories = [...categories].sort((a, b) => b.count - a.count);

  // Loading skeleton for the chart
  const LoadingSkeleton = () => (
    <Box sx={{ p: 3, height: 400 }}>
      <Skeleton variant="rectangular" width="100%" height="100%" />
    </Box>
  );

  // Calculate the maximum count for axis domain
  const maxCount = Math.max(...categories?.map((cat) => cat.count), 0);
  // Round up to the nearest nice number for the domain max
  const domainMax = Math.ceil(maxCount / 1000) * 1000;

  return (
    <Card>
      <CardHeader
        title={
          loading ? (
            <Skeleton width={200} height={32} />
          ) : (
            translate("Posts by Category")
          )
        }
        subheader={
          loading ? (
            <Skeleton width={300} />
          ) : (
            translate("Distribution of posts across different categories")
          )
        }
      />
      <Divider />
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <Box sx={{ p: 4, height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={sortedCategories}
              margin={{
                top: 20,
                right: 30,
                insetInlineStart: 20,
                bottom: 80,
              }}
              barSize={40}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                stroke="#e0e0e0"
              />
              <XAxis
                dataKey="category"
                interval={0}
                tick={{
                  fontSize: 12,
                  fill: "#666",
                  angle: -45,
                  textAnchor: "end",
                  dy: 10,
                }}
                height={80}
                axisLine={{ stroke: "#e0e0e0" }}
              />
              <YAxis
                tickFormatter={formatNumber}
                tick={{
                  fontSize: 12,
                  fill: "#666",
                }}
                axisLine={{ stroke: "#e0e0e0" }}
                label={{
                  value: translate("Number of Posts"),
                  angle: -90,
                  position: "insideLeft",
                  style: { fill: "#666" },
                  dx: -20,
                }}
              />
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ fill: "rgba(0, 0, 0, 0.05)" }}
              />
              <Bar
                dataKey="count"
                fill="#2196f3"
                radius={[4, 4, 0, 0]}
                label={{
                  position: "top",
                  formatter: formatNumber,
                  fill: "#666",
                  fontSize: 12,
                  dy: -6,
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}
    </Card>
  );
}

export default PostsByCategory;
