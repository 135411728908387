import React, { useState } from "react";
import styled from "styled-components";
import { useTheme } from "../../../themes/ThemeContext";
import { DatePicker } from "../../../../global/styled-components/DatePicker";
import {
  CheckBoxInput,
  RadioContainer,
} from "../../../column_popups/DownloadPopup";
import Select from "react-select";
import Spinner from "../../../../global/loaders/Spinner";
import { handleDownload, handleDownloadCSV } from "./utils";
import {
  Checkbox,
  CheckboxContainer,
  CheckboxLabel,
  SearchButton,
  SearchContainer,
  SearchIcon,
  SearchInput,
  SearchInputContainer,
} from "../global-search/SearchTab";
// import { useSearch } from "../global-search/SearchContext";
import { useTranslation } from "../../../translate/TranslateContext";

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  border-bottom: 1px solid #d3d3d3; /* Thin line at the bottom */
  box-shadow: 0 1px 2px -3px rgba(0, 0, 0, 0.1); /* Optional: subtle box shadow */
`;

const Tab = styled.div`
  padding: 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  transition: background-color 0.3s;
  border-bottom: ${({ $selected }) =>
    $selected ? "2px solid #1e2836" : "none"};
  border-start-start-radius: 5px;
  border-start-end-radius: 5px;

  &:hover {
    background-color: ${({ theme }) =>
      theme.post_hover_background || "#f5f5f5"};
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    height: 1px;
    background-color: #1e2836;
    transform: ${({ $selected }) => ($selected ? "scaleX(1)" : "scaleX(0)")};
    transform-origin: bottom left;
    transition: transform 0.3s;
  }

  p {
    margin: 0 0 0 8px;
  }

  .material-icons {
    font-size: 24px;
  }
`;

const TabContent = styled.div`
  background-color: ${({ theme }) => theme.popup_background};
  padding-top: 20px;
  border-radius: 0 5px 5px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  font-size: 14px;
  width: 100%;
`;

const SectionTitle = styled.h4`
  color: ${({ theme }) => theme.text};
  margin: 10px 0;
  text-align: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 30px;
`;

const DateRangeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;

  & > div {
    width: 48%;
    text-align: center;
  }

  label {
    display: block;
    margin-bottom: 8px;
    text-align: center;
  }
`;

const DownloadTab = () => {
  const { theme } = useTheme();
  const { translate } = useTranslation();
  const [From, setFrom] = useState("");
  const [To, setTo] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: "Original",
    label: translate("Original"),
  });
  const [mediaSelection, setMediaSelection] = useState({
    videos: false,
    images: false,
  });
  const [CSVloading, setCSVLoading] = useState(false);
  const [MediaLoading, setMediaLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("csv");
  const [searchInput, setSearchInput] = useState("");
  const [isExactMatch, setIsExactMatch] = useState(true);

  const handleFromChange = (e) => setFrom(e.target.value);
  const handleToChange = (e) => setTo(e.target.value);
  const handleLanguageChange = (selectedOption) =>
    setSelectedLanguage(selectedOption);

  const toggleSelection = (mediaType) =>
    setMediaSelection((prevState) => ({
      ...prevState,
      [mediaType]: !prevState[mediaType],
    }));

  return (
    <>
      <TabsContainer>
        <Tab
          $selected={activeTab === "csv"}
          onClick={() => setActiveTab("csv")}
        >
          CSV Download
        </Tab>
        <Tab
          $selected={activeTab === "media"}
          onClick={() => setActiveTab("media")}
        >
          Media Download
        </Tab>
      </TabsContainer>

      <TabContent theme={theme}>
        {" "}
        <SearchContainer>
          <SearchInputContainer>
            <SearchInput
              type="text"
              placeholder={translate("Enter Search Term...")}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              title="Press Enter to search"
            />
            <SearchIcon className="material-icons">search</SearchIcon>
          </SearchInputContainer>
          <CheckboxContainer>
            <CheckboxLabel>
              <Checkbox
                type="checkbox"
                checked={isExactMatch}
                onChange={(e) => setIsExactMatch(e.target.checked)}
              />
              {translate("Match Exact Words")}
            </CheckboxLabel>
          </CheckboxContainer>
        </SearchContainer>
        {/* Date Range Inputs */}
        <DateRangeContainer>
          <div>
            <label>{translate("From")}</label>
            <DatePicker
              type="datetime-local"
              className="date-time-picker"
              $theme={theme}
              value={From}
              onChange={handleFromChange}
              fontSize={18}
              paddingHorizontal={"8px"}
            />
          </div>
          <div>
            <label>{translate("To")}</label>
            <DatePicker
              type="datetime-local"
              className="date-time-picker"
              $theme={theme}
              value={To}
              onChange={handleToChange}
              fontSize={18}
              paddingHorizontal={"8px"}
            />
          </div>
        </DateRangeContainer>
        {activeTab === "csv" && (
          <>
            <SectionTitle>{translate("Choose Text Language")}</SectionTitle>
            <InputContainer>
              <StyledSelect
                options={[
                  { value: "Original", label: translate("Original") },
                  { value: "en", label: translate("English") },
                  { value: "ar", label: translate("Arabic") },
                  { value: "es", label: translate("Spanish") },
                  { value: "fr", label: translate("French") },
                  { value: "de", label: translate("German") },
                  { value: "ru", label: translate("Russian") },
                ]}
                value={selectedLanguage}
                onChange={handleLanguageChange}
                theme={theme}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    fontSize: "14px",
                    backgroundColor:
                      theme === "dark" ? "#333" : theme.column_background,
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: theme === "dark" ? "#333" : "#fff",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: theme === "dark" ? "#fff" : "#000",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused
                      ? theme === "dark"
                        ? "#555"
                        : "#ddd"
                      : theme === "dark"
                      ? "#333"
                      : "#fff",
                    color: state.isFocused
                      ? theme === "dark"
                        ? "#fff"
                        : "#000"
                      : theme === "dark"
                      ? "#fff"
                      : "#000",
                    cursor: "pointer",
                  }),
                  input: (base) => ({
                    ...base,
                    color: theme.text,
                  }),
                }}
              />
            </InputContainer>
            {CSVloading ? (
              <Spinner />
            ) : (
              <SearchButton
                theme={theme}
                onClick={() => {
                  handleDownloadCSV({
                    From,
                    To,
                    searchInput,
                    isExactMatch,
                    selectedLanguage,
                    setCSVLoading,
                  });
                }}
                disabled={CSVloading}
              >
                {translate("Download CSV")}
                <span className="material-icons">upload_file</span>
              </SearchButton>
            )}
          </>
        )}
        {activeTab === "media" && (
          <>
            <SectionTitle>Choose Media Types</SectionTitle>
            <InputContainer>
              <RadioContainer>
                <CheckBoxInput>
                  <input
                    type="checkbox"
                    id="videos"
                    name="media"
                    checked={mediaSelection.videos}
                    onChange={() => toggleSelection("videos")}
                  />
                  <label htmlFor="videos">Videos</label>
                </CheckBoxInput>
                <CheckBoxInput>
                  <input
                    type="checkbox"
                    id="images"
                    name="media"
                    checked={mediaSelection.images}
                    onChange={() => toggleSelection("images")}
                  />
                  <label htmlFor="images">Images</label>
                </CheckBoxInput>
              </RadioContainer>
            </InputContainer>
            {MediaLoading ? (
              <Spinner />
            ) : (
              <SearchButton
                theme={theme}
                onClick={() => {
                  handleDownload({
                    From,
                    To,
                    searchInput,
                    isExactMatch,
                    mediaSelection,
                    setMediaLoading,
                  });
                }}
                disabled={MediaLoading}
              >
                {translate("Download Media")}
                <span className="material-icons">download</span>
              </SearchButton>
            )}
          </>
        )}
      </TabContent>
    </>
  );
};

export default DownloadTab;
