import React, { useState, useEffect } from "react";
import Post from "../../../../post/Post";
import styled, { keyframes, css } from "styled-components";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { getFullPosts, getPostById } from "../../../map_utils/MapUtiles";
import { useFilter } from "../../../contexts/FilterContext";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useMapRef } from "../../../contexts/MapContext";
import { useLocations } from "../../../contexts/LocationsContext";
import { useClickedPost } from "../../../contexts/ClickedPostContext";
import { useTranslation } from "../../../../translate/TranslateContext";

countries.registerLocale(enLocale);

const defaultColumn = {
  get_video: true,
  get_image: true,
  get_preview: true,
  get_text_only: true,
};

const loadingAnimation = keyframes`
  0% {
    background-color: ${({ theme }) => theme.chip_inactive};
  }
  50% {
    background-color: ${({ theme }) => theme.chip_active};
  }
  100% {
    background-color: ${({ theme }) => theme.chip_inactive};
  }
`;

const LoadButton = styled.button`
  font-size: 14px;
  font-weight: bold;
  margin-top: 2px;
  padding: 10px;
  background-color: ${({ theme }) => theme.chip_inactive};
  color: ${({ theme }) => theme.text};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  &:hover {
    opacity: 0.87;
  }
  ${({ $loading }) =>
    $loading &&
    css`
      animation: ${loadingAnimation} 1s infinite;
      cursor: wait;
    `}
`;

const NoNewsMessage = styled.h3`
  margin-inline-start: 10px;
`;

const SkeletonPost = styled.div`
  display: flex;
  padding: 10px 0;
  gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.border_color};
`;

const SkeletonAvatar = styled(Skeleton).attrs(() => ({
  circle: true,
  width: 40,
  height: 40,
}))`
  margin-inline-end: 10px;
`;

const SkeletonContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SkeletonLine = styled(Skeleton)`
  margin-bottom: 5px;
`;

const NavigateButton = styled.button`
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  background-color: ${({ theme }) => theme.map_button_background || "blue"};
  border: none;
  color: white;
  cursor: pointer;
  text-decoration: none;
  margin-top: 10px; /* Adjust as needed */
  padding: 5px 10px; /* Add some padding */
  border-radius: 5px 5px 0 0; /* Rounded corners */
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: ${({ theme }) =>
      theme.button_hover_background || "rgb(30, 40, 54)"};
  }

  &:active {
    transform: scale(0.95); /* Scale down on click */
  }
`;

const postCache = new Map();

const NewsMapSidebar = ({
  setIsPostPopupOpen,
  setCurrentId,
  setCurrentSender,
  setCurrentSource,
  setCurrentTweetId,
  setCurrentPostUrl,
}) => {
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [loadingMore, setLoadingMore] = useState(false);
  const {
    search,
    categories,
    startDate,
    endDate,
    countries,
    hasImages,
    hasVideos,
    hasText,
  } = useFilter();
  const { clickedPostId } = useClickedPost();
  const { translate } = useTranslation();

  const { mapRef } = useMapRef();
  const {
    locations,
    markersRef,
    markerClusterRef,
    fullPostLoading,
    setFullPostLoading,
  } = useLocations();

  const [translatedTexts, setTranslatedTexts] = useState(new Map());

  // Handle translation for bookmarked posts
  const handleTranslation = (postId, translatedText) => {
    setTranslatedTexts((prev) => new Map(prev).set(postId, translatedText));
  };

  const handleOpenPopup = (
    sender_username,
    post_id,
    source,
    tweetId,
    post_url
  ) => {
    setCurrentSender(sender_username);
    setCurrentId(post_id);
    setIsPostPopupOpen(true);
    setCurrentSource(source);
    setCurrentTweetId(tweetId);
    setCurrentPostUrl(post_url);
  };

  // Fetch data on filter change (initial loading)
  useEffect(() => {
    const fetchData = async () => {
      const data = await getFullPosts(
        hasImages,
        hasVideos,
        hasText,
        limit,
        page,
        categories,
        countries,
        startDate,
        endDate,
        search,
        setFullPostLoading
      );

      if (!data || data.length === 0) {
        setNews([]); // Reset news if no data found
        return;
      }

      const filteredData = data.filter(
        (post) => post?.text?.toLowerCase() ?? false
      );
      setNews(filteredData);
      setPage(1); // Reset page to 1 when fetching new data
    };

    fetchData();
  }, [
    startDate,
    endDate,
    countries,
    categories,
    limit,
    search,
    hasImages,
    hasVideos,
    hasText,
  ]);

  // Fetch more data on page change (for loading more)
  useEffect(() => {
    if (page === 1) return; // Skip the effect for the initial page

    const fetchMoreData = async () => {
      setLoadingMore(true);

      const data = await getFullPosts(
        hasImages,
        hasVideos,
        hasText,
        limit,
        page,
        categories,
        countries,
        startDate,
        endDate,
        search,
        setLoadingMore
      );
      setLoadingMore(false);

      if (!data || data.length === 0) return;

      const filteredData = data.filter(
        (post) => post?.text?.toLowerCase() ?? false
      );

      // Append new data to the existing news state
      setNews((prevNews) => [...prevNews, ...filteredData]);
    };

    fetchMoreData();
  }, [
    page,
    limit,
    categories,
    countries,
    startDate,
    endDate,
    search,
    hasImages,
    hasVideos,
    hasText,
  ]);

  useEffect(() => {
    const addClickedPost = async () => {
      if (clickedPostId) {
        if (news.some((existingPost) => existingPost.id === clickedPostId)) {
          return;
        }

        let post;

        // Check the cache first
        if (postCache.has(clickedPostId)) {
          post = postCache.get(clickedPostId);
        } else {
          post = await getPostById(clickedPostId);
          postCache.set(clickedPostId, post);
        }

        if (
          post &&
          !news.some((existingPost) => existingPost.post_id === post.post_id)
        ) {
          setNews((prevNews) => [post, ...prevNews]);
        }
      }
    };

    addClickedPost();
  }, [clickedPostId, news]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleNavigateToMarker = (postId) => {
    const location = locations.find((loc) => loc.pid === postId);

    if (location && mapRef.current) {
      const { lat, lng } = location; // Assuming these are your keys for lat/lng
      mapRef.current.setView([lat, lng], 10); // Maintain current zoom level

      // Access the marker reference
      const marker = markersRef.current[postId];

      if (marker) {
        setTimeout(() => {
          // Check if the marker belongs to a cluster
          const cluster = markerClusterRef.current.getVisibleParent(marker);

          if (cluster) {
            // Check if spiderfy method exists on the cluster
            if (typeof cluster.spiderfy === "function") {
              cluster.spiderfy(); // Open the cluster to show individual markers
            }
            marker.openPopup(); // Open the marker's popup
          } else {
            marker.openPopup(); // Open the marker's popup if not in a cluster
          }
        }, 1000); // Delay to ensure map is set
      } else {
        console.error("Marker not found for postId:", postId);
      }
    } else {
      console.error("Location not found or mapRef is not set");
    }
  };

  return (
    <>
      {fullPostLoading ? (
        <>
          {Array.from({ length: 10 }).map((_, index) => (
            <SkeletonPost key={index}>
              <SkeletonAvatar />
              <SkeletonContent>
                <SkeletonLine height={20} width="80%" />
                <SkeletonLine height={15} width="60%" />
                <SkeletonLine height={12} width="90%" />
              </SkeletonContent>
            </SkeletonPost>
          ))}
        </>
      ) : news.length > 0 ? (
        <>
          {news.map((post, index) => (
            <div key={`${post.post_id}-${index}`}>
              <NavigateButton onClick={() => handleNavigateToMarker(post.id)}>
                <FaMapMarkerAlt style={{ marginRight: "5px" }} />
                {translate("Locate on Map")}
              </NavigateButton>
              <Post
                post={post}
                column={defaultColumn}
                onOpenPopup={handleOpenPopup}
                inputValue={search}
                translatedText={translatedTexts.get(post.id)}
                onTranslate={handleTranslation}
              />
            </div>
          ))}

          <LoadButton
            onClick={handleLoadMore}
            disabled={loadingMore}
            $loading={loadingMore}
          >
            {loadingMore ? translate("Loading...") : translate("Load More")}
          </LoadButton>
        </>
      ) : (
        <NoNewsMessage>{translate("No News yet")}</NoNewsMessage>
      )}
    </>
  );
};

export default NewsMapSidebar;
