import React from "react";
import styled from "styled-components";
import { useFilter } from "../../../contexts/FilterContext";
import { website } from "../../../../../global/globalUtils";
import { useTranslation } from "../../../../translate/TranslateContext";

// Styled components
const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  padding-top: 0;
  border-radius: 8px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const SectionTitle = styled.h3`
  font-size: 18px;
  color: ${({ theme }) => theme.primary};
  margin-bottom: 10px;
`;

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); // Two columns
  gap: 20px;
`;

const ShadedRegionItem = styled.div`
  display: flex;
  align-items: center;
`;

const ShadedRegionColor = styled.div`
  width: 20px;
  height: 20px;
  margin-inline-end: 10px;
  background-color: ${({ color }) => color};
  border: 1px solid #000;
  border-radius: 50%;
  flex-shrink: 0;
`;

const ShadedRegionLabel = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.text};
`;

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
`;

const CategoryIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-inline-end: 10px;
`;

const CategoryLabel = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.text};
`;

const KeyTab = () => {
  const { dbCategories, shadedRegions } = useFilter();
  const { translate } = useTranslation();

  // Filter out categories with a null icon
  const filteredCategories = dbCategories.filter((category) => category.icon);

  console.log("filtered Categories: ", filteredCategories);

  // Filter out shaded regions with duplicate names
  const uniqueShadedRegions = [];
  const regionNames = new Set();

  shadedRegions.forEach((region) => {
    if (!regionNames.has(region.name)) {
      uniqueShadedRegions.push(region);
      regionNames.add(region.name);
    }
  });

  return (
    <LegendContainer>
      {/* Shaded Regions Section */}
      <Section>
        <SectionTitle>{translate("Shaded Regions")}</SectionTitle>
        <ItemContainer>
          {uniqueShadedRegions.length > 0 ? (
            uniqueShadedRegions.map((region) => (
              <ShadedRegionItem key={region.id}>
                <ShadedRegionColor color={region.fillcolor} />
                <ShadedRegionLabel>{region.name}</ShadedRegionLabel>
              </ShadedRegionItem>
            ))
          ) : (
            <p>{translate("No shaded regions")}</p>
          )}
        </ItemContainer>
      </Section>

      {/* Categories Section */}
      <Section>
        <SectionTitle>{translate("Categories")}</SectionTitle>
        <ItemContainer>
          {filteredCategories.length > 0 ? (
            filteredCategories.map((category) => (
              <CategoryItem key={category.id}>
                <CategoryIcon
                  src={`${website}${category.icon}`}
                  alt={category.category}
                />
                <CategoryLabel>{translate(category.category)}</CategoryLabel>
              </CategoryItem>
            ))
          ) : (
            <p>{translate("No categories available.")}</p>
          )}
        </ItemContainer>
      </Section>
    </LegendContainer>
  );
};

export default KeyTab;
