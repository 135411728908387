import React, { useState, useEffect } from "react";
import { ContentRow } from "../components/ContentRow";
import { getMyProfile } from "./Profile_utils";
import CircularProgress from "@mui/material/CircularProgress";
import "../../../../assets/css/edtitableContentRow.css";
import { EditableContentRow } from "./EditableContentRow";
import SettingsButton from "../components/SettingsButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../../translate/TranslateContext";

const Profile = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getProfile = async () => {
      setIsLoading(true);
      try {
        const result = await getMyProfile();
        setProfile({
          name: result.name,
          email: result.email,
          phone: result.phone,
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch profile:", error);
        setIsLoading(false);
      }
    };

    getProfile();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <ContentRow title={"Email"}>{profile.email}</ContentRow>
          <EditableContentRow
            title={"Name"}
            value={profile.name || ""}
            attributeKey={"name"}
          />

          <EditableContentRow
            title={"Phone Number"}
            value={profile.phone || ""}
            attributeKey={"phone"}
          />

          <ContentRow title={"Password"}>
            <SettingsButton
              $backgroundColor="#007bff"
              $hoverBackgroundColor="#0056b3"
              $hoverColor="white"
              $color="white"
              $borderColor="transparent"
              onClick={() => navigate("/reset-password")}
            >
              {translate("Change Password")}
            </SettingsButton>
          </ContentRow>
        </>
      )}
    </>
  );
};
export default Profile;
