import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { useTheme } from "../../../themes/ThemeContext";
import { ExportButton } from "./Bookmarks";
import { toast } from "sonner";
import { translateTexts } from "../../../column/column-utils/ColumnUtils";
import { useTranslation } from "../../../translate/TranslateContext";

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    position: "relative",
    inset: "auto",
    border: "none",
    background: "transparent",
    overflow: "visible",
    padding: 0,
    WebkitOverflowScrolling: "touch",
    outline: "none",
  },
};

const StyledModalContent = styled.div`
  background-color: ${({ theme }) =>
    theme === "dark" ? "#1e1e1e" : "#ffffff"};
  color: ${({ theme }) => (theme === "dark" ? "#e0e0e0" : "#333333")};
  padding: 28px;
  border-radius: 12px;
  width: 360px;
  max-width: 90vw;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.3s ease, opacity 0.3s ease;

  h2 {
    margin: 0 0 20px 0;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: ${({ theme }) => (theme === "dark" ? "#ffffff" : "#333333")};
  }
`;

// Improved language select with better styling
const LanguageSelect = styled.div`
  margin-bottom: 24px;
  width: 100%;

  label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => (theme === "dark" ? "#b0b0b0" : "#555555")};
  }

  select {
    width: 100%;
    padding: 12px 14px;
    border-radius: 8px;
    border: 1px solid
      ${({ theme }) => (theme === "dark" ? "#444444" : "#e0e0e0")};
    background-color: ${({ theme }) =>
      theme === "dark" ? "#2a2a2a" : "#f8f8f8"};
    color: ${({ theme }) => (theme === "dark" ? "#e0e0e0" : "#333333")};
    font-size: 16px;
    outline: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23${({
      theme,
    }) =>
      theme === "dark"
        ? "BBBBBB"
        : "666666"}%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 14px top 50%;
    background-size: 12px auto;
    transition: border-color 0.2s, box-shadow 0.2s;

    &:hover {
      border-color: ${({ theme }) =>
        theme === "dark" ? "#666666" : "#cccccc"};
    }

    &:focus {
      border-color: ${({ theme }) =>
        theme === "dark" ? "#6a9fff" : "#3a80ff"};
      box-shadow: 0 0 0 3px
        ${({ theme }) =>
          theme === "dark"
            ? "rgba(106, 159, 255, 0.2)"
            : "rgba(58, 128, 255, 0.15)"};
    }
  }
`;

// Improved button container with better spacing
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
`;

// Enhanced button styling
const Button = styled.button`
  padding: 12px 18px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  flex: 1;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:active {
    transform: translateY(1px);
  }
`;

const FinalExportButton = styled(Button)`
  background-color: #28a745;
  color: white;
  margin-inline-start: 12px;

  &:hover:not(:disabled) {
    background-color: #218838;
    box-shadow: 0 2px 8px rgba(40, 167, 69, 0.3);
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.3);
    outline: none;
  }
`;

const CancelButton = styled(Button)`
  background-color: ${({ theme }) =>
    theme === "dark" ? "#444444" : "#e0e0e0"};
  color: ${({ theme }) => (theme === "dark" ? "#e0e0e0" : "#444444")};

  &:hover:not(:disabled) {
    background-color: ${({ theme }) =>
      theme === "dark" ? "#555555" : "#d0d0d0"};
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
    outline: none;
  }
`;

// Loading indicator component
const LoadingIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  font-size: 14px;
  color: ${({ theme }) => (theme === "dark" ? "#b0b0b0" : "#666666")};

  .spinner {
    width: 16px;
    height: 16px;
    border: 2px solid transparent;
    border-top-color: currentColor;
    border-radius: 50%;
    margin-inline-end: 8px;
    animation: spin 0.8s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

// Set the root element for accessibility for the modal
Modal.setAppElement("#root");

const ExportCsv = ({ bookmarks }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userLanguage, setUserLanguage] = useState("original");
  const [loading, setLoading] = useState(false);
  const { theme } = useTheme();
  const { translate } = useTranslation();

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleLanguageChange = (event) => {
    setUserLanguage(event.target.value);
  };

  // Batch translate function to handle all texts at once
  const translateCSVTexts = async (texts, targetLang) => {
    if (targetLang === "original") return texts;

    setLoading(true);
    try {
      // Assuming translateTexts is a function that can handle an array of texts
      const translations = await translateTexts(texts, targetLang);
      setLoading(false);
      return translations;
    } catch (error) {
      console.error("Translation API error:", error);
      setLoading(false);
      return texts; // Return original texts if translation fails
    }
  };

  // Utility function to remove emojis and special characters
  const removeEmojisAndSpecialChars = (text) => {
    if (typeof text !== "string") return "";
    return text.replace(/[^\p{L}\p{N}\p{P}\p{Z}]|\*/gu, "");
  };

  const export2csv = async () => {
    if (bookmarks.length > 200) {
      toast.warning("Exporting more than 200 posts is not supported.");
      return;
    }

    setLoading(true);

    const header = [
      "channel_name",
      "channel_username",
      "date",
      "time",
      "text",
      "post_url",
    ];

    // Field mapping to handle different field names
    const fieldMapping = {
      channel_name: "chat_title",
      channel_username: "sender_username",
      post_url: "post_url",
      text: "text",
    };

    try {
      // First, collect all the texts that need translation
      const textsToTranslate = [];
      const bookmarkData = bookmarks.map((row) => {
        const rowData = {};

        // Handle date and time separately
        if (row.timestamp) {
          const dateObj = new Date(row.timestamp);
          rowData.date = dateObj.toLocaleDateString(userLanguage);
          rowData.time = dateObj.toLocaleTimeString(userLanguage, {
            timeStyle: "short",
          });
        } else {
          rowData.date = "";
          rowData.time = "";
        }

        // Process other fields
        header.forEach((fieldName) => {
          if (fieldName !== "date" && fieldName !== "time") {
            const actualFieldName = fieldMapping[fieldName] || fieldName;
            const fieldValue = row[actualFieldName] || "";

            if (typeof fieldValue === "string") {
              const cleanedValue = removeEmojisAndSpecialChars(
                fieldValue.replace(/\n/g, " ")
              );
              rowData[fieldName] = cleanedValue;

              // Collect text fields for batch translation
              if (fieldName === "text" && userLanguage !== "original") {
                textsToTranslate.push({
                  index: textsToTranslate.length,
                  originalText: cleanedValue,
                  rowIndex: bookmarks.indexOf(row),
                });
              }
            } else {
              rowData[fieldName] = fieldValue;
            }
          }
        });

        return rowData;
      });

      // Translate all texts in a single batch if needed
      if (textsToTranslate.length > 0 && userLanguage !== "original") {
        const originalTexts = textsToTranslate.map((item) => item.originalText);
        const translatedTexts = await translateCSVTexts(
          originalTexts,
          userLanguage
        );

        // Apply translations back to the data
        textsToTranslate.forEach((item, idx) => {
          const translatedText = translatedTexts[idx] || item.originalText;
          const decodedValue = decodeURIComponent(
            encodeURIComponent(translatedText)
          );
          bookmarkData[item.rowIndex].text =
            removeEmojisAndSpecialChars(decodedValue);
        });
      }

      // Generate CSV rows
      const csvRows = bookmarkData.map((rowData) => {
        return header.map((field) => rowData[field] || "").join("+");
      });

      // Add header and create CSV string
      csvRows.unshift(header.join("+"));
      const csvString = csvRows.join("\r\n");

      // Create and download the CSV file
      const blob = new Blob(["\uFEFF" + csvString], {
        type: "text/csv;charset=utf-8;",
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "bookmarked_posts.csv";
      a.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);

      closeModal();
    } catch (error) {
      console.error("Error generating CSV:", error);
      toast.error("Failed to generate CSV file.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ExportButton
        as={ExportButton}
        onClick={openModal}
        title={translate("Export Bookmarks as CSV")}
      >
        <i className="material-icons">upload_file</i>
      </ExportButton>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Export Options"
        closeTimeoutMS={300}
      >
        <StyledModalContent theme={theme}>
          <h2>{translate("Export Bookmarks")}</h2>

          <LanguageSelect theme={theme}>
            <label htmlFor="language-select">
              {translate("Select language for export:")}
            </label>
            <select
              id="language-select"
              value={userLanguage}
              onChange={handleLanguageChange}
              disabled={loading}
            >
              <option value="original">Original Language</option>
              <option value="en">English (United States)</option>
              <option value="fr">French (France)</option>
              <option value="es">Spanish (Spain)</option>
              <option value="ar">Arabic</option>
              <option value="de">German</option>
              <option value="pt">Portuguese</option>
              <option value="ru">Russian</option>
            </select>
          </LanguageSelect>

          <ButtonContainer>
            <CancelButton onClick={closeModal} theme={theme} disabled={loading}>
              {translate("Cancel")}
            </CancelButton>
            <FinalExportButton onClick={export2csv} disabled={loading}>
              {loading ? "Processing..." : "Export CSV"}
            </FinalExportButton>
          </ButtonContainer>

          {loading && (
            <LoadingIndicator theme={theme}>
              <div className="spinner"></div>
              <span>{translate("Preparing your export file...")}</span>
            </LoadingIndicator>
          )}
        </StyledModalContent>
      </Modal>
    </>
  );
};

export default ExportCsv;
