import { getAuth, webApi } from "../../../global/globalUtils";

export const createNewColumn = async (columnData, translate) => {
  try {
    const response = await fetch(`${webApi}/column/create`, {
      method: "POST",
      body: columnData,
      credentials: "include",
    });

    const data = await response.json();

    if (!response.ok) {
      return {
        success: false,
        message: data.message || translate("Failed to create column"),
        statusCode: response.status,
      };
    }

    return {
      success: true,
      message: data.message || translate("Column created successfully"),
      column: data.column,
    };
  } catch (error) {
    console.error("Error in createNewColumn:", error);
    return {
      success: false,
      message: "Network error occurred while creating column",
    };
  }
};

export const getNextColumnPosition = async () => {
  try {
    const response = await getAuth("/column/");

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    const columns = data.data;
    const maxPosition = Math.max(
      ...columns.map((column) => column.column_position),
      0
    );
    return maxPosition + 1;
  } catch (error) {
    console.error("Error fetching columns:", error);
    return null;
  }
};

export const validateIconFile = (file) => {
  // Check if file exists
  if (!file) {
    return {
      valid: false,
      error: "No file selected",
    };
  }

  // Check file type
  const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  if (!allowedTypes.includes(file.type)) {
    return {
      valid: false,
      error: "Invalid file type. Only JPEG, PNG, and GIF are allowed.",
    };
  }

  // Check file size (max 2MB)
  const maxSize = 2 * 1024 * 1024;
  if (file.size > maxSize) {
    return {
      valid: false,
      error: "File size exceeds maximum allowed (2MB).",
    };
  }

  return { valid: true };
};
