import React, { useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import { playSound, audioFiles } from "../utils/playSound";
import { useCustomStyles } from "../utils/CustomStyles";
import { useTranslation } from "../../translate/TranslateContext";

// Styled Components
const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SectionHeader = styled.h3`
  color: ${({ theme }) => theme.text};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
  margin-top: 8px;
`;

const ToggleContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 8px;
  margin-top: 12px;
`;

const ToggleOption = styled.div`
  position: relative;
`;

const ToggleInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;

  &:focus + label {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.primary || "#2684FF"};
  }

  &:checked + label {
    background: ${({ theme }) => theme.primary || "#2684FF"};
    color: white;
    border-color: ${({ theme }) => theme.primary || "#2684FF"};
  }
`;

const ToggleLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  padding: 8px 16px;
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.border_color || "#d1d5db"};
  background: ${({ theme, checked }) =>
    checked ? theme.primary || "#2684FF" : "transparent"};
  color: ${({ theme, checked }) => (checked ? "white" : theme.text)};
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme, checked }) =>
      checked
        ? theme.primary || "#2684FF"
        : theme.hover_background || "#f3f4f6"};
  }
`;

const SoundSelectorContainer = styled.div`
  margin-top: 4px;
  position: relative;
  z-index: 10; // Ensure dropdown appears above other elements
`;
const AnimatedContainer = styled.div`
  max-height: ${({ show }) => (show ? "200px" : "0")};
  opacity: ${({ show }) => (show ? "1" : "0")};
  overflow: ${({ show }) =>
    show
      ? "visible"
      : "hidden"}; // Change from always hidden to visible when shown
  transition: all 0.3s ease;
  margin-top: ${({ show }) => (show ? "12px" : "0")};
  position: relative; // Add positioning context
`;

const PlayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.border_color || "#d1d5db"};
  border-radius: 4px;
  margin-top: 8px;
  color: ${({ theme }) => theme.text};
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) => theme.hover_background || "#f3f4f6"};
  }

  svg {
    margin-inline-end: 6px;
  }
`;

// Sound icon SVG component
const SoundIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
    <path d="M15.54 8.46a5 5 0 0 1 0 7.07"></path>
    <path d="M19.07 4.93a10 10 0 0 1 0 14.14"></path>
  </svg>
);

const Notifications = ({
  onNotificationChange,
  onNotificationSoundChange,
  column,
}) => {
  const [notificationEnabled, setNotificationEnabled] = useState(
    column && column.notifications == 1
  );
  const [notificationSound, setNotificationSound] = useState(
    column ? column.isLoudSound : "Off"
  );

  const { translate } = useTranslation();

  const handleNotificationChange = (event) => {
    onNotificationChange(event);
    setNotificationEnabled(event.target.value === "On");
  };

  const handleNotificationSoundChange = (selectedOption) => {
    const event = { target: { value: selectedOption.value } };
    onNotificationSoundChange(event);
    setNotificationSound(selectedOption.value);
    playSound(selectedOption.value);
  };

  const handlePlaySound = () => {
    if (notificationSound !== "Off") {
      playSound(notificationSound);
    }
  };

  const soundOptions = [
    { value: "Off", label: "Off" },
    ...Object.keys(audioFiles).map((key) => ({
      value: key,
      label: key.charAt(0).toUpperCase() + key.slice(1),
    })),
  ];

  const baseCustomStyles = useCustomStyles();

  // Add dropdown menu height control to prevent overflow issues
  const customStyles = {
    ...baseCustomStyles,
    menu: (provided) => ({
      ...provided,
      maxHeight: "140px", // Limit dropdown height
      maxWidth: "160px", // Limit dropdown width
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "140px", // Ensure menu list also has max height
      overflowY: "auto", // Enable vertical scrolling
    }),
  };

  return (
    <NotificationContainer>
      <div>
        <SectionHeader>{translate("Notifications")}</SectionHeader>
        <ToggleContainer>
          <ToggleOption>
            <ToggleInput
              type="radio"
              id="notification-on"
              name="notification"
              value="On"
              checked={notificationEnabled === true}
              onChange={handleNotificationChange}
            />
            <ToggleLabel
              htmlFor="notification-on"
              checked={notificationEnabled === true}
            >
              {translate("On")}
            </ToggleLabel>
          </ToggleOption>

          <ToggleOption>
            <ToggleInput
              type="radio"
              id="notification-off"
              name="notification"
              value="Off"
              checked={notificationEnabled === false}
              onChange={handleNotificationChange}
            />
            <ToggleLabel
              htmlFor="notification-off"
              checked={notificationEnabled === false}
            >
              {translate("Off")}
            </ToggleLabel>
          </ToggleOption>
        </ToggleContainer>
      </div>

      <AnimatedContainer show={notificationEnabled}>
        <SectionHeader>Notification Sound</SectionHeader>
        <SoundSelectorContainer>
          <Select
            styles={customStyles}
            value={soundOptions.find(
              (option) => option.value === notificationSound
            )}
            onChange={handleNotificationSoundChange}
            options={soundOptions}
            isSearchable
          />

          {notificationSound !== "Off" && (
            <PlayButton onClick={handlePlaySound}>
              <SoundIcon /> {translate("Test Sound")}
            </PlayButton>
          )}
        </SoundSelectorContainer>
      </AnimatedContainer>
    </NotificationContainer>
  );
};

export default Notifications;
