import { postAuth } from "../../../global/globalUtils";

export const changePassword = async (currentPassword, newPassword) => {
  try {
    const response = await postAuth("/auth/change-password", {
      newPassword,
      oldPassword: currentPassword,
    });

    const data = await response.json();

    // Check if response is not ok (status code not in 200 range)
    if (!response.ok) {
      return {
        success: false,
        message: data.message || "Failed to change password",
      };
    }

    return { success: true, message: data.message };
  } catch (error) {
    return {
      success: false,
      message: "Network error occurred while changing password",
    };
  }
};

export const changeForgottedPassword = async (newPassword, token) => {
  try {
    const response = await postAuth("/auth/reset-password", {
      newPassword,
      token,
    });

    const data = await response.json();

    // Check if response is not ok (status code not in 200 range)
    if (!response.ok) {
      return {
        success: false,
        message: data.message || "Failed to change password",
      };
    }

    return { success: true, message: data.message };
  } catch (error) {
    return {
      success: false,
      message: "Network error occurred while changing password",
    };
  }
};

