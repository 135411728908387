import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded"; // Import the Article icon
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import logo from "../../../assets/images/teledeck.png";
import whiteLogo from "../../../assets/images/Teledeck.svg";
import { useTheme } from "../../themes/ThemeContext";
import { useTranslation } from "../../translate/TranslateContext";

export default function Content() {
  const { theme } = useTheme();
  const { translate } = useTranslation();

  const items = [
    {
      icon: <ArticleRoundedIcon sx={{ color: "text.secondary" }} />, // Use the Article icon
      title: translate("The Ultimate News Monitoring Platform"),
      description: translate(
        "Discover Teledeck, an exciting news monitoring platform complete with a live map and a news auditor."
      ),
    },
    {
      icon: <LocalOfferRoundedIcon sx={{ color: "text.secondary" }} />,
      title: translate("Explore Our Bundles"),
      description: (
        <>
          {translate("Whether you're an individual or part of an organization")}{" "}
          <a
            href="https://teledeck.news/pricing"
            target="_blank"
            rel="noopener noreferrer"
          >
            {translate("View Bundles")}
          </a>
        </>
      ),
    },
    {
      icon: <PersonAddRoundedIcon sx={{ color: "text.secondary" }} />,
      title: translate("Don't have an account?"),
      description: translate(
        "Subscribe as a solo user or as part of an organization"
      ),
    },
    {
      icon: <ThumbUpAltRoundedIcon sx={{ color: "text.secondary" }} />,
      title: translate("Great User Experience"),
      description: translate("Integrate our product into your routine"),
    },
  ];

  return (
    <Stack
      sx={{
        flexDirection: "column",
        alignSelf: "center",
        gap: 4,
        maxWidth: 450,
      }}
    >
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <img
          src={theme === "light" ? logo : whiteLogo}
          alt="Teledeck"
          style={{ width: "250px" }}
        />
      </Box>
      {items.map((item, index) => (
        <Stack key={index} direction="row" sx={{ gap: 2 }}>
          {item.icon}
          <div>
            <Typography gutterBottom sx={{ fontWeight: "medium" }}>
              {item.title}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {item.description}
            </Typography>
          </div>
        </Stack>
      ))}
    </Stack>
  );
}
