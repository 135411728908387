import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useBookmarks } from "../../../bookmarks/bookmark_context";
import { CloseButton } from "../../../column_popups/PostSharePopup";
import { useTheme } from "../../../themes/ThemeContext";

// Improved dark overlay
const Overlay = styled.div`
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Enhanced modal container with better aesthetics
const ModalContainer = styled.div`
  background-color: ${({ theme }) =>
    theme === "dark" ? "#1e1e1e" : "#ffffff"};
  color: ${({ theme }) => (theme === "dark" ? "#e0e0e0" : "#333333")};
  padding: 28px;
  border-radius: 12px;
  width: 400px;
  max-width: 90vw;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1000;
  transform: translateY(0);
  opacity: 1;
  animation: modalFadeIn 0.3s ease;

  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

// Improved title styling
const DeleteTitle = styled.h3`
  margin: 0 0 20px 0;
  padding: 0;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => (theme === "dark" ? "#ffffff" : "#333333")};
`;

// Enhanced close button
const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  top: 16px;
  inset-inline-end: 16px;
  background: ${({ theme }) =>
    theme === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.05)"};
  color: ${({ theme }) => (theme === "dark" ? "#e0e0e0" : "#555555")};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  border: none;
  transition: all 0.2s ease;

  &:hover {
    background: ${({ theme }) =>
      theme === "dark" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.1)"};
    transform: scale(1.05);
  }
`;

// Better category list with more spacing and visual hierarchy
const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 45vh;
  padding: 4px;
  margin-bottom: 20px;

  /* Customize scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => (theme === "dark" ? "#2a2a2a" : "#f0f0f0")};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => (theme === "dark" ? "#555555" : "#c0c0c0")};
    border-radius: 10px;
  }
`;

// Improved category item
const CategoryItem = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) =>
      theme === "dark" ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.03)"};
  }
`;

// Styled custom checkbox
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const StyledCheckbox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: ${(props) =>
    props.checked
      ? props.theme === "dark"
        ? "#6a9fff"
        : "#3a80ff"
      : props.theme === "dark"
      ? "#444444"
      : "#e0e0e0"};
  border-radius: 4px;
  transition: all 0.2s;
  border: 1px solid
    ${(props) =>
      props.checked
        ? props.theme === "dark"
          ? "#6a9fff"
          : "#3a80ff"
        : props.theme === "dark"
        ? "#555555"
        : "#d0d0d0"};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px
      ${(props) =>
        props.theme === "dark"
          ? "rgba(106, 159, 255, 0.2)"
          : "rgba(58, 128, 255, 0.15)"};
  }

  &:hover {
    background: ${(props) =>
      props.checked
        ? props.theme === "dark"
          ? "#7aafff"
          : "#4a90ff"
        : props.theme === "dark"
        ? "#555555"
        : "#d0d0d0"};
  }
`;

const CheckIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  visibility: ${(props) => (props.checked ? "visible" : "hidden")};
`;

// Category text
const CategoryText = styled.span`
  flex: 1;
  font-size: 15px;
  color: ${({ theme }) => (theme === "dark" ? "#e0e0e0" : "#444444")};
`;

// Button container
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

// Enhanced delete button
const DeleteButton = styled.button`
  background-color: ${(props) => (props.disabled ? "#989898" : "#ff4d4d")};
  color: white;
  border: none;
  padding: 12px 28px;
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-weight: 600;
  font-size: 16px;
  box-shadow: ${(props) =>
    props.disabled ? "none" : "0 4px 12px rgba(255, 77, 77, 0.3)"};
  transition: all 0.3s ease;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};

  &:hover:not(:disabled) {
    background-color: #e03c3c;
    transform: translateY(-2px);
  }

  &:active:not(:disabled) {
    transform: translateY(1px);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 77, 77, 0.3);
  }
`;

// Counter badge for selected items
const SelectionCounter = styled.div`
  background-color: ${({ theme }) =>
    theme === "dark" ? "#444444" : "#f0f0f0"};
  color: ${({ theme }) => (theme === "dark" ? "#e0e0e0" : "#555555")};
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 12px;
  position: absolute;
  top: 28px;
  inset-inline-start: 28px;
  display: flex;
  align-items: center;

  span {
    font-weight: 600;
    margin-inline-start: 4px;
  }
`;

const DeleteCategoryModal = ({ onClose }) => {
  const {
    bookmarks,
    categories,
    deleteContextCategories,
    unbookmarkAllPosts,
    unbookmarkUncategorizedPosts,
  } = useBookmarks();
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [selectUncategorized, setSelectUncategorized] = useState(false);
  const { theme } = useTheme();

  // Check if there are any uncategorized bookmarks
  const hasUncategorizedBookmarks = useMemo(() => {
    return bookmarks.some((bookmark) => bookmark.bookmark_category === null);
  }, [bookmarks]);

  const handleSelectCategory = (categoryId) => {
    setSelectedCategoryIds((prev) =>
      prev.includes(categoryId)
        ? prev.filter((id) => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  const handleSelectAll = () => {
    if (
      selectedCategoryIds.length === categories.length &&
      (selectUncategorized || !hasUncategorizedBookmarks)
    ) {
      // Deselect everything
      setSelectedCategoryIds([]);
      setSelectUncategorized(false);
    } else {
      // Select all category IDs
      setSelectedCategoryIds(categories.map((category) => category.id));
      // Only select uncategorized if there are uncategorized bookmarks
      if (hasUncategorizedBookmarks) {
        setSelectUncategorized(true);
      }
    }
  };

  const handleDelete = () => {
    if (
      selectUncategorized &&
      selectedCategoryIds.length === categories.length
    ) {
      // Delete all bookmarks (including uncategorized)
      unbookmarkAllPosts();
    } else if (selectUncategorized && selectedCategoryIds.length > 0) {
      // Delete both selected categories and uncategorized bookmarks
      deleteContextCategories(selectedCategoryIds);
      unbookmarkUncategorizedPosts();
    } else if (selectUncategorized) {
      // Delete only uncategorized bookmarks
      unbookmarkUncategorizedPosts();
    } else {
      // Delete only selected categories
      deleteContextCategories(selectedCategoryIds);
    }
    onClose();
  };

  // Calculate total items selected
  const totalSelected =
    selectedCategoryIds.length + (selectUncategorized ? 1 : 0);

  // Custom checkbox component for better styling
  const Checkbox = ({ checked, onChange, theme }) => (
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} onChange={onChange} />
      <StyledCheckbox checked={checked} theme={theme}>
        <CheckIcon viewBox="0 0 24 24" checked={checked}>
          <polyline points="20 6 9 17 4 12" />
        </CheckIcon>
      </StyledCheckbox>
    </CheckboxContainer>
  );

  // Adjust criteria for "Select All" to be checked
  const isAllSelected =
    selectedCategoryIds.length === categories.length &&
    (!hasUncategorizedBookmarks || selectUncategorized);

  return (
    <>
      <Overlay onClick={onClose}>
        <ModalContainer onClick={(e) => e.stopPropagation()} theme={theme}>
          <DeleteTitle theme={theme}>Delete Categories</DeleteTitle>
          <StyledCloseButton onClick={onClose} theme={theme}>
            ×
          </StyledCloseButton>

          {totalSelected > 0 && (
            <SelectionCounter theme={theme}>
              Selected: <span>{totalSelected}</span>
            </SelectionCounter>
          )}

          <CategoryList theme={theme}>
            {categories?.map((category) => (
              <CategoryItem key={category.id} theme={theme}>
                <Checkbox
                  checked={selectedCategoryIds.includes(category.id)}
                  onChange={() => handleSelectCategory(category.id)}
                  theme={theme}
                />
                <CategoryText theme={theme}>{category.name}</CategoryText>
              </CategoryItem>
            ))}

            {/* Only show uncategorized option if there are uncategorized bookmarks */}
            {hasUncategorizedBookmarks && (
              <CategoryItem theme={theme}>
                <Checkbox
                  checked={selectUncategorized}
                  onChange={() => setSelectUncategorized(!selectUncategorized)}
                  theme={theme}
                />
                <CategoryText theme={theme}>
                  Uncategorized Bookmarks
                </CategoryText>
              </CategoryItem>
            )}

            {/* Divider before Select All option */}
            <div
              style={{
                height: 1,
                background:
                  theme === "dark"
                    ? "rgba(255,255,255,0.1)"
                    : "rgba(0,0,0,0.07)",
                margin: "4px 0",
              }}
            />

            <CategoryItem theme={theme}>
              <Checkbox
                checked={isAllSelected}
                onChange={handleSelectAll}
                theme={theme}
              />
              <CategoryText theme={theme}>Select All</CategoryText>
            </CategoryItem>
          </CategoryList>

          <ButtonContainer>
            <DeleteButton
              onClick={handleDelete}
              disabled={
                selectedCategoryIds.length === 0 && !selectUncategorized
              }
            >
              Delete Selected
            </DeleteButton>
          </ButtonContainer>
        </ModalContainer>
      </Overlay>
    </>
  );
};

export default DeleteCategoryModal;
