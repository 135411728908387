import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import SidePanelTabs from "./Tabs";
import { useTranslation } from "../../translate/TranslateContext";

const ToggleButton = styled.button`
  position: fixed;
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  inset-inline-end: ${(props) => (props.$show ? "430px" : "-8px")};
  top: 8px;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 60px;
  transition: right 0.3s ease; /* Add animation */
  z-index: 1000;

  @media (max-width: 768px) {
    inset-inline-end: ${(props) => (props.$show ? "65%" : "-8px")};
  }

  span {
    margin-inline-start: 8px;
    color: #333;
    font-size: 16px;
    color: ${({ theme }) => theme.text};
  }
`;

const iconAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(180deg); }
`;

const Icon = styled.span`
  font-size: 30px !important;
  margin-inline-end: 15px;
  transition: transform 0.3s ease;
  animation: ${iconAnimation} 0.3s forwards;
`;

const SidePanelButton = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const { direction } = useTranslation();

  const toggleSidebar = () => {
    setShowSidebar((prev) => !prev);
  };

  return (
    <div>
      <ToggleButton $show={showSidebar} onClick={toggleSidebar}>
        <Icon className="material-symbols-outlined">
          {direction === "ltr"
            ? showSidebar
              ? "right_panel_open"
              : "right_panel_close"
            : showSidebar
            ? "right_panel_close"
            : "right_panel_open"}
        </Icon>
      </ToggleButton>
      <SidePanelTabs show={showSidebar} />
    </div>
  );
};

export default SidePanelButton;
