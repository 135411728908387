import React, { createContext, useContext, useState, useEffect } from "react";
import { postAuth } from "../../../global/globalUtils";
import { syncBookmarksFromServer } from "../../bookmarks/bookmark_local_db";

const BookmarkSyncContext = createContext({
  bookmarksSynced: false,
  isSyncing: false,
  syncBookmarks: () => Promise.resolve(false),
});

const defaultAuthState = {
  isAuthenticated: false,
  email_verified: false,
  isLoading: true,
  role: "user",
  bundle: "",
  userId: "",
  tokenAttemptedRefresh: false,
};

const AuthContext = createContext({
  ...defaultAuthState,
  setAuth: () => {},
});
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(defaultAuthState);
  const [bookmarksSynced, setBookmarksSynced] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);

  const updateAuth = (newAuth) => {
    setAuth((prev) => ({ ...prev, ...newAuth }));
  };

  const syncBookmarks = async () => {
    try {
      setIsSyncing(true);
      const success = await syncBookmarksFromServer();
      setBookmarksSynced(success);
      return success;
    } catch (error) {
      console.error("Failed to sync bookmarks:", error);
      return false;
    } finally {
      setIsSyncing(false);
    }
  };

  const handleAuthResponse = (response, data) => {
    if (response.ok) {
      switch (data.status) {
        case "unverified":
          updateAuth({
            isAuthenticated: false,
            email_verified: false,
            isLoading: false,
          });
          break;
        case "success":
          updateAuth({
            isAuthenticated: true,
            isLoading: false,
            email_verified: data.email_verified,
            role: data.role,
            userId: data.userId,
            bundle: data.bundle,
          });
          syncBookmarks();

          break;
        case "completed":
          updateAuth({
            isAuthenticated: true,
            isLoading: false,
            email_verified: data.email_verified,
            tokenAttemptedRefresh: false, // Reset on successful refresh
            role: data.role,
            userId: data.userId,
            bundle: data.bundle,
          });
          syncBookmarks();

          break;
        default:
          return false;
      }
      return true;
    } else {
      if (!auth.tokenAttemptedRefresh) {
        updateAuth({ tokenAttemptedRefresh: true }); // Prevent infinite loop
      }
      return false;
    }
  };

  const verifyToken = async () => {
    try {
      const response = await postAuth("/auth/validate-token", {});
      const data = await response.json();
      const authSuccess = handleAuthResponse(response, data);

      if (!authSuccess) {
        updateAuth({ isAuthenticated: false, isLoading: false });
      }
    } catch (error) {
      console.error("Request failed, Error during token validation:", error);
      updateAuth({ isAuthenticated: false, isLoading: false });
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <AuthContext.Provider value={{ ...auth, setAuth: updateAuth }}>
      <BookmarkSyncContext.Provider
        value={{ bookmarksSynced, isSyncing, syncBookmarks }}
      >
        {children}
      </BookmarkSyncContext.Provider>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export const useBookmarkSync = () => useContext(BookmarkSyncContext);
