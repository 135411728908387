import React, { useEffect, useState } from "react";
import {
  Button as MuiButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import {
  fetchSubscriptions,
  updateSubscription,
  deactivateSubscription,
  formatDateForInput,
} from "./utils";
import FilterBar from "./FilterBar";
import { formatDate } from "../Users/UsersUtils";
import { fetchBundles } from "../../../../components/bundles/utils";
import { useTranslation } from "../../../../components/translate/TranslateContext";

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  & > button {
    margin: 0 5px;
  }

  .page-info {
    margin: 0 10px;
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Table = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [filters, setFilters] = useState({});
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editSubscription, setEditSubscription] = useState({
    id: "",
    bundle_id: "",
    user_id: "",
    organization_id: "",
    type: "",
    start_date: "",
    end_date: "",
    is_active: null,
  });

  const { translate } = useTranslation();

  useEffect(() => {
    fetchSubscriptions(
      filters,
      setSubscriptions,
      setLoading,
      setCount,
      currentPage,
      itemsPerPage
    );
    fetchBundles(setBundles);
  }, [filters, currentPage, itemsPerPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  const handleEditClick = (subscription) => {
    setEditSubscription({
      id: subscription.id,
      bundle_id: subscription.Bundle.id,
      type: subscription.type,
      user_id: subscription.User?.id,
      organization_id: subscription.Organization?.id,
      start_date: subscription.start_date,
      end_date: subscription.end_date,
      is_active: subscription.is_active,
    });
    setEditModalOpen(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditSubscription((prevOrg) => ({
      ...prevOrg,
      [name]: value,
    }));
  };

  const handleEditSubmit = async () => {
    await updateSubscription(editSubscription);
    setEditModalOpen(false);
    fetchSubscriptions(
      filters,
      setSubscriptions,
      setLoading,
      setCount,
      currentPage,
      itemsPerPage
    );
  };

  return (
    <section className="content-area-table">
      <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>{translate("Edit Subscriptions")}</DialogTitle>
        <DialogContent>
          <InputLabel id="bundle-label" shrink>
            {translate("Select Bundle")}
          </InputLabel>
          <Select
            labelId="bundle-label"
            name="bundle_id"
            value={editSubscription.bundle_id}
            onChange={handleEditChange}
            fullWidth
            displayEmpty
            label="Select Bundle"
          >
            <MenuItem value={null}>
              <em>{translate("None")}</em>
            </MenuItem>
            <MenuItem value={1}>
              <em>{translate("Free Trial")}</em>
            </MenuItem>
            {bundles.map((bundle) => (
              <MenuItem key={bundle.id} value={bundle.id}>
                {bundle.display_name}
              </MenuItem>
            ))}
          </Select>

          <TextField
            margin="dense"
            label={translate("Start Date")}
            type="datetime-local"
            fullWidth
            value={formatDateForInput(editSubscription.start_date)}
            onChange={handleEditChange}
            name="start_date"
          />

          <TextField
            margin="dense"
            label={translate("End Date")}
            type="datetime-local"
            fullWidth
            value={formatDateForInput(editSubscription.end_date)}
            onChange={handleEditChange}
            name="end_date"
          />

          <InputLabel id="status-label" shrink>
            {translate("Status")}
          </InputLabel>
          <Select
            labelId="status-label"
            name={translate("is_active")}
            value={editSubscription.is_active}
            onChange={handleEditChange}
            fullWidth
            displayEmpty
            label={translate("Status")}
          >
            <MenuItem value={null}>
              <em>{translate("None")}</em>
            </MenuItem>
            <MenuItem value={1}>{translate("Active")}</MenuItem>
            <MenuItem value={0}>{translate("Inactive")}</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={() => setEditModalOpen(false)} color="secondary">
            {translate("Cancel")}
          </MuiButton>
          <MuiButton onClick={handleEditSubmit} color="primary">
            {translate("Save")}
          </MuiButton>
        </DialogActions>
      </Dialog>

      <FilterBar
        filters={filters}
        setFilters={setFilters}
        setLoading={setLoading}
        bundles={bundles}
      />
      <FlexContainer>
        {/* <p style={{ marginBottom: "20px" }}>
          Note: If a user is N/A it means it's an Organizational subscription,
          if Organization is N/A then it's a solo user subscription
        </p> */}
        <p style={{ marginBottom: "20px" }}>Number Of Subscriptions: {count}</p>
      </FlexContainer>

      <div className="data-table-diagram">
        {loading ? (
          <p>{translate("Loading...")}</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>{translate("Account")}</th>
                  <th>{translate("Type")}</th>
                  <th>{translate("Bundle")}</th>
                  <th>{translate("Start Date")}</th>
                  <th>{translate("End Date")}</th>
                  <th>{translate("Status")}</th>
                  <th>{translate("Created At")}</th>
                  <th>{translate("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.length > 0 ? (
                  subscriptions.map((item) => (
                    <tr key={item.id}>
                      <td>
                        {item.type === "individual"
                          ? item.User?.email || "N/A"
                          : item.Organization?.name || "N/A"}
                      </td>
                      <td>{item.type || "N/A"}</td>
                      <td>{item.Bundle.display_name || "N/A"}</td>
                      <td>{formatDate(item.start_date)}</td>
                      <td>{formatDate(item.end_date)}</td>
                      <td>
                        {item.is_active
                          ? translate("Active")
                          : translate("Inactive")}
                      </td>

                      <td>{formatDate(item.created_at)}</td>
                      <td>
                        <ButtonContainer>
                          <MuiButton
                            onClick={() => handleEditClick(item)}
                            variant="contained"
                            sx={{
                              backgroundColor: "#ffc107", // Yellow color for "Edit"
                              color: "#000",
                              "&:hover": {
                                backgroundColor: "#e0a800",
                              },
                            }}
                          >
                            {translate("Edit")}
                          </MuiButton>
                          <MuiButton
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure you want to deactivate this subscription?"
                                )
                              ) {
                                deactivateSubscription(
                                  item.id,
                                  subscriptions,
                                  setSubscriptions
                                );
                              }
                            }}
                            variant="contained"
                            sx={{
                              backgroundColor: "red",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#cc0000",
                              },
                            }}
                            startIcon={<DeleteIcon />}
                          >
                            {translate("Deactivate")}
                          </MuiButton>
                        </ButtonContainer>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">{translate("No subscriptions found")}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <PaginationControls>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                {translate("First")}
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                {translate("Previous")}
              </MuiButton>
              <span className="page-info">
                {translate("Page")} {currentPage} {translate("of")} {totalPages}
              </span>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                {translate("Next")}
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                {translate("Last")}
              </MuiButton>
            </PaginationControls>
          </>
        )}
      </div>
    </section>
  );
};

export default Table;
