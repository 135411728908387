import React, { useContext, useEffect, useRef, useState } from "react";
import { EventSourceContext } from "../sse-context/EventSourceContext";
import Post from "../post/Post";
import TelegramPopup from "../post/widgets/TelegramWidget";
import TwitterPopup from "../post/widgets/TwitterWidget";
import FacebookPopup from "../post/widgets/FacebookWidget";
import { website } from "../../global/globalUtils";
import { showToast, translateTexts } from "./column-utils/ColumnUtils";
import EditPopup from "../column_popups/EditColumnPopup";
import { getPostsFromApi } from "../post/utils";
import { useSearch } from "../side-panel/tabs/global-search/SearchContext";
import {
  Column,
  ColumnBody,
  LoadButton,
  LoadingContainer,
  NoPostsStatment,
} from "./column-components/ColumnStyles";
import ColumnHead from "./column-components/ColumnHeader";
import { useTranslationLoading } from "./TranslationLoadingContext";
import Spinner from "../../global/loaders/Spinner";
import { formatDateForAPI, getCurrentUTC2Time } from "../../global/dateUtils";
import { useTranslation } from "../translate/TranslateContext";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const DraggableColumn = ({ static_column, index }) => {
  const { subscribe, unsubscribe } = useContext(EventSourceContext);
  const { globalSearch, globalDate, globalColumns, globalIsExactMatch } =
    useSearch();

  // this is the state for the posts
  const [localMessages, setLocalMessages] = useState([]);

  // this is the state for the column
  const [column, setColumn] = useState(static_column);
  // reference for the column body
  const columnRef = useRef();

  // this is the state for the search input and its debounced value
  const [inputValue, setInputValue] = useState(() => {
    return localStorage.getItem(`inputValue-${column.id}`) || "";
  });
  const [debouncedValue, setDebouncedValue] = useState("");

  // state for opening the edit popup
  const [isEditPopupVisible, setEditPopupVisible] = useState(false);

  // these are states for the post popup for telegram widget
  const [isOpen, setIsOpen] = useState(false);
  const [currentsender, setCurrentSender] = useState("");
  const [currentId, setCurrentPost] = useState("");
  const [currentTweetId, setCurrentTweetId] = useState("");
  const [currentSource, setCurrentSource] = useState("");
  const [postUrl, setPostUrl] = useState("");

  // states for pagination
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState(false);
  const [loadingTop, setLoadingTop] = useState(false);
  const [loadingBottom, setLoadingBottom] = useState(false);

  const [forceUpdate, setForceUpdate] = useState(false);

  // Date input state
  const [dateValue, setDateValue] = useState("");
  const [isLoadBeforeDate, setIsLoadBeforeDate] = useState(true);

  // state for sending the correct date to the API
  const [firstInteractionTime, setFirstInteractionTime] = useState(
    getCurrentUTC2Time()
  );

  const [language, setLanguage] = useState("original");
  const [translatedMessages, setTranslatedMessages] = useState(new Map());
  const { setTranslationLoading } = useTranslationLoading();

  const [selectedSource, setSelectedSource] = useState("");

  const { translate } = useTranslation();

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: column.id });

  const apiCallVersion = useRef(0);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "grab",
  };

  const handleSourceClick = (source) => {
    setSelectedSource((prevSelectedSource) => {
      // If the clicked source is already selected, deactivate it
      if (prevSelectedSource === source) {
        return null;
      }
      // Otherwise, select the clicked source exclusively
      return source;
    });
  };

  // fetch column data
  useEffect(() => {
    const fetchColumn = async () => {
      try {
        setColumn(static_column);
      } catch (error) {
        console.error("Failed to fetch column:", error);
      }
    };

    fetchColumn();
  }, [static_column]);

  // handler function for the edit popup
  const handlePopupEdit = async (column2, type) => {
    // Set the state of the clicked chip and reset others
    const updatedColumn = {
      ...column2,
      channels: column2.channels,
      get_text_only: 0,
      get_image: 0,
      get_video: 0,
      get_preview: 0,
    };
    // Toggle the state of the clicked chip
    updatedColumn[type] = column2[type] ? 0 : 1;
    try {
      setColumn(updatedColumn);

      setForceUpdate((f) => !f); // Toggle forceUpdate to trigger re-render
    } catch (error) {
      console.error("Failed to update column:", error);
    }
  };

  // handle post popup for telegram widget
  const handleOpenPopup = (
    sender_username,
    post_id,
    source,
    tweetId,
    post_url
  ) => {
    setCurrentSender(sender_username);
    setCurrentPost(post_id);
    setCurrentSource(source);
    // review if facebook need
    if (source === "Twitter") {
      setCurrentTweetId(tweetId);
    }
    if (source === "Facebook") {
      setPostUrl(post_url);
    }
    setIsOpen(true);
  };

  // Date functions
  const handleDateChange = (event) => {
    setIsLoadBeforeDate(true);
    const selectedDateTime = event.target.value;
    setDateValue(selectedDateTime);
    hasMounted.current = false;
    setLanguage("original");
  };

  const updateTimestamp = () => {
    setIsLoadBeforeDate(true);
    setFirstInteractionTime(getCurrentUTC2Time());
  };
  const hasMounted = useRef(false);

  const fetchPosts = async () => {
    // const currentVersion = apiCallVersion.current; //
    setLoading(true);
    try {
      let searchTerm = debouncedValue;
      // Handle global search
      if (
        globalSearch &&
        (globalColumns?.length === 0 || globalColumns.includes(column.id))
      ) {
        searchTerm = globalSearch;
      }

      // Determine which date to use and format it properly
      let dateToSend;
      if (
        globalDate &&
        (globalColumns?.length === 0 || globalColumns.includes(column.id))
      ) {
        dateToSend = formatDateForAPI(globalDate);
      } else if (dateValue) {
        dateToSend = formatDateForAPI(dateValue);
      } else {
        dateToSend = firstInteractionTime;
      }

      const columnExactMatch = globalIsExactMatch.get(column.id);

      const posts = await getPostsFromApi({
        column: column,
        search: searchTerm,
        page: 1,
        limit: 10,
        date: dateToSend,
        isLoadBeforeDate: isLoadBeforeDate,
        source: selectedSource,
        exactSearchMatch: columnExactMatch ?? true,
      });

      // if (currentVersion === apiCallVersion.current && posts.data) {
      //   setLocalMessages(posts.data);
      //   setPage(posts.page + 1); // Update page after fetching
      //   setTotalPages(posts.totalPages || 1);
      // }
      if (posts.data) {
        setLocalMessages(posts.data);
        setPage(2);
        setTotalPages(posts.totalPages || 1);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoading(false);
      hasMounted.current = true;
    }
  };

  useEffect(() => {
    // Use the consistent UTC+2 time format from our utility function
    setFirstInteractionTime(getCurrentUTC2Time());
  }, []); // Only set it once when component mounts, not on every column change

  // handle debouncing for the search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
      hasMounted.current = false;
      setLanguage("original");
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  // fetch posts from the database
  useEffect(() => {
    // Modify guard clause
    const hasActiveFilter = globalSearch || debouncedValue || dateValue;
    const isGlobalSearchCleared = globalSearch === "";
    if (!hasActiveFilter && !isGlobalSearchCleared && localMessages.length > 0)
      return;
    //
    //
    apiCallVersion.current += 1;

    const debounceTimer = setTimeout(() => {
      fetchPosts();
    }, 300); // Small delay to prevent rapid refetching

    return () => clearTimeout(debounceTimer);
  }, [
    debouncedValue,
    globalSearch,
    globalColumns,
    column.id,
    globalDate,
    dateValue,
    firstInteractionTime,
    selectedSource,
    // globalIsExactMatch.get(column.id),
    forceUpdate,
    isLoadBeforeDate,
  ]);

  // load more posts for pagination
  const loadMorePosts = async (isLoadBeforeDate) => {
    if (page >= totalPages || loading || loadingTop || loadingBottom) return;

    if (isLoadBeforeDate) {
      setLoadingBottom(true);
    } else {
      setLoadingTop(true);
    }

    setIsLoadBeforeDate(isLoadBeforeDate);
    const nextPage = page + 1;

    let dateToSend = firstInteractionTime; // Default date
    if (
      globalDate &&
      (globalColumns?.length === 0 || globalColumns.includes(column.id))
    ) {
      dateToSend = formatDateForAPI(globalDate);
    } else if (dateValue) {
      dateToSend = formatDateForAPI(dateValue);
    }

    const columnExactMatch = globalIsExactMatch.get(column.id);

    const result = await getPostsFromApi({
      column: column,
      search: debouncedValue || inputValue,
      page: nextPage,
      limit: 10,
      date: dateToSend,
      isLoadBeforeDate: isLoadBeforeDate,
      source: selectedSource,
      exactSearchMatch: columnExactMatch ?? true,
    });

    if (result.data && result.data?.length > 0) {
      setLocalMessages((prevMessages) =>
        isLoadBeforeDate
          ? [...prevMessages, ...result.data]
          : [...result.data, ...prevMessages]
      );
      setPage(nextPage);
      setTotalPages(result.totalPages || totalPages);
    }

    setLoadingTop(false);
    setLoadingBottom(false);
  };

  // handle live messages
  useEffect(() => {
    // console.log("subscribing to column", column.id);
    const filterMessage = (message, channel) => {
      // console.log("subscribing to channel", channel);
      // console.log("subscribing to message", message);
      // Text filtering: Use globalSearch first, then debouncedValue
      const searchTerm = globalSearch || debouncedValue;
      if (searchTerm && (!message.text || !message.text.includes(searchTerm))) {
        return false;
      }

      // Filter for image, video, or preview if required
      if (column.get_image && !message.has_image) return false;
      if (column.get_preview === 1 && !message.has_preview) return false;
      if (column.get_video && !message.has_video) return false;

      // Exclude messages with media if "text only" is enabled
      if (
        column.get_text_only &&
        (message.has_image || message.has_video || message.has_preview)
      ) {
        return false;
      }

      if (selectedSource && selectedSource !== message.source_name)
        return false;

      // Pass all filters
      return true;
    };

    const handleMessage = (newMessage) => {
      // Ignore messages if a specific date is set
      if (dateValue) return;

      // Check if the message belongs to one of the column's channels
      const matchedChannel = column.channels.find(
        (channel) => channel.id === newMessage.sender_id
      );

      if (matchedChannel && filterMessage(newMessage, matchedChannel)) {
        // Enrich the message with channel details
        newMessage.chat_title = matchedChannel.chat_title;
        newMessage.sender_username = matchedChannel.sender_username;
        newMessage.image = `${website}${matchedChannel.image}`;
        newMessage.profile_picture_url = matchedChannel.profile_picture_url;
        newMessage.isApi = true;
        newMessage.id = newMessage.sender_id + "" + newMessage.post_id;

        // Add the message to the top of the list
        setLocalMessages((prevMessages) => [newMessage, ...prevMessages]);

        // Show a notification if enabled
        if (column.notifications) {
          showToast(
            column.name,
            index,
            newMessage.text,
            matchedChannel.sender_username,
            column.isLoudSound,
            newMessage.id,
            column.id
          );
        }
      }
    };

    subscribe(handleMessage);
    return () => unsubscribe(handleMessage);
  }, [
    subscribe,
    unsubscribe,
    column,
    dateValue,
    globalSearch,
    debouncedValue,
    globalColumns,
    index,
  ]);

  const translatedMessagesRef = useRef(new Map());

  useEffect(() => {
    if (!hasMounted.current) {
      return;
    }
    const translateMessages = async () => {
      if (language === "original") {
        fetchPosts();
        return;
      }
      // Identify new messages that need translation
      const newMessages = localMessages;

      if (newMessages?.length > 0) {
        const textsToTranslate = newMessages.map((message) => message.text);

        const loadingStates = {};
        newMessages.forEach((msg) => {
          loadingStates[msg.id] = true; // Set loading true for each new message
        });
        setTranslationLoading((prev) => ({ ...prev, ...loadingStates }));

        const translations = await translateTexts(
          textsToTranslate,
          language,
          setTranslationLoading
        );

        if (translations) {
          // ✅ Store translations in ref to prevent re-renders
          newMessages.forEach((msg, index) => {
            translatedMessagesRef.current.set(
              msg.id,
              translations[index] || msg.text
            );
          });

          // ✅ Update localMessages only once at the end
          setLocalMessages((prevMessages) =>
            prevMessages.map((msg) => ({
              ...msg,
              text: translatedMessagesRef.current.get(msg.id) || msg.text,
            }))
          );

          // ✅ Update loading states after translation is done
          setTranslationLoading((prev) => {
            const newState = { ...prev };
            newMessages.forEach((msg) => {
              newState[msg.id] = false;
            });
            return newState;
          });
        }
      }
    };

    translateMessages();
  }, [language, setTranslationLoading]);

  const handleTranslation = (postId, translatedText) => {
    setTranslatedMessages((prev) => new Map(prev).set(postId, translatedText));
  };

  return (
    <Column
      key={column.id}
      ref={setNodeRef}
      id={`column-${column.name}-${index}`}
      style={style}
      {...listeners}
      {...attributes}
    >
      <ColumnHead
        column={column}
        setColumn={setColumn}
        inputValue={inputValue}
        setInputValue={setInputValue}
        setEditPopupVisible={() => setEditPopupVisible(!isEditPopupVisible)}
        handleDateChange={handleDateChange}
        dateValue={dateValue}
        updateTimestamp={updateTimestamp}
        setForceUpdate={setForceUpdate}
        setLanguage={setLanguage}
        language={language}
        selectedSource={selectedSource}
        handleSourceClick={handleSourceClick}
      />
      <ColumnBody ref={columnRef}>
        {loading && (
          <LoadingContainer $fullHeight={true}>
            <Spinner loading={loading} size={46} />
          </LoadingContainer>
        )}
        {loadingTop && !loading && (
          <LoadingContainer>
            <Spinner loading={loadingTop} />{" "}
          </LoadingContainer>
        )}
        {dateValue && !loading && !loadingTop && (
          <LoadButton
            onClick={() => {
              loadMorePosts(false);
            }}
          >
            {translate("Load More")}
          </LoadButton>
        )}
        {localMessages.length === 0 &&
        !loading &&
        !loadingTop &&
        !loadingBottom ? (
          <NoPostsStatment>
            {translate("No posts found for the specified criteria")}
          </NoPostsStatment>
        ) : (
          localMessages.map((msg, index) => (
            <div key={index} data-msg-index={index}>
              <Post
                column={column}
                post={msg}
                onOpenPopup={handleOpenPopup}
                inputValue={debouncedValue}
                translatedText={translatedMessages.get(msg.id)}
                onTranslate={handleTranslation}
              />
            </div>
          ))
        )}
        {isOpen &&
          (currentSource === "Telegram" ? (
            <TelegramPopup
              senderusername={currentsender}
              post_id={currentId}
              onClose={() => setIsOpen(false)}
            />
          ) : currentSource === "Facebook" ? (
            <FacebookPopup postUrl={postUrl} onClose={() => setIsOpen(false)} />
          ) : (
            <TwitterPopup
              tweetId={currentTweetId}
              onClose={() => setIsOpen(false)}
            />
          ))}
        {loadingBottom && !loading && (
          <LoadingContainer>
            <Spinner loading={loadingBottom} />
          </LoadingContainer>
        )}
        {page < totalPages && !loading && !loadingBottom && (
          <LoadButton
            onClick={() => {
              loadMorePosts(true);
            }}
          >
            {translate("Load More")}
          </LoadButton>
        )}
      </ColumnBody>

      {isEditPopupVisible && (
        <EditPopup
          column={column}
          onClose={() => setEditPopupVisible(!isEditPopupVisible)}
          onSave={handlePopupEdit}
        />
      )}
    </Column>
  );
};

export default DraggableColumn;
