import { toast } from "sonner";
import { deleteAuth, getAuth, postAuth } from "../../../../global/globalUtils";

export const fetchData = async (
  setUsers,
  setOrgDetails,
  setCount,
  setLoading,
  currentPage,
  itemsPerPage
) => {
  setLoading(true);
  try {
    const response = await getAuth(
      `/dashboard/organization/members?page=${currentPage}&limit=${itemsPerPage}`
    );
    const responseData = await response.json();
    setUsers(responseData.data);
    setCount(responseData.total);

    setOrgDetails({
      id: responseData.organization.id,
      name: responseData.organization.name,
      maxCapacity: responseData.organization.max_capacity,
      organizationCode: responseData.organization.code.code,
      codeExpiration: responseData.organization.code.code_expiry,
    });
  } catch (error) {
    console.error("Failed to fetch users:", error);
    toast.error("Failed to fetch users");
    setUsers([]);
  } finally {
    setLoading(false);
  }
};

export const regenerateCode = async (id, setOrgDetails, setLoading) => {
  try {
    setLoading(true);
    const response = await postAuth(`/dashboard/organization/regenerate-code`, {
      organization_id: id,
    });
    const data = await response.json();
    if (response.ok) {
      toast.success("Code regenerated successfully");
      setOrgDetails((prev) => ({
        ...prev,
        organizationCode: data.code.code,
        codeExpiration: data.code.code_expiry,
        regenerated_at: data.code.code_regenerated_at,
      }));
    }
  } catch (error) {
    toast.error("Failed to regenerate code");
    console.error("Failed to regenerate code:", error);
  } finally {
    setLoading(false);
  }
};

// Function to copy the organization code to clipboard
export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  toast.success("Code copied to clipboard!");
};

export const deleteUser = async (id, users, setUsers) => {
  try {
    const response = await deleteAuth(`/dashboard/user/${id}`);
    await response.json();
    if (response.ok) {
      toast.success("User deleted successfully");
      const updatedUsers = users.filter((user) => user.id !== id);
      setUsers(updatedUsers);
    }
  } catch (error) {
    console.error("Failed to delete user:", error);
    toast.error("Failed to delete user");
  }
};

export const deactivateUser = async (id, users, setUsers) => {
  try {
    const response = await postAuth(`/dashboard/user/deactivate/${id}`);
    await response.json();
    if (response.ok) {
      toast.success("User deactivated successfully");
      const updatedUsers = users.map((user) =>
        user.id === id ? { ...user, is_disabled: true } : user
      );
      setUsers(updatedUsers);
    }
  } catch (error) {
    console.error("Failed to deactivate user:", error);
  }
};

export const activateUser = async (id, users, setUsers) => {
  try {
    const response = await postAuth(`/dashboard/user/activate/${id}`);
    await response.json();
    if (response.ok) {
      console.log(response.data);
      toast.success("User activated successfully");
      const updatedUsers = users.map((user) =>
        user.id === id ? { ...user, is_disabled: false } : user
      );
      setUsers(updatedUsers);
    }
  } catch (error) {
    console.error("Failed to deactivate user:", error);
  }
};
