import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Popover,
  Typography,
  styled,
} from "@mui/material";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import { Link } from "react-router-dom";
import TableChartTwoToneIcon from "@mui/icons-material/TableChartTwoTone";
import { handleLogout } from "../../../../../components/authentication/logout/Logout";
import { useTranslation } from "../../../../translate/TranslateContext";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-inline-start: ${theme.spacing(1)};
        padding-inline-end: ${theme.spacing(0)};
        color: ${theme.colors.alpha.trueWhite[70]};

        &:hover {
          color: ${theme.colors.alpha.trueWhite[100]};
        }
          margin-inline-end: ${theme.spacing(2)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[90]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: start;
        padding-inline-start: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

function HeaderUserbox() {
  const { translate } = useTranslation();
  const user = {
    name: "Cog Developers",
    avatar: "/static/images/avatars/avatar_6.png",
    jobtitle: "Administrator",
  };

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      component="span"
      sx={{
        display: { xs: "none", sm: "inline-block" },
      }}
    >
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar alt={user.name} src={user.avatar} />
        <ExpandMoreTwoToneIcon
          fontSize="small"
          sx={{
            ml: 0.5,
          }}
        />
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210,
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        {/* <Button component={Link} to="/admin/users" color="primary" fullWidth>
          <TableChartTwoToneIcon
            sx={{
              mr: 1,
            }}
          />
          {"Tables"}
        </Button> */}
        <Divider
          sx={{
            mb: 0,
          }}
        />
        <Divider />
        <Box m={1}>
          <Button
            color="primary"
            fullWidth
            onClick={() => handleLogout(translate)}
          >
            <LockOpenTwoToneIcon
              sx={{
                mr: 1,
              }}
            />
            {"Logout"}
          </Button>
        </Box>
      </Popover>
    </Box>
  );
}

export default HeaderUserbox;
