import React, { useRef, useEffect } from "react";
import { useTheme } from "../../themes/ThemeContext";
import {
  Wrapper,
  TooltipContainer,
  Tip,
  CategoryList,
  Category,
  NoCategoriesMessage,
} from "./CategoryPopup";
import { languageOptions, translateText } from "../utils";
import { useTranslation } from "../../translate/TranslateContext";

const TranslatePopup = ({
  isOpen,
  postId,
  text,
  onTranslate,
  onClose,
  setLoading,
}) => {
  const { theme } = useTheme();
  const { direction } = useTranslation();
  const popupRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleLanguageClick = async (language) => {
    try {
      setLoading((prev) => ({
        ...prev,
        [postId]: true,
      }));
      const translatedText = await translateText(text, language, setLoading);
      onTranslate(language, translatedText);
      onClose();
    } catch (error) {
      console.error("Translation failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper ref={popupRef} $direction={direction}>
      <Tip theme={theme} />
      <TooltipContainer theme={theme} $isOpen={isOpen}>
        <CategoryList theme={theme}>
          {languageOptions.length > 0 ? (
            languageOptions.map((language, index) => (
              <Category
                key={index}
                theme={theme}
                onClick={() => handleLanguageClick(language.code)}
              >
                {language.name}
              </Category>
            ))
          ) : (
            <NoCategoriesMessage theme={theme}>
              No languages available
            </NoCategoriesMessage>
          )}
        </CategoryList>
      </TooltipContainer>
    </Wrapper>
  );
};

export default TranslatePopup;
