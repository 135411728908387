import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Paper,
} from "@mui/material";
import { formatDate, getTeamMembers } from "./utils";
import { useTranslation } from "../../../../components/translate/TranslateContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const ViewTeamPopup = ({ open, onClose, id }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [total, setTotal] = useState(0); // Track the total number of team members
  const [page, setPage] = useState(1); // Start from page 1
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const { translate, direction } = useTranslation();

  const theme = createTheme({
    direction: direction, // Enables RTL layout for table mui
  });

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); // MUI passes zero-based page, so we increment to keep 1-based indexing
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to page 1 when rows per page change
  };

  // Fetch team members when id, page, or rowsPerPage change
  useEffect(() => {
    if (id) {
      getTeamMembers(id, setTeamMembers, page, rowsPerPage, setTotal); // Pass 1-based page to backend
    }
  }, [id, page, rowsPerPage]);

  const handleClose = () => {
    setPage(1);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{translate("Team Members")}</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translate("Email")}</TableCell>
                <TableCell>{translate("Role")}</TableCell>
                <TableCell>{translate("Is Verified")}</TableCell>
                <TableCell>{translate("Created At")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamMembers.map((member) => (
                <TableRow key={member.id}>
                  <TableCell>{member.email || "N/A"}</TableCell>
                  <TableCell>{member.role || "N/A"}</TableCell>
                  <TableCell>
                    {formatDate(member.created_at) || "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination Controls */}
        <ThemeProvider theme={theme}>
          <TablePagination
            component="div"
            count={total}
            page={page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage={translate("Rows per page")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${translate("from")} ${count}`
            }
          />
        </ThemeProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {translate("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewTeamPopup;
