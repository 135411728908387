import React, { createContext, useEffect, useRef } from "react";
import { getAuth, webSse } from "../../global/globalUtils";
import { getAllChannelIds } from "../localdb/DBColumn";
import { toast } from "sonner";

export const EventSourceContext = createContext({
  subscribe: () => {},
  unsubscribe: () => {},
  reconnect: () => {},
});

export const EventSourceProvider = ({ children }) => {
  const eventSourceRef = useRef(null);
  const subscribers = useRef(new Set());

  // Function to fetch SSE token using the access token
  const fetchSseToken = async () => {
    try {
      const response = await getAuth("/auth/sse-token", {});
      const data = await response.json();
      connectEventSource(data.sseToken); // Connect with the new token
    } catch (error) {
      console.error("Failed to fetch SSE token with access token:", error);
    }
  };

  // Function to initialize or reconnect the EventSource with the new token
  const connectEventSource = async (token, channelIds = null) => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close(); // Close existing connection if open
    }

    try {
      // If channelIds not provided, fetch from IndexedDB
      const channels = channelIds || (await getAllChannelIds());

      // First initialize a session with the channels
      const sessionResponse = await fetch(`${webSse}/init?token=${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ channels: channels }),
      });

      if (!sessionResponse.ok) {
        toast.error("Failed to initialize session, refresh the page");
      }

      const { sessionId } = await sessionResponse.json();

      // Create EventSource with session ID
      const eventSource = new EventSource(
        `${webSse}/events?sessionId=${sessionId}&token=${token}`
      );

      eventSource.onmessage = function (event) {
        const newMessage = JSON.parse(event.data);

        // Handle connected event
        if (newMessage.type === "connected") {
          // console.log("Connected to channels");
          return;
        }

        // Check if newMessage is null or undefined
        if (newMessage == null) {
          // This covers both null and undefined
          console.warn(
            "Received null or undefined message, refreshing the page..."
          );
          window.location.reload(); // Force a refresh of the page
        } else {
          subscribers.current.forEach((handler) => handler(newMessage));
        }
      };

      eventSource.onerror = function (err) {
        console.error("EventSource failed:", err);
        eventSource.close();
        // Reconnect with a new token after a delay
        setTimeout(fetchSseToken, 5000);
      };

      eventSourceRef.current = eventSource;
    } catch (error) {
      console.error("Failed to fetch channel IDs:", error);
      // Fallback to connection without channels
      const eventSource = new EventSource(`${webSse}/events?token=${token}`);

      // Set up the same event handlers for the fallback connection
      eventSource.onmessage = function (event) {
        const newMessage = JSON.parse(event.data);

        if (newMessage == null) {
          console.warn(
            "Received null or undefined message, refreshing the page..."
          );
          window.location.reload();
        } else {
          subscribers.current.forEach((handler) => handler(newMessage));
        }
      };

      eventSource.onerror = function (err) {
        console.error("EventSource failed:", err);
        eventSource.close();
        setTimeout(fetchSseToken, 5000);
      };

      eventSourceRef.current = eventSource;
    }
  };

  // New reconnect function
  const reconnect = async () => {
    try {
      // Get fresh SSE token
      const response = await getAuth("/auth/sse-token", {});
      const { sseToken } = await response.json();

      // Get latest channel IDs
      const channelIds = await getAllChannelIds();

      // Reconnect with new channels
      await connectEventSource(sseToken, channelIds);
    } catch (error) {
      console.error("Failed to reconnect SSE:", error);
      toast.error("Failed to reconnect to real-time updates");
    }
  };

  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  const subscribe = (handler) => {
    subscribers.current.add(handler);
  };

  const unsubscribe = (handler) => {
    subscribers.current.delete(handler);
  };

  return (
    <EventSourceContext.Provider value={{ subscribe, unsubscribe, reconnect }}>
      {children}
    </EventSourceContext.Provider>
  );
};
