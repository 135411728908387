import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import MuiCard from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import AppTheme from "../shared-theme/AppTheme";
import { CircularProgress, Typography } from "@mui/material";
import { toast } from "sonner";
import { useTheme } from "../../themes/ThemeContext";
import logo from "../../../assets/images/teledeck.png";
import whiteLogo from "../../../assets/images/Teledeck.svg";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { changePassword } from "./utils";
import { useAuth } from "../auth-context/AuthContext";
import LanguageSwitcher from "../../settings/tabs/components/LanguageSwitcher";
import styledComponents from "styled-components";
import { useTranslation } from "../../translate/TranslateContext";

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "100%",
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: "auto",
  boxShadow:
    "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  ...theme.applyStyles("dark", {
    boxShadow:
      "hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px",
  }),
}));

const SignUpContainer = styled(Stack)(({ theme }) => ({
  height: "calc((1 - var(--template-frame-height, 0)) * 100dvh)",
  minHeight: "100%",
  padding: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(4),
  },
  "&::before": {
    content: '""',
    display: "block",
    position: "absolute",
    zIndex: -1,
    inset: 0,
    backgroundImage:
      "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
    backgroundRepeat: "no-repeat",
    ...theme.applyStyles("dark", {
      backgroundImage:
        "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))",
    }),
  },
}));

const LanguageContainer = styledComponents.div`
  position: absolute;
  inset-inline-start: 10px;
  top: 10px;
`;

export default function ChangePassword(props) {
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [errors, setErrors] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { translate, direction } = useTranslation();
  const { setAuth } = useAuth();

  const passwordRequirements = {
    minLength: (password) => password.length >= 6,
    hasUpperCase: (password) => /[A-Z]/.test(password),
    hasLowerCase: (password) => /[a-z]/.test(password),
    hasNumber: (password) => /\d/.test(password),
    hasSpecial: (password) => /[!@#$%^&*(),.?":{}|<>]/.test(password),
  };

  const validateInputs = () => {
    let isValid = true;
    const newErrors = {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    };

    if (!currentPassword) {
      newErrors.currentPassword = translate("Current password is required");
      isValid = false;
    }

    if (!newPassword) {
      newErrors.newPassword = translate("New password is required");
      isValid = false;
    } else {
      // Check password requirements
      const failedRequirements = [];
      if (!passwordRequirements.minLength(newPassword))
        failedRequirements.push(translate("at least 6 characters"));
      if (!passwordRequirements.hasUpperCase(newPassword))
        failedRequirements.push(translate("an uppercase letter"));
      if (!passwordRequirements.hasLowerCase(newPassword))
        failedRequirements.push(translate("a lowercase letter"));
      if (!passwordRequirements.hasNumber(newPassword))
        failedRequirements.push(translate("a number"));

      if (failedRequirements.length > 0) {
        newErrors.newPassword = `${translate(
          "Password must contain"
        )} ${failedRequirements.join(", ")}`;
        isValid = false;
      }
    }

    if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = translate("Passwords do not match");
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateInputs()) return;

    try {
      setLoading(true);
      const result = await changePassword(currentPassword, newPassword);

      if (result.success) {
        toast.success(translate(translate("Password changed successfully")));
        setAuth((prevState) => ({
          ...prevState,
          isAuthenticated: false,
        }));
        window.location.href = "/login";
      } else {
        // Now the error message from the API will be shown
        toast.error(result.message);
      }
    } catch (error) {
      // This should now rarely occur - only for unexpected errors
      console.error("An unexpected error occurred:", error);
      toast.error(
        translate("An unexpected error occurred while changing password")
      );
    } finally {
      setLoading(false);
    }
  };

  // Add these state variables at the top of your component:
  const [passwordVisibility, setPasswordVisibility] = React.useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [passwordStrength, setPasswordStrength] = React.useState({
    hasMinLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
  });

  // Add this function to check password requirements
  const checkPasswordStrength = (password) => {
    setPasswordStrength({
      hasMinLength: password.length >= 6,
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
    });
  };

  return (
    <AppTheme {...props}>
      <LanguageContainer>
        <LanguageSwitcher />
      </LanguageContainer>
      <CssBaseline enableColorScheme />
      <Stack
        direction={{ xs: "column-reverse", md: "row" }}
        sx={{
          justifyContent: "center",
          gap: { xs: 6, sm: 12 },
          p: { xs: 2, sm: 4, md: 0 },
          m: "auto",
        }}
      >
        <SignUpContainer direction="column" justifyContent="space-between">
          <Card variant="outlined">
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <img
                src={theme === "light" ? logo : whiteLogo}
                alt="Teledeck"
                style={{ width: "250px", alignSelf: "center" }}
              />

              <Typography variant="h6" align="center">
                {translate("Change Password")}
              </Typography>

              <FormControl>
                <FormLabel htmlFor="currentPassword">
                  {translate("Current Password")}
                </FormLabel>
                <TextField
                  value={currentPassword}
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                    setErrors({ ...errors, currentPassword: "" });
                  }}
                  required
                  fullWidth
                  id="currentPassword"
                  name="currentPassword"
                  type={
                    passwordVisibility.currentPassword ? "text" : "password"
                  }
                  variant="outlined"
                  error={!!errors.currentPassword}
                  helperText={errors.currentPassword}
                  sx={{
                    ...(direction === "rtl" && {
                      "& .MuiInputBase-root": {
                        padding: 0, // Removes padding inside the input field when in RTL
                      },
                    }),
                    "& .MuiFormHelperText-root": {
                      textAlign: "start", // Ensures the helper text is aligned to the right for RTL
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          setPasswordVisibility({
                            ...passwordVisibility,
                            currentPassword:
                              !passwordVisibility.currentPassword,
                          })
                        }
                        edge="end"
                      >
                        {passwordVisibility.currentPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="newPassword">
                  {translate("New Password")}
                </FormLabel>
                <TextField
                  value={newPassword}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setNewPassword(newValue);
                    setErrors({ ...errors, newPassword: "" });
                    checkPasswordStrength(newValue);
                  }}
                  required
                  fullWidth
                  id="newPassword"
                  name="newPassword"
                  type={passwordVisibility.newPassword ? "text" : "password"}
                  variant="outlined"
                  error={!!errors.newPassword}
                  helperText={errors.newPassword}
                  sx={{
                    ...(direction === "rtl" && {
                      "& .MuiInputBase-root": {
                        padding: 0, // Removes padding inside the input field when in RTL
                      },
                    }),
                    "& .MuiFormHelperText-root": {
                      textAlign: "start", // Ensures the helper text is aligned to the right for RTL
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          setPasswordVisibility({
                            ...passwordVisibility,
                            newPassword: !passwordVisibility.newPassword,
                          })
                        }
                        edge="end"
                      >
                        {passwordVisibility.newPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="confirmPassword">
                  {translate("Confirm New Password")}
                </FormLabel>
                <TextField
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setErrors({ ...errors, confirmPassword: "" });
                  }}
                  required
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  type={
                    passwordVisibility.confirmPassword ? "text" : "password"
                  }
                  variant="outlined"
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  sx={{
                    ...(direction === "rtl" && {
                      "& .MuiInputBase-root": {
                        padding: 0, // Removes padding inside the input field when in RTL
                      },
                    }),
                    "& .MuiFormHelperText-root": {
                      textAlign: "start", // Ensures the helper text is aligned to the right for RTL
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() =>
                          setPasswordVisibility({
                            ...passwordVisibility,
                            confirmPassword:
                              !passwordVisibility.confirmPassword,
                          })
                        }
                        edge="end"
                      >
                        {passwordVisibility.confirmPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </FormControl>

              <Box
                sx={{
                  mt: 2,
                  bgcolor: "background.paper",
                  p: 2,
                  borderRadius: 1,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mb: 1, color: "text.secondary" }}
                >
                  {translate("Password Requirements")}
                </Typography>
                <Stack spacing={1}>
                  {[
                    [
                      {
                        label: translate("At least 6 characters"),
                        check: passwordStrength.hasMinLength,
                      },
                      {
                        label: translate("One uppercase letter"),
                        check: passwordStrength.hasUpperCase,
                      },
                    ],
                    [
                      {
                        label: translate("One lowercase letter"),
                        check: passwordStrength.hasLowerCase,
                      },
                      {
                        label: translate("One number"),
                        check: passwordStrength.hasNumber,
                      },
                    ],
                  ].map((row, rowIndex) => (
                    <Stack
                      key={rowIndex}
                      direction="row"
                      spacing={2}
                      sx={{ justifyContent: "flex-start" }}
                    >
                      {row.map((requirement, colIndex) => (
                        <Stack
                          key={`${rowIndex}-${colIndex}`}
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          sx={{ minWidth: "180px" }}
                        >
                          {requirement.check ? (
                            <CheckCircleOutlineIcon
                              sx={{
                                fontSize: 16,
                                color: "success.main",
                              }}
                            />
                          ) : (
                            <RadioButtonUncheckedIcon
                              sx={{
                                fontSize: 16,
                                color: "text.disabled",
                              }}
                            />
                          )}
                          <Typography
                            variant="body2"
                            sx={{
                              color: requirement.check
                                ? "success.main"
                                : "text.secondary",
                              transition: "color 0.2s",
                            }}
                          >
                            {requirement.label}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  ))}
                </Stack>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  mt: 2,
                }}
              >
                <Button
                  type="button"
                  variant="text"
                  onClick={() => navigate("/")}
                >
                  {translate("Back")}
                </Button>{" "}
                <Button
                  type="submit"
                  disabled={loading}
                  variant="contained"
                  sx={{
                    backgroundColor: "rgb(30, 40, 54)",
                    minWidth: "150px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      backgroundColor: "rgb(20, 30, 44)",
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress size={20} sx={{ color: "white" }} />
                  ) : (
                    translate("Change Password")
                  )}
                </Button>
              </Box>
            </Box>
          </Card>
        </SignUpContainer>
      </Stack>
    </AppTheme>
  );
}
